import * as React from 'react';
import ReactMapboxGl, { Marker } from 'react-mapbox-gl';
import { makeStyles } from '@material-ui/styles';
import { withProps } from 'recompose';
import { get } from 'lodash';

import { useOverlay } from '@dpdgroupuk/fmx-ui/components/Overlay';

import location from '../../../../../assets/images/location.png';
import ArrowBadge from './ArrowBadge';
import { calculateMapFitBounds, getSelectedPickupShopIndex } from '../utils';

const { REACT_APP_MAPBOX_API_TOKEN } = process.env;

const Mapbox = ReactMapboxGl({
  accessToken: REACT_APP_MAPBOX_API_TOKEN,
  attributionControl: false,
});

const useStyles = makeStyles(({ typography }) => ({
  shopBadge: {
    width: typography.pxToRem(44),
    height: typography.pxToRem(49),

    '&:hover': {
      cursor: 'pointer',
    },
  },
  housePin: {
    height: typography.pxToRem(49),
  },
}));

type MapProps = {
  pickupShops: any,
  parcelDestination: Object,
  containerStyle?: Object,
  onMarkerClick: Function,
  selectedPickupShopCode?: string,
  fitBounds: any,
  mapRef: any,
  pickupShopSelectionChanged: boolean,
  selectedPickupShop: any,
};

type SelectedShopMarkerProps = {
  pickupShop: any,
  onMarkerClick: Function,
  index: Number,
};

const SelectedShopMarker = ({
  pickupShop,
  onMarkerClick,
  index,
}: SelectedShopMarkerProps) => {
  const {
    pickupLocation: {
      pickupLocationCode,
      addressPoint: { latitude, longitude },
    },
  } = pickupShop;
  const styles = useStyles();

  return (
    <Marker
      key={pickupLocationCode}
      coordinates={[longitude, latitude]}
      anchor="bottom"
      onClick={onMarkerClick(pickupLocationCode)}
    >
      <ArrowBadge className={styles.shopBadge} color="blue">
        {index + 1}
      </ArrowBadge>
    </Marker>
  );
};

const PickupShopsMap = ({
  pickupShops = [],
  parcelDestination,
  containerStyle,
  onMarkerClick,
  fitBounds,
  selectedPickupShopCode,
  mapRef,
  pickupShopSelectionChanged,
  selectedPickupShop,
}: MapProps) => {
  const styles = useStyles();
  const { show, hide } = useOverlay();
  const [bounds, setBounds] = React.useState(fitBounds);

  const padding =
    window.innerWidth > 768
      ? { top: 106, bottom: 80, left: 60, right: 60 }
      : { top: 86, bottom: 60, left: 40, right: 40 };

  React.useEffect(() => {
    if (!pickupShopSelectionChanged) {
      const first5Shops = pickupShops.slice(0, 5);
      setBounds(
        calculateMapFitBounds({
          parcelDestination,
          pickupShops: [...first5Shops],
        })
      );
    } else {
      setBounds(fitBounds);
    }
  }, [fitBounds, parcelDestination, pickupShopSelectionChanged, pickupShops]);

  const selectedPickupShopIndex = getSelectedPickupShopIndex({
    pickupShops,
    selectedShopCode: selectedPickupShopCode,
  });

  React.useEffect(() => {
    show();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Mapbox
      style="mapbox://styles/mapbox/streets-v11" // eslint-disable-line react/style-prop-object
      containerStyle={{
        width: '100vw',
        height: '100%',
        position: 'relative',
        background: 'rgb(26, 29, 33)',
        ...containerStyle,
      }}
      fitBounds={bounds}
      fitBoundsOptions={{
        padding,
      }}
      ref={mapRef}
      onStyleLoad={() => {
        hide();
      }}
    >
      <Marker
        coordinates={[parcelDestination.longitude, parcelDestination.latitude]}
        anchor="bottom"
      >
        <img
          src={location}
          alt="Parcel destination"
          className={styles.housePin}
        />
      </Marker>
      {pickupShops.map(
        (
          {
            pickupLocation: {
              pickupLocationCode,
              addressPoint: { latitude, longitude },
            },
          },
          index
        ) => {
          if (pickupLocationCode === selectedPickupShopCode) {
            return null;
          }

          return (
            <Marker
              key={pickupLocationCode}
              coordinates={[longitude, latitude]}
              anchor="bottom"
              onClick={onMarkerClick(pickupLocationCode)}
            >
              <ArrowBadge className={styles.shopBadge} color="red">
                {index + 1}
              </ArrowBadge>
            </Marker>
          );
        }
      )}
      <SelectedShopMarker
        pickupShop={selectedPickupShop}
        index={selectedPickupShopIndex}
        onMarkerClick={onMarkerClick}
      />
    </Mapbox>
  );
};

export default withProps(({ parcelDestination, selectedPickupShop }: any) => ({
  fitBounds: calculateMapFitBounds({
    parcelDestination,
    pickupShops: [selectedPickupShop],
  }),
  selectedPickupShopCode: get(
    selectedPickupShop,
    'pickupLocation.pickupLocationCode'
  ),
}))(PickupShopsMap);
