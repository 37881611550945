import { compose } from 'redux';
import { withProps } from 'recompose';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { getReasonPhrase } from 'http-status-codes';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import ErrorScreen from '@dpdgroupuk/fmx-ui/components/ErrorScreen';
import NotFound from '@dpdgroupuk/fmx-ui/components/Icons/NotFound';

import { ERROR, HOME } from '../../constants/error';
import { getBackgroundImage } from '../../utils/images';
import { goToReferenceEntry } from '../historyActions';
import { getStatusMessage } from '../../utils/errorHandling';

export default compose(
  withRouter,
  withProps(({ location }) => {
    const { status } = queryString.parse(location.search);
    return {
      status,
    };
  }),
  withPageControl({
    title: ({ status }) => {
      try {
        return getReasonPhrase(status);
      } catch (e) {
        return ERROR;
      }
    },
    backgroundImage: getBackgroundImage({
      desktopImage: IMAGES.DRIVER_BG,
      mobileImage: IMAGES.DRIVER_BG_MOBILE,
    }),
  }),
  withProps(props => ({
    title: getStatusMessage(props.status),
    buttonText: HOME,
    onClick: () => goToReferenceEntry(props.history),
    icon: NotFound,
  }))
)(ErrorScreen);
