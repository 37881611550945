// @flow
import { get } from 'lodash';
import { StatusCodes } from 'http-status-codes';
import React from 'react';
import NotAuthorised from './NotAuthorised';
import DefaultError from './DefaultError';
import TimeoutError from './TimeoutError';

type Props = {
  options?: Object,
  error: any,
};

const ErrorScreen = ({ options, error }: Props) => {
  const NotFoundComponent = get(options, 'notFoundComponent');

  switch (get(error, 'statusCode')) {
    case StatusCodes.REQUEST_TIMEOUT:
      return <TimeoutError />;
    case StatusCodes.NOT_FOUND:
      return NotFoundComponent ? <NotFoundComponent /> : <DefaultError />;
    case StatusCodes.FORBIDDEN:
      return <NotAuthorised />;
    default:
      return <DefaultError />;
  }
};

export default ErrorScreen;
