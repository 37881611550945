import { withProps } from 'recompose';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withTrackProps } from '@dpdgroupuk/react-event-tracker';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';
import DriverProfileComponent from '@dpdgroupuk/fmx-ui/components/DriverProfile';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';

import { DRIVER_PROFILE, trackable } from '../../constants/analytics';
import * as MESSAGES from '../../constants/message';
import {
  backToDpdSiteHeader,
  changeDeliverySpeedDeal,
  getDpdAppHeader,
  goBackToDetailsSpeedDeal,
  trackAnotherParcelHeader,
  whatHappensNextSpeedDeal,
  whatHappensNextHeader,
  changeDeliveryHeader,
  goBackToDetailsHeader,
} from '../controlActions';
import { goToTracking } from '../historyActions';
import { getParcelData } from '../../store/orm/Parcel/selectors';
import { getDriverData } from '../../store/orm/Driver/selectors';
import { fetchCacheableDriverRelatedData } from './actions';
import { getRouteData } from '../../store/orm/Route/selectors';
import { getRouteCode } from '../../models/parcel';
import { isElectric } from '../../models/driver';
import { getBackgroundImage } from '../../utils/images';
import withFetch from '../../HOCs/withFetch';
import withFunctionalityLog from '../../HOCs/withFunctionalityLog';

const createHeaderControlActions = props => [
  goBackToDetailsHeader(props),
  whatHappensNextHeader(props),
  changeDeliveryHeader(props),
  trackAnotherParcelHeader(props),
  backToDpdSiteHeader(props),
  getDpdAppHeader(props),
];

const createSpeedDealControlActions = props => [
  goBackToDetailsSpeedDeal(props),
  changeDeliverySpeedDeal(props),
  whatHappensNextSpeedDeal(props),
];

const mapStateToProps = (state, { match }) => {
  const { parcelId, driverId } = match.params;
  const parcel = getParcelData(state, parcelId);
  const routeCode = getRouteCode(parcel);
  const driver = getDriverData(state, driverId);

  return {
    driver,
    route: getRouteData(state, routeCode),
    isVehicleElectric: isElectric(driver.vehicleTypeCode),
    parcel,
    entityCode: parcel.parcelCode,
  };
};

const DriverProfile = compose(
  withRouter,
  withFetch(fetchCacheableDriverRelatedData),
  connect(mapStateToProps),
  withModal,
  withPageControl({
    title: MESSAGES.LEARN_ABOUT_YOUR_DRIVER,
    backgroundImage: getBackgroundImage({
      desktopImage: IMAGES.DRIVER_BG,
      mobileImage: IMAGES.DRIVER_BG_MOBILE,
    }),
    backgroundOpacity: 0.87,
    trackable: trackable(DRIVER_PROFILE),
    headerMenuActions: createHeaderControlActions,
    speedDealActions: createSpeedDealControlActions,
  }),
  withProps(props => ({
    goToTracking: () => goToTracking(props),
  })),
  withTrackProps({
    goToTracking: DRIVER_PROFILE.GO_TO_TRACKING_MAP,
  }),
  withFunctionalityLog('XFI3ze41lOVpi6naNbay')
)(DriverProfileComponent);

export default DriverProfile;
