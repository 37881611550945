import React from 'react';

import EmptyPageComponent from '../../../components/EmptyPageComponent';
import type { AuthorizedParcel } from '../../../types';

const NoNexSteps = ({ parcel }: { parcel: AuthorizedParcel }) => {
  return <EmptyPageComponent value={parcel.parcelStatusHtml} />;
};

export default NoNexSteps;
