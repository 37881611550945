import api from './api';

export const postPushToken = (parcelCode: string, token: string) =>
  api.post({
    path: '/messaging/token',
    body: {
      parcelCode,
      token,
    },
  });

export const deletePushToken = parcelCode =>
  api.delete({
    path: '/messaging/token',
    body: {
      parcelCode,
    },
  });
