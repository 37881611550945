export const PARCEL = '/parcels/:parcelId';
export const OPTION_CODE = '/:actionCode';
export const ROUTES = {
  DELIVERY_OPTIONS: `${PARCEL}/options`,
  CHANGE_DELIVERY: `${PARCEL}/options${OPTION_CODE}`,
  DRIVER_PROFILE: `${PARCEL}/drivers/:driverId`,
  EVENTS: `${PARCEL}/events`,
  TRACKING: PARCEL,
  RATE: `${PARCEL}/rate`,
  TRACKING_MAP: `${PARCEL}/mapInfo`,
  MAP_INFO: `${PARCEL}/mapInfo`,
  WHAT_HAPPENS_NEXT: `${PARCEL}/next`,
  REFERENCE_ENTRY: '/',
};
