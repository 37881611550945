import { formatDefaultDate } from '@dpdgroupuk/fmx-ui/utils/date';

import { changeDeliveryOption } from '../../../../store/orm/Parcel/actions';
import type { Address } from '../../../../components/SearchAddress/types';
import { getALAStreetString } from '../../../../models/address';
import type { ChangeDeliveryOptionOptions } from '../../../../apis';

type ChangeAlternativeAddress = {
  day: string,
  address: Address,
  contactName: string,
  contactEmail: string,
  contactPhone: string,
};

type ChangeDeliveryAlternativeAddressBody = {
  diaryDate?: string,
  deliveryCountryCode: string,
  deliveryPostcode: string,
  deliveryName: string,
  deliveryStreet: string,
  deliveryCity: string,
  contactName?: string,
  contactEmail?: string,
  contactPhone?: string,
};

export const changeAlternativeAddress = (
  parcelCode: string,
  {
    day,
    address,
    contactName,
    contactEmail,
    contactPhone,
  }: ChangeAlternativeAddress,
  options: ChangeDeliveryOptionOptions
) => (dispatch: Function) => {
  const data: ChangeDeliveryAlternativeAddressBody = {
    diaryDate: formatDefaultDate(day),
    deliveryCountryCode: address.country || 'GB',
    deliveryPostcode: address.postcode,
    deliveryName: address.organisation,
    deliveryStreet: getALAStreetString(address),
    deliveryCity: address.town,
    contactName,
    contactEmail,
    contactPhone,
  };
  return dispatch(changeDeliveryOption(parcelCode, data, options));
};
