import * as React from 'react';
import { compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';

import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import type { AuthorizedParcel, Parcel, Depot } from '@dpdgroupuk/fmx-ui/types';
import BlockDocument from '@dpdgroupuk/fmx-ui/components/BlockDocument';
import Utility from '@dpdgroupuk/fmx-ui/components/Icons/Utility';
import Identity from '@dpdgroupuk/fmx-ui/components/Icons/Identity';
import CollectionInfo from '@dpdgroupuk/fmx-ui/components/CollectionInfo';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes/constants';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { formatDayOfWeekDayMonth } from '@dpdgroupuk/fmx-ui/utils/date';

import { fetchParcelDepot } from '../actions';
import {
  COLLECT_FROM_DEPOT,
  trackable,
} from '../../../../../constants/analytics';
import { getDepotAddressString } from '../../../../../models/depot';
import { getDeliveryTimeStart } from '../../../../../models/parcel';
import TrackingStatus from '../../../../../components/TrackingStatus';
import withFetch from '../../../../../HOCs/withFetch';
import { withParcelInfo } from '../../../hocs';
import {
  backToDpdSiteHeader,
  backToDpdSiteSpeedDeal,
  getDpdAppHeader,
  goToParcelInfoHeader,
  goToParcelInfoSpeedDeal,
  showDepotMapHeader,
  showDepotMapSpeedDeal,
  trackAnotherParcelHeader,
} from '../../../../controlActions';
import { getBackgroundImage } from '../../../../../utils/images';
import { MORE_COOL_STUFF } from '../../../../../constants/message';

const useStyles = makeStyles(({ palette, breakpoints, typography }) => ({
  wrapper: {
    marginBottom: typography.pxToRem(18),
  },
  title: {
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(14.4),
      letterSpacing: 0.44,
      marginBottom: typography.pxToRem(9),
    },
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(16),
      letterSpacing: 0.57,
      marginBottom: typography.pxToRem(18),
    },
    textAlign: 'center',
    fontWeight: 400,
  },
  containerBlock: {
    height: typography.pxToRem(100),
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  plus: {
    [breakpoints.down('xs')]: {
      marginLeft: typography.pxToRem(5),
      marginRight: typography.pxToRem(5),
    },
    [breakpoints.up('sm')]: {
      marginLeft: typography.pxToRem(7),
      marginRight: typography.pxToRem(7),
    },
    fontSize: typography.pxToRem(24),
    color: palette.icon.default,
  },
  text: {
    fontSize: typography.pxToRem(15),
    letterSpacing: 0.17,
  },
  subtitle: {
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(10.8),
      lineHeight: 1.33,
    },
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(15),
      lineHeight: 1.53,
    },
    marginTop: typography.pxToRem(18),
    letterSpacing: 0.44,
    marginBottom: typography.pxToRem(35),
    fontWeight: 400,
    textAlign: 'center',
  },
  topContainer: {
    marginTop: typography.pxToRem(31),
  },
  icon: {
    '& *': {
      fill: palette.icon.blockIcon,
    },
    '& #RED_IMPORTANT': {
      fill: '#E30238',
    },
  },
  utility: {
    [breakpoints.down('xs')]: {
      height: typography.pxToRem(38.7),
      width: typography.pxToRem(38.7),
    },
    [breakpoints.up('sm')]: {
      height: typography.pxToRem(43),
      width: typography.pxToRem(43),
    },
    marginBottom: typography.pxToRem(9),
  },
  identity: {
    [breakpoints.down('xs')]: {
      height: typography.pxToRem(28.8),
      width: typography.pxToRem(43.3),
    },
    [breakpoints.up('sm')]: {
      height: typography.pxToRem(32),
      width: typography.pxToRem(48),
    },
    marginBottom: typography.pxToRem(5),
  },
  collectionDetailsContainer: {
    paddingLeft: typography.pxToRem(10),
    paddingRight: typography.pxToRem(10),
  },
}));

type Props = {
  parcel: Parcel & AuthorizedParcel,
  depot: Depot,
};

const CollectFromDepot = ({ parcel, depot }: Props) => {
  const styles = useStyles();
  const { collectFromDepotDiaryDate } = parcel;
  const collectionDate = formatDayOfWeekDayMonth(collectFromDepotDiaryDate);
  const timeStart = getDeliveryTimeStart(collectFromDepotDiaryDate);
  const depotAddress = getDepotAddressString(depot);

  return (
    <>
      <TrackingStatus status={parcel.parcelStatusHtml} />
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={styles.wrapper}
      >
        <Grid className={styles.topContainer}>
          <Typography className={styles.title}>
            To collect your parcel, you’ll need:
          </Typography>
        </Grid>
        <Grid container className={styles.containerBlock}>
          <BlockDocument
            title="Photo ID"
            subtitle="* Valid passport or driver’s license"
          >
            <Identity className={clsx(styles.icon, styles.identity)} />
          </BlockDocument>
          <AddIcon className={styles.plus} />
          <BlockDocument title="Utility Bill">
            <Utility className={clsx(styles.icon, styles.utility)} />
          </BlockDocument>
        </Grid>
        <Grid>
          <Typography className={styles.subtitle}>
            Without both of these items we can’t give you your parcel
          </Typography>
        </Grid>
        <Grid className={styles.collectionDetailsContainer}>
          <Typography className={styles.text}>
            Your parcel is ready for collection:
          </Typography>
          <CollectionInfo
            collectionDate={collectionDate}
            collectionTime={timeStart}
            depotAddress={depotAddress}
          />
        </Grid>
      </Grid>
    </>
  );
};
const createHeaderControlActions = props => [
  showDepotMapHeader(props),
  goToParcelInfoHeader(props),
  trackAnotherParcelHeader(props),
  backToDpdSiteHeader(props),
  getDpdAppHeader(props),
];

const createSpeedDealControlActions = props => [
  backToDpdSiteSpeedDeal(props),
  goToParcelInfoSpeedDeal(props),
  showDepotMapSpeedDeal(props),
];

export default compose(
  withRouter,
  withFetch(fetchParcelDepot, {
    dataPropMapper: 'depot',
  }),
  withModal,
  withPageControl({
    title: MORE_COOL_STUFF,
    backgroundImage: getBackgroundImage({
      desktopImage: IMAGES.COLLECT_FROM_DEPOT,
      mobileImage: IMAGES.COLLECT_FROM_DEPOT_MOBILE,
    }),
    backgroundOpacity: 0.8,
    trackable: trackable(COLLECT_FROM_DEPOT),
    headerMenuActions: createHeaderControlActions,
    speedDealActions: createSpeedDealControlActions,
    // isMobileMenuShevronVisible: true,
  }),
  withParcelInfo({ paddingLeft410: 1, paddingRight410: 1 })
)(CollectFromDepot);
