import moment from 'moment';
import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from '@dpdgroupuk/redux-action-creator';
import { apiService, fetchTimeoutHandler } from '../../../../services';
import type { ChangeDeliveryOptionOptions } from '../../../../apis';
import { DeliveryActionActions } from '../../../../store/orm/Action';

export const ACTIONS = createActionTypes('PARCEL', {
  SUBMIT_DUTY_PAYMENT: createAsyncActionTypes('SUBMIT_DUTY_PAYMENT'),
  IS_PARCEL_DUTY_PAID: createAsyncActionTypes('PARCEL_DUTY_PAID'),
  GET_CUSTOMS_DESCRIPTION: createAsyncActionTypes('GET_CUSTOMS_DESCRIPTION'),
});

export const submitDutyPayment = createAsyncAction(
  (
    parcelCode: string,
    data?: {
      chargesPaid: boolean,
      paymentNonce: string,
      paymentReference: string,
      total: string,
      contactEmail: string,
    },
    options: ChangeDeliveryOptionOptions
  ) => {
    const { chargesPaid, ...body } = data;

    return fetchTimeoutHandler(
      chargesPaid
        ? apiService.submitDutyPayment(parcelCode, body, options)
        : apiService.submitCancellationPayment(parcelCode, options)
    );
  },
  ACTIONS.SUBMIT_DUTY_PAYMENT
);

export const isParcelDutyPaid = createAsyncAction(async parcelCode => {
  return fetchTimeoutHandler(apiService.getParcelDuty(parcelCode)).then(
    ({ data }) => data
  );
}, ACTIONS.IS_PARCEL_DUTY_PAID);

export const fetchCustomsDescription = createAsyncAction(async parcel => {
  const { data } = await apiService.getCustomsDescription(
    parcel.parcelCode,
    parcel.consignmentNumber,
    moment(parcel.invoiceDate).format('DD/MM/YYYY')
  );

  return data;
}, ACTIONS.GET_CUSTOMS_DESCRIPTION);

export const fetchIsDutyPaidAndActions = parcel => dispatch =>
  Promise.all([
    dispatch(
      DeliveryActionActions.fetchDeliveryActionsByCode(parcel.parcelCode)
    ),
    dispatch(isParcelDutyPaid(parcel.parcelCode)),
    dispatch(fetchCustomsDescription(parcel)),
  ]).then(([actions, dutyPaid, customsDescription]) => ({
    actions,
    dutyPaid,
    customsDescription,
  }));
