import React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { getDeliveryActionsByDepotCode } from '../../store/orm/Action/selectors';

let ProtectedDeliveryOptionRoute = ({
  actionCode,
  deliveryActions,
  match,
  ...props
}: Object) => {
  if (
    deliveryActions &&
    deliveryActions.availableActions.includes(actionCode)
  ) {
    return <Route {...props} match={match} />;
  } else {
    return <Redirect to={match.url} />;
  }
};

ProtectedDeliveryOptionRoute = compose(
  withRouter,
  connect((state, { match }) => {
    const parcelId = match.params.parcelId;

    return {
      deliveryActions: getDeliveryActionsByDepotCode(state, parcelId),
    };
  })
)(ProtectedDeliveryOptionRoute);

export default ProtectedDeliveryOptionRoute;
