import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { useTracker, withTrackProps } from '@dpdgroupuk/react-event-tracker';

import Like from '@dpdgroupuk/fmx-ui/images/Rating/Like.png';
import Dislike from '@dpdgroupuk/fmx-ui/images/Rating/Dislike.png';
import Button from '../../../components/Rate/Button';
import * as MESSAGE from '../../../constants/message';
import { goToReferenceEntry } from '../../historyActions';
import RateInfoButton from './RateInfoButton';
import { withRemoteConfig } from '../../../components/RemoteConfig/ConfigContext';
import { Config } from '../../../components/RemoteConfig';
import DislikedText from '../../../components/Rate/DislikedText';

import {
  RATE_DELIVERY_CONFIRMATION,
  RATE_SORRY_CONFIRMATION,
} from '../../../constants/analytics';
import { RATE_INFO_NAMES } from '../constants';
import { RATE_TYPE } from '../../../constants/rate';

const useStyles = makeStyles(({ typography, breakpoints }) => {
  return {
    textBlock: {
      marginTop: typography.pxToRem(40),
      [breakpoints.down('xs')]: {
        marginTop: typography.pxToRem(32),
      },
    },
    text: {
      fontSize: typography.pxToRem(18),
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.83,
      letterSpacing: typography.pxToRem(0.64),
      marginBottom: typography.pxToRem(180),
      whiteSpace: 'pre-line',
    },
    homeButtonBlock: {
      marginTop: typography.pxToRem(50),
    },
    thumbs: {
      width: typography.pxToRem(81),
      height: typography.pxToRem(81),
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [breakpoints.down('xs')]: {
        marginTop: typography.pxToRem(20),
        width: typography.pxToRem(69),
        height: typography.pxToRem(69),
      },
    },
    thumbsIcon: {
      maxWidth: typography.pxToRem(81),
      maxHeight: typography.pxToRem(81),
      [breakpoints.down('xs')]: {
        maxWidth: typography.pxToRem(69),
        maxHeight: typography.pxToRem(69),
      },
    },
  };
});

type Props = {
  data: any,
  remoteConfig: Config,
  onHomeClick: Function,
};

const DeliveryNoInvestigationComplete = ({
  data,
  onHomeClick,
  remoteConfig: { contactNumber },
}: Props) => {
  const styles = useStyles();

  const track = useTracker();
  const { deliveryRating, experienceComplimentIcon } = data;
  React.useEffect(() => {
    if (deliveryRating === RATE_TYPE.LIKE) {
      track.setInterfaceId(RATE_DELIVERY_CONFIRMATION.INTERFACE_ID);
      track.logEvent(RATE_DELIVERY_CONFIRMATION.LOAD);
    } else {
      track.setInterfaceId(RATE_SORRY_CONFIRMATION.INTERFACE_ID);
      track.logEvent(RATE_SORRY_CONFIRMATION.LOAD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid>
      <div className={styles.thumbs}>
        {experienceComplimentIcon && (
          <RateInfoButton icon={experienceComplimentIcon} />
        )}
        {!experienceComplimentIcon ? (
          deliveryRating === RATE_TYPE.LIKE ? (
            <img src={Like} alt="" className={styles.thumbsIcon} />
          ) : (
            <img src={Dislike} alt="" className={styles.thumbsIcon} />
          )
        ) : null}
      </div>
      <div className={styles.textBlock}>
        {deliveryRating === RATE_TYPE.LIKE ? (
          <Typography className={styles.text}>
            {MESSAGE.THANKS_POSITIVE}
          </Typography>
        ) : (
          <DislikedText
            contactNumber={contactNumber}
            showContactNumber={
              experienceComplimentIcon === RATE_INFO_NAMES.NOT_RECEIVED
            }
          />
        )}
      </div>
      <div className={styles.homeButtonBlock}>
        <Button onClick={onHomeClick}>{MESSAGE.HOME}</Button>
      </div>
    </Grid>
  );
};

export default compose(
  withRouter,
  withRemoteConfig(),
  withProps(({ history }) => ({
    onHomeClick: () => goToReferenceEntry(history),
  })),
  withTrackProps(({ data: { deliveryRating } }) => ({
    onHomeClick:
      deliveryRating === RATE_TYPE.LIKE
        ? RATE_DELIVERY_CONFIRMATION.ON_HOME
        : RATE_SORRY_CONFIRMATION.ON_HOME,
  }))
)(DeliveryNoInvestigationComplete);
