import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { mapProps } from 'recompose';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';
import RateExperience from './components/RateExperience';
import { RATE_EXPERIENCE, trackable } from '../../../../constants/analytics';
import { withParcelInfo } from '../../hocs';
import {
  createHeaderControlActions,
  createSpeedDealControlActions,
} from './controlActions';
import withFetch from '../../../../HOCs/withFetch';
import { MORE_COOL_STUFF } from '../../../../constants/message';
import { fetchRouteAndDriver } from '../../../../store/actions/rate';

export default compose(
  withRouter,
  withFetch(({ parcel }) => dispatch =>
    dispatch(fetchRouteAndDriver(parcel)).then(result => {
      return {
        driver: result,
      };
    })
  ),
  mapProps(({ fetchedData: { driver }, ...props }) => ({ ...props, driver })),
  withModal,
  withPageControl({
    title: MORE_COOL_STUFF,
    backgroundImage: IMAGES.TRACKING_BG,
    trackable: trackable(RATE_EXPERIENCE),
    headerMenuActions: createHeaderControlActions,
    speedDealActions: createSpeedDealControlActions,
    // isMobileMenuShevronVisible: true,
  }),
  withParcelInfo({
    paddingLeft: 48,
    paddingRight: 48,
    xsPaddingLeft: 10,
    xsPaddingRight: 10,
    paddingLeft410: 3,
    paddingRight410: 3,
  })
)(RateExperience);
