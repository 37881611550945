import { attr, Model } from 'redux-orm';
import type { PayloadAction } from '@dpdgroupuk/redux-action-creator';

import { ACTIONS } from './actions';
import { ENTITY_NAME } from '../constants';

export class PickupLocation extends Model {
  // eslint-disable-next-line no-use-before-define
  static reducer(action: PayloadAction, PickupLocation: PickupLocation) {
    if (action.type === ACTIONS.FETCH_PICKUP_LOCATION_BY_LOCATION_ID.SUCCESS) {
      PickupLocation.create({
        ...action.payload,
      });
    }
    if (action.type === ACTIONS.FETCH_VOUCHERS.SUCCESS) {
      PickupLocation.withId(action.payload.pickupLocationCode).set(
        'vouchers',
        action.payload
      );
    }
    if (action.type === ACTIONS.FETCH_VOUCHERS_INFO_BY_VOUCHER_CODE.SUCCESS) {
      PickupLocation.withId(
        action.payload.pickupLocationCode
      ).vouchers.vouchersInfo = action.payload.vouchersInfo;
    }
  }

  static modelName = ENTITY_NAME.PICKUP_LOCATION;

  static fields = {
    pickupLocationCode: attr(),
    vouchers: attr(),
  };

  static options = {
    idAttribute: 'pickupLocationCode',
  };
}
