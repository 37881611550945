import { useMemo } from 'react';

import type { AuthorizedParcel } from '@dpdgroupuk/fmx-ui/types';
import { formatMessage } from '@dpdgroupuk/fmx-ui/utils/string';
import { formatReadableDate } from '@dpdgroupuk/fmx-ui/utils/date';

import {
  PARCEL_ISSUE_SUB_TEXT,
  PARCEL_ISSUE_TEXT_WITH_DELAY_$,
  PARCEL_ISSUE_TEXT_WITH_ESTIMATED_TIME_ROW_1_$,
  PARCEL_ISSUE_TEXT_WITH_ESTIMATED_TIME_ROW_2_$,
} from './constants';

export const useParcelIssue = (parcel: AuthorizedParcel) => {
  const issueDelayDays = parcel.issueDetails.issueDelayDays;
  const estimatedDeliveryDate = parcel.estimatedDeliveryDate;
  const issueName = parcel.issueDetails.issueName;
  const issueCode = parcel.issueDetails.issueCode;

  const parcelIssueText: Array<string> = useMemo(() => {
    if (issueDelayDays && issueDelayDays !== '') {
      return [
        formatMessage(
          PARCEL_ISSUE_TEXT_WITH_DELAY_$,
          issueDelayDays.toString(),
          issueName
        ),
      ];
    }
    if (estimatedDeliveryDate && estimatedDeliveryDate !== '') {
      return [
        formatMessage(PARCEL_ISSUE_TEXT_WITH_ESTIMATED_TIME_ROW_1_$, issueName),
        formatMessage(
          PARCEL_ISSUE_TEXT_WITH_ESTIMATED_TIME_ROW_2_$,
          formatReadableDate(estimatedDeliveryDate)
        ),
      ];
    }

    return [
      formatMessage(PARCEL_ISSUE_TEXT_WITH_ESTIMATED_TIME_ROW_1_$, issueName),
    ];
  }, [estimatedDeliveryDate, issueDelayDays, issueName]);

  const parcelIssueSubText: string = useMemo(() => {
    if (issueDelayDays || estimatedDeliveryDate) return PARCEL_ISSUE_SUB_TEXT;
    return '';
  }, [estimatedDeliveryDate, issueDelayDays]);

  return {
    parcelIssueText,
    parcelIssueSubText,
    issueCode,
  };
};
