// @flow
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Chat = (props: Object) => (
  <SvgIcon viewBox="0 0 42 34" {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Parcel-photo---Like/Dislike---multiple-images"
        transform="translate(-1188.000000, -636.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="chat-(3)" transform="translate(1188.000000, 636.000000)">
          <path
            d="M35.8371403,4.98139535 C31.8676903,1.76828753 26.601731,0 20.9894438,0 C15.3771566,0 10.1111972,1.76828753 6.14174726,4.98139535 C-1.53074664,11.1919662 -2.06355872,21.1331924 4.81859727,27.8397463 C4.14370197,28.9395349 3.06031742,30.2190275 1.45300099,30.8587738 C0.680423475,31.1678647 0.245293612,31.8363636 0.378496631,32.5264271 C0.51169965,33.2164905 1.16883454,33.7556025 2.02133387,33.8634249 C2.42094292,33.9137421 2.98927581,33.9640592 3.68193151,33.9640592 C5.53789357,33.9640592 8.27299556,33.6118393 11.070259,31.9801268 C14.1872096,33.3386892 17.5972069,34 20.9805636,34 C26.4152468,34 31.7966487,32.2964059 35.8460205,29.0186047 C39.8154705,25.8054968 42,21.5429175 42,17 C42,12.4570825 39.8065903,8.19450317 35.8371403,4.98139535 Z M34.0739142,27.9635535 C28.1174032,32.7739963 18.8958417,33.7289582 11.6421349,30.2868192 C11.165614,30.0587686 10.5743752,30.1300344 10.1949233,30.4364774 C10.1596255,30.4507306 10.1243277,30.4721103 10.0978543,30.49349 C7.7064254,31.9900722 5.35911883,32.3036418 3.80601374,32.3036418 L3.79718928,32.3036418 C5.58855481,31.2489077 6.71808579,29.6810597 7.37992035,28.4624142 C7.48581388,28.2557433 7.5034628,28.0419459 7.44169158,27.8424016 C7.41521819,27.6499839 7.31814913,27.4575662 7.15048437,27.3007814 C0.690979081,21.4070981 1.02630859,12.427605 7.92703692,6.85461793 C15.1366214,1.03220053 26.8643298,1.03220053 34.0650897,6.85461793 C41.2834987,12.6770353 41.2834987,22.1411361 34.0739142,27.9635535 Z"
            id="Shape"
          />
          <circle id="Oval" cx="21.5" cy="17.5" r="1.5" />
          <circle id="Oval" cx="28.5" cy="17.5" r="1.5" />
          <circle id="Oval" cx="13.5" cy="17.5" r="1.5" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default Chat;
