import queryString from 'query-string';
import { formatPathName } from '../../../utils/path';
import { ROUTES } from '../constants';

export const getLocationDriverProfile = params => ({
  pathname: formatPathName(ROUTES.DRIVER_PROFILE, params),
});

export const getLocationDeliveryOptions = params => ({
  pathname: formatPathName(ROUTES.DELIVERY_OPTIONS, params),
});

export const getLocationChangeDelivery = params => ({
  pathname: formatPathName(ROUTES.CHANGE_DELIVERY, params),
});

export const getLocationWhatHappendNext = params => ({
  pathname: formatPathName(ROUTES.WHAT_HAPPENS_NEXT, params),
});

export const getLocationTrackingMap = params => ({
  pathname: formatPathName(ROUTES.TRACKING_MAP, params),
});

export const getLocationTracking = params => ({
  pathname: formatPathName(ROUTES.TRACKING, params),
});

export const getLocationShowMap = params => ({
  pathname: formatPathName(`${ROUTES.MAP_INFO}`, params),
});

export const getLocationEvents = params => ({
  pathname: formatPathName(ROUTES.EVENTS, params),
});

export const getLocationReferenceEntry = () => ({
  pathname: ROUTES.REFERENCE_ENTRY,
});

export const getRateLocation = (params, searchParams) => ({
  pathname: formatPathName(ROUTES.RATE, params),
  search: queryString.stringify(searchParams),
});
