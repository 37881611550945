import { pick } from 'lodash';

import { formatDefaultDate } from '@dpdgroupuk/fmx-ui/utils/date';

import { changeDeliveryOption } from '../../../../store/orm/Parcel/actions';
import type { Address } from '../../../../components/SearchAddress/types';
import type { ChangeDeliveryOptionOptions } from '../../../../apis';

type DeliveryToNeighbourDataProps = {
  day?: string,
  address: Address,
  contactName: string,
  contactEmail: string,
  contactPhone: string,
};

type DeliveryToNeighbourBody = {
  diaryDate?: string,
  address: {
    udprn: string,
    property: string,
    organisation?: string,
    street?: string,
    city?: string,
    postcode?: string,
  },
  contactName: string,
  contactEmail: string,
  contactPhone: string,
};

export const changeDeliveryToNeighbourAndDate = (
  parcelCode: string,
  {
    day,
    address,
    contactName,
    contactEmail,
    contactPhone,
  }: DeliveryToNeighbourDataProps,
  options: ChangeDeliveryOptionOptions
) => (dispatch: Function) => {
  const data: DeliveryToNeighbourBody = {
    ...(day && { diaryDate: formatDefaultDate(day) }),
    address: pick(address, [
      'organisation',
      'property',
      'street',
      'city',
      'postcode',
      'udprn',
    ]),
    contactName,
    contactEmail,
    contactPhone,
  };
  return dispatch(changeDeliveryOption(parcelCode, data, options));
};
