import * as React from 'react';
import { compose } from 'redux';
import { useHistory, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import * as ENUM from '@dpdgroupuk/redback-enums';
import {
  withPageControl,
  usePageBackgroundImage,
} from '@dpdgroupuk/fmx-ui/components/Page';
import type { AuthorizedParcel, Parcel } from '@dpdgroupuk/fmx-ui/types';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';

import TrackingStatus from '../../../../../components/TrackingStatus';
import {
  backToDpdSiteHeader,
  backToDpdSiteSpeedDeal,
  getDpdAppHeader,
  goToParcelInfoSpeedDeal,
  goBackToDetailsSpeedDeal,
  trackAnotherParcelHeader,
  goBackToDetailsHeader,
  goToParcelInfoHeader,
} from '../../../../controlActions';
import { API_HOST } from '../../../../../constants/app';
import Mapbox from '../../../../../components/Mapbox';
import { withParcelInfo } from '../../../hocs';
import { MORE_COOL_STUFF } from '../../../../../constants/message';

const useStyles = makeStyles(({ typography }) => ({
  container: {
    paddingTop: typography.pxToRem(30),
    marginBottom: typography.pxToRem(30),
  },
}));

type Props = {
  parcel: Parcel & AuthorizedParcel,
  backgroundImage?: string,
};

const MapInfo = ({ parcel, backgroundImage }: Props) => {
  const styles = useStyles();
  const { goBack } = useHistory();
  const srcMap = parcelStatusType => {
    const size = 'size=1200*600';
    const api = `${API_HOST}/map/`;
    if (parcelStatusType === ENUM.PARCEL_STATUS.COLLECT_FROM_DEPOT_INSTRUCTIONS)
      return api + `depot?depotCode=${parcel.deliveryDepot.depotCode}&` + size;
    return api + `pickup?pickupLocationId=${parcel.pickupLocationCode}&` + size;
  };
  usePageBackgroundImage(backgroundImage);

  return (
    <>
      <TrackingStatus status={parcel.parcelStatusHtml} />
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={styles.container}
      >
        <Mapbox mapSrc={srcMap(parcel.parcelStatusType)} onClose={goBack} />
      </Grid>
    </>
  );
};

const createControlHeaderActions = props => [
  goBackToDetailsHeader(props),
  goToParcelInfoHeader(props),
  trackAnotherParcelHeader(props),
  backToDpdSiteHeader(props),
  getDpdAppHeader(props),
];

const createSpeedDealControlActions = props => [
  backToDpdSiteSpeedDeal(props),
  goToParcelInfoSpeedDeal(props),
  goBackToDetailsSpeedDeal(props),
];

export default compose(
  withRouter,
  withModal,
  withPageControl({
    title: MORE_COOL_STUFF,
    backgroundImage: IMAGES.TRACKING_BG,
    backgroundOpacity: 0.8,
    headerMenuActions: createControlHeaderActions,
    speedDealActions: createSpeedDealControlActions,
    // isMobileMenuShevronVisible: true,
  }),
  withParcelInfo()
)(MapInfo);
