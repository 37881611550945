import { BRAINTREE_PAYMENT_PROBLEM, PAYMENT_FAILED_TRY_ANOTHER } from './error';

export const CHALLENGE_REQUIRED_STATUS = 'challenge_required';
export const FAILED_STATUSES = [
  'authentication_unavailable',
  'authenticate_unable_to_authenticate',
  'authenticate_frictionless_failed',
  'authenticate_rejected',
  'lookup_error',
  'lookup_failed_acs_error',
  'lookup_bypassed',
];

export const getModalErrorMessage = braintreeStatus => {
  switch (braintreeStatus) {
    case 'authenticate_frictionless_failed':
    case 'authentication_unavailable':
    case 'authenticate_rejected':
      return PAYMENT_FAILED_TRY_ANOTHER;
    default:
      return BRAINTREE_PAYMENT_PROBLEM;
  }
};
