import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { PhoneOutlined, PersonOutline } from '@material-ui/icons';

import {
  CONTACT_NAME_REQUIRED,
  INVALID_PHONE,
} from '@dpdgroupuk/fmx-ui/constants/validation.js';
import SubmitButton from '@dpdgroupuk/fmx-ui/components/SubmitButton';
import { InputFormField } from '@dpdgroupuk/fmx-ui/components/Input/index';
import FormField from '@dpdgroupuk/fmx-ui/components/FormField';
import { isPhoneValid } from '@dpdgroupuk/fmx-ui/utils/validation';
import {
  isUkMobile,
  setReadOnlyFieldsValue,
} from '../helpers/getContactDetails';

const useStyles = makeStyles(({ breakpoints, palette, typography }) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      padding: `0 ${typography.pxToRem(40)}`,
    },
  },
  title: {
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
      lineHeight: 1.72,
      fontWeight: 300,
    },
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(21),
      lineHeight: 1.49,
    },
    letterSpacing: 0.44,
    textAlign: 'center',
    margin: `${typography.pxToRem(40)} 0`,
    maxWidth: typography.pxToRem(500),
  },
  icon: {
    fill: palette.primary.main,
    verticalAlign: 'middle',
    marginRight: typography.pxToRem(10),
    alignSelf: 'center',
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(16),
    },
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(24),
    },
  },
  inputRow: {
    width: '100%',
    maxWidth: typography.pxToRem(510),
    marginBottom: typography.pxToRem(30),
    alignItems: 'center',
  },
  label: {
    fontWeight: 300,
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(16),
      marginBottom: typography.pxToRem(8),
    },
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(19),
      marginBottom: 0,
    },
  },
  input: {
    [breakpoints.up('sm')]: {
      marginLeft: typography.pxToRem(20),
    },
  },
}));

const validate = values => {
  const errors = {};

  if (!values.contactName) {
    errors.contactName = CONTACT_NAME_REQUIRED;
  }

  if (!values.contactPhone || !isPhoneValid(values.contactPhone)) {
    errors.contactPhone = INVALID_PHONE;
  }

  return errors;
};

type Props = {
  values: Object,
  title: string,
  valid: boolean,
  handleSubmit: Function,
};

const ContactDetailsForm = ({
  values = {},
  title,
  valid,
  handleSubmit,
}: Props) => {
  const styles = useStyles();
  const [
    shouldShowMaskedContactNumber,
    setShouldShowMaskedContactNumber,
  ] = useState(false);

  const fieldStyles = {
    label: styles.label,
    inputRow: styles.inputRow,
  };
  const gridOptions = {
    flex: {
      direction: 'row',
      justify: 'space-between',
    },
    placeholder: {
      xs: 12,
      sm: 4,
    },
    input: {
      xs: 12,
      sm: 8,
    },
  };
  const isPhoneEntered = values.contactPhone;
  const isSubmitDisabled = !valid || !isPhoneEntered;

  const [readOnlyFields, setReadOnlyFields] = useState(false);

  useEffect(() => {
    // Validate the number entered is a UK mobile, of it isn't then set the contactPhone to null so the user can set a new one
    const isValidNumber = isPhoneValid(values.contactPhone);
    if (!isValidNumber) {
      values.contactPhone = null;
    }

    // Set fields to be read-only if the values meet criteria
    setReadOnlyFieldsValue(values, setReadOnlyFields);

    // If phone number provided, then set masked contact number
    if (values.contactPhone) {
      setShouldShowMaskedContactNumber(true);
      // Set masked phone number
      values.maskedContactPhone = `*******${values.contactPhone.substring(
        values.contactPhone.length - 4,
        values.contactPhone.length
      )}`;
    }
  }, []);

  return (
    <div className={styles.container}>
      <Typography className={styles.title}>{title}</Typography>

      <FormField
        placeholder="Contact Name"
        iconComponent={<PersonOutline className={styles.icon} />}
        styles={fieldStyles}
        gridOptions={gridOptions}
      >
        <Field
          className={styles.input}
          component={InputFormField}
          name="contactName"
          fullWidth
          readOnly={readOnlyFields}
        />
      </FormField>
      {!shouldShowMaskedContactNumber && (
        <>
          <FormField
            placeholder="Mobile Number"
            iconComponent={<PhoneOutlined className={styles.icon} />}
            styles={fieldStyles}
            gridOptions={gridOptions}
          >
            <Field
              className={styles.input}
              component={InputFormField}
              name="contactPhone"
              fullWidth
              readOnly={readOnlyFields}
            />
          </FormField>
        </>
      )}
      {shouldShowMaskedContactNumber && (
        <>
          <FormField
            placeholder="Mobile Number"
            iconComponent={<PhoneOutlined className={styles.icon} />}
            styles={fieldStyles}
            gridOptions={gridOptions}
          >
            <Field
              className={styles.input}
              component={InputFormField}
              name="maskedContactPhone"
              fullWidth
              readOnly={readOnlyFields}
            />
          </FormField>
        </>
      )}

      <SubmitButton
        onSubmit={handleSubmit}
        isSubmitDisabled={isSubmitDisabled}
        children="SEND VERIFICATION PIN"
      />
    </div>
  );
};

export default compose(
  reduxForm({
    form: 'getContacts',
    validate,
  }),
  connect(state => ({
    values: getFormValues('getContacts')(state),
  }))
)(ContactDetailsForm);
