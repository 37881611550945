import * as React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { ErrorOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import type { AuthorizedParcel, Parcel } from '@dpdgroupuk/fmx-ui/types/Parcel';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';

import { GENERAL_VIEW, trackable } from '../../../../../constants/analytics';
import TrackingStatus from '../../../../../components/TrackingStatus';
import {
  backToDpdSiteHeader,
  backToDpdSiteSpeedDeal,
  getDpdAppHeader,
  goToParcelInfoHeader,
  goToParcelInfoSpeedDeal,
  trackAnotherParcelHeader,
  trackAnotherParcelSpeedDeal,
  changeDeliverySpeedDeal,
  whatHappensNextSpeedDeal,
  goToPollutrackHeader,
} from '../../../../controlActions';
import * as MESSAGE from '../../../../../constants/message';
import { withParcelInfo } from '../../../hocs';

const useStyles = makeStyles(({ breakpoints, typography }) => ({
  parcelInstruction: {
    marginTop: '1.7375rem',
    fontWeight: '300',
    lineHeight: '1.53',
    letterSpacing: '0.44px',
    fontSize: '0.9375rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    [breakpoints.down('xs')]: {
      fontWeight: 'normal',
      lineHeight: 1.72,
    },
  },
  instructionWithImage: {
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    marginRight: typography.pxToRem(10),
    width: typography.pxToRem(36),
  },
}));

type Props = {
  parcel: Parcel & AuthorizedParcel,
};

const GeneralView = ({ parcel }: Props) => {
  const styles = useStyles();
  const { parcelStatusHtml, parcelStatusInfo, deliveredToConsumer } = parcel;

  return (
    <>
      <TrackingStatus status={parcelStatusHtml} />
      {!deliveredToConsumer && parcelStatusInfo && (
        <Typography
          className={clsx(
            styles.parcelInstruction,
            styles.instructionWithImage
          )}
        >
          <ErrorOutline className={styles.image} />
          {parcelStatusInfo}
        </Typography>
      )}
    </>
  );
};

const createHeaderControlActions = props => {
  const generalHeaderControlActions = [
    goToParcelInfoHeader(props),
    trackAnotherParcelHeader(props),
    backToDpdSiteHeader(props),
    getDpdAppHeader(props),
  ];

  if (props.parcel.airDiagActive) {
    return [...generalHeaderControlActions, goToPollutrackHeader(props)];
  }

  return generalHeaderControlActions;
};

const deliveredActions = props => [
  backToDpdSiteSpeedDeal(props),
  trackAnotherParcelSpeedDeal(props),
  goToParcelInfoSpeedDeal(props),
];
const notDeliveredActions = props => [
  goToParcelInfoSpeedDeal(props),
  changeDeliverySpeedDeal(props),
  whatHappensNextSpeedDeal(props),
];

const createSpeedDealControlActions = props =>
  props.parcel.deliveredToConsumer
    ? deliveredActions(props)
    : notDeliveredActions(props);

export default compose(
  withRouter,
  withModal,
  withPageControl({
    title: MESSAGE.MORE_COOL_STUFF,
    backgroundImage: IMAGES.TRACKING_BG,
    trackable: trackable(GENERAL_VIEW),
    headerMenuActions: createHeaderControlActions,
    speedDealActions: createSpeedDealControlActions,
    // isMobileMenuShevronVisible: true,
  }),
  withParcelInfo()
)(GeneralView);
