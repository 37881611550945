import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(({ palette, typography }) => ({
  parcelNumber: {
    fontWeight: 400,
    color: palette.text.default,
    marginTop: typography.pxToRem(20),
  },
}));

type Props = {
  parcelNumber: string,
  className?: string,
};

const CompleteParcelNumber = ({ parcelNumber, className }: Props) => {
  const styles = useStyles();

  return (
    <Typography className={clsx(styles.parcelNumber, className)}>
      Parcel No. {parcelNumber}
    </Typography>
  );
};

export default CompleteParcelNumber;
