// @flow
import * as React from 'react';
import clsx from 'clsx';
import { chunk } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Radio from '@dpdgroupuk/fmx-ui/components/Icons/Radio';

const useStyles = makeStyles(({ typography, breakpoints, palette }) => ({
  radioIcon: {
    '& .checkMark': {
      fill: palette.primary.main,
    },
    marginRight: `${typography.pxToRem(10)}`,
  },
  text: {
    fontSize: typography.pxToRem(19),
    lineHeight: 0.85,
    letterSpacing: typography.pxToRem(0.4),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
    },
  },
  row: {
    height: `${typography.pxToRem(50)}!important`,
    display: 'flex',
    width: '100%',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: `${typography.pxToRem(15)} ${typography.pxToRem(
      30
    )} ${typography.pxToRem(15)} ${typography.pxToRem(30)}`,
  },
  clickable: {
    cursor: 'pointer',
  },
  container: {
    display: 'flex',
    width: '100%',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  columnWithBorder: {
    display: 'flex',
    flexFlow: 'column nowrap',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      height: '66%',
      top: '17%',
      borderRight: `solid ${typography.pxToRem(2)} #979797`,
      opacity: 0.4,
    },
    '&:first-child:before': {
      borderRight: 'none',
    },
  },
  disabled: {
    opacity: 0.2,
  },
}));

const getRadioRow = (styles, format, el, active, onSelect, isDisabled, key) => {
  return (
    <div
      className={clsx(styles.row, {
        [styles.clickable]: !isDisabled,
      })}
      onClick={isDisabled ? null : () => onSelect(el)}
      key={key}
      data-testid={`timeslot${isDisabled ? '-disabled' : '-enabled'}`}
    >
      <Radio selected={key === active} className={styles.radioIcon} />
      <Typography
        className={clsx(styles.text, {
          [styles.disabled]: isDisabled,
        })}
      >
        {format(el)}
      </Typography>
    </div>
  );
};

type Props = {
  items: Array<any>,
  active: any,
  onSelect: Function,
  format?: Function,
  width: string,
};

const CallMeTimeslotList = ({
  items,
  active,
  onSelect,
  format = item => item,
  width,
}: Props) => {
  const styles = useStyles();

  const columns = isWidthUp('md', width) ? 3 : 1;
  items = chunk(items, items.length / columns);
  return (
    <div className={styles.container} data-testid="call-me-timeslot-list">
      {items.map((_, i) => {
        return (
          <div
            className={styles.columnWithBorder}
            key={i}
            data-testid="call-me-timeslot-list-column"
          >
            {items[i].map(el => {
              return getRadioRow(
                styles,
                format,
                el,
                active,
                onSelect,
                el.disabled,
                el.key
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default withWidth()(CallMeTimeslotList);
