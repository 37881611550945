import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Lottie from 'lottie-react-web';
import clsx from 'clsx';

import Radio from '@dpdgroupuk/fmx-ui/components/Icons/Radio';

import electricCar from '../../../assets/lottie/greenDeliveryDriverAnimation';
import { NOT_AVAILABLE } from '../../../constants/values';

const useStyles = makeStyles(({ typography, breakpoints, palette }) => ({
  radioIcon: {
    '& .checkMark': {
      fill: palette.primary.main,
    },
    flex: 1,
  },
  row: {
    cursor: 'pointer',
    height: `${typography.pxToRem(50)}!important`,
  },
  listContainer: {
    marginBottom: typography.pxToRem(30),
    width: '45%',
    [breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  text: {
    flex: 2,
    fontSize: typography.pxToRem(19),
    lineHeight: 0.85,
    letterSpacing: typography.pxToRem(0.44),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
    },
  },
  greenColor: {
    color: '#1ca521',
  },
  greyColor: {
    color: '#929699',
  },
  disabled: {
    pointerEvents: 'none',
  },
  centerItem: {
    flex: 2,
  },
  ecoText: {
    fontSize: typography.pxToRem(16),
    color: '#1ca521',
    marginLeft: typography.pxToRem(13),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(14),
      marginLeft: typography.pxToRem(5),
    },
  },
  disableRadio: {
    flex: 1,
    height: '100%',
  },
  line: {
    height: typography.pxToRem(2),
    width: typography.pxToRem(13),
    background: '#929699',
  },
}));

type Props = {
  items: Array<any>,
  onSelect: Function,
};

const EcoTimeSlotList = ({ items, onSelect }: Props) => {
  const styles = useStyles();
  const [active, setActive] = React.useState('');
  const onClick = el => {
    setActive(el);
    onSelect(el);
  };

  return (
    <Grid className={styles.listContainer}>
      <GridList cols={1}>
        {items.map((el, i) => {
          const time = `${el.startTime} - ${el.endTime}`;
          const notAvailable = el.slotStatus === NOT_AVAILABLE;
          return (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              key={i}
              className={clsx(styles.row, notAvailable && styles.disabled)}
              onClick={() => onClick(time)}
            >
              <Typography
                className={clsx(
                  styles.text,
                  el.greenSlot && styles.greenColor,
                  notAvailable && styles.greyColor
                )}
              >
                {time}
              </Typography>

              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                className={styles.centerItem}
              >
                {el.greenSlot && (
                  <>
                    <Lottie
                      width="1.44rem"
                      height="1.44rem"
                      style={{
                        position: 'relative',
                        margin: 'auto 0',
                      }}
                      options={{ animationData: electricCar, autoPlay: true }}
                    />
                    <Typography className={styles.ecoText}>
                      eCo Friendly
                    </Typography>
                  </>
                )}
              </Grid>

              {notAvailable ? (
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={styles.disableRadio}
                >
                  <div className={styles.line} />
                </Grid>
              ) : (
                <Radio
                  selected={time === active}
                  className={styles.radioIcon}
                />
              )}
            </Grid>
          );
        })}
      </GridList>
    </Grid>
  );
};

export default EcoTimeSlotList;
