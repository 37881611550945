import { get, last } from 'lodash';
import { validate } from 'isemail';

import { isPhoneValid } from '@dpdgroupuk/fmx-ui/utils/validation';
import { getAnyway } from '@dpdgroupuk/fmx-ui/utils/helpers';
import { formatMessage } from '@dpdgroupuk/fmx-ui//utils/string';
import { formatTime, getHoursMinutes } from '@dpdgroupuk/fmx-ui/utils/date';

import typeof { AuthorizedParcel, Parcel } from '../types';
import { PARCEL_DRIVER_ARRIVE_TIME_$ } from '../constants/message';

export const isHistoryParcel = ({
  undelivered,
  deliveredToConsumer,
}: Parcel): boolean => !undelivered && deliveredToConsumer;

export const getDeliveryTimeStart = (estimatedDeliveryStartTime: string) =>
  formatTime(estimatedDeliveryStartTime);

export const isAuthorizedParcel = (
  parcel: Parcel | AuthorizedParcel
): boolean => !!(parcel && parcel.collectionDetails);

export const isCreateProfilePossible = ({
  consumerId,
  deliveryDetails: { address },
}: AuthorizedParcel) => {
  return !consumerId && address.udprn;
};

export const getRouteCode = parcel => parcel.deliveryDepot.route.routeCode;

export const getDeliveryAddress = parcel =>
  getAnyway(parcel, 'deliveryDetails.address', {});

export const getParcelNotificationDetails = parcel =>
  getAnyway(parcel, 'deliveryDetails.notificationDetails', {});

export const hasParcelNotificationDetails = parcel => {
  const notificationDetails = getParcelNotificationDetails(parcel);

  return !!(notificationDetails.email || notificationDetails.mobile);
};

export const getNextSteps = parcel => get(parcel, 'nextSteps', {});

export const getLatestOutForDeliveryDate = parcel =>
  last(getAnyway(parcel, 'outForDeliveryDetails.outForDeliveryDate', []));

export const getParcelCodeFromString = value => {
  const arrayElement = value.split('/');
  const parcelIndex = arrayElement.indexOf('parcels') + 1;
  if (parcelIndex === -1) {
    return null;
  }
  return arrayElement[parcelIndex];
};

export const getContactInformation = parcel => {
  const {
    mobile,
    contactName,
    email,
  } = parcel.deliveryDetails.notificationDetails;
  return {
    ...(contactName && { contactName }),
    ...(mobile && { contactPhone: mobile }),
    ...(email && { contactEmail: email }),
  };
};

export const getParcelDepotCode = parcel =>
  get(parcel, 'deliveryDepot.depotCode', null);

export const getParcelDriverCode = parcel => {
  const depotCode = getParcelDepotCode(parcel);
  const driverCode = get(parcel, 'deliveryDepot.driverCode', null);
  if (!depotCode || !driverCode) {
    return null;
  }

  return `${depotCode}*${driverCode}`;
};

export const getPodDriverCode = parcel =>
  get(parcel, 'deliveryDetails.podDetails.podDriverCode', null);

export const getPodParcelDriverCode = parcel => {
  const driverCode = getPodDriverCode(parcel);

  if (driverCode) {
    const depotCode = getParcelDepotCode(parcel);
    if (depotCode) {
      return `${depotCode}*${driverCode}`;
    }
  }

  const depotAndDriverCode = getParcelDriverCode(parcel);

  return depotAndDriverCode || null;
};

export const getDriverArriveTimeText = parcel => {
  const estimatedMinsToStop = get(
    parcel,
    'deliveryDepot.route.stop.estimatedMinsToStop',
    0
  );

  if (!estimatedMinsToStop) {
    return null;
  }

  const estimatedMinsToStopFormatted = getHoursMinutes(estimatedMinsToStop);

  return formatMessage(
    PARCEL_DRIVER_ARRIVE_TIME_$,
    estimatedMinsToStopFormatted
  );
};

export const getSenderRefs = parcel => {
  const refs = [
    get(parcel, 'shipperDetails.shippingRef1'),
    get(parcel, 'shipperDetails.shippingRef2'),
    get(parcel, 'shipperDetails.shippingRef3'),
  ];
  return refs.filter(ref => !!ref).join(', ');
};

export const formatReferenceNumberForServer = (value: string) =>
  value.replace(/ /g, '');

export const normalizeReferenceNumber = (val: string) => {
  const refNumber = formatReferenceNumberForServer(val);
  if (refNumber.length > 4) {
    return `${refNumber.slice(0, 4)} ${normalizeReferenceNumber(
      refNumber.slice(4, 28)
    )}`;
  }
  return refNumber;
};

export const getInfoForThreeDSecure = (amount, parcel) => ({
  amount,
  email: get(parcel, 'deliveryDetails.notificationDetails.email'),
  billingAddress: {
    givenName: get(parcel, 'deliveryDetails.notificationDetails.contactName'),
    phoneNumber: get(parcel, 'deliveryDetails.notificationDetails.mobile'),
    streetAddress: get(parcel, 'deliveryDetails.address.street'),
  },
  additionalInformation: {
    shippingGivenName: get(
      parcel,
      'deliveryDetails.notificationDetails.contactName'
    ),
    shippingPhone: get(parcel, 'deliveryDetails.notificationDetails.mobile'),
    shippingAddress: {
      streetAddress: get(parcel, 'deliveryDetails.address.street'),
    },
  },
});
