import { StatusCodes } from 'http-status-codes';
import { INCORRECT_PARCEL_DETAILS } from '../../constants/message';
import { TRY_AGAIN_LATER, UNEXPECTED_END_OF_JSON } from '../../constants/error';

export const formatPostcodeForServer = (value: string = '') =>
  value.toUpperCase();

export const parseRefNumberAndPostcode = (values: Object) => {
  if (
    values.referenceNumber &&
    !values.postcode &&
    values.referenceNumber.indexOf('*') > -1
  ) {
    const [postcode, referenceNumber] = values.referenceNumber.split('*');
    return {
      ...values,
      postcode,
      referenceNumber,
    };
  }
  return values;
};

export const getReferenceEntryError = e => {
  let title;
  switch (e.statusCode) {
    case StatusCodes.BAD_REQUEST:
    case StatusCodes.NOT_FOUND:
      title = INCORRECT_PARCEL_DETAILS;
      break;
    case StatusCodes.TOO_MANY_REQUESTS:
      title = e.message;
      break;
    default: {
      title = e.message;
    }
  }
  if (e.message === UNEXPECTED_END_OF_JSON) {
    title = TRY_AGAIN_LATER;
  }
  return title;
};
