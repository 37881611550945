import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import BraintreeWebDropIn from 'braintree-web-drop-in';

import { CHALLENGE_REQUIRED_STATUS } from '../../../constants/threeDSecure';
import { OK } from '../../../constants/message';
import { BRAINTREE_PAYMENT_PROBLEM } from '../../../constants/error';

const useBraintree = (options, errorHandler, modal) => {
  const [dropinInstance, setdropinInstance] = useState(null);
  const [paymentMethodRequestable, setPaymentMethodRequestable] = useState(
    false
  );
  const [isChallengeModalClosed, setChallengeModalClosed] = useState(false);
  const [threeDSecureInfo, setThreeDSecureInfo] = useState(null);
  const dropInRef = useRef(null);

  const onPaymentMethodRequestable = useCallback(() => {
    if (dropinInstance) {
      const isPaymentMethodRequestable = dropinInstance.isPaymentMethodRequestable();
      setPaymentMethodRequestable(isPaymentMethodRequestable);
    }
  }, [dropinInstance]);

  const onCustomerChallengeCancel = useCallback(() => {
    dropinInstance.clearSelectedPaymentMethod();
    setChallengeModalClosed(true);
  }, [dropinInstance]);

  const onCardFocus = useCallback(() => {
    setThreeDSecureInfo(null);
    setChallengeModalClosed(false);
  }, []);

  useEffect(() => {
    (async () => {
      if (!dropinInstance) {
        const dropin = await BraintreeWebDropIn.create({
          container: dropInRef.current,
          preselectVaultedPaymentMethod: true,
          threeDSecure: true,
          ...options,
        });
        setdropinInstance(dropin);
      }
    })();
  }, [options, dropinInstance, errorHandler]);

  useEffect(() => {
    if (dropinInstance) {
      dropinInstance.on(
        'noPaymentMethodRequestable',
        onPaymentMethodRequestable
      );
      dropinInstance.on('paymentMethodRequestable', onPaymentMethodRequestable);
      dropinInstance.on('3ds:customer-canceled', onCustomerChallengeCancel);
      dropinInstance.on('card:focus', onCardFocus);
    }

    return () => {
      dropinInstance && dropinInstance.teardown();
    };
  }, [
    dropinInstance,
    onPaymentMethodRequestable,
    onCustomerChallengeCancel,
    onCardFocus,
  ]);

  useEffect(() => {
    if (
      !isChallengeModalClosed &&
      threeDSecureInfo &&
      threeDSecureInfo.status === CHALLENGE_REQUIRED_STATUS
    ) {
      dropinInstance.clearSelectedPaymentMethod();
      modal.showModal({
        title: BRAINTREE_PAYMENT_PROBLEM,
        buttonText: OK,
        description: null,
      });
    }
  }, [isChallengeModalClosed, threeDSecureInfo, modal, dropinInstance]);

  const requestPaymentMethod = useCallback(
    async options => {
      if (dropinInstance) {
        try {
          const paymentResult = await dropinInstance.requestPaymentMethod(
            options
          );

          setThreeDSecureInfo(paymentResult.threeDSecureInfo);

          return paymentResult;
        } catch (err) {
          errorHandler(err);
        }
      }
    },
    [dropinInstance, errorHandler]
  );

  const state = { paymentMethodRequestable, dropInRef };
  const api = useMemo(
    () => ({
      requestPaymentMethod,
    }),
    [requestPaymentMethod]
  );

  return [state, api];
};

export default useBraintree;
