import React from 'react';
import { compose, withProps } from 'recompose';
import Wizard, { Step } from '@dpdgroupuk/fmx-ui/components/Wizard';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import * as ENUM from '@dpdgroupuk/redback-enums';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import type { AuthorizedParcel } from '@dpdgroupuk/fmx-ui/types';

import DutyPaymentCapture from './components/DutyPaymentCapture';
import { DUTY_PAYMENT_STEPS } from './constants';
import withParcel from '../../../../HOCs/withParcel';
import { withDeliveryUpdate } from '../../hocs';

import {
  DTY_DELIVERY_OPTION,
  DTY_PAYMENT,
  trackable,
} from '../../../../constants/analytics';

import * as MESSAGES from '../../../../constants/message';

import { withParcelInfo } from '../../../Tracking/hocs';
import { submitDutyPayment } from './actions';
import DutyPaymentComponent from './components/DutyPaymentComponent';
import PaymentProcessing from './components/PaymentProcessing';
import PaymentConfirmation from './components/PaymentConfirmation';
import CancelledConfirmation from './components/CancelledConfirmation';
import { createHeaderControlActions } from './contolActions';

const useStyles = makeStyles(() => ({
  step: {
    minHeight: 'auto',
    backgroundColor: 'transparent',
  },
}));

type Props = {
  parcel: AuthorizedParcel,
  updateDelivery: Function,
  onSubmitSuccess: Function,
  onSubmitFailure: Function,
};

function DutyPayment({
  parcel,
  updateDelivery,
  onSubmitSuccess,
  onSubmitFailure,
}: Props) {
  const styles = useStyles();

  const isCustomsDeclarationTypeE = parcel?.customsDeclarationType === 'E';

  return (
    <Wizard
      actionCode={ENUM.PARCEL_ACTION_CODE.DTY}
      onSubmit={updateDelivery}
      onSubmitSuccess={onSubmitSuccess}
      onSubmitFailure={onSubmitFailure}
      initialStepId={DUTY_PAYMENT_STEPS.DUE}
      initialData={{ chargesPaid: true }}
    >
      <Step
        stepId={DUTY_PAYMENT_STEPS.DUE}
        parcel={parcel}
        fullScreenStep
        component={DutyPaymentComponent}
        wrapperClassName={styles.step}
        isCustomsDeclarationTypeE={isCustomsDeclarationTypeE}
      />
      <Step
        stepId={DUTY_PAYMENT_STEPS.CAPTURE}
        parcel={parcel}
        fullScreenStep
        component={DutyPaymentCapture}
        wrapperClassName={styles.step}
        isCustomsDeclarationTypeE={isCustomsDeclarationTypeE}
      />
      <Step
        stepId={DUTY_PAYMENT_STEPS.PAYMENT_PROCESSING}
        fullScreenStep
        parcel={parcel}
        component={PaymentProcessing}
        wrapperClassName={styles.step}
      />
      <Step
        stepId={DUTY_PAYMENT_STEPS.PAYMENT_CONFIRMATION}
        fullScreenStep
        component={PaymentConfirmation}
        wrapperClassName={styles.step}
        parcel={parcel}
        isCustomsDeclarationTypeE={isCustomsDeclarationTypeE}
      />
      <Step
        stepId={DUTY_PAYMENT_STEPS.CANCELLED_CONFIRMATION}
        fullScreenStep
        component={CancelledConfirmation}
        wrapperClassName={styles.step}
        parcel={parcel}
      />
    </Wizard>
  );
}

export default compose(
  withRouter,
  withParcel(),
  withModal,
  withProps(() => ({
    onSubmitSuccess: ({
      goToStep,
      data: { chargesPaid },
      result: {
        data: { redbackCallSuccess },
      },
    }) => {
      if (chargesPaid) {
        if (!redbackCallSuccess) {
          return goToStep(DUTY_PAYMENT_STEPS.PAYMENT_PROCESSING);
        } else {
          return goToStep(DUTY_PAYMENT_STEPS.PAYMENT_CONFIRMATION);
        }
      } else {
        return goToStep(DUTY_PAYMENT_STEPS.CANCELLED_CONFIRMATION);
      }
    },
  })),
  withDeliveryUpdate({
    actionCode: ENUM.PARCEL_ACTION_CODE.DTY,
    changeDeliveryAction: submitDutyPayment,
    trackable: DTY_PAYMENT.ON_SUBMIT,
    isDutyPayment: true,
  }),
  withPageControl({
    backgroundImage: IMAGES.MAP,
    trackable: trackable(DTY_DELIVERY_OPTION),
    title: MESSAGES.DUTY_PAYMENT,
    speedDealActions: [],
    headerMenuActions: createHeaderControlActions,
  }),
  withParcelInfo({
    paddingLeft: 48,
    paddingRight: 48,
    xsPaddingLeft: 10,
    xsPaddingRight: 10,
    paddingLeft410: 3,
    paddingRight410: 3,
  })
)(DutyPayment);
