import * as React from 'react';
import { compose } from 'redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { withProps } from 'recompose';
import { withRouter } from 'react-router-dom';

import DeliveryInfo from '@dpdgroupuk/fmx-ui/components/DeliveryInfo';
import { ParcelOptionIcon } from '@dpdgroupuk/fmx-ui/components/OptionIcon';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import {
  DEFAULT_DATE_FORMAT,
  formatDayOfWeekDayMonthOrdinal,
} from '@dpdgroupuk/fmx-ui/utils/date';

import TrackAnotherParcelButton from '../../../components/TrackAnotherParcelButton';
import * as MESSAGE from '../../../../../constants/message';
import { getSelectedPickupShop } from '../utils';
import {
  trackAnotherParcelSpeedDeal,
  goToParcelInfoSpeedDeal,
  trackAnotherParcelHeader,
  goToParcelInfoHeader,
  goBackToDeliveryDetailsSpeedDeal,
  backToDpdSiteHeader,
  backToGreenSiteHeader,
  getDpdAppHeader,
} from '../../../../controlActions';
import {
  DELIVERY_OPTION_CONFIRMATION,
  trackable,
} from '../../../../../constants/analytics';
import CompleteParcelNumber from '../../../components/CompleteParcelNumber';
import typeof { AuthorizedParcel } from '../../../../../types/Parcel';
import PickupShopCardShopInfo from './PickupShopCardShopInfo';

const useStyles = makeStyles(({ palette, typography, breakpoints }) => ({
  pickupShopHeader: {
    color: palette.text.primary,
    fontSize: typography.pxToRem(19),
    letterSpacing: 0.41,
    marginTop: typography.pxToRem(40),
    marginBottom: typography.pxToRem(17.5),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(18),
    },
  },
  pickupShopAddress: {
    fontSize: typography.pxToRem(17),
    marginTop: typography.pxToRem(29),
    textAlign: 'center',
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
    },
  },
  timeMessage: {
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
      maxWidth: typography.pxToRem(260),
    },
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(21),
    },
    fontWeight: 300,
    lineHeight: 1.52,
    letterSpacing: 0.44,
    textAlign: 'center',
    maxWidth: typography.pxToRem(460),
  },
}));

type Props = {
  actionCode?: string,
  selectedItem: any,
  parcelAvailableDateFormatted: string,
  parcel: AuthorizedParcel,
};

const CompleteStep = ({
  actionCode,
  selectedItem,
  parcelAvailableDateFormatted,
  parcel,
}: Props) => {
  const styles = useStyles();

  const title = React.useMemo(() => {
    return MESSAGE.PICKUP_COMPLETE_WITHOUT_TIME.replace(
      '{{kind}}',
      selectedItem.pickupLocation.kind === 'LKR' ? 'Locker' : 'Shop'
    ).replace('{{date}}', parcelAvailableDateFormatted);
  }, [parcelAvailableDateFormatted, selectedItem]);

  return (
    <>
      <DeliveryInfo
        actionCode={
          selectedItem.pickupLocation.kind === 'LKR' ? 'PKL' : actionCode
        }
        title={title}
        getOptionIcon={() => ParcelOptionIcon}
      />
      <Typography className={styles.timeMessage}>
        {MESSAGE.LET_YOU_KNOW_TIME_MESSAGE}
      </Typography>
      <CompleteParcelNumber parcelNumber={parcel.parcelNumber} />
      <TrackAnotherParcelButton />
      <Typography className={styles.pickupShopHeader}>
        <strong>
          {MESSAGE.YOUR_PICKUP_$.replace(
            '{{kind}}',
            selectedItem.pickupLocation.kind === 'LKR' ? 'LOCKER' : 'SHOP'
          )}
        </strong>
      </Typography>

      <PickupShopCardShopInfo pickupShopInfo={selectedItem} pointChosen />
    </>
  );
};

const createSpeedDealControlActions = props => [
  goBackToDeliveryDetailsSpeedDeal(props),
  trackAnotherParcelSpeedDeal({ ...props, replace: true }),
  goToParcelInfoSpeedDeal({ ...props, replace: true }),
];

const createHeaderControlActions = props => [
  trackAnotherParcelHeader({ ...props, replace: true }),
  goToParcelInfoHeader({ ...props, replace: true }),
  backToDpdSiteHeader(props),
  backToGreenSiteHeader(props),
  getDpdAppHeader(props),
];

export default compose(
  withRouter,
  withModal,
  withProps(
    ({ pickupShops, data: { pickupLocationCode, pickupDate } }: any) => ({
      selectedItem: getSelectedPickupShop({ pickupShops, pickupLocationCode }),
      parcelAvailableDateFormatted: formatDayOfWeekDayMonthOrdinal(
        pickupDate,
        DEFAULT_DATE_FORMAT
      ),
    })
  ),
  withPageControl({
    trackable: trackable(DELIVERY_OPTION_CONFIRMATION),
    speedDealActions: createSpeedDealControlActions,
    headerMenuActions: createHeaderControlActions,
  })
)(CompleteStep);
