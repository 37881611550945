import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from '@dpdgroupuk/redux-action-creator';

import { apiService, fetchTimeoutHandler } from '../../../services';

export const ACTIONS = createActionTypes('ACTION', {
  FETCH_SEND_AUTH_SMS: createAsyncActionTypes('FETCH_SEND_AUTH_SMS'),
  FETCH_AUTH_PARCEL: createAsyncActionTypes('FETCH_AUTH_PARCEL'),
});

export const sendAuthSms = createAsyncAction(
  (phoneNumber: number, parcelCode: string) =>
    fetchTimeoutHandler(
      apiService.sendAuthSms({ phoneNumber, parcelCode })
    ).then(({ data }) => data),
  ACTIONS.FETCH_SEND_AUTH_SMS
);

export const authParcel = (parcelCode: string, otp: string) =>
  fetchTimeoutHandler(apiService.authParcel(parcelCode, otp))
    .then(({ data }) => data)
    .catch(e => e);
