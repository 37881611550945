import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { getAddressWithPostcode } from '@dpdgroupuk/fmx-ui/utils/address';
import { formatOrdinalNumber } from '@dpdgroupuk/fmx-ui/utils/string';
import { fullDateValue } from '@dpdgroupuk/fmx-ui/utils/date';
import VipIcon from '@dpdgroupuk/fmx-ui/components/Icons/Vip';

import TrackAnotherParcelButton from '../../components/TrackAnotherParcelButton';
import type { AuthorizedParcel } from '../../../../types';
import {
  backToDpdSiteHeader,
  backToGreenSiteHeader,
  getDpdAppHeader,
  goToParcelInfoHeader,
  goToParcelInfoSpeedDeal,
  goBackToDeliveryDetailsSpeedDeal,
  trackAnotherParcelHeader,
  trackAnotherParcelSpeedDeal,
} from '../../../controlActions';
import {
  DELIVERY_OPTION_CONFIRMATION,
  trackable,
} from '../../../../constants/analytics';
import CompleteParcelNumber from '../../components/CompleteParcelNumber';
import * as MESSAGE from '../../../../constants/message';

const useStyles = makeStyles(({ breakpoints, typography, palette }) => ({
  container: {
    padding: `${typography.pxToRem(20)} 0 ${typography.pxToRem(20)}`,
    alignItems: 'center',
  },
  icon: {
    fontSize: 70,
    fill: palette.icon.vip,
  },
  title: {
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
      maxWidth: typography.pxToRem(260),
    },
    fontSize: typography.pxToRem(21),
    fontWeight: 300,
    lineHeight: 1.52,
    letterSpacing: 0.44,
    textAlign: 'center',
    maxWidth: typography.pxToRem(460),
  },
  topTitle: {
    margin: `${typography.pxToRem(30)} 0 ${typography.pxToRem(30)}`,
  },
  subTitle: {
    [breakpoints.down('xs')]: {
      maxWidth: '90%',
      whiteSpace: 'unset',
    },
    margin: `${typography.pxToRem(30)} 0 ${typography.pxToRem(30)}`,
    color: '#b7b9bd',
    maxWidth: 'inherit',
  },
  upgradeParcelNumber: {
    marginTop: 0,
  },
}));

type Props = {
  data: {
    day: string,
    time: Object,
  },
  parcel: AuthorizedParcel,
};

const CompleteStepComponent = ({
  data = { day: '', time: {} },
  parcel,
}: Props) => {
  const { day, time } = data;

  const { year, month, dayOfWeek, dayNumber } = fullDateValue(day);
  const title = `As requested, your parcel will now be delivered on ${dayOfWeek} the ${formatOrdinalNumber(
    dayNumber
  )} ${month} ${year} between <b>${time.startTime} and ${time.endTime}</b>`;

  const subTitle = `${getAddressWithPostcode(
    parcel.deliveryDetails.address
  )}\n `;

  const styles = useStyles();

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={styles.container}
    >
      <VipIcon className={styles.icon} />
      <Typography className={clsx(styles.title, styles.topTitle)}>
        {MESSAGE.GIVEN_THE_VIP}
      </Typography>
      <Typography
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {subTitle && (
        <Typography className={clsx(styles.title, styles.subTitle)}>
          {subTitle}
        </Typography>
      )}
      <CompleteParcelNumber
        parcelNumber={parcel.parcelNumber}
        className={styles.upgradeParcelNumber}
      />
      <TrackAnotherParcelButton />
    </Grid>
  );
};

const createSpeedDealControlActions = props => [
  trackAnotherParcelSpeedDeal({ ...props, replace: true }),
  goToParcelInfoSpeedDeal({ ...props, replace: true }),
  goBackToDeliveryDetailsSpeedDeal(props),
];

const createHeaderControlActions = props => [
  trackAnotherParcelHeader({ ...props, replace: true }),
  goToParcelInfoHeader({ ...props, replace: true }),
  backToDpdSiteHeader(props),
  backToGreenSiteHeader(props),
  getDpdAppHeader(props),
];

const CompleteStep = compose(
  withRouter,
  withModal,
  withPageControl({
    trackable: trackable(DELIVERY_OPTION_CONFIRMATION),
    speedDealActions: createSpeedDealControlActions,
    headerMenuActions: createHeaderControlActions,
  })
)(CompleteStepComponent);

export default CompleteStep;
