import { filterBadWords } from '../../utils/string';
import { API_HOST } from '../../constants/app';

export const submitFeedbackForm = values => {
  const formData = new URLSearchParams();
  const description = filterBadWords(values.comment);

  const body = {
    priority: 'Consumer',
    subject: 'Enquiry from Make It Right Webform',
    captcha_settings:
      '{&quot;keyname&quot;:&quot;DPD&quot;,&quot;fallback&quot;:&quot;true&quot;,&quot;orgId&quot;:&quot;00D20000000MS7w&quot;,&quot;ts&quot;:&quot;1585138775026&quot;}',
    orgid: '00D20000000MS7w',
    type: 'Delivery',
    external: '1',
    '00N2X000003vXBv': 'https://www.dpd.co.uk/forms/make_it_right.jsp',
    recordType: '012w0000000APya',
    origin: 'Email',
    retURL: 'https://www.dpd.co.uk/forms/make_it_right.jsp?Submit=Success',
    '00Nw0000003ji84': 'Consumer',
    '00Nw0000003ji6p': values.contactName,
    '00Nw0000003ji7I': values.trackingNumber,
    '00N2X000003vXC6': values.address,
    '00Nw0000003ji7j': values.postcode,
    '00Nw0000003ji6n': values.mobile,
    '00Nw0000003jiTT': values.email,
    description,
    'g-recaptcha-response': values.recaptcha,
  };

  for (const key in body) {
    formData.append(key, body[key]);
  }

  return fetch(`${API_HOST}/salesforce/feedback`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow',
    body: encodeURI(formData.toString()),
  });
};
