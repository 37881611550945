// @flow
import React from 'react';
import { map, find } from 'lodash';
import { withProps } from 'recompose';
import { compose } from 'redux';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withTrack, withTrackProps } from '@dpdgroupuk/react-event-tracker';
import SubmitButton from '@dpdgroupuk/fmx-ui/components/SubmitButton';
import SelectDay from '@dpdgroupuk/fmx-ui/components/DayPicker';
import CallMePhoneIcon from '@dpdgroupuk/fmx-ui/components/Icons/CallMePhoneIcon';

import * as MESSAGE from '../../../constants/message';
import { RATE_STEPS } from '../steps';
import {
  RATE_DELIVERY_CALL_ME_DATE_SELECT,
  trackable,
} from '../../../constants/analytics';
import withFetch from '../../../HOCs/withFetch';
import { fetchCallMeSlots } from '../actions';

const useStyles = makeStyles(({ typography, palette, breakpoints }) => ({
  chooseDayText: {
    fontSize: typography.pxToRem(20),
    letterSpacing: typography.pxToRem(0.7),
    lineHeight: 1.6,
    color: palette.text.primary,
    marginTop: typography.pxToRem(10),
    marginBottom: typography.pxToRem(30),
    maxWidth: '55%',
    [breakpoints.down('xs')]: {
      marginTop: typography.pxToRem(47),
      marginBottom: typography.pxToRem(19),
      maxWidth: '80%',
    },
  },
  submitButton: {
    marginTop: typography.pxToRem(20),
  },
  submitText: {
    fontSize: typography.pxToRem(13.5),
    lineHeight: 1.2,
    letterSpacing: typography.pxToRem(0.9),
  },
  dayContainer: {
    '& div:first-child': {
      [breakpoints.down('xs')]: {
        marginLeft: typography.pxToRem(8),
      },
      [breakpoints.up('sm')]: {
        marginLeft: typography.pxToRem(20),
      },
    },
  },
  callMePhoneIcon: {
    height: typography.pxToRem(63),
    width: typography.pxToRem(63),
  },
}));

type Props = {
  data: Object,
  onSubmit: Function,
  onSelectDate: Function,
  slotsData: Object,
};

const RateDeliveryCallMeDateSelect = ({
  data,
  onSubmit,
  onSelectDate,
  slotsData = {},
}: Props) => {
  const styles = useStyles();
  const { selectedDate } = data;
  const dates = map(slotsData.callMeSlots, 'date');

  return (
    <Grid>
      <CallMePhoneIcon className={styles.callMePhoneIcon} />

      <Typography className={styles.chooseDayText}>
        {MESSAGE.GREAT_CHOOSE_A_DAY}
      </Typography>

      <SelectDay
        selectedDate={selectedDate}
        onSelect={onSelectDate}
        dates={dates}
        classes={{
          dayContainer: styles.dayContainer,
        }}
      />

      <SubmitButton
        onSubmit={onSubmit}
        isSubmitHidden={!selectedDate}
        className={styles.submitButton}
      >
        <span className={styles.submitText}>Submit</span>
      </SubmitButton>
    </Grid>
  );
};

export default compose(
  withFetch(fetchCallMeSlots, {
    dataPropMapper: 'slotsData',
  }),
  withProps<any, any>(({ addStepData, goToStep, slotsData, data }: any) => ({
    onSelectDate: selectedDate => addStepData({ selectedDate }),
    onSubmit: () => {
      const selectedTimeslot = find(
        slotsData.callMeSlots,
        slot => slot.date === data.selectedDate
      );
      delete selectedTimeslot.date;

      addStepData({
        slotsData: selectedTimeslot,
      });
      goToStep(RATE_STEPS.RATE_DELIVERY_CALL_ME_SLOT_SELECT);
    },
  })),
  withTrack(trackable(RATE_DELIVERY_CALL_ME_DATE_SELECT)),
  withTrackProps({
    onSubmit: RATE_DELIVERY_CALL_ME_DATE_SELECT.ON_SUBMIT,
    onSelectDate: RATE_DELIVERY_CALL_ME_DATE_SELECT.ON_DATE_SELECTION,
  })
)(RateDeliveryCallMeDateSelect);
