import React from 'react';
import { withProps } from 'recompose';
import { compose } from 'redux';

import ContactDetailsForm from '@dpdgroupuk/fmx-ui/components/ContactDetailsForm';
import { withTrackModal } from '@dpdgroupuk/react-event-tracker';

import { getParcelNotificationDetails } from '../../../models/parcel';
import { CONTACT_DETAILS } from '../../../constants/analytics';

const getInitialValues = (parcel, data) => {
  const notiDetails = getParcelNotificationDetails(parcel);
  return {
    contactName: (data && data.contactName) || notiDetails.contactName, // Take contact name from data, if no data then take from parcel notification
    contactPhone: (data && data.contactPhone) || notiDetails.mobile,
    contactEmail: (data && data.contactEmail) || notiDetails.email,
  };
};

const ContactDetailsFormComponent = props => {
  return <ContactDetailsForm {...props} />;
};

export default compose(
  withProps(
    ({ parcel, addStepData, submitWizard, goToStep, nextStep, data }) => ({
      title:
        'Provide your contact information and we will keep you updated on any changes to your delivery',
      initialValues: getInitialValues(parcel, data),
      onChange: ({ contactName, contactPhone, contactEmail }) => {
        addStepData({
          contactName,
          contactPhone,
          contactEmail,
        });
      },
      onSubmit: nextStep
        ? () => {
            goToStep(nextStep);
          }
        : submitWizard, // If the next step is provided, go to it. If not then submit the wizard
      analytics: CONTACT_DETAILS,
    })
  ),
  withTrackModal(CONTACT_DETAILS)
)(ContactDetailsFormComponent);
