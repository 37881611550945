import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';

import { useModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { useGoogleReCaptcha } from '@dpdgroupuk/react-google-recaptcha-v3';
import { useOverlay } from '@dpdgroupuk/fmx-ui/components/Overlay';

import { fetchParcel, loginAndFetchParcel } from '../../store/actions/parcels';
import { getParcelData } from '../../store/orm/Parcel/selectors';
import { ACTION } from '../../constants/recaptcha';
import { POSTCODE_FAILED_MODAL } from '../../constants/analytics';

export const useParcelData = () => {
  const dispatch = useDispatch();
  const { parcelId: parcelCode } = useParams();
  const parcelData = useSelector(state => getParcelData(state, parcelCode));

  return useCallback(() => {
    if (typeof parcelCode !== 'string') {
      throw new Error('No parcel code');
    }

    if (parcelData) {
      return Promise.resolve(parcelData);
    }

    return dispatch(fetchParcel(parcelCode));
  }, [dispatch, parcelCode, parcelData]);
};

export const usePostcodeSubmitCallback = () => {
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  const { parcelId = '' } = params;
  const { showModal } = useModal();
  const { executeRecaptchaAsync } = useGoogleReCaptcha();
  const { showWhile } = useOverlay();

  return useCallback(
    showWhile(async ({ postcode = '' }: {| postcode: string |}) => {
      const token = await executeRecaptchaAsync(ACTION.REFERENCE);

      return dispatch(loginAndFetchParcel(parcelId, postcode, token)).catch(
        error => {
          showModal({
            title: error.message,
            description: null,
            analytics: {
              loadId: POSTCODE_FAILED_MODAL.LOAD,
              interfaceId: POSTCODE_FAILED_MODAL.INTERFACE_ID,
              confirm: POSTCODE_FAILED_MODAL.ON_OK,
              modal: true,
            },
          });
        }
      );
    }),
    [dispatch, parcelId, showModal]
  );
};
