import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import AddIcon from '@material-ui/icons/Add';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { useTracker } from '@dpdgroupuk/react-event-tracker';
import BlockDocument from '@dpdgroupuk/fmx-ui/components/BlockDocument';
import PickupPass from '@dpdgroupuk/fmx-ui/components/Icons/PickupPass';
import Identity from '@dpdgroupuk/fmx-ui/components/Icons/Identity';
import type { Parcel, PickupLocation } from '@dpdgroupuk/fmx-ui/types';
import type { PickupLocationParcel } from '@dpdgroupuk/fmx-ui/types/Parcel';
import type { VouchersInfo } from '@dpdgroupuk/fmx-ui/types/PickupLocation';
import VoucherModal from '@dpdgroupuk/fmx-ui/components/VoucherModal';
import useModalState from '@dpdgroupuk/fmx-ui/hooks/useModalState';
import { formatDayOfWeekDayMonthOrdinal } from '@dpdgroupuk/fmx-ui/utils/date';
import { PICKUP_PASS_HIGH_VALUE_WARNING } from '../../../../../constants/message';
import { PARCEL_VALUE_HIGH } from '../../../../../constants/values';
import { API_HOST } from '../../../../../constants/app';
import { COLLECT_FROM_PICKUP } from '../../../../../constants/analytics';
import { getExpiryDateText } from '../../../../ChangeDeliveryOption/pages/CollectFromPickupShop/utils';
import PickupShopCardWithOpeningHours from '../../../../ChangeDeliveryOption/pages/CollectFromPickupShop/components/PickupShopCardWithOpeningHours';

const useStyles = makeStyles(({ palette, breakpoints, typography }) => ({
  title: {
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(14.4),
      letterSpacing: 0.51,
      marginBottom: typography.pxToRem(9),
    },
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(16),
      letterSpacing: 0.57,
      marginBottom: typography.pxToRem(18),
    },
    textAlign: 'center',
    fontWeight: 400,
  },
  upperCaseTitle: {
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(13.5),
      letterSpacing: 0.44,
      width: typography.pxToRem(236),
      height: typography.pxToRem(36),
    },
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(15),
      letterSpacing: 0.54,
      width: typography.pxToRem(262),
      height: typography.pxToRem(40),
    },
    textAlign: 'center',
    fontWeight: 400,
    color: palette.text.redWhite,
    textTransform: 'uppercase',
  },
  hint: {
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(13),
    },
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(12),
    },
    color: palette.text.secondary,
    letterSpacing: 0.09,
    textAlign: 'center',
    fontWeight: 400,
  },
  link: {
    color: '#1d9ad0',
    fontSize: typography.pxToRem(13),
    letterSpacing: 'normal',
    textAlign: 'center',
    fontWeight: 400,
    textTransform: 'uppercase',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  topContainer: {
    [breakpoints.down('xs')]: {
      marginTop: typography.pxToRem(18),
    },
    [breakpoints.up('sm')]: {
      marginTop: typography.pxToRem(31),
    },
  },
  upperCaseContainer: {
    [breakpoints.down('xs')]: {
      marginTop: typography.pxToRem(15),
    },
    [breakpoints.up('sm')]: {
      marginTop: typography.pxToRem(24),
    },
  },
  hintContainer: {
    [breakpoints.down('xs')]: {
      marginTop: typography.pxToRem(11),
    },
    [breakpoints.up('sm')]: {
      marginTop: typography.pxToRem(15),
    },
  },
  containerBlock: {
    height: typography.pxToRem(100),
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    marginBottom: typography.pxToRem(5),
    '& path': {
      fill: palette.icon.blockIcon,
    },
    '& #RED_IMPORTANT': {
      fill: '#E30238',
    },
  },
  pickupPass: {
    [breakpoints.down('xs')]: {
      height: typography.pxToRem(41.4),
      width: typography.pxToRem(22.5),
    },
    [breakpoints.up('sm')]: {
      height: typography.pxToRem(46),
      width: typography.pxToRem(25),
    },
  },
  identity: {
    [breakpoints.down('xs')]: {
      height: typography.pxToRem(28),
      width: typography.pxToRem(43),
    },
    [breakpoints.up('sm')]: {
      height: typography.pxToRem(32),
      width: typography.pxToRem(48),
    },
  },
  plus: {
    [breakpoints.down('xs')]: {
      marginLeft: typography.pxToRem(3),
      marginRight: typography.pxToRem(3),
      fontSize: typography.pxToRem(22),
    },
    [breakpoints.up('sm')]: {
      marginLeft: typography.pxToRem(7),
      marginRight: typography.pxToRem(7),
      fontSize: typography.pxToRem(24),
    },
    color: palette.icon.default,
  },
  arrow: {
    color: palette.icon.blockIcon,
    marginLeft: typography.pxToRem(13),
    marginBottom: typography.pxToRem(4),
    height: typography.pxToRem(17),
  },
  linkContainer: {
    [breakpoints.down('xs')]: {
      marginTop: typography.pxToRem(38),
      '& #firstLink': {
        marginTop: 0,
        marginBottom: typography.pxToRem(20),
      },
      '& a': {
        marginTop: typography.pxToRem(20),
      },
    },
    [breakpoints.up('sm')]: {
      marginTop: typography.pxToRem(42),
      '& #firstLink': {
        marginTop: 0,
        marginBottom: typography.pxToRem(20),
      },
      '& a': {
        marginTop: typography.pxToRem(20),
      },
    },
  },
  voucherContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [breakpoints.down('xs')]: {
      marginTop: typography.pxToRem(25),
      marginLeft: typography.pxToRem(45),
      justifyContent: 'flex-start',
    },
    [breakpoints.up('sm')]: {
      position: 'absolute',
      left: typography.pxToRem(21),
      bottom: '20%',
      justifyContent: 'center',
    },
  },
  voucherText: {
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(11),
    },
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(13),
    },
    lineHeight: 1.62,
    letterSpacing: 0.11,
    paddingLeft: '15px',
    fontWeight: 400,
    fontFamily: 'PlutoSansRegular',
  },
  shopCardContainer: {
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    [breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& #logo': {
      marginRight: typography.pxToRem(24),
      [breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
    direction: 'row',
    justify: 'flex-start',
    alignItems: 'center',
    [breakpoints.up('sm')]: {
      maxWidth: typography.pxToRem(550),
    },
  },
  expiryAlert: {
    marginTop: typography.pxToRem(5),
    fontStyle: 'normal',
    fontWeight: '450',
    fontSize: '16px',
    lineHeight: '18px',
    letterSpacing: '0.571429px',
    color: '#E5444B',
  },
}));

type Props = {
  parcel: Parcel & PickupLocationParcel,
  vouchersInfo: VouchersInfo,
  pickupLocation: PickupLocation,
};

const CollectFromPickupInfo = ({
  parcel,
  vouchersInfo: { voucherCode, notificationShopText },
  pickupLocation,
}: Props) => {
  const styles = useStyles();
  const { url } = useRouteMatch();
  const tracker = useTracker();
  const [open, show, hide] = useModalState(false);

  const onVoucherTap = React.useCallback(() => {
    show();
    tracker.logEvent(COLLECT_FROM_PICKUP.VOUCHER_TAP);
  }, [tracker, show]);

  const onCloseModal = React.useCallback(() => {
    hide();
    tracker.logEvent(COLLECT_FROM_PICKUP.VOUCHER_CLOSE);
  }, [tracker, hide]);

  const voucherSrc = `${API_HOST}/pickupLocations/${parcel.pickupLocationCode}/vouchers/${voucherCode}`;

  const expiryDateString = React.useMemo(
    () => formatDayOfWeekDayMonthOrdinal(parcel.pickupExpiryDate),
    [parcel.pickupExpiryDate]
  );

  const expiryDateText = React.useMemo(
    () => getExpiryDateText(expiryDateString),
    [expiryDateString]
  );

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={styles.content}
    >
      <Typography className={styles.expiryAlert}>{expiryDateText}</Typography>

      <Grid className={styles.topContainer}>
        <Typography className={styles.title}>
          To collect your parcel, you’ll need:
        </Typography>
      </Grid>
      <Grid container className={styles.containerBlock}>
        <BlockDocument directionRow title="Your DPD Pickup Pass">
          <PickupPass className={clsx(styles.icon, styles.pickupPass)} />
        </BlockDocument>
        {pickupLocation.kind === 'SHP'
          ? parcel.pickupValueGoods === PARCEL_VALUE_HIGH && (
              <>
                <AddIcon className={styles.plus} />
                <BlockDocument
                  title="Photo ID"
                  subtitle="* Valid passport or driver’s license"
                >
                  <Identity className={clsx(styles.icon, styles.identity)} />
                </BlockDocument>
              </>
            )
          : null}
      </Grid>
      <Grid className={styles.upperCaseContainer}>
        {parcel.pickupValueGoods === PARCEL_VALUE_HIGH && (
          <Typography className={styles.upperCaseTitle}>
            {PICKUP_PASS_HIGH_VALUE_WARNING}
          </Typography>
        )}
      </Grid>
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="center"
        className={styles.linkContainer}
      >
        <RouterLink
          to={`${url}/pickupPass`}
          className={styles.link}
          id="firstLink"
        >
          View your pickup pass
        </RouterLink>
        <span className={styles.shopCardContainer}>
          <PickupShopCardWithOpeningHours
            pickupLocation={pickupLocation}
            pointChosen
          />
        </span>

        {/* <RouterLink to={`${url}/shopDetails`} className={styles.link}> */}
        {/*  See more shop details */}
        {/* </RouterLink> */}

        {voucherCode && (
          <Link onClick={onVoucherTap} className={styles.link}>
            {notificationShopText}
          </Link>
        )}
      </Grid>
      <VoucherModal
        open={open}
        onClose={onCloseModal}
        voucherSrc={voucherSrc}
      />
    </Grid>
  );
};

export default CollectFromPickupInfo;
