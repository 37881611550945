import * as React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import type { AuthorizedParcel, Parcel } from '@dpdgroupuk/fmx-ui/types/Parcel';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import DeliveryImages from '@dpdgroupuk/fmx-ui/components/DeliveryImages';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';

import { DELIVERY_IMAGE, trackable } from '../../../../../constants/analytics';
import { MORE_COOL_STUFF } from '../../../../../constants/message';

import TrackingStatus from '../../../../../components/TrackingStatus';
import {
  backToDpdSiteHeader,
  backToDpdSiteSpeedDeal,
  getDpdAppHeader,
  goToParcelInfoHeader,
  goToParcelInfoSpeedDeal,
  trackAnotherParcelSpeedDeal,
  trackAnotherParcelHeader,
  changeDeliverySpeedDeal,
  whatHappensNextSpeedDeal,
} from '../../../../controlActions';
import { getImagesData } from '../model';
import { withParcelInfo } from '../../../hocs';

type Props = {
  parcel: Parcel & AuthorizedParcel,
};

const DeliveryImageView = ({ parcel }: Props) => {
  const images = React.useMemo(() => getImagesData(parcel), [parcel]);

  return (
    <>
      <TrackingStatus status={parcel.parcelStatusHtml} />
      {images && images.length !== 0 && (
        <DeliveryImages images={images} entityCode={parcel.parcelCode} />
      )}
    </>
  );
};

const createHeaderControlActions = props => [
  goToParcelInfoHeader(props),
  trackAnotherParcelHeader(props),
  backToDpdSiteHeader(props),
  getDpdAppHeader(props),
];

const getCardedControlActions = props => [
  goToParcelInfoSpeedDeal(props),
  changeDeliverySpeedDeal(props),
  whatHappensNextSpeedDeal(props),
];

const getDeliveredControlActions = props => [
  backToDpdSiteSpeedDeal(props),
  trackAnotherParcelSpeedDeal(props),
  goToParcelInfoSpeedDeal(props),
];

const createSpeedDealControlActions = props =>
  props.parcel.deliveredToConsumer
    ? getDeliveredControlActions(props)
    : getCardedControlActions(props);

export default compose(
  withRouter,
  withModal,
  withPageControl({
    title: MORE_COOL_STUFF,
    backgroundImage: IMAGES.TRACKING_BG,
    trackable: trackable(DELIVERY_IMAGE),
    headerMenuActions: createHeaderControlActions,
    speedDealActions: createSpeedDealControlActions,
    // isMobileMenuShevronVisible: true,
  }),
  withParcelInfo()
)(DeliveryImageView);
