import React from 'react';

const RadioSquare = (props: Object) =>
  props.selected ? (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="1.5"
        fill="#438DED"
        stroke="#438DED"
      />
      <g clipPath="url(#clip0_408_11440)">
        <path
          d="M6.25006 10.45L3.80007 8L2.9834 8.81666L6.25006 12.0833L13.2501 5.08333L12.4334 4.26666L6.25006 10.45Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_408_11440">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="1.5"
        fill="white"
        stroke="#414042"
      />
    </svg>
  );

export default RadioSquare;
