import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from '@dpdgroupuk/redux-action-creator';

import { apiService, fetchTimeoutHandler } from '../../../services';
import type { ChangeDeliveryOptionOptions } from '../../../apis';

export const ACTIONS = createActionTypes('PARCEL', {
  FETCH_PARCEL_BY_CODE: createAsyncActionTypes('FETCH_PARCEL_BY_CODE'),
  CHANGE_DELIVERY_OPTION: createAsyncActionTypes('CHANGE_DELIVERY_OPTION'),
});

export const fetchParcelByCode = createAsyncAction(
  (parcelCode: string) =>
    fetchTimeoutHandler(apiService.getParcelByParcelCode(parcelCode)).then(
      ({ data }) => data
    ),
  ACTIONS.FETCH_PARCEL_BY_CODE
);

export const changeDeliveryOption = createAsyncAction(
  (parcelCode: string, data: any, options: ChangeDeliveryOptionOptions) =>
    fetchTimeoutHandler(
      apiService.changeDeliveryOption(parcelCode, data, options)
    ).then(({ data }) => data),
  ACTIONS.CHANGE_DELIVERY_OPTION
);
