// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import StarRateIcon from '@material-ui/icons/StarRate';
import { Typography } from '@material-ui/core';

import PhotoWithFlag from '@dpdgroupuk/fmx-ui/components/DriverProfile/PhotoWithFlag';

const useStyles = makeStyles(({ typography, palette }) => ({
  wrapper: {
    width: typography.pxToRem(120),
    display: 'flex',
    flexDirection: 'column',
  },
  photo: {
    maxHeight: '6.125rem',
    borderRadius: '50%',
    width: '6.125rem',
    marginBottom: typography.pxToRem(35),
    alignSelf: 'center',
  },
  info: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  name: {
    fontSize: typography.pxToRem(15),
    color: palette.text.primary,
  },
  rating: {
    fontSize: typography.pxToRem(20),
    fontWeight: 300,
    letterSpacing: typography.pxToRem(0.63),
    minWidth: typography.pxToRem(55),
    marginLeft: typography.pxToRem(16),
  },
}));

type Props = {
  photo: string,
  name: string,
  rating: number,
};

const DriverWithRating = ({ photo, name, rating }: Props) => {
  const styles = useStyles();
  const positiveRating = rating > 3;

  return (
    <div className={styles.wrapper}>
      <PhotoWithFlag photo={photo} className={styles.photo} />
      <div className={styles.info}>
        <Typography className={styles.name}>{name}</Typography>
        {positiveRating && (
          <Typography className={styles.rating}>
            {rating.toFixed(1)}
            <StarRateIcon htmlColor="#dc0032" />
          </Typography>
        )}
      </div>
    </div>
  );
};

export default DriverWithRating;
