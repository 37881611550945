import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { get } from 'lodash';
import { compose, withProps } from 'recompose';
import SubmitButton from '@dpdgroupuk/fmx-ui/components/SubmitButton';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';

import RateWithPhoto from '../../../components/Rate/RateWithPhoto';
import { NegativeCompliments, PositiveCompliments } from './DriverCompliments';
import SkipButton from '../../../components/Rate/Button';
import type { Driver } from '../../../types';
import * as MESSAGE from '../../../constants/message';
import { DRIVER_COMPLIMENTS } from '../constants';
import RateDriverNotes from './RateDriverNotes';
import { RATE_DRIVER } from '../../../constants/analytics';

const { REACT_APP_DRIVER_NOTES_ENABLED } = process.env;

const useStyles = makeStyles(({ typography, breakpoints, palette }) => {
  return {
    container: {
      padding: typography.pxToRem(20),
    },
    text: {
      marginTop: typography.pxToRem(41),
      fontSize: typography.pxToRem(14),
      letterSpacing: typography.pxToRem(0.57),
      [breakpoints.down('xs')]: {
        marginTop: typography.pxToRem(25),
      },
    },
    complimentBlock: {
      marginTop: typography.pxToRem(25),
      [breakpoints.down('xs')]: {
        marginTop: typography.pxToRem(22),
      },
    },
    skipBlock: {
      marginTop: typography.pxToRem(20),
      marginBottom: typography.pxToRem(10),
      [breakpoints.down('xs')]: {
        marginTop: typography.pxToRem(16),
      },
    },
    customComplimentWrapper: {
      marginTop: typography.pxToRem(41),
      [breakpoints.down('xs')]: {
        marginTop: typography.pxToRem(31),
      },

      '& .MuiButton-contained.Mui-disabled': {
        boxShadow: 'none',
      },
      '& .MuiFormHelperText-root': {
        display: 'none',
      },
    },
    sendButton: {
      height: typography.pxToRem(29),
      boxSizing: 'border-box',
      padding: 0,
      letterSpacing: '0.35px',
      width: typography.pxToRem(51),
      minWidth: typography.pxToRem(51),
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      boxShadow: 'none',

      '& .MuiButton-label': {
        fontSize: typography.pxToRem(9.9),
      },
    },
    header: {
      fontSize: typography.pxToRem(16),
      marginBottom: typography.pxToRem(10),
      color: palette.text.primary,
      [breakpoints.down('xs')]: {
        marginTop: typography.pxToRem(20),
      },
    },
    submitButton: {
      marginTop: typography.pxToRem(25),
    },
  };
});

type Props = {
  data: any,
  onSelectRating: Function,
  driver: Driver,
  onSelectCompliment: Function,
  onFeedbackSubmit: Function,
  onSkip: Function,
  onChange: Function,
  setActive: Function,
};

const RateDriverCompliment = ({
  data,
  onSelectRating,
  driver,
  onSelectCompliment,
  onFeedbackSubmit,
  onSkip,
  onChange,
  setActive,
}: Props) => {
  const styles = useStyles();
  const driverName = get(driver, 'driverName');
  const { driverComplimentIcon = '' } = data;
  const [submitVisible, setSubmitVisible] = React.useState(false);

  return (
    <Grid className={styles.container}>
      {data.driverRating < 3 && (
        <Typography className={styles.header}>
          {MESSAGE.DRIVER_FEEDBACK_QUESTION.replace(
            '{{driverName}}',
            driverName
          )}
        </Typography>
      )}
      <RateWithPhoto
        driver={driver}
        onChange={(...args) => {
          onSelectRating(...args);
          setSubmitVisible(false);
        }}
        value={data.driverRating}
      />
      <Typography className={styles.text}>
        {data.driverRating >= 3 ? (
          <>
            {MESSAGE.DRIVER_RATING_POSITIVE}
            <br />{' '}
            {MESSAGE.DRIVER_DID_BEST.replace(
              '{{driverName}}',
              driver.driverName
            )}
          </>
        ) : (
          <>{MESSAGE.DRIVER_RATING_NEGATIVE}</>
        )}
      </Typography>
      <div className={styles.complimentBlock}>
        {data.driverRating >= 3 ? (
          <PositiveCompliments
            active={driverComplimentIcon}
            setActive={setActive}
            onComplete={() => setSubmitVisible(true)}
          />
        ) : (
          <NegativeCompliments
            active={driverComplimentIcon}
            setActive={setActive}
            onComplete={() => setSubmitVisible(true)}
          />
        )}
      </div>
      {REACT_APP_DRIVER_NOTES_ENABLED === 'true' && data.driverRating >= 3 ? (
        <div className={styles.customComplimentWrapper}>
          <RateDriverNotes
            onSubmit={onFeedbackSubmit}
            onChange={values => {
              setSubmitVisible(!!values.driverNotes);
              onChange(values);
            }}
          />
        </div>
      ) : null}

      <SubmitButton
        onSubmit={() => onSelectCompliment(driverComplimentIcon)}
        isSubmitHidden={
          (!driverComplimentIcon && !data.driverNotes) || !submitVisible
        }
        className={styles.submitButton}
      />
      <div className={styles.skipBlock}>
        <SkipButton onClick={onSkip}>
          {data.driverRating >= 3 ? MESSAGE.SKIP_COMPLIMENT : MESSAGE.SKIP}
        </SkipButton>
      </div>
    </Grid>
  );
};

export default compose(
  withProps(
    ({
      addStepData,
      data: { driverComplimentType, driverComplimentIcon },
      submitWizard,
    }) => ({
      setActive: value => {
        addStepData({
          driverComplimentType: DRIVER_COMPLIMENTS[value],
          driverComplimentIcon: value,
        });
      },
      onSelectRating: (e, value) => {
        addStepData({
          driverRating: value,
          driverComplimentType: '',
          driverComplimentIcon: '',
        });
      },
      onChange: values => {
        addStepData({
          driverNotes: values.driverNotes,
        });
      },
      onFeedbackSubmit: () => submitWizard(),
      onSelectCompliment: value => {
        const driverData = value
          ? { driverComplimentType, driverComplimentIcon }
          : {
              driverComplimentType: DRIVER_COMPLIMENTS.NOT_SET,
              driverComplimentIcon: value,
            };

        !value && addStepData(driverData);
        submitWizard({ stepData: driverData });
      },
    })
  ),
  withProps(({ onSelectCompliment }) => ({
    onSkip: () => onSelectCompliment(null),
  })),
  withTrackProps({
    onSelectCompliment: RATE_DRIVER.ON_CLICK_COMPLIMENT,
    onSkip: RATE_DRIVER.ON_SKIP_COMPLIMENT,
    onSelectRating: RATE_DRIVER.ON_CLICK_STAR,
  })
)(RateDriverCompliment);
