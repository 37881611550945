export const RATE_STEPS = {
  RATE_EXPERIENCE: 'RateExperience',
  RATE_DELIVERY: 'RateDelivery',
  RATE_DRIVER: 'RateDriver',
  RATE_DRIVER_COMPLIMENT: 'RateDriverCompliment',
  DRIVER_COMPLETE: 'DriverComplete',
  DELIVERY_NEGATIVE_COMPLETE: 'DeliveryNegativeComplete',
  DELIVERY_NO_INVESTIGATION_COMPLETE: 'DeliveryNoInvestigationComplete',
  RATE_DELIVERY_CALL_ME_OPTION: 'RateDeliveryCallMeOption',
  RATE_DELIVERY_CALL_ME_DATE_SELECT: 'RateDeliveryCallMeDateSelect',
  RATE_DELIVERY_CALL_ME_SLOT_SELECT: 'RateDeliveryCallMeSlotSelect',
  RATE_DELIVERY_CALL_ME_CONTACT_NUMBER: 'RateDeliveryCallMeContactNumber',
  RATE_DELIVERY_CALL_ME_CONFIRMATION: 'RateDeliveryCallMeConfirmation',
};
