import { changeDeliveryOption } from '../../../../store/orm/Parcel/actions';
import type { ChangeDeliveryOptionOptions } from '../../../../apis';

export const changeCollectFromPickup = (
  parcelCode: string,
  {
    pickupDate,
    pickupLocationCode,
    contactName,
    contactEmail,
    contactPhone,
  }: {
    pickupDate: string,
    pickupLocationCode: string,
    contactName: string,
    contactEmail: string,
    contactPhone: string,
  },
  options: ChangeDeliveryOptionOptions
) => (dispatch: Function) =>
  dispatch(
    changeDeliveryOption(
      parcelCode,
      {
        pickupDate,
        pickupLocationCode,
        contactName,
        email: contactEmail,
        mobile: contactPhone,
      },
      options
    )
  );
