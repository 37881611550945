import { StatusCodes } from 'http-status-codes';

import * as apiService from '../apis';

const fetchTimeoutHandler = fetchRequest =>
  Promise.race([
    fetchRequest,
    new Promise((resolve, reject) =>
      setTimeout(
        () =>
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({
            statusCode: StatusCodes.REQUEST_TIMEOUT,
          }),
        30000
      )
    ),
  ]);

export { apiService, fetchTimeoutHandler };
