import * as React from 'react';
import OnTime from '@dpdgroupuk/fmx-ui/images/Rating/OnTime.png';
import RateInformed from '@dpdgroupuk/fmx-ui/images/Rating/Informed.png';
import Followed from '@dpdgroupuk/fmx-ui/images/Rating/Followed.png';
import NotReceived from '@dpdgroupuk/fmx-ui/images/Rating/NotReceived.png';
import NotGreat from '@dpdgroupuk/fmx-ui/images/Rating/NotGreat.png';
import Complaint from '@dpdgroupuk/fmx-ui/images/Rating/Complaint.png';
import Respect from '@dpdgroupuk/fmx-ui/images/Rating/Respect.png';
import Damaged from '@dpdgroupuk/fmx-ui/images/Rating/Damaged.png';
import Rude from '@dpdgroupuk/fmx-ui/images/Rating/Rude.png';
import DidntFollow from '@dpdgroupuk/fmx-ui/images/Rating/Instructions.png';
import Care from '@dpdgroupuk/fmx-ui/images/Rating/Care.png';
import Extramile from '@dpdgroupuk/fmx-ui/images/Rating/ExtraMile.png';
import Friendly from '@dpdgroupuk/fmx-ui/images/Rating/FriendlyManner.png';
import WrongPlace from '@dpdgroupuk/fmx-ui/images/Rating/WrongPlace.png';
import * as ENUM from '@dpdgroupuk/redback-enums';

export const DRIVER_COMPLIMENTS = ENUM.COMPLIMENT_TYPE.DRIVER;
export const DELIVERY_COMPLIMENTS = ENUM.COMPLIMENT_TYPE.DELIVERY;

export const RATE_INFO_NAMES = {
  ON_TIME: 'DELIVERED_ON_TIME',
  INFORMED: 'KEPT_ME_INFORMED',
  FOLLOWED: 'FOLLOWED_MY_INSTRUCTIONS',
  NOT_RECEIVED: 'HAVE_NOT_GOT_MY_PARCEL',
  NOT_GREAT: 'BAD_EXPERIENCE',
  COMPLAINT: 'DELIVERY_COMPLAINTS',
  WRONG_PLACE: 'DELIVERED_TO_WRONG_PLACE',
  RESPECT: 'RESPECTED_MY_PROPERTY',
  CARE: 'DELIVERED_WITH_CARE',
  EXTRA_MILE: 'WENT_EXTRA_MILE',
  FRIENDLY: 'FRIENDLY_MANNER',
  DAMAGED: 'NOT_DELIVERED_CAREFULLY',
  RUDE: 'DRIVER_WAS_RUDE',
  DIDNT_FOLLOW: 'NOT_FOLLOW_INSTRUCTIONS',
};

export const RATE_INFO_ICONS = {
  [RATE_INFO_NAMES.ON_TIME]: <img src={OnTime} alt="" />,
  [RATE_INFO_NAMES.INFORMED]: <img src={RateInformed} alt="" />,
  [RATE_INFO_NAMES.FOLLOWED]: <img src={Followed} alt="" />,
  [RATE_INFO_NAMES.NOT_RECEIVED]: <img src={NotReceived} alt="" />,
  [RATE_INFO_NAMES.NOT_GREAT]: <img src={NotGreat} alt="" />,
  [RATE_INFO_NAMES.COMPLAINT]: <img src={Complaint} alt="" />,
  [RATE_INFO_NAMES.WRONG_PLACE]: <img src={WrongPlace} alt="" />,
  [RATE_INFO_NAMES.RESPECT]: <img src={Respect} alt="" />,
  [RATE_INFO_NAMES.CARE]: <img src={Care} alt="" />,
  [RATE_INFO_NAMES.EXTRA_MILE]: <img src={Extramile} alt="" />,
  [RATE_INFO_NAMES.FRIENDLY]: <img src={Friendly} alt="" />,
  [RATE_INFO_NAMES.DAMAGED]: <img src={Damaged} alt="" />,
  [RATE_INFO_NAMES.RUDE]: <img src={Rude} alt="" />,
  [RATE_INFO_NAMES.DIDNT_FOLLOW]: <img src={DidntFollow} alt="" />,
};
