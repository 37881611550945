import React from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary as MuiExpansionPanelSummary,
  Grid,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  BREAKDOWN_OF_CONTENTS,
  ITEM_DESCRIPTION,
  QUANTITY,
} from '../../../../../constants/message';

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    background: 'transparent',
    border: 'none',
    marginTop: '1.875rem',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
      marginTop: '1.875rem',
    },
    '@media (max-width: 600px)': {
      paddingLeft: '0.625rem',
      paddingRight: '0.625rem',
    },
  },
  expanded: {
    margin: 0,
    marginTop: '1.875rem',
  },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    padding: 0,
    minHeight: 'unset',
    flexWrap: 'nowrap',
    '&$expanded': {
      minHeight: 'unset',
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
    '@media (max-width: 380px)': {
      maxWidth: '90%',
    },
  },
  expanded: {
    margin: 0,
  },
  expandIcon: {
    padding: 0,
    marginLeft: '0.5rem',
    color: '#979797',
    '@media (max-width: 600px)': {
      marginLeft: 0,
    },
  },
})(MuiExpansionPanelSummary);

const useStyles = makeStyles(({ breakpoints, typography, palette }) => ({
  panelHeader: {
    fontSize: typography.pxToRem(20),
    color: '#1d9ad0',
    letterSpacing: typography.pxToRem(0.44),

    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
    },
  },
  dutyPanelDetails: {
    padding: 0,
  },
  dutyDetails: {
    maxWidth: typography.pxToRem(280),
    marginTop: typography.pxToRem(16),
  },
  divider: {
    width: typography.pxToRem(2),
    height: typography.pxToRem(54),
    background: '#979797',
    marginTop: typography.pxToRem(5),
    flex: 'none',
  },
  dutyDetailsTitle: {
    fontSize: typography.pxToRem(18),

    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(14),
    },
  },
  dutyDetailsItem: {
    fontSize: typography.pxToRem(15),
    fontWeight: 300,
    lineHeight: 1.61,
    marginTop: typography.pxToRem(16),

    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(14),
    },
  },
  dividerTitle: {
    background: '#FFFFFF',
    width: typography.pxToRem(2),
    height: typography.pxToRem(30),
  },
  itemRight: {
    marginLeft: typography.pxToRem(20),
  },
}));

type Props = {
  customsDescription: Array,
};

const DutyDescriptionPanel = ({ customsDescription }: Props) => {
  const styles = useStyles();

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={styles.panelHeader}>
          {BREAKDOWN_OF_CONTENTS}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        classes={{
          root: styles.dutyPanelDetails,
        }}
      >
        <Grid container className={styles.dutyDetails}>
          <Grid item xs={8} className={clsx(styles.dutyDetailsTitle)}>
            {ITEM_DESCRIPTION}
          </Grid>
          <Grid
            item
            xs={1}
            className={clsx(styles.divider, styles.dividerTitle)}
          />
          <Grid
            item
            xs={3}
            className={clsx(styles.dutyDetailsTitle, styles.itemRight)}
          >
            {QUANTITY}
          </Grid>
          {customsDescription.map(item => (
            <>
              <Grid item xs={8} className={clsx(styles.dutyDetailsItem)}>
                {item.customs_item_description}
              </Grid>
              <Grid item xs={1} className={styles.divider} />
              <Grid
                item
                xs={3}
                className={clsx(styles.dutyDetailsItem, styles.itemRight)}
              >
                {item.customs_item_quantity}
              </Grid>
            </>
          ))}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default DutyDescriptionPanel;
