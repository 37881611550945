export const WEEK_DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const responsiveCarouselMap = {
  '0': {
    items: 1,
  },
};

export const mapOffsetBottom = 17.8125;
