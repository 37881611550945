import { formatDefaultDate, getTimeArray } from '@dpdgroupuk/fmx-ui/utils/date';

import { fetchActionsDatesByCode } from '../../actions';
import { DepotActions } from '../../../../store/orm/Depot';
import { changeDeliveryOption } from '../../../../store/orm/Parcel/actions';
import type { ChangeDeliveryOptionOptions } from '../../../../apis';

export const fetchDepotAndDates = (
  parcelCode,
  actionCode,
  depotCode
) => dispatch => {
  return Promise.all([
    dispatch(fetchActionsDatesByCode(parcelCode, actionCode)),
    dispatch(DepotActions.fetchDepotByCode(depotCode)),
  ]).then(([dates, depot]) => ({ dates, depot }));
};

export const changeCollectFromDepot = (
  parcelCode: string,
  {
    day,
    diaryTime,
    contactName,
    contactEmail,
    contactPhone,
  }: {
    day: string,
    diaryTime: string,
    contactName: string,
    contactEmail: string,
    contactPhone: string,
  },
  options: ChangeDeliveryOptionOptions
) => (dispatch: Function) =>
  dispatch(
    changeDeliveryOption(
      parcelCode,
      {
        diaryDate: formatDefaultDate(day),
        diaryTime: getTimeArray(diaryTime)[0],
        contactName,
        contactEmail,
        contactPhone,
      },
      options
    )
  );
