import * as React from 'react';
import { compose } from 'redux';
import { withProps, withState } from 'recompose';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import { get } from 'lodash';

import ArrowsCarousel from '@dpdgroupuk/fmx-ui/components/ArrowsCarousel';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { isMobile } from '@dpdgroupuk/fmx-ui/utils/helpers';
import { useTracker } from '@dpdgroupuk/react-event-tracker';
import { useSpeedDealMenuOpened } from '@dpdgroupuk/fmx-ui/components/Page/hooks';
import {
  formatDayOfWeekDayMonthOrdinal,
  formatDefaultDate,
} from '@dpdgroupuk/fmx-ui/utils/date';

import SelectPickupShopStepMobile from './SelectPickupShopStepMobile';
import { PKU_STEPS } from '../../../steps';
import PickupShopsMap from './PickupShopsMap';
import {
  hasParcelNotificationDetails,
  getContactInformation,
} from '../../../../../models/parcel';
import ConfirmationDialog from './ConfirmationDialog';
import GetPickupShopSlides from '../GetPickupShopSlides';
import {
  getParcelAvailableDate,
  getPickupShopName,
  getParcelPickupStatus,
  getParcelPickupStatusModal,
  getSelectedPickupShopIndex,
} from '../utils';
import { responsiveCarouselMap, mapOffsetBottom } from '../constants';
import {
  whatHappensNextSpeedDeal,
  doSomethingDifferentSpeedDeal,
  goBackToDeliveryDetailsSpeedDeal,
} from '../../../../controlActions';
import NoPickupShops from './NoPickupShops';
import { PICKUP_SHOP_DELIVERY_OPTION } from '../../../../../constants/analytics';

const useStyles = makeStyles(({ palette, typography, breakpoints }) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100%',

    '& canvas': {
      height: `calc(100vh - ${mapOffsetBottom}rem)`,
      [breakpoints.down(1025)]: {
        height: '46vh !important',
      },
    },
  },
  container: {
    padding: `${typography.pxToRem(11)} ${typography.pxToRem(
      30
    )} 0 ${typography.pxToRem(30)}`,
    background: palette.background.default,
    width: '100%',
    boxSizing: 'border-box',
    height: 'auto',

    [breakpoints.down(1025)]: {
      padding: 0,
      paddingTop: typography.pxToRem(23),
      paddingBottom: typography.pxToRem(200),
      height: typography.pxToRem(500),
      boxSizing: 'content-box',
    },
  },
  flex: {
    display: 'flex',
  },
  status: {
    marginBottom: typography.pxToRem(10),
    color: palette.text.primary,
    fontSize: typography.pxToRem(19),
    lineHeight: 1.78,
    letterSpacing: 0,
    textAlign: 'center',
  },
  list: {
    listStyle: 'none',
    padding: `0 ${typography.pxToRem(20)}`,
    height: typography.pxToRem(430),
    overflowY: 'auto',
  },
  listItem: {
    marginBottom: typography.pxToRem(40),
  },
  arrowIcon: {
    width: typography.pxToRem(16),
    height: typography.pxToRem(33),
  },
  noShops: {
    marginTop: typography.pxToRem(-20),
    marginBottom: typography.pxToRem(-20),
    width: '100%',
    backgroundColor: palette.background.modal,
    [breakpoints.down('xs')]: {
      paddingBottom: typography.pxToRem(250),
    },
  },
  carouselWrapper: {
    width: typography.pxToRem(794),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  bold: {
    textAlign: 'center',
    fontWeight: 500,
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
      lineHeight: '23.44px',
      fontWeight: 500,
    },
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(18),
      lineHeight: '23.44px',
    },
  },
  statusContainer: {
    fontWeight: 300,
    marginTop: typography.pxToRem(17.5),
    fontSize: typography.pxToRem(16),
  },
}));

type Props = {
  parcel: any,
  pickupShops: any,
  customerName: string,
  isDialogOpen: boolean,
  onSelect: Function,
  onDeliveryUpdate: Function,
  setDialogOpen: Function,
  onConfirmationClose: Function,
};

const SelectPickupShopStep = ({
  parcel,
  pickupShops = [],
  onSelect,
  customerName,
  onDeliveryUpdate,
  isDialogOpen,
  setDialogOpen,
  onConfirmationClose,
}: Props) => {
  const tracker = useTracker();
  const bottomComponentRef = React.useRef({});
  const mapRef = React.useRef({});
  const wrapperRef = React.useRef({});
  const mapHeight = get(mapRef, 'current.container.clientHeight');
  const [selectedShopCode, selectPickupShop] = React.useState(
    get(pickupShops, '[0].pickupLocation.pickupLocationCode')
  );
  const [
    pickupShopSelectionChanged,
    setPickupShopSelectionChanged,
  ] = React.useState(false);
  const [currentCarouselIndex, setCurrentCarouselIndex] = React.useState(0);
  const noPickupShops = !pickupShops || pickupShops.length === 0;

  useSpeedDealMenuOpened(noPickupShops);

  const styles = useStyles({
    footerHeight: bottomComponentRef.current.clientHeight,
    mapHeight,
  });

  const mapContainerStyles = () =>
    window.innerWidth < 1025
      ? { height: '46vh' }
      : { height: `calc(100vh - ${mapOffsetBottom}rem)` };

  const onCardClick = React.useCallback(
    id => () => {
      selectPickupShop(id);
      setPickupShopSelectionChanged(true);

      const currentShopIndex = getSelectedPickupShopIndex({
        pickupShops,
        selectedShopCode: id,
      });

      setCurrentCarouselIndex(currentShopIndex);

      if (isMobile) {
        wrapperRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [pickupShops]
  );
  const onSlideChange = React.useCallback(
    index => {
      const currentShopCode = get(
        pickupShops[index],
        'pickupLocation.pickupLocationCode'
      );

      setCurrentCarouselIndex(index);
      selectPickupShop(currentShopCode);
      setPickupShopSelectionChanged(true);

      if (index < currentCarouselIndex) {
        tracker.logEvent(PICKUP_SHOP_DELIVERY_OPTION.ON_CLICK_LEFT);
      } else {
        tracker.logEvent(PICKUP_SHOP_DELIVERY_OPTION.ON_CLICK_RIGHT);
      }
    },
    [pickupShops, currentCarouselIndex, tracker]
  );

  const selectedShopIndex = React.useMemo(
    () =>
      getSelectedPickupShopIndex({
        pickupShops,
        selectedShopCode,
      }),
    [pickupShops, selectedShopCode]
  );

  const selectedShopItem = React.useMemo(() => pickupShops[selectedShopIndex], [
    pickupShops,
    selectedShopIndex,
  ]);

  const parcelAvailableDate = React.useMemo(
    () => getParcelAvailableDate(parcel, selectedShopItem),
    [parcel, selectedShopItem]
  );

  const onSelectClick = React.useCallback(
    id => () => {
      selectPickupShop(id);
      setPickupShopSelectionChanged(true);
      onSelect(formatDefaultDate(parcelAvailableDate), id);
      setDialogOpen(true);
    },
    [setDialogOpen, parcelAvailableDate, onSelect]
  );

  const parcelAvailableDateFormatted = React.useMemo(
    () => formatDayOfWeekDayMonthOrdinal(parcelAvailableDate),
    [parcelAvailableDate]
  );

  const selectedPickupShopName = React.useMemo(
    () => getPickupShopName(selectedShopItem),
    [selectedShopItem]
  );

  const parcelPickupStatus = React.useMemo(
    () =>
      getParcelPickupStatus({
        customerName,
        date: parcelAvailableDateFormatted,
        boldStyles: styles.bold,
        containerStyles: styles.statusContainer,
      }),
    [customerName, parcelAvailableDateFormatted, styles.bold]
  );

  const parcelAvailableDateModal = React.useMemo(
    () => formatDayOfWeekDayMonthOrdinal(parcelAvailableDate),
    [parcelAvailableDate]
  );
  const parcelPickupStatusModal = React.useMemo(
    () =>
      getParcelPickupStatusModal({
        shopName: selectedPickupShopName,
        date: parcelAvailableDateModal,
        kind: selectedShopItem.pickupLocation.kind,
      }),
    [
      selectedPickupShopName,
      parcelAvailableDateModal,
      selectedShopItem.pickupLocation.kind,
    ]
  );

  const pickupShopSlides = React.useMemo(
    () =>
      GetPickupShopSlides({
        pickupShops,
        selectedShopCode,
        onCardClick,
        onSelectClick,
      }),
    [selectedShopCode, pickupShops, onCardClick, onSelectClick]
  );

  if (!pickupShops || pickupShops.length === 0) {
    return <NoPickupShops wrapperClassName={styles.noShops} />;
  }

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      <PickupShopsMap
        mapRef={mapRef}
        pickupShops={pickupShops}
        parcelDestination={get(
          parcel,
          'deliveryDetails.address.addressPoint',
          {}
        )}
        containerStyle={{ marginTop: '4.125rem', ...mapContainerStyles() }}
        onMarkerClick={onCardClick}
        selectedPickupShop={selectedShopItem}
        pickupShopSelectionChanged={pickupShopSelectionChanged}
      />
      {window.innerWidth < 1025 ? (
        <SelectPickupShopStepMobile
          bottomComponentRef={bottomComponentRef}
          mapHeight={mapHeight}
          listItems={pickupShopSlides}
          parcelPickupStatus={parcelPickupStatus}
        />
      ) : (
        <Grid
          className={styles.container}
          /* $FlowFixMe This comment suppresses an error found when upgrading Flow
           * to v0.112.0. To view the error, delete this comment and run Flow. */
          ref={bottomComponentRef}
        >
          <Typography className={styles.status}>
            {parcelPickupStatus}
          </Typography>
          <div className={styles.carouselWrapper}>
            <ArrowsCarousel
              mouseTrackingEnabled
              touchTrackingEnabled
              dotsDisabled
              buttonsDisabled
              infinite={false}
              items={pickupShopSlides}
              responsive={responsiveCarouselMap}
              swipeDisabled={!isMobile}
              arrowIconClassName={styles.arrowIcon}
              slideIndex={currentCarouselIndex}
              slideToIndex={currentCarouselIndex}
              onSlideIndexChange={onSlideChange}
            />
          </div>
        </Grid>
      )}
      <ConfirmationDialog
        open={isDialogOpen}
        onClose={onConfirmationClose}
        title={parcelPickupStatusModal}
        onConfirmClick={onDeliveryUpdate}
      />
    </div>
  );
};

const createSpeedDealControlActions = props => [
  whatHappensNextSpeedDeal(props),
  goBackToDeliveryDetailsSpeedDeal(props),
  doSomethingDifferentSpeedDeal(props),
];

export default compose(
  withState('isDialogOpen', 'setDialogOpen', false),
  withProps(
    ({ addStepData, parcel, goToStep, submitWizard, setDialogOpen }: any) => ({
      customerName: get(parcel, 'shipperDetails.customerDisplayName'),
      onSelect: (pickupDate, pickupLocationCode) => {
        addStepData({
          pickupDate,
          pickupLocationCode,
          ...getContactInformation(parcel),
        });
      },
      onDeliveryUpdate: hasParcelNotificationDetails(parcel)
        ? submitWizard
        : () => goToStep(PKU_STEPS.GET_CONTACTS),
      onConfirmationClose: () => setDialogOpen(false),
    })
  ),
  withPageControl({
    speedDealActions: createSpeedDealControlActions,
  })
)(SelectPickupShopStep);
