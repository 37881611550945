import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { branch, withProps } from 'recompose';
import * as ENUM from '@dpdgroupuk/redback-enums';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import type { Action } from '@dpdgroupuk/fmx-ui/types';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes/constants';
import OptionsList from '@dpdgroupuk/fmx-ui/components/OptionsList';
import { ParcelOptionIcon } from '@dpdgroupuk/fmx-ui/components/OptionIcon';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';
import { formatMessage } from '@dpdgroupuk/fmx-ui/utils/string';

import { DELIVERY_OPTIONS, trackable } from '../../../constants/analytics';
import {
  backToDpdSiteHeader,
  backToGreenSiteHeader,
  getDpdAppHeader,
  goToParcelInfoHeader,
  goToParcelInfoSpeedDeal,
  goBackToDeliveryDetailsSpeedDeal,
  trackAnotherParcelHeader,
  whatHappensNextHeader,
  whatHappensNextSpeedDeal,
} from '../../controlActions';
import { goToChangeDelivery } from '../../historyActions';
import { getParcelData } from '../../../store/orm/Parcel/selectors';
import { getDeliveryActionsByDepotCode } from '../../../store/orm/Action/selectors';
import withParcel from '../../../HOCs/withParcel';
import { withRemoteConfig } from '../../../components/RemoteConfig/ConfigContext';
import * as MESSAGES from '../../../constants/message';
import withFetch from '../../../HOCs/withFetch';
import { fetchDeliveryActions } from '../actions';
import { getLocationChangeDelivery } from '../../Parcel/actions/location';
import NoDeliveryOptions from './NoDeliveryOptions';

type Props = {
  deliveryActions: Action,
  onOptionSelect: Function,
  vip: object,
};

const DeliveryOptionsComponent = ({
  deliveryActions,
  onOptionSelect,
  vip,
}: Props) => {
  const actionsSubtitles = {
    [ENUM.PARCEL_ACTION_CODE.UPG]: formatMessage(
      MESSAGES.PICK_YOUR_SLOT_$,
      vip.after12
    ),
  };

  if (
    !deliveryActions ||
    !deliveryActions.availableActions ||
    get(deliveryActions, 'availableActions', []).length === 0
  ) {
    return <NoDeliveryOptions />;
  }
  return (
    <OptionsList
      title="What would you like to do?"
      unavailableOptionText="This option isn't available on this delivery"
      actions={deliveryActions}
      onOptionSelect={onOptionSelect}
      getOptionIcon={() => ParcelOptionIcon}
      actionSubtitles={actionsSubtitles}
    />
  );
};

const createSpeedDealControlActions = props => [
  goToParcelInfoSpeedDeal(props),
  goBackToDeliveryDetailsSpeedDeal(props),
  whatHappensNextSpeedDeal(props),
];
const mapStateToProps = (state, { match }) => {
  const parcelId = match.params.parcelId;

  return {
    deliveryActions: getDeliveryActionsByDepotCode(state, parcelId),
    parcel: getParcelData(state, parcelId),
  };
};

const createHeaderControlActions = props => [
  trackAnotherParcelHeader(props),
  goToParcelInfoHeader(props),
  whatHappensNextHeader(props),
  backToDpdSiteHeader(props),
  backToGreenSiteHeader(props),
  getDpdAppHeader(props),
];

const DeliveryOptions = compose(
  withParcel(),
  withModal,
  withFetch(fetchDeliveryActions),
  connect(mapStateToProps),
  withRemoteConfig(),
  withPageControl({
    title: MESSAGES.DELIVERY_OPTIONS,
    backgroundImage: IMAGES.MAP,
    trackable: trackable(DELIVERY_OPTIONS),
    speedDealActions: createSpeedDealControlActions,
    headerMenuActions: createHeaderControlActions,
  }),
  withProps(({ remoteConfig: { vip }, ...props }) => ({
    onOptionSelect: actionCode =>
      goToChangeDelivery({
        ...props,
        actionCode,
      }),
    vip,
  })),
  branch(
    ({ deliveryActions }) =>
      deliveryActions.availableActions.includes(ENUM.PARCEL_ACTION_CODE.DTY),
    () => ({ parcel }: Object) => (
      <Redirect
        to={getLocationChangeDelivery({
          parcelId: parcel.parcelCode,
          actionCode: ENUM.PARCEL_ACTION_CODE.DTY.toLowerCase(),
        })}
      />
    )
  ),
  withTrackProps({
    onOptionSelect: actionCode => DELIVERY_OPTIONS[actionCode],
  })
)(DeliveryOptionsComponent);

export default DeliveryOptions;
