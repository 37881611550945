import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import PickupShopCardShopInfo from './PickupShopCardShopInfo';
import CustomOpeningHoursPanel from '../../../../Tracking/pages/CollectFromPickupTransit/components/CustomOpeningHoursPanel';

type Props = {
  pickupLocation: Object,
  pointChosen: boolean,
};

const useStyles = makeStyles(() => ({
  openingHoursContainer: {
    marginTop: '20px',
  },
}));

const PickupShopCardWithOpeningHours = ({
  pickupLocation,
  pointChosen,
}: Props) => {
  const styles = useStyles();

  return (
    <>
      <PickupShopCardShopInfo
        pickupShopInfo={{ pickupLocation }}
        pointChosen={pointChosen}
      />
      <CustomOpeningHoursPanel
        pickupShopInfo={{ pickupLocation }}
        containerClassName={styles.openingHoursContainer}
      />
    </>
  );
};

export default PickupShopCardWithOpeningHours;
