import * as React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withProps } from 'recompose';
import { withRouter } from 'react-router-dom';

import { withTrackProps } from '@dpdgroupuk/react-event-tracker';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { withOverlay } from '@dpdgroupuk/fmx-ui/components/Overlay';
import { DIALOG_TYPE } from '@dpdgroupuk/fmx-ui/constants/dialogTypes';

import { CHECK_DETAILS_AND_TRY_AGAIN } from '../../constants/error';
import withParcel from '../../HOCs/withParcel';
import { fetchParcel } from '../../store/actions/parcels';
import { DELIVERY_OPTIONS_ERROR } from '../../constants/analytics';
import { catchRequestError } from '../../utils/errorHandling';
import { postpone } from '../../utils/promise';
import PaymentProcessingModal from './pages/DutyPayment/components/PaymentProcessingModal';

export const REACT_APP_RECAPTURE_SITE_KEY: string =
  process.env.REACT_APP_RECAPTURE_SITE_KEY || '';

type Props = {
  actionCode: string,
  dates: Function,
};

export const withDeliveryUpdate = ({
  actionCode,
  changeDeliveryAction,
  trackable,
  isDutyPayment = false,
}): Props =>
  compose(
    withRouter,
    withParcel(),
    withModal,
    withOverlay,
    withProps(({ modal }) => ({
      showError: message => {
        modal.showModal({
          title: message,
          description: CHECK_DETAILS_AND_TRY_AGAIN,
        });
      },
    })),
    withTrackProps({
      showError: DELIVERY_OPTIONS_ERROR.ON_LOAD,
    }),
    connect(
      null,
      (dispatch, { overlay, parcel, modal, history }) => ({
        onSubmitFailure: error => catchRequestError({ error, modal, history }),
        updateDelivery: overlay.showWhile(async (data, query = {}) => {
          if (isDutyPayment) {
            modal.showModal({
              type: DIALOG_TYPE.NO_BUTTONS,
              children: <PaymentProcessingModal />,
            });
          }

          const res = await dispatch(
            changeDeliveryAction(parcel.parcelCode, data, {
              actionCode,
              ...query,
            })
          );

          // let parcel update on redback
          await postpone(500);
          await dispatch(fetchParcel(parcel.parcelCode));

          if (isDutyPayment) {
            modal.hideModal();
          }

          return res;
        }),
      })
    ),
    withTrackProps({
      updateDelivery: trackable,
    })
  );
