import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { withProps } from 'recompose';

import { withTrackProps } from '@dpdgroupuk/react-event-tracker';
import SubmitButton from '@dpdgroupuk/fmx-ui/components/SubmitButton';
import { formatESGDate } from '@dpdgroupuk/fmx-ui/utils/date';
import { USER_TYPE } from '@dpdgroupuk/redback-enums';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import StepLayout from '../../components/StepLayout';
import ChangeDeliveryDay from '../../components/ChangeDeliveryDay';
import { TBC_STEPS } from '../../steps';
import TimeSlotList from '../../components/TimeSlotList';
import type { AuthorizedParcel } from '../../../../types';
import MapContainer from '../../components/MapContainer';
import { fetchDepotTimeSlots, fetchParcelClaimsDispatch } from '../../actions';
import {
  getContactInformation,
  getLatestOutForDeliveryDate,
  hasParcelNotificationDetails,
} from '../../../../models/parcel';
import TimeSlotErrorComponent from '../../components/TimeSlotErrorComponent';
import { filterAvailableTimeSlots } from '../../../../utils/date';
import withFetch from '../../../../HOCs/withFetch';
import { COLLECT_FROM_DEPOT_DELIVERY_OPTION } from '../../../../constants/analytics';
import { CHOOSE_TIME_SLOT } from '../../../../constants/message';
import { ORIGIN } from '../../../../constants/values';
import mapStateToProps from '../../utils/mapStateToProps';

const useStyles = makeStyles(({ typography, breakpoints, palette }) => ({
  wrapper: {
    paddingBottom: typography.pxToRem(40),
  },
  title: {
    fontSize: typography.pxToRem(21),
    textAlign: 'center',
    color: palette.text.redWhite,
    textTransform: 'uppercase',
    marginTop: typography.pxToRem(73),
    marginBottom: typography.pxToRem(40),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(18),
      marginTop: typography.pxToRem(40),
      marginBottom: typography.pxToRem(30),
    },
  },
  errorContainer: {
    marginBottom: typography.pxToRem(70),
  },
}));

type Props = {
  data: Object,
  goToStep: Function,
  onDateChange: Function,
  depotAddress: string,
  parcel: AuthorizedParcel,
  showMap: boolean,
  toggleMap: Function,
  timeSlots: Array<string>,
  onSubmit: Function,
  onSelect: Function,
};

const ChooseTimeSlotComponent = ({
  data,
  goToStep,
  onDateChange,
  depotAddress,
  parcel,
  showMap,
  toggleMap,
  timeSlots,
  onSubmit,
  onSelect,
}: Props) => {
  const styles = useStyles();

  return (
    <StepLayout title="Your Collection depot" copyText={depotAddress}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={styles.wrapper}
      >
        <MapContainer showMap={showMap} parcel={parcel} toggleMap={toggleMap}>
          <ChangeDeliveryDay day={data.day} onDateChange={onDateChange} />
          <Typography className={styles.title}>{CHOOSE_TIME_SLOT}</Typography>
          {timeSlots.length ? (
            <>
              <TimeSlotList
                items={timeSlots}
                onSelect={onSelect}
                active={data.diaryTime}
              />
              <SubmitButton
                onSubmit={onSubmit}
                isSubmitHidden={!data.diaryTime}
              />
            </>
          ) : (
            <Grid className={styles.errorContainer}>
              <TimeSlotErrorComponent
                onSubmit={() => goToStep(TBC_STEPS.SELECT_DAY)}
              />
            </Grid>
          )}
        </MapContainer>
      </Grid>
    </StepLayout>
  );
};

export default compose(
  withRouter,
  withFetch(({ parcel }) => fetchParcelClaimsDispatch({ parcel })),
  connect(mapStateToProps),
  withProps(({ parcel, goToStep, addStepData }) => ({
    onSelect: time =>
      addStepData({ diaryTime: time, ...getContactInformation(parcel) }),
    onDateChange: () => {
      addStepData({ diaryTime: '' });
      goToStep(TBC_STEPS.SELECT_DAY);
    },
  })),
  withTrackProps({
    onSelect: COLLECT_FROM_DEPOT_DELIVERY_OPTION.ON_SLOT_SELECT,
    onDateChange: COLLECT_FROM_DEPOT_DELIVERY_OPTION.ON_DATE_CHANGE,
  }),
  withFetch(({ data, parcel }) =>
    fetchDepotTimeSlots(
      parcel.parcelCode,
      parcel.deliveryDepot.depotCode,
      formatESGDate(data.day),
      getLatestOutForDeliveryDate(parcel)
    )
  ),
  withProps(
    ({
      data,
      fetchedData,
      parcel,
      goToStep,
      submitWizard,
      addStepData,
      claims,
    }) => ({
      timeSlots: filterAvailableTimeSlots(data.day, fetchedData),
      onSubmit: () => {
        const { origin, userType } = claims;

        if (origin === ORIGIN.FMP && userType === USER_TYPE.RECEIVER) {
          addStepData({
            funcAfterAuth: submitWizard,
          });

          // Go to contact page before sms auth
          return goToStep(TBC_STEPS.GET_CONTACTS);
        }

        return hasParcelNotificationDetails(parcel)
          ? submitWizard()
          : goToStep(TBC_STEPS.GET_CONTACTS_NO_AUTH);
      },
    })
  )
)(ChooseTimeSlotComponent);
