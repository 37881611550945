import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from '@dpdgroupuk/redux-action-creator';

import { apiService, fetchTimeoutHandler } from '../../../services';

export const ACTIONS = createActionTypes('PICKUP_LOCATION', {
  FETCH_PICKUP_LOCATION_BY_LOCATION_ID: createAsyncActionTypes(
    'FETCH_PICKUP_LOCATION_BY_LOCATION_ID'
  ),
  FETCH_VOUCHERS: createAsyncActionTypes('FETCH_VOUCHERS'),
  FETCH_VOUCHERS_INFO_BY_VOUCHER_CODE: createAsyncActionTypes(
    'FETCH_VOUCHERS_INFO_BY_VOUCHER_CODE'
  ),
  FETCH_RELATED_PARCEL_NUMBERS: createAsyncActionTypes(
    'FETCH_RELATED_PARCEL_NUMBERS'
  ),
  FETCH_PARCEL_DATA: createAsyncActionTypes('FETCH_PARCEL_DATA'),
});

export const fetchPickupLocationByLocationId = createAsyncAction(
  (pickupLocationCode: string) =>
    fetchTimeoutHandler(
      apiService.getPickupLocationByLocationId(pickupLocationCode)
    ).then(({ data }) => data),
  ACTIONS.FETCH_PICKUP_LOCATION_BY_LOCATION_ID
);

export const fetchRelatedParcelNumbers = createAsyncAction(
  (pickupLocationCode: string, parcelCode: string) =>
    fetchTimeoutHandler(
      apiService.getRelatedParcelNumbersForMultiPickup(
        pickupLocationCode,
        parcelCode
      )
    ).then(({ data }) => data),
  ACTIONS.FETCH_RELATED_PARCEL_NUMBERS
);

export const fetchParcelDataInfo = createAsyncAction(
  (mainParcelCode: string, pickupLocationCode: string, parcelCode: string) =>
    fetchTimeoutHandler(
      apiService.getParcelData(mainParcelCode, pickupLocationCode, parcelCode)
    ).then(({ data }) => data),
  ACTIONS.FETCH_PARCEL_DATA
);

export const fetchVouchers = createAsyncAction(
  (pickupLocationCode: string) =>
    fetchTimeoutHandler(
      apiService.getVouchersByLocationId(pickupLocationCode)
    ).then(({ data }) => ({
      voucherArray: data,
      pickupLocationCode,
    })),
  ACTIONS.FETCH_VOUCHERS
);

export const fetchVouchersInfoByVoucherCode = createAsyncAction(
  (pickupLocationCode: string, voucherCode: string) =>
    fetchTimeoutHandler(
      apiService.getVouchersInfoByVoucherCode(pickupLocationCode, voucherCode)
    ).then(({ data }) => ({
      pickupLocationCode,
      vouchersInfo: data,
    })),
  ACTIONS.FETCH_VOUCHERS_INFO_BY_VOUCHER_CODE
);
