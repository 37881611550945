import * as React from 'react';
import type { PickupAddress } from '@dpdgroupuk/fmx-ui/types';
import { joinStringsWithComma } from '@dpdgroupuk/fmx-ui/utils/string';

export const getPickupAddressString = (address: PickupAddress): string => {
  const { organisation, property, street, town, postcode } = address;

  return (
    <>
      <strong>{organisation}</strong>
      <br />
      {joinStringsWithComma([property, street, town, postcode])}
    </>
  );
};
