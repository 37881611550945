import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { SpinnerBox } from '@dpdgroupuk/fmx-ui/components/Spinner';

const useStyles = makeStyles(() => ({
  spinnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '14px',
  },
  spinner: {
    position: 'absolute',
    left: '0%',
    right: '0.3%',
    top: '0%',
    bottom: '0%',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
    gap: '16px',
  },
  text: {
    fontFamily: "'Roboto'",
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.571429px',
    textAlign: 'left',
  },
  titleText: {
    fontWeight: '700',
  },
}));

const PaymentProcessingModal = () => {
  const styles = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={2} className={clsx(styles.spinnerContainer)}>
        <SpinnerBox />
      </Grid>
      <Grid item xs={10} sm container className={clsx(styles.textContainer)}>
        <Typography className={clsx(styles.text, styles.titleText)}>
          Your request is currently being processed
        </Typography>

        <Typography className={clsx(styles.text)}>
          Please wait a few moments.
        </Typography>
        <Typography className={clsx(styles.text)}>
          Do not navigate back or refresh this page while your request is
          currently being processed.
        </Typography>
      </Grid>
    </Grid>
  );
};
export default PaymentProcessingModal;
