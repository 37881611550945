export const PARCEL_ISSUE_TEXT_WITH_DELAY_$ =
  'There is currently a {0} day delay in your area due to {1}*';

export const PARCEL_ISSUE_TEXT_WITH_ESTIMATED_TIME_ROW_1_$ =
  'Your parcel has been delayed due to {0} in your area';

export const PARCEL_ISSUE_TEXT_WITH_ESTIMATED_TIME_ROW_2_$ =
  'Your estimated delivery date is now {0}*';

export const PARCEL_ISSUE_SUB_TEXT =
  '*This date is dependent on conditions improving and may change. Please check here for regular updates.';
