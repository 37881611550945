import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from '@dpdgroupuk/redux-action-creator';

import { apiService, fetchTimeoutHandler } from '../../../services';

export const ACTIONS = createActionTypes('CLAIM', {
  FETCH_PARCEL_CLAIMS: createAsyncActionTypes('FETCH_PARCEL_CLAIMS'),
});

export const fetchParcelClaims = createAsyncAction(
  (parcelCode: string) =>
    fetchTimeoutHandler(apiService.getVerifySession(parcelCode)).then(
      ({ data }) => ({ ...data, parcelCode })
    ),
  ACTIONS.FETCH_PARCEL_CLAIMS
);
