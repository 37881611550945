export const UNSUPPORTED_BROWSER = module => `${module}/unsupported-browser`;

export const MESSAGING = {
  PERMISSION_BLOCKED: 'messaging/permission-blocked',
  UNSUPPORTED_BROWSER: UNSUPPORTED_BROWSER('messaging'),
};

export const INSTALLATIONS = {
  APP_OFFLINE: 'installations/app-offline',
};
