import { lifecycle } from 'recompose';
import { contactUsLogin } from '../apis';

const withQueryParams = () =>
  lifecycle({
    async componentWillMount() {
      try {
        const host = window.location.origin;
        const path = window.location.pathname;
        const params = new URL(window.location).searchParams;
        const { parcelNumber, postcode, dpdSession } = Object.fromEntries(
          params.entries()
        );

        if (parcelNumber && postcode && dpdSession) {
          await contactUsLogin(
            parcelNumber,
            postcode.replace(/\s+/g, ''),
            dpdSession
          );

          window.location = `${host}${path}`;
        }
      } catch (e) {
        // continue as normal if errors
      }
    },
  });

export default withQueryParams;
