import * as React from 'react';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Field, reduxForm } from 'redux-form';
import { useHistory } from 'react-router-dom';

import FormWithSubmit from '@dpdgroupuk/fmx-ui/components/FormWithSubmit';
import { InputFormField } from '@dpdgroupuk/fmx-ui/components/Input';
import Button from '@dpdgroupuk/fmx-ui/components/Button';
import {
  validateParcelReferenceNumberWithMessage,
  validatePostcodeWithMessage,
  validateEmailWithMessage,
  required,
} from '@dpdgroupuk/fmx-ui/utils/validation';
import GoogleReCaptchaProvider from '@dpdgroupuk/react-google-recaptcha-v3';
import { compose, withProps } from 'recompose';
import { getAddressLine } from '@dpdgroupuk/fmx-ui/utils/address';
import { useTracker, withTrack } from '@dpdgroupuk/react-event-tracker';
import { REQUIRED_FIELD } from '@dpdgroupuk/fmx-ui/constants/validation';

import * as MESSAGE from '../../../constants/message';
import HomeButton from '../../../components/Rate/Button';
import { goToReferenceEntry } from '../../historyActions';
import {
  getDeliveryAddress,
  getParcelNotificationDetails,
  normalizeReferenceNumber,
} from '../../../models/parcel';

import { RATE_SORRY, trackable } from '../../../constants/analytics';

const SALESFORCE_RECAPTCHA_KEY = process.env.REACT_APP_SALESFORCE_RECAPTCHA_KEY;

const INVESTIGATE_RESPONSE = (
  <>
    We will respond within <b>24 hours</b>
  </>
);

const useStyles = makeStyles(({ typography, palette, breakpoints }) => ({
  header: {
    fontSize: typography.pxToRem(18),
    letterSpacing: typography.pxToRem(0.64),
    color: palette.text.primary,
    [breakpoints.down('xs')]: {
      width: '80%',
      alignSelf: 'center',
    },
  },
  headerFirstRow: {
    marginBottom: typography.pxToRem(25),
    [breakpoints.down('xs')]: {
      marginBottom: typography.pxToRem(15),
      marginTop: typography.pxToRem(20),
    },
  },
  form: {
    width: '100%',
    marginTop: typography.pxToRem(28),
  },
  marginRight: {
    marginRight: typography.pxToRem(16),
  },
  marginTop: {
    marginTop: typography.pxToRem(14),
    [breakpoints.down('xs')]: {
      marginTop: typography.pxToRem(15),
    },
  },
  mediumField: {
    width: `calc(50% - ${typography.pxToRem(8)})`,
  },
  largeField: {
    width: `calc(70% - ${typography.pxToRem(8)})`,
  },
  smallField: {
    width: `calc(30% - ${typography.pxToRem(8)})`,
    [breakpoints.down('xs')]: {
      width: `calc(30% - ${typography.pxToRem(6)})`,
    },
  },
  flex: {
    display: 'flex',
  },
  submitButton: {
    alignSelf: 'flex-end',
    marginLeft: `calc(30% - ${typography.pxToRem(105)})`,
    [breakpoints.down('xs')]: {
      marginLeft: 0,
      marginTop: typography.pxToRem(23),
    },
  },
  submitButtonMobile: {
    alignSelf: 'center',
    marginTop: typography.pxToRem(30),
  },
  footer: {
    fontSize: typography.pxToRem(13),
    color: palette.text.primary,
    letterSpacing: typography.pxToRem(0.39),
    fontWeight: 300,
    marginTop: typography.pxToRem(11),
    maxWidth: '100%',
  },
  homeButtonWrapper: {
    marginTop: typography.pxToRem(30),
    [breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
  mobColumn: {
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  mobFieldMarginTop: {
    [breakpoints.down('xs')]: {
      marginTop: typography.pxToRem(15),
    },
  },
  mobFullSizeField: {
    [breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
    },
  },
  mobMarginRight: {
    [breakpoints.down('xs')]: {
      marginRight: typography.pxToRem(12),
    },
  },
  mobLargeField: {
    [breakpoints.down('xs')]: {
      width: `calc(70% - ${typography.pxToRem(6)})`,
      marginRight: 0,
    },
  },
  recaptchaContainer: {
    marginTop: typography.pxToRem(30),
  },
  field: {
    '& input::placeholder, textarea::placeholder': {
      color: palette.text.placeholder,
      opacity: 1,
    },
  },
  disabledInput: {
    '& input': {
      color: '#808285',
    },
  },
  sfFormWrapper: {
    [breakpoints.down('xs')]: {
      paddingLeft: typography.pxToRem(26),
      paddingRight: typography.pxToRem(26),
    },
    [breakpoints.down(410)]: {
      paddingLeft: typography.pxToRem(7),
      paddingRight: typography.pxToRem(7),
    },
  },
}));

const validateReference = [
  required(),
  validateParcelReferenceNumberWithMessage,
];
const validatePostcode = [
  required(REQUIRED_FIELD),
  validatePostcodeWithMessage,
];
const validateRequiredField = [required(REQUIRED_FIELD)];
const validateEmail = [required(REQUIRED_FIELD), validateEmailWithMessage];

const DeliveryNegativeComplete = ({
  handleSubmit,
  valid,
  data,
  submitWizard,
}: any) => {
  const styles = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const tracker = useTracker();
  const { experienceComplimentType } = data;

  const onHomeClick = React.useCallback(() => {
    tracker.logEvent(RATE_SORRY.ON_HOME);
    return goToReferenceEntry(history);
  }, [history, tracker]);

  const [validRecaptcha, setValidRecaptcha] = React.useState(false);
  const onRecaptcha = React.useCallback(
    recaptcha => {
      if (recaptcha) {
        setValidRecaptcha(true);
      } else {
        setValidRecaptcha(false);
      }
    },
    [setValidRecaptcha]
  );
  const recaptcha = React.useRef();

  const formDisabled = !valid || !validRecaptcha;

  const onSubmit = React.useCallback(
    async values => {
      if (formDisabled) {
        return;
      }
      tracker.logEvent(RATE_SORRY.ON_SEND);

      const recaptchaToken = await recaptcha.current.getResponse();
      const data = {
        experienceComplimentType,
      };

      submitWizard({
        stepData: { ...data, salesforceValues: values, recaptchaToken },
      });
    },
    [experienceComplimentType, submitWizard, tracker, formDisabled]
  );

  return (
    <GoogleReCaptchaProvider
      scriptId="v2"
      sitekey={SALESFORCE_RECAPTCHA_KEY}
      visibility="visible"
      size={null}
      containerId="form-recapctha"
      ref={recaptcha}
      theme={theme.palette.type}
      callback={onRecaptcha}
    >
      <Grid container direction="column" className={styles.sfFormWrapper}>
        <Typography className={clsx(styles.header, styles.headerFirstRow)}>
          {MESSAGE.RATE_COMPLETE_SORRY}
        </Typography>
        <Typography className={styles.header}>
          {MESSAGE.RATE_COMPLETE_INVESTIGATE}
        </Typography>
        <FormWithSubmit
          className={styles.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid item>
            <Field
              component={InputFormField}
              name="contactName"
              placeholder={`${MESSAGE.CONTACT_NAME} *`}
              className={clsx(
                styles.field,
                styles.mediumField,
                styles.marginRight,
                styles.mobFullSizeField
              )}
              validate={validateRequiredField}
              required
            />
            <Field
              component={InputFormField}
              name="trackingNumber"
              placeholder={`${MESSAGE.REFERENCE_ENTRY_FORM_NUMBER_PLACEHOLDER} *`}
              className={clsx(
                styles.field,
                styles.mediumField,
                styles.mobFullSizeField,
                styles.mobFieldMarginTop,
                styles.disabledInput
              )}
              validate={validateReference}
              normalize={normalizeReferenceNumber}
              required
              disabled
            />
          </Grid>
          <Grid item className={styles.marginTop}>
            <Field
              component={InputFormField}
              name="address"
              placeholder={`${MESSAGE.ADDRESS} *`}
              className={clsx(
                styles.field,
                styles.largeField,
                styles.marginRight,
                styles.mobFullSizeField
              )}
              validate={validateRequiredField}
              required
            />
            <Field
              component={InputFormField}
              name="postcode"
              placeholder={`${MESSAGE.REFERENCE_ENTRY_FORM_POSTCODE_PLACEHOLDER} *`}
              className={clsx(
                styles.field,
                styles.smallField,
                styles.mobFieldMarginTop,
                styles.mobMarginRight
              )}
              validate={validatePostcode}
              inputProps={{
                maxLength: 8,
              }}
              required
            />
            <Field
              component={InputFormField}
              name="mobile"
              placeholder={MESSAGE.CONTACT_NUMBER}
              className={clsx(
                styles.field,
                styles.mediumField,
                styles.marginRight,
                styles.marginTop,
                styles.mobLargeField
              )}
            />
            <Field
              component={InputFormField}
              name="email"
              placeholder={`${MESSAGE.EMAIL} *`}
              className={clsx(
                styles.field,
                styles.mediumField,
                styles.marginTop,
                styles.mobFullSizeField
              )}
              validate={validateEmail}
              required
            />
          </Grid>
          <Grid
            item
            className={clsx(styles.marginTop, styles.flex, styles.mobColumn)}
          >
            <Field
              component={InputFormField}
              name="comment"
              placeholder={MESSAGE.INVESTIGATE_DESCRIPTION}
              className={clsx(
                styles.field,
                styles.largeField,
                styles.marginRight,
                styles.mobFullSizeField
              )}
              multiline
              rows={5}
            />
            {window.innerWidth > 600 && (
              <Button
                onClick={handleSubmit(onSubmit)}
                className={styles.submitButton}
                disabled={!valid || !validRecaptcha}
                type="submit"
              >
                {MESSAGE.SUBMIT}
              </Button>
            )}
          </Grid>
        </FormWithSubmit>
        <Typography className={styles.footer}>
          {INVESTIGATE_RESPONSE}
        </Typography>
        <div className={styles.recaptchaContainer}>
          <div
            className="g-recaptcha"
            id="form-recapctha"
            data-sitekey={SALESFORCE_RECAPTCHA_KEY}
          />
        </div>
        {window.innerWidth <= 600 && (
          <Button
            className={styles.submitButtonMobile}
            disabled={formDisabled}
            type="submit"
          >
            {MESSAGE.SUBMIT}
          </Button>
        )}
        <div className={styles.homeButtonWrapper}>
          <HomeButton onClick={onHomeClick}>{MESSAGE.HOME}</HomeButton>
        </div>
      </Grid>
    </GoogleReCaptchaProvider>
  );
};

export default compose(
  withTrack(trackable(RATE_SORRY)),
  withProps(({ parcel }) => ({
    initialValues: {
      ...getParcelNotificationDetails(parcel),
      address: getAddressLine(getDeliveryAddress(parcel)),
      postcode: getDeliveryAddress(parcel).postcode,
      trackingNumber: parcel.parcelNumber,
    },
  })),
  reduxForm({ form: 'rateSorry' })
)(DeliveryNegativeComplete);
