import { formatDefaultDate } from '@dpdgroupuk/fmx-ui/utils/date';

import api from './api';

export const getPickupLocationByLocationId = (locationId: string) => {
  return api.get({
    path: `/pickupLocations/${locationId}`,
  });
};

export const getRelatedParcelNumbersForMultiPickup = (
  locationId: number,
  mainParcelCode: string
) => {
  return api.get({
    path: `/pickupLocations/${locationId}/parcels/${mainParcelCode}/related`,
  });
};

export const getParcelData = (
  mainParcelCode: string,
  locationId: number,
  parcelCode: string
) => {
  return api.get({
    path: `/pickupLocations/${locationId}/parcels/${mainParcelCode}/data?relatedParcelCode=${parcelCode}`,
  });
};

export const getVouchersByLocationId = (locationId: string) => {
  const startDate = formatDefaultDate(Date.now());
  return api.get({
    path: `/pickupLocations/${locationId}/vouchers?startDate=${startDate}`,
  });
};

export const getVouchersInfoByVoucherCode = (
  locationId: string,
  voucherCode: string
) => {
  return api.get({
    path: `/pickupLocations/${locationId}/vouchers/${voucherCode}`,
  });
};
