import * as React from 'react';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { withProps } from 'recompose';
import { isMobile } from '@dpdgroupuk/fmx-ui/utils/helpers';
import OpeningHoursPanel from './OpeningHoursPanel';
import PickupShopCardShopInfo from './PickupShopCardShopInfo';

const useStyles = makeStyles({
  pickupCardWrapper: {
    position: 'relative',
  },
});

type Props = {
  index: number,
  selected: boolean,
  pointChosen: boolean,
  onSelectClick: Function,
  pickupShopInfo: any,
  openingHours: any,
  opened?: boolean,
  onCardClick: Function,
};

const PickupShopCard = ({
  index,
  selected,
  pointChosen = false,
  onSelectClick,
  pickupShopInfo,
  openingHours,
  opened,
  onCardClick,
}: Props) => {
  const styles = useStyles();

  const onLogoClick = React.useCallback(() => {
    if (isMobile) {
      onCardClick();
    }
  }, [onCardClick]);

  return (
    <div className={styles.pickupCardWrapper}>
      <PickupShopCardShopInfo
        index={index}
        pickupShopInfo={pickupShopInfo}
        onLogoClick={onLogoClick}
        selected={selected}
        pointChosen={pointChosen}
        onSelectClick={onSelectClick}
      />
      <OpeningHoursPanel
        openingHours={openingHours}
        opened={opened}
        pickupLocationCode={pickupShopInfo.pickupLocation.pickupLocationCode}
        openedPickupShop={get(
          pickupShopInfo,
          'pickupLocation.pickupLocationCode'
        )}
      />
    </div>
  );
};

export default withProps(({ pickupShopInfo }: any) => ({
  openingHours: get(
    pickupShopInfo,
    'pickupLocation.pickupLocationAvailability.pickupLocationOpenWindow',
    []
  ),
}))(PickupShopCard);
