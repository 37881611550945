import type { AuthorizedParcel } from '@dpdgroupuk/fmx-ui/types/Parcel';

import { RouteActions } from '../../../../store/orm/Route';
import { DriverActions } from '../../../../store/orm/Driver';
import { getRouteCode } from '../../../../models/parcel';
import { getDriverId } from '../../../../models/route';

export const fetchRouteAndDriver = ({
  parcel,
}: {
  parcel: AuthorizedParcel,
}) => async dispatch => {
  const route = await dispatch(
    RouteActions.fetchRouteByCode(getRouteCode(parcel))
  );
  const driver = await dispatch(DriverActions.fetchDriver(getDriverId(route)));

  return [route, driver];
};
