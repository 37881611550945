import * as React from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import MuiToolbar from '@material-ui/core/Toolbar';
import { Grid, Typography, AppBar } from '@material-ui/core';

const Toolbar = withStyles({
  root: {
    flexDirection: 'column',
  },
  regular: {
    minHeight: 'unset',
  },
  gutters: {
    paddingLeft: 0,
    paddingRight: 0,
  },
})(MuiToolbar);

const useStyles = makeStyles(({ palette, breakpoints, typography }) => ({
  appBar: ({ mapHeight }) => ({
    bottom: 0,
    background: palette.background.default,
    minHeight: typography.pxToRem(520),
    top: mapHeight + 66,
    borderRadius: typography.pxToRem(3),
  }),
  container: {
    background: palette.background.dialog,
    width: '100%',
    padding: 0,
    paddingTop: typography.pxToRem(23),
    paddingBottom: typography.pxToRem(5),
    boxSizing: 'content-box',
  },
  list: {
    listStyle: 'none',
    padding: `0 ${typography.pxToRem(210)} 0 ${typography.pxToRem(50)}`,
    marginBottom: typography.pxToRem(200),
    [breakpoints.down(900)]: {
      paddingRight: typography.pxToRem(50),
    },
    [breakpoints.down('xs')]: {
      padding: `0 ${typography.pxToRem(20)}`,
    },
  },
  listItem: {
    marginBottom: typography.pxToRem(40),
    [breakpoints.down(1025)]: {
      paddingTop: typography.pxToRem(10),
    },
  },
  status: {
    marginBottom: typography.pxToRem(33),
    color: palette.text.primary,
    lineHeight: 1.29,
    letterSpacing: typography.pxToRem(0.44),
    textAlign: 'center',
    padding: `0 ${typography.pxToRem(35)}`,
    fontSize: typography.pxToRem(16),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(14),
    },
  },
}));

type Props = {
  bottomComponentRef: any,
  mapHeight: number,
  listItems: any[],
  parcelPickupStatus: string,
};

const SelectPickupShopStepMobile = ({
  bottomComponentRef,
  mapHeight,
  listItems = [],
  parcelPickupStatus,
}: Props) => {
  const styles = useStyles({ mapHeight });

  return (
    <AppBar position="absolute" component="div" className={styles.appBar}>
      <Toolbar>
        <Grid className={styles.container} ref={bottomComponentRef}>
          <Typography className={styles.status}>
            {parcelPickupStatus}
          </Typography>
          <ul className={styles.list}>
            {listItems.map((item, index) => {
              return (
                <li key={index} className={styles.listItem}>
                  {item}
                </li>
              );
            })}
          </ul>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default SelectPickupShopStepMobile;
