import DefaultPickupShopImage from '../assets/images/PickupShops/Pickup.png';
import { shopLogos } from '../constants/shops';

export const getShopImage = (value: string) => {
  const shopName = value.toLocaleLowerCase();
  if (shopName.includes('cyclerepublic')) return shopLogos.CycleRepublic;
  if (shopName.includes('doddle')) return shopLogos.Doddle;
  if (shopName.includes('halfords')) return shopLogos.Halfords;
  if (shopName.includes('matalan')) return shopLogos.Matalan;
  if (shopName.includes('sainsburys')) return shopLogos.Sainsburys;
  return DefaultPickupShopImage;
};

export const getBackgroundImage = ({ desktopImage, mobileImage }: any) => {
  if (window.innerWidth < 600 && mobileImage) {
    return mobileImage;
  }

  return desktopImage;
};
