import { withProps, compose } from 'recompose';
import SelectDay from '@dpdgroupuk/fmx-ui/components/DayPicker';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';

import * as MESSAGE from '../../../../constants/message';
import { DTN_STEPS } from '../../steps';

import { DTN_DELIVERY_OPTION } from '../../../../constants/analytics';

export default compose(
  withProps(({ addStepData, goToStep }) => ({
    title: MESSAGE.DAY_OF_DELIVERY,
    subtitle: MESSAGE.SELECT_UPCOMING_DAY,
    onSelect: day => {
      addStepData({ day });
      goToStep(DTN_STEPS.SELECT_NEIGHBOUR);
    },
  })),
  withTrackProps({
    onSelect: DTN_DELIVERY_OPTION.ON_SELECT_DATE,
  })
)(SelectDay);
