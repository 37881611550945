import React, { createContext, useContext } from 'react';
import { defaultThemeName } from '@dpdgroupuk/fmx-ui/themes';
import { THEME_EVENT } from '@dpdgroupuk/redback-enums';

import type { Config } from './types';

export const defaultContextValue: Config = {
  deliveryEducation: [],
  themeName: defaultThemeName,
  useSalesforceForm: false,
  contactNumber: '0121 275 9222',
  dutyPaymentContactUsLink: 'mailto:defer@dpdgroup.co.uk',
  dutyPaymentTermsLink: 'https://www.dpd.co.uk/',
  eventName: THEME_EVENT.NONE,
  dislikeCallMe: false,
  vip: {
    after12: '4.99',
    before12: '9.99',
  },
};
export const ConfigContext = createContext(defaultContextValue);

export const useRemoteConfig = (): { [string]: any } =>
  useContext(ConfigContext);

export const withRemoteConfig = () => Component => props => (
  <ConfigContext.Consumer>
    {value => <Component {...props} remoteConfig={value} />}
  </ConfigContext.Consumer>
);
