import React from 'react';
import { compose } from 'redux';
import { withRouter, useHistory } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Parcel } from '@dpdgroupuk/fmx-ui/components/Icons';
import Button from '@dpdgroupuk/fmx-ui/components/Button';

import TrackingStatus from '../TrackingStatus';
import withParcel from '../../HOCs/withParcel';
import { VIEW_DELIVERY_DETAILS } from '../../constants/message';
import { goToTracking } from '../../pages/historyActions';
import type { AuthorizedParcel } from '../../types';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingTop: typography.pxToRem(150),
    alignItems: 'center',
    [breakpoints.down('md')]: {
      paddingTop: typography.pxToRem(70),
    },
  },
  width: {
    maxWidth: typography.pxToRem(960),
  },
  status: {
    fontSize: typography.pxToRem(19),
    marginBottom: typography.pxToRem(87),
    whiteSpace: 'break-spaces',
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(16),
      marginBottom: typography.pxToRem(63),
    },
    '& .REDTEXT': {
      fontWeight: 500,
      color: 'inherit',
    },
  },
  button: {
    marginTop: typography.pxToRem(84),
    marginBottom: typography.pxToRem(20),
    [breakpoints.down('xs')]: {
      marginTop: typography.pxToRem(65),
      marginBottom: 0,
    },
  },
  icon: {
    width: typography.pxToRem(216),
    height: typography.pxToRem(238),
  },
}));

const EmptyPageComponent = ({
  parcel,
  value,
}: {
  parcel: AuthorizedParcel,
  value: string,
}) => {
  const styles = useStyles();
  const history = useHistory();

  const onClick = React.useCallback(() => {
    goToTracking({ history, parcel });
  }, [history, parcel]);

  return (
    <Container
      maxWidth="md"
      className={styles.container}
      classes={{ maxWidthMd: styles.width }}
    >
      <TrackingStatus status={value} className={styles.status} />
      <Parcel className={styles.icon} />
      <Button className={styles.button} onClick={onClick}>
        {VIEW_DELIVERY_DETAILS}
      </Button>
    </Container>
  );
};

export default compose(
  withRouter,
  withParcel()
)(EmptyPageComponent);
