import * as React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import * as ENUM from '@dpdgroupuk/redback-enums';
import BackToTrackingMap from '@dpdgroupuk/fmx-ui/components/BackToTrackingMap';

import ChangeDeliveryDay from './pages/ChangeDeliveryDay';
import DeliverToNeighbour from './pages/DeliverToNeighbour';
import DeliverToSafePlace from './pages/DeliverToSafePlace';
import SelectAlternateAddress from './pages/SelectAlternateAddress';
import KeepMeInformed from './pages/KeepMeInformed';
import CollectFromDepot from './pages/CollectFromDepot';
import UpgradeDelivery from './pages/Upgrade';
import Precise from './pages/Precise';
import ProtectedDeliveryOptionRoute from './ProtectedDeliveryOptionRoute';
import SelectPickupShop from './pages/CollectFromPickupShop/components';
import { getLocationDeliveryOptions } from '../Parcel/actions/location';
import type { AuthorizedParcel } from '../../types';
import withParcel from '../../HOCs/withParcel';
import DutyPayment from './pages/DutyPayment';

type Props = {
  parcel: AuthorizedParcel,
  goToTracking: Function,
};

const Routes = ({ parcel, goToTracking }: Props) => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <ProtectedDeliveryOptionRoute
          path={`${path}/${ENUM.PARCEL_ACTION_CODE.RED}`}
          actionCode={ENUM.PARCEL_ACTION_CODE.RED}
          render={props => (
            <BackToTrackingMap onClick={goToTracking}>
              <ChangeDeliveryDay {...props} />
            </BackToTrackingMap>
          )}
        />
        <ProtectedDeliveryOptionRoute
          path={`${path}/${ENUM.PARCEL_ACTION_CODE.DTN}`}
          actionCode={ENUM.PARCEL_ACTION_CODE.DTN}
          render={props => (
            <BackToTrackingMap onClick={goToTracking}>
              <DeliverToNeighbour {...props} />
            </BackToTrackingMap>
          )}
        />
        <ProtectedDeliveryOptionRoute
          path={`${path}/${ENUM.PARCEL_ACTION_CODE.SFP}`}
          actionCode={ENUM.PARCEL_ACTION_CODE.SFP}
          render={props => (
            <BackToTrackingMap onClick={goToTracking}>
              <DeliverToSafePlace {...props} />
            </BackToTrackingMap>
          )}
        />
        <ProtectedDeliveryOptionRoute
          path={`${path}/${ENUM.PARCEL_ACTION_CODE.ALA}`}
          actionCode={ENUM.PARCEL_ACTION_CODE.ALA}
          render={props => (
            <BackToTrackingMap onClick={goToTracking}>
              <SelectAlternateAddress {...props} />
            </BackToTrackingMap>
          )}
        />
        <ProtectedDeliveryOptionRoute
          path={`${path}/${ENUM.PARCEL_ACTION_CODE.KMI}`}
          actionCode={ENUM.PARCEL_ACTION_CODE.KMI}
          render={props => (
            <BackToTrackingMap onClick={goToTracking}>
              <KeepMeInformed {...props} />
            </BackToTrackingMap>
          )}
        />
        <ProtectedDeliveryOptionRoute
          path={`${path}/${ENUM.PARCEL_ACTION_CODE.TBC}`}
          actionCode={ENUM.PARCEL_ACTION_CODE.TBC}
          render={props => (
            <BackToTrackingMap onClick={goToTracking}>
              <CollectFromDepot {...props} />
            </BackToTrackingMap>
          )}
        />
        <ProtectedDeliveryOptionRoute
          path={`${path}/${ENUM.PARCEL_ACTION_CODE.UPG}`}
          actionCode={ENUM.PARCEL_ACTION_CODE.UPG}
          render={props => (
            <BackToTrackingMap onClick={goToTracking}>
              <UpgradeDelivery {...props} />
            </BackToTrackingMap>
          )}
        />
        <ProtectedDeliveryOptionRoute
          path={`${path}/${ENUM.PARCEL_ACTION_CODE.ONE}`}
          actionCode={ENUM.PARCEL_ACTION_CODE.ONE}
          render={props => (
            <BackToTrackingMap onClick={goToTracking}>
              <Precise {...props} />
            </BackToTrackingMap>
          )}
        />
        <ProtectedDeliveryOptionRoute
          path={`${path}/${ENUM.PARCEL_ACTION_CODE.PKU}`}
          actionCode={ENUM.PARCEL_ACTION_CODE.PKU}
          render={props => (
            <BackToTrackingMap onClick={goToTracking}>
              <SelectPickupShop {...props} />
            </BackToTrackingMap>
          )}
        />
        <ProtectedDeliveryOptionRoute
          path={`${path}/${ENUM.PARCEL_ACTION_CODE.DTY}`}
          actionCode={ENUM.PARCEL_ACTION_CODE.DTY}
          component={DutyPayment}
        />
        <Route
          children={() => (
            <Redirect
              to={getLocationDeliveryOptions({
                parcelId: parcel.parcelCode,
              })}
            />
          )}
        />
      </Switch>
    </>
  );
};

export default withParcel()(Routes);
