import React from 'react';
import { compose } from 'redux';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { withRouter } from 'react-router-dom';
import { withProps, withState } from 'recompose';
import { makeStyles } from '@material-ui/styles';
// TODO refactor createMuiTheme -> createTheme https://github.com/mui/material-ui/issues/27172#issuecomment-876008423
// eslint-disable-next-line import/no-unresolved
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

import type { Depot } from '@dpdgroupuk/fmx-ui/types';
import * as ENUM from '@dpdgroupuk/redback-enums';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes/constants';
import Wizard, { Step } from '@dpdgroupuk/fmx-ui/components/Wizard';

import withParcel from '../../../../HOCs/withParcel';
import { TBC_STEPS } from '../../steps';
import * as MESSAGES from '../../../../constants/message';
import {
  COLLECT_FROM_DEPOT_DELIVERY_OPTION,
  trackable,
} from '../../../../constants/analytics';
import SelectDayStep from './SelectDayStep';
import CompleteStep from './CompleteStep';
import ChooseTimeSlotStep from './ChooseTimeSlotStep';
import { fetchDepotAndDates, changeCollectFromDepot } from './actions';
import { getDepotAddressString } from '../../../../models/depot';
import type { AuthorizedParcel } from '../../../../types';
import { useCollectFromDepotControlActions } from './hooks';
import { withDeliveryUpdate } from '../../hocs';
import GetContactDetailsStep from '../../components/GetContactDetailsStep';
import withFetch from '../../../../HOCs/withFetch';
import {
  backToDpdSiteHeader,
  backToGreenSiteHeader,
  doSomethingDifferentHeader,
  getDpdAppHeader,
  trackAnotherParcelHeader,
  whatHappensNextHeader,
} from '../../../controlActions';
import GetContactDetailsNoEmailStep from '../../components/GetContactDetailsNoEmailStep';
import EnterCodeStep from '../../components/EnterCodeStep';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  completeStep: {
    paddingLeft: typography.pxToRem(120),
    paddingRight: typography.pxToRem(120),
    [breakpoints.down('xs')]: {
      paddingLeft: typography.pxToRem(15),
      paddingRight: typography.pxToRem(15),
    },
  },
}));

type Props = {
  theme: ThemeOptions,
  dates: Array<string>,
  parcel: AuthorizedParcel,
  depot: Depot,
  history: Object,
  showMap: boolean,
  setShowMap: Function,
  updateDelivery: Function,
  onSubmitSuccess: Function,
  onSubmitFailure: Function,
};

const CollectFromDepotComponent = ({
  dates,
  depot,
  parcel,
  history,
  setShowMap,
  showMap,
  updateDelivery,
  onSubmitSuccess,
  onSubmitFailure,
  ...restProps
}: Props) => {
  const styles = useStyles();
  const depotAddress = getDepotAddressString(depot);
  const toggleMap = React.useCallback(() => setShowMap(!showMap), [
    setShowMap,
    showMap,
  ]);
  useCollectFromDepotControlActions({
    history,
    parcel,
    showMap,
    toggleMap,
    ...restProps,
  });

  return (
    <Wizard
      actionCode={ENUM.PARCEL_ACTION_CODE.TBC}
      initialStep={TBC_STEPS.SELECT_DAY}
      onSubmit={updateDelivery}
      onSubmitSuccess={onSubmitSuccess}
      onSubmitFailure={onSubmitFailure}
    >
      <Step
        parcel={parcel}
        toggleMap={toggleMap}
        showMap={showMap}
        depotAddress={depotAddress}
        dates={dates}
        stepId={TBC_STEPS.SELECT_DAY}
        component={SelectDayStep}
      />
      <Step
        showMap={showMap}
        toggleMap={toggleMap}
        parcel={parcel}
        depotAddress={depotAddress}
        stepId={TBC_STEPS.CHOOSE_TIME_SLOT}
        component={ChooseTimeSlotStep}
      />

      {/* SMS Authentication */}
      <Step
        stepId={TBC_STEPS.GET_CONTACTS}
        component={GetContactDetailsNoEmailStep}
        parcel={parcel}
        nextStep={TBC_STEPS.ENTER_CODE}
      />
      <Step
        stepId={TBC_STEPS.ENTER_CODE}
        component={EnterCodeStep}
        parcel={parcel}
      />
      {/* / SMS Authentication / */}

      <Step
        stepId={TBC_STEPS.GET_CONTACTS_NO_AUTH}
        component={GetContactDetailsStep}
        parcel={parcel}
      />
      <Step
        actionCode={ENUM.PARCEL_ACTION_CODE.TBC}
        showMap={showMap}
        toggleMap={toggleMap}
        depotAddress={depotAddress}
        parcel={parcel}
        stepId={TBC_STEPS.COMPLETE}
        component={CompleteStep}
        wrapperClassName={styles.completeStep}
      />
    </Wizard>
  );
};

const createHeaderControlActions = props => [
  doSomethingDifferentHeader(props),
  trackAnotherParcelHeader(props),
  whatHappensNextHeader(props),
  backToDpdSiteHeader(props),
  backToGreenSiteHeader(props),
  getDpdAppHeader(props),
];

const CollectFromDepot = compose(
  withRouter,
  withParcel(),
  withFetch(({ match, parcel }) =>
    fetchDepotAndDates(
      match.params.parcelId,
      ENUM.PARCEL_ACTION_CODE.TBC,
      parcel.deliveryDepot.depotCode
    )
  ),
  withProps(({ fetchedData }) => ({
    dates: fetchedData.dates,
    depot: fetchedData.depot,
    onSubmitSuccess: ({ goToStep }) => goToStep(TBC_STEPS.COMPLETE),
  })),
  withPageControl({
    title: MESSAGES.COLLECT_FROM_DEPOT,
    backgroundImage: IMAGES.MAP,
    trackable: trackable(COLLECT_FROM_DEPOT_DELIVERY_OPTION),
    headerMenuActions: createHeaderControlActions,
  }),
  withState('showMap', 'setShowMap', false),
  withDeliveryUpdate({
    actionCode: ENUM.PARCEL_ACTION_CODE.TBC,
    changeDeliveryAction: changeCollectFromDepot,
    trackable: COLLECT_FROM_DEPOT_DELIVERY_OPTION.ON_SUBMIT,
  })
)(CollectFromDepotComponent);

export default CollectFromDepot;
