import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  dutyDetailsItem: {
    fontSize: typography.pxToRem(18),
    fontWeight: 300,
    lineHeight: 1.61,
    letterSpacing: typography.pxToRem(0.45),

    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(14),
    },
  },
  dutyDetailsLeftItem: {
    textAlign: 'left',
  },
  dutyDetailsRightItem: {
    textAlign: 'right',
    fontWeight: 500,
  },
}));

type Props = {
  name: string,
  value: string,
  nameClassName?: string,
  valueClassName?: string,
};

const DutyDetailsItem = ({
  name,
  value,
  nameClassName,
  valueClassName,
}: Props) => {
  const styles = useStyles();

  return (
    <>
      <Grid
        item
        xs={9}
        className={clsx(
          styles.dutyDetailsItem,
          styles.dutyDetailsLeftItem,
          nameClassName
        )}
      >
        {name}
      </Grid>
      <Grid
        item
        xs={3}
        className={clsx(
          styles.dutyDetailsItem,
          styles.dutyDetailsRightItem,
          valueClassName
        )}
      >
        {value}
      </Grid>
    </>
  );
};

export default DutyDetailsItem;
