import React from 'react';
import Grid from '@material-ui/core/Grid';
import Rating from '@material-ui/lab/Rating';
import { makeStyles } from '@material-ui/styles';

import PhotoWithFlag from '@dpdgroupuk/fmx-ui/components/DriverProfile/PhotoWithFlag';
import {
  getDriverImageUrl,
  getImageSize,
} from '@dpdgroupuk/fmx-ui/utils/images';
import {
  RatingStar,
  RatingStarFilled,
} from '@dpdgroupuk/fmx-ui/components/Icons';
import { useEnvContext } from '@dpdgroupuk/fmx-ui/env';

import type { Driver } from '../../types';

const useStyles = makeStyles(({ typography, palette }) => {
  return {
    photo: {
      '& img': {
        width: typography.pxToRem(78),
        maxHeight: typography.pxToRem(78),
      },
    },
    iconFilled: {
      color: palette.icon.red,
      marginRight: typography.pxToRem(7),
    },
    iconHover: {
      color: palette.icon.red,
      marginRight: typography.pxToRem(7),
    },
    iconEmpty: {
      color: '#979797',
      marginRight: typography.pxToRem(7),
    },
    ratingContainer: {
      marginTop: typography.pxToRem(40),
    },
  };
});

type Props = {
  driver: Driver,
  value: any,
  onChange: Function,
};

export default function RateWithPhoto({ driver, value, onChange }: Props) {
  const styles = useStyles();
  const { API_HOST } = useEnvContext();
  const photo = getDriverImageUrl(API_HOST, driver.driverPhoto, {
    size: getImageSize(78, 78),
    rounded: true,
  });

  return (
    <Grid container justify="center" spacing={2}>
      <Grid item>
        <PhotoWithFlag photo={photo} className={styles.photo} />
      </Grid>
      <Grid item className={styles.ratingContainer}>
        <Rating
          value={value}
          onChange={onChange}
          size="large"
          classes={{
            iconFilled: styles.iconFilled,
            iconHover: styles.iconHover,
            iconEmpty: styles.iconEmpty,
          }}
          emptyIcon={<RatingStar />}
          icon={<RatingStarFilled />}
        />
      </Grid>
    </Grid>
  );
}
