import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ palette, typography }) => ({
  img: {
    margin: typography.pxToRem(35),
  },
  dialogContainer: {
    backgroundColor: palette.background.modal,
    alignItems: 'center',
    margin: typography.pxToRem(10),
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: palette.border.iconBlock,
  },
  imgContainer: {
    minHeight: typography.pxToRem(150),
    minWidth: typography.pxToRem(150),
    backgroundColor: '#fff',
    position: 'relative',
    margin: typography.pxToRem(15),
    border: `${typography.pxToRem(1)} solid #808285`,
    borderRadius: typography.pxToRem(3),
  },
  textContainer: {
    marginTop: typography.pxToRem(17),
  },
  text: {
    fontSize: typography.pxToRem(17),
    fontWeight: 300,
    lineHeight: 1.66,
    letterSpacing: 0.44,
  },
}));

type Props = {
  QrCode: React.Node,
  open: boolean,
  onClose: any => any,
};

const PickupPassModal = ({ open, onClose, QrCode }: Props) => {
  const styles = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: styles.dialogContainer }}
    >
      <IconButton
        aria-label="close"
        size="small"
        className={styles.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <Grid className={styles.textContainer}>
        <Typography className={styles.text}>DPD Pickup Pass</Typography>
      </Grid>
      <Grid className={styles.imgContainer}>{QrCode}</Grid>
    </Dialog>
  );
};

export default PickupPassModal;
