import api from './api';

export const sendAuthSms = body => {
  return api.post({
    path: `/sms/auth`,
    body,
  });
};

export const authParcel = (parcelCode, otp) => {
  return api.put({
    path: `/sms/auth/${parcelCode}`,
    body: { otp },
  });
};
