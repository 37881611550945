import * as React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { withProps } from 'recompose';
import { get } from 'lodash';
import type { AuthorizedParcel, Parcel } from '@dpdgroupuk/fmx-ui/types/Parcel';
import DeliveryImages from '@dpdgroupuk/fmx-ui/components/DeliveryImages';
import {
  getDriverImageUrl,
  getImageSize,
} from '@dpdgroupuk/fmx-ui/utils/images';
import { useEnvContext } from '@dpdgroupuk/fmx-ui/env';

import { withTrackProps } from '@dpdgroupuk/react-event-tracker';
import TrackingStatus from '../../../../../components/TrackingStatus';
import { getImagesData } from '../../DeliveryImage/model';
import DriverWithRating from '../../../../../components/Rate/DriverWithRating';
import RateButtons from '../../../../../components/Rate/RateButtons';
import { goToRating } from '../../../../historyActions';
import withFetch from '../../../../../HOCs/withFetch';
import { fetchParcel } from '../../../../../store/actions/parcels';
import { RATE_EXPERIENCE } from '../../../../../constants/analytics';
import { RATE_TYPE } from '../../../../../constants/rate';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  imagesContainer: {
    paddingTop: 0,
    [breakpoints.down('xs')]: {
      marginRight: typography.pxToRem(30),
    },
    [breakpoints.down(345)]: {
      marginRight: typography.pxToRem(20),
      marginLeft: typography.pxToRem(20),
    },
  },
  driverInfoWrapper: {
    marginTop: typography.pxToRem(34),
  },
  rateButtonsWrapper: {
    width: '100%',
    marginTop: typography.pxToRem(48),
    marginBottom: typography.pxToRem(25),
    [breakpoints.down('xs')]: {
      marginTop: typography.pxToRem(38),
    },
  },
  carouselText: {
    height: 'auto',
    [breakpoints.down('xs')]: {
      marginRight: typography.pxToRem(30),
    },
    [breakpoints.down(345)]: {
      marginRight: typography.pxToRem(20),
      marginLeft: typography.pxToRem(20),
    },
  },
}));

type Props = {
  parcel: Parcel & AuthorizedParcel,
  onSelect: Function,
  driver: any,
};

const RateExperienceView = ({ parcel, onSelect, driver }: Props) => {
  const { API_HOST, ENTITY_PREFIX } = useEnvContext();
  const images = React.useMemo(() => getImagesData(parcel), [parcel]);
  const photo = getDriverImageUrl(
    API_HOST,
    ENTITY_PREFIX,
    parcel.parcelCode,
    driver.driverPhoto,
    {
      size: getImageSize(78, 78),
      rounded: true,
    }
  );

  const styles = useStyles();

  const onPositiveClick = React.useCallback(() => {
    onSelect(RATE_TYPE.LIKE);
  }, [onSelect]);

  const onNegativeClick = React.useCallback(() => {
    onSelect(RATE_TYPE.DISLIKE);
  }, [onSelect]);

  return (
    <>
      <TrackingStatus status={parcel.parcelStatusHtml} />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={styles.driverInfoWrapper}
      >
        {images && images.length !== 0 && (
          <DeliveryImages
            images={images}
            containerClassName={styles.imagesContainer}
            carouselTextClassName={styles.carouselText}
            entityCode={parcel.parcelCode}
            withCarousel
          />
        )}
        <DriverWithRating
          name={get(driver, 'driverName', 'Your driver')}
          rating={get(driver, 'rating', 0)}
          photo={photo}
        />
      </Grid>
      <Grid className={styles.rateButtonsWrapper}>
        <RateButtons
          onPositiveClick={onPositiveClick}
          onNegativeClick={onNegativeClick}
        />
      </Grid>
    </>
  );
};

export default compose(
  withRouter,
  withFetch(({ parcel }) => fetchParcel(parcel.parcelCode)),
  withProps(({ parcel, history }) => ({
    onSelect: deliveryRating => {
      goToRating({ parcel, history, deliveryRating });
    },
  })),
  withTrackProps({
    onSelect: rating =>
      rating === RATE_TYPE.LIKE
        ? RATE_EXPERIENCE.ON_LIKE
        : RATE_EXPERIENCE.ON_DISLIKE,
  })
)(RateExperienceView);
