import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withProps, withState } from 'recompose';
import { get } from 'lodash';
import { Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import * as ENUM from '@dpdgroupuk/redback-enums';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import BackToTrackingMapContainer from '@dpdgroupuk/fmx-ui/components/BackToTrackingMap';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes/constants';
import {
  withTrackProps,
  createLogEntryPayload,
} from '@dpdgroupuk/react-event-tracker';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import Button from '@dpdgroupuk/fmx-ui/components/Button';

import type { AuthorizedParcel } from '../../../types';
import {
  WHAT_HAPPENS_NEXT_PAGE_HEADER,
  WHAT_HAPPENS_NEXT_HEADER,
  CHANGE_DELIVERY,
} from '../../../constants/message';
import withParcel from '../../../HOCs/withParcel';
import {
  SPEED_DEAL,
  trackable,
  WHAT_HAPPENS_NEXT,
} from '../../../constants/analytics';
import {
  trackAnotherParcelSpeedDeal,
  goToParcelInfoSpeedDeal,
  trackAnotherParcelHeader,
  backToDpdSiteHeader,
  backToGreenSiteHeader,
  getDpdAppHeader,
  goToParcelInfoHeader,
  goToPollutrackHeader,
  goBackToDeliveryDetailsSpeedDeal,
} from '../../controlActions';
import { goToTracking, goToDeliveryOptions } from '../../historyActions';
import { getParcelRoadMapPreferences } from '../model';
import TrackingStatus from '../../../components/TrackingStatus';
import NextSteps from './NextSteps';
import { getDeliveryActionsByDepotCode } from '../../../store/orm/Action/selectors';
import { getClaimsData } from '../../../store/orm/Claim/selectors';
import { fetchActionsNeighboursAndDriver } from '../actions';
import { getBackgroundImage } from '../../../utils/images';
import ChatBotWrapper from './ChatBotWrapper';
import { useChatIconWithAction, useChatBotListener, useDelay } from '../hooks';
import { ORIGIN } from '../../../constants/values';
import withFetch from '../../../HOCs/withFetch';
import NoNexSteps from './NoNextSteps';

const useStyles = makeStyles(({ palette, typography, breakpoints }) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingTop: typography.pxToRem(170),
  },
  title: {
    color: palette.text.primary,
    fontSize: typography.pxToRem(19),
    lineHeight: 1.65,
    letterSpacing: typography.pxToRem(0.13),
    marginTop: typography.pxToRem(29),
    textAlign: 'center',

    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
      lineHeight: 1.77,
      letterSpacing: typography.pxToRem(0.37),
    },
  },
  contentWrapper: {
    marginTop: typography.pxToRem(45),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginTop: typography.pxToRem(23.4),
      alignItems: 'flex-start',
      marginLeft: typography.pxToRem(10),
    },
  },
  stepsWrapper: {
    paddingRight: typography.pxToRem(25),

    [breakpoints.down('xs')]: {
      width: '100%',
      paddingRight: 0,
    },
  },
  button: {
    backgroundColor: palette.error.main,

    '&:hover': {
      backgroundColor: palette.error.main,
    },
  },
  changeWrapper: {
    paddingLeft: typography.pxToRem(56),

    [breakpoints.down('xs')]: {
      paddingLeft: 0,
      marginTop: typography.pxToRem(33),
    },
  },
  divider: {
    width: typography.pxToRem(2),
    height: typography.pxToRem(216),
    backgroundColor: '#979797',

    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  width: {
    maxWidth: typography.pxToRem(960),
  },
  status: {
    fontSize: typography.pxToRem(19),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(16),
    },
    '& .REDTEXT': {
      fontWeight: 500,
      color: 'inherit',
    },
  },
  link: {
    textTransform: 'uppercase',
    marginTop: typography.pxToRem(55),
    color: '#ffffff',
    fontSize: typography.pxToRem(11),
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.56,
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: typography.pxToRem(6.5),
    boxShadow: `0 ${typography.pxToRem(2)} ${typography.pxToRem(
      3
    )} 0 rgba(0, 0, 0, 0.5)`,
    backgroundColor: palette.primary.main,
    padding: `${typography.pxToRem(13)} ${typography.pxToRem(19)}`,
    '&:hover': {
      textDecoration: 'none',
    },
    [breakpoints.down('xs')]: {
      padding: `${typography.pxToRem(12)} ${typography.pxToRem(
        17
      )} ${typography.pxToRem(10)}`,
      marginTop: typography.pxToRem(46),
      fontSize: typography.pxToRem(9),
    },
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: typography.pxToRem(40),
    [breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      paddingLeft: typography.pxToRem(24),
    },
  },
}));

type Props = {
  parcel: AuthorizedParcel,
  deliveryActions: any,
  goToTracking: Function,
  chatAvailable: boolean,
  showChat: boolean,
  loadChat: boolean,
  chatOrigin: boolean,
  setLoadChat: Function,
  setChatAvailable: Function,
  setShowChat: Function,
  onChangeDelivery: Function,
  claims: object,
};

const WhatHappensNextComponent = ({
  parcel,
  deliveryActions,
  goToTracking,
  showChat,
  chatAvailable,
  setChatAvailable,
  loadChat,
  setLoadChat,
  setShowChat,
  chatOrigin,
  onChangeDelivery,
  claims: { dpdSession },
}: Props) => {
  window.dpdGroupApp.parcelCode = parcel.parcelCode;
  window.dpdGroupApp.postcode = parcel.deliveryDetails.address.postcode;
  window.dpdGroupApp.businessUnit = parcel.businessUnit;
  window.dpdGroupApp.dpdSession = dpdSession;
  const delay10s = useDelay();
  const availableActions = get(deliveryActions, 'availableActions', []);
  const isKMIinActions = availableActions.includes(ENUM.PARCEL_ACTION_CODE.KMI);
  const showChatIcon = chatOrigin && !isKMIinActions && delay10s;
  const { deliveredToConsumer, nextSteps, parcelStatusType } = parcel;

  useChatBotListener({
    setShowChat,
    chatAvailable,
    setChatAvailable,
    setLoadChat,
  });
  useChatIconWithAction({
    showChat,
    setShowChat,
    setLoadChat,
    loadChat,
    showChatIcon,
    chatOrigin,
  });

  const styles = useStyles();
  const preferences = getParcelRoadMapPreferences(parcel, availableActions);
  const noWHNStatuses = [
    ENUM.PARCEL_STATUS.COLLECT_FROM_DEPOT_INSTRUCTIONS,
    ENUM.PARCEL_STATUS.PICKUP_DELIVERY,
    ENUM.PARCEL_STATUS.PICKUP_DELIVERY_COLLECTED,
  ];
  const noNextSteps =
    get(nextSteps, 'code') === 'N' ||
    get(nextSteps, 'code') === null ||
    (deliveredToConsumer === false && noWHNStatuses.includes(parcelStatusType));

  return (
    <>
      {noNextSteps ? (
        <NoNexSteps parcel={parcel} />
      ) : (
        <BackToTrackingMapContainer onClick={goToTracking}>
          <Container
            maxWidth="md"
            className={styles.container}
            classes={{ maxWidthMd: styles.width }}
          >
            <TrackingStatus
              status={parcel.parcelStatusHtml}
              className={styles.status}
            />
            <Typography className={styles.title}>
              {WHAT_HAPPENS_NEXT_HEADER}
            </Typography>
            <div className={styles.contentWrapper}>
              <div className={styles.stepsWrapper}>
                <NextSteps
                  preferences={preferences}
                  parcel={parcel}
                  availableActions={availableActions}
                />
              </div>
              <div className={styles.divider} />
              <div className={styles.changeWrapper}>
                <Button type="submit" onClick={onChangeDelivery}>
                  {CHANGE_DELIVERY}
                </Button>
              </div>
            </div>
          </Container>
        </BackToTrackingMapContainer>
      )}
      <ChatBotWrapper
        show={showChat}
        loadChat={loadChat}
        chatAvailable={chatAvailable}
      />
    </>
  );
};

const createSpeedDealControlActions = props => [
  trackAnotherParcelSpeedDeal(props),
  goToParcelInfoSpeedDeal(props),
  goBackToDeliveryDetailsSpeedDeal(props),
];

const mapStateToProps = (state, { match }) => {
  const parcelId = match.params.parcelId;

  return {
    deliveryActions: getDeliveryActionsByDepotCode(state, parcelId),
    claims: getClaimsData(state, parcelId),
  };
};

const createHeaderControlActions = props => {
  const generalHeaderControlActions = [
    trackAnotherParcelHeader(props),
    goToParcelInfoHeader(props),
    backToDpdSiteHeader(props),
    backToGreenSiteHeader(props),
    getDpdAppHeader(props),
  ];

  if (props.parcel.airDiagActive) {
    return [...generalHeaderControlActions, goToPollutrackHeader(props)];
  }

  return generalHeaderControlActions;
};

const WhatHappensNext = compose(
  withRouter,
  withParcel(),
  withFetch(({ parcel }) => fetchActionsNeighboursAndDriver({ parcel })),
  connect(mapStateToProps),
  withState('showChat', 'setShowChat', false),
  withState('chatAvailable', 'setChatAvailable', false),
  withState('loadChat', 'setLoadChat', false),
  withProps(
    ({ history, parcel, claims: { origin }, fetchedData: { neighbours } }) => ({
      goToTracking: () => goToTracking({ history, parcel }),
      neighbours,
      chatOrigin:
        origin === ORIGIN.EMAIL ||
        origin === ORIGIN.SMS ||
        origin === ORIGIN.PRCU,
      onChangeDelivery: () => {
        goToDeliveryOptions({
          parcel,
          history,
        });
      },
    })
  ),
  withTrackProps({
    setShowChat: open =>
      createLogEntryPayload(SPEED_DEAL.CLICK_CHAT_ICON, { open }),
    goToTracking: WHAT_HAPPENS_NEXT.GO_TO_TRACKING_MAP,
    onChangeDelivery: WHAT_HAPPENS_NEXT.ON_CHANGE_THIS_DELIVERY,
  }),
  withModal,
  withPageControl({
    title: WHAT_HAPPENS_NEXT_PAGE_HEADER,
    backgroundImage: getBackgroundImage({
      desktopImage: IMAGES.DRIVER_BG,
      mobileImage: IMAGES.DRIVER_BG_MOBILE,
    }),
    backgroundOpacity: 0.87,
    trackable: trackable(WHAT_HAPPENS_NEXT),
    speedDealActions: createSpeedDealControlActions,
    headerMenuActions: createHeaderControlActions,
  })
)(WhatHappensNextComponent);

export default WhatHappensNext;
