import api from './api';

export const getDriverByDriverCode = (driverCode: string) =>
  api.get({
    path: `/drivers/${driverCode}`,
  });

export const getDriverFeedbackByDriverCode = (driverCode: string) =>
  api.get({
    path: `/drivers/${driverCode}/rating`,
  });
