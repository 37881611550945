import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Carousel from './Carousel';

type Props = {
  children: React.Node,
};

const MultiplePickupPassQRCodes = ({ children }: Props) => {
  // Because children can look like: [el1, [el2, el3]] we need to manipulate the array to a format we can use IE [el1, el2, el3]
  let qrCodes = [];
  for (const childEl of children) {
    if (Array.isArray(childEl)) qrCodes.push(...childEl);
    else qrCodes.push(childEl);
  }

  qrCodes = qrCodes.map((QrCode, index) => {
    return (
      <div key={index + 'qr-code'}>
        <Typography>{`Parcel ${index + 1} of ${qrCodes.length}`}</Typography>
        {QrCode}
      </div>
    );
  });

  return (
    <>
      <Carousel items={qrCodes} />
    </>
  );
};
export default MultiplePickupPassQRCodes;
