import api from './api';

export const login = (
  parcelCode: string,
  postcode: string,
  recaptchaToken: string,
  origin: string
) => {
  return api.post({
    path: '/login',
    headers: {
      'g-recaptcha-response': recaptchaToken,
    },
    body: {
      parcelCode,
      postcode,
      origin: origin || 'PRTK',
    },
  });
};

export const contactUsLogin = (
  parcelNumber: string,
  postcode: string,
  dpdSession: string
) => {
  return api.post({
    path: '/contactus/login',
    body: {
      parcelNumber,
      postcode,
      dpdSession,
    },
  });
};
