import { attr, Model } from 'redux-orm';
import type { PayloadAction } from '@dpdgroupuk/redux-action-creator';

import { ACTIONS } from './actions';
import { ENTITY_NAME } from '../constants';

export class Depot extends Model {
  // eslint-disable-next-line no-use-before-define
  static reducer(action: PayloadAction, Depot: Depot) {
    if (action.type === ACTIONS.FETCH_DEPOT_BY_CODE.SUCCESS) {
      Depot.create({
        ...action.payload,
      });
    }
  }

  static modelName = ENTITY_NAME.DEPOT;

  static fields = {
    depotCode: attr(),
  };

  static options = {
    idAttribute: 'depotCode',
  };
}
