import { withStyles } from '@material-ui/core/styles';
import { TYPES } from '@dpdgroupuk/fmx-ui/themes';

export default withStyles(theme => ({
  '@global': {
    '.REDTEXT': {
      color: theme.palette.type === TYPES.LIGHT ? '#df002c' : 'inherit',
    },
  },
}))(() => null);
