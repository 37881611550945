import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import DeliveryInfo from '@dpdgroupuk/fmx-ui/components/DeliveryInfo';
import { ParcelOptionIcon } from '@dpdgroupuk/fmx-ui/components/OptionIcon';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';

import TrackAnotherParcelButton from '../../../components/TrackAnotherParcelButton';
import {
  backToDpdSiteHeader,
  backToGreenSiteHeader,
  getDpdAppHeader,
  goToParcelInfoHeader,
  goToParcelInfoSpeedDeal,
  goBackToDeliveryDetailsSpeedDeal,
  trackAnotherParcelHeader,
  trackAnotherParcelSpeedDeal,
} from '../../../../controlActions';
import {
  DELIVERY_OPTION_CONFIRMATION,
  trackable,
} from '../../../../../constants/analytics';
import CompleteParcelNumber from '../../../components/CompleteParcelNumber';
import typeof { AuthorizedParcel } from '../../../../../types/Parcel';

type Props = {
  actionCode?: string,
  parcel: AuthorizedParcel,
};

const CompleteStepComponent = ({ actionCode, parcel }: Props) => {
  const title =
    "Thanks for the contact information, we'll use it to keep you updated\non your delivery";

  return (
    <>
      <DeliveryInfo
        actionCode={actionCode}
        title={title}
        getOptionIcon={() => ParcelOptionIcon}
      />
      <CompleteParcelNumber parcelNumber={parcel.parcelNumber} />
      <TrackAnotherParcelButton />
    </>
  );
};

const createSpeedDealControlActions = props => [
  trackAnotherParcelSpeedDeal({ ...props, replace: true }),
  goToParcelInfoSpeedDeal({ ...props, replace: true }),
  goBackToDeliveryDetailsSpeedDeal(props),
];

const createHeaderControlActions = props => [
  trackAnotherParcelHeader({ ...props, replace: true }),
  goToParcelInfoHeader({ ...props, replace: true }),
  backToDpdSiteHeader(props),
  backToGreenSiteHeader(props),
  getDpdAppHeader(props),
];

const CompleteStep = compose(
  withRouter,
  withModal,
  withPageControl({
    trackable: trackable(DELIVERY_OPTION_CONFIRMATION),
    speedDealActions: createSpeedDealControlActions,
    headerMenuActions: createHeaderControlActions,
  })
)(CompleteStepComponent);

export default CompleteStep;
