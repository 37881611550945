import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { withProps } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import SubmitButton from '@dpdgroupuk/fmx-ui/components/SubmitButton';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';

import { fetchPaymentToken } from '../../actions';
import ChangeDeliveryDay from '../../components/ChangeDeliveryDay';
import { UPG_STEPS } from '../../steps';

import {
  hasParcelNotificationDetails,
  getContactInformation,
  getInfoForThreeDSecure,
} from '../../../../models/parcel';
import type { AuthorizedParcel } from '../../../../types';
import withFetch from '../../../../HOCs/withFetch';
import { UPGRADE_DELIVERY_OPTION } from '../../../../constants/analytics';
import { makePaymentParams } from '../../../../utils/payment';
import { catchRequestError } from '../../../../utils/errorHandling';
import { onProcessPaymentResult } from '../../../../utils/threeDSecure';
import useBraintree from '../../../../components/BraintreeWebDropIn/hooks/useBraintree';
import { BRAINTREE_PAYMENT_PROBLEM } from '../../../../constants/error';
import { OK } from '../../../../constants/message';

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    '& .braintree-heading': {
      width: '100%',
      textAlign: 'center',
    },
    '& .braintree-sheet__label, .braintree-heading': {
      color: '#000',
    },
    marginTop: spacing(2),
    marginBottom: spacing(2),
  },
}));

type Props = {
  modal: Object,
  history: History,
  data: Object,
  fetchedData: Array<any>,
  submitWizard: Function,
  goToStep: Function,
  addStepData: Function,
  parcel: AuthorizedParcel,
  onDateChange: Function,
};

function PaymentStep({
  modal,
  history,
  data,
  fetchedData: [tokenResult, defaultExport],
  submitWizard,
  goToStep,
  addStepData,
  onDateChange,
  parcel,
}: Props) {
  const styles = useStyles();
  const { default: DropIn } = defaultExport;

  const [state, api] = useBraintree(
    makePaymentParams(tokenResult.token, data.time.upgradeCost),
    React.useCallback(error => catchRequestError({ error, modal, history }), [
      history,
      modal,
    ]),
    modal
  );

  const onSubmit = React.useCallback(async () => {
    const paymentResult: any = await api.requestPaymentMethod({
      threeDSecure: getInfoForThreeDSecure(data.time.upgradeCost, parcel),
    });

    return onProcessPaymentResult(
      paymentResult,
      () => {
        if (!hasParcelNotificationDetails(parcel)) {
          addStepData({
            paymentNonce: paymentResult.nonce,
            vendorRef: paymentResult.type,
          });

          goToStep(UPG_STEPS.GET_CONTACTS);
        } else {
          const stepData = {
            paymentNonce: paymentResult.nonce,
            vendorRef: paymentResult.type,
            ...getContactInformation(parcel),
          };

          submitWizard({ stepData });
        }
      },
      () =>
        modal.showModal({
          title: BRAINTREE_PAYMENT_PROBLEM,
          buttonText: OK,
          description: null,
        })
    );
  }, [
    addStepData,
    goToStep,
    submitWizard,
    api,
    data.time.upgradeCost,
    parcel,
    modal,
  ]);

  return (
    <>
      <ChangeDeliveryDay day={data.day} onDateChange={onDateChange} />
      <Grid className={styles.title}>
        <DropIn ref={state.dropInRef} />
      </Grid>
      <SubmitButton
        onSubmit={onSubmit}
        isSubmitDisabled={!state.paymentMethodRequestable}
      />
    </>
  );
}

export default compose(
  withRouter,
  withModal,
  withProps(({ addStepData, goToStep }) => ({
    onDateChange: () => {
      addStepData({ day: '', time: null });
      goToStep(UPG_STEPS.SELECT_DAY);
    },
  })),
  withTrackProps({
    onDateChange: UPGRADE_DELIVERY_OPTION.ON_DATE_CHANGE,
  }),
  withFetch(
    ({ parcel }) => dispatch => {
      return Promise.all([
        dispatch(fetchPaymentToken(parcel.parcelCode)),
        import('../../../../components/BraintreeWebDropIn'),
      ]);
    },
    { deliveryOptions: true }
  )
)(PaymentStep);
