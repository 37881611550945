import { connect } from 'react-redux';
import { getParcelData } from '../store/orm/Parcel/selectors';

const mapStateToPropsParcel = (state, { match }) => ({
  parcel: getParcelData(state, match.params.parcelId),
});

const withParcel = () => connect(mapStateToPropsParcel);

export default withParcel;
