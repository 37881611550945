import * as React from 'react';
import { useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { get } from 'lodash';
import { withProps } from 'recompose';

import SubmitButton from '@dpdgroupuk/fmx-ui/components/SubmitButton';
import { useTracker, withTrackProps } from '@dpdgroupuk/react-event-tracker';
import { sortAddresses } from '@dpdgroupuk/fmx-ui/utils/address';

import type { AuthorizedParcel } from '../../../../types';

import {
  checkDepotCoverageByPostcode,
  fetchLocationsByPostcode,
} from '../../actions';

import SearchAddress from '../../../../components/SearchAddress';
import { noop } from '../../../../utils/function';
import ChangeDeliveryDay from '../../components/ChangeDeliveryDay';
import {
  POSTCODE_MUST_BE_WITHIN_DEPOT_AREA,
  NO_ADDRESSES_FOR_CURRENT_POSTCODE,
} from '../../../../constants/error';
import { getContactInformation } from '../../../../models/parcel';
import { ALA_DELIVERY_OPTION } from '../../../../constants/analytics';
import { ALA_STEPS } from '../../steps';

type Props = {
  addStepData: Function,
  onSubmit: Function,
  onSelectAddress: Function,
  parcel: AuthorizedParcel,
  data: Object,
  onDateChange: Function,
};

const useStyles = makeStyles(({ typography }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  submitButtonContainer: {
    marginTop: typography.pxToRem(20),
  },
}));

const SelectAddressStep = ({
  data,
  parcel,
  addStepData,
  onSubmit,
  onSelectAddress,
  onDateChange,
}: Props) => {
  const tracker = useTracker();
  const { address } = data;
  const styles = useStyles();
  const dispatch = useDispatch();
  const [addresses, setAddresses] = React.useState([]);

  const searchAddresses = React.useCallback(
    postcode => {
      const depotCode = get(parcel, 'deliveryDepot.depotCode');

      // add whitespace in postcode if space isn't already there
      if (!/\s/.test(postcode)) {
        postcode = postcode.replace(/^(.*)(\d)/, '$1 $2');
      }

      return dispatch(checkDepotCoverageByPostcode(depotCode, postcode))
        .then(res => {
          if (!res.covered) {
            throw new Error();
          }

          return Promise.resolve();
        })
        .catch(() => {
          throw new Error('notCovered');
        })
        .then(() => dispatch(fetchLocationsByPostcode(postcode)))
        .catch(e => {
          const notCovered = /notCovered/.test(e);

          throw new SubmissionError({
            _error: notCovered
              ? POSTCODE_MUST_BE_WITHIN_DEPOT_AREA
              : NO_ADDRESSES_FOR_CURRENT_POSTCODE,
          });
        });
    },
    [dispatch, parcel]
  );

  const onSubmitSearch = React.useCallback(
    postcode => {
      tracker.logEvent(ALA_DELIVERY_OPTION.ON_POSTCODE_ENTER);
      return searchAddresses(postcode).then(data => setAddresses(data));
    },
    [searchAddresses, tracker]
  );

  const onSearchInputChange = React.useCallback(() => {
    if (addresses.length) {
      setAddresses([]);
      addStepData({ address: null });
    }
  }, [addresses, setAddresses, addStepData]);

  return (
    <div className={styles.container}>
      <ChangeDeliveryDay day={data.day} onDateChange={onDateChange} />
      <SearchAddress
        selectedAddress={address}
        onSubmit={data => onSubmitSearch(data.postcode)}
        addresses={sortAddresses(addresses)}
        onSelect={onSelectAddress}
        onChange={onSearchInputChange}
      />
      <SubmitButton onSubmit={onSubmit} isSubmitHidden={!address} />
    </div>
  );
};

SelectAddressStep.defaultProps = {
  data: { address: null, day: '' },
  addStepData: noop,
};

export default compose(
  withProps(({ submitWizard, addStepData, parcel, goToStep }) => ({
    onSelectAddress: address =>
      addStepData({ address, ...getContactInformation(parcel) }),
    onSubmit: submitWizard,
    onDateChange: () => {
      addStepData({ address: '' });
      goToStep(ALA_STEPS.SELECT_DAY);
    },
  })),
  withTrackProps({
    onSelectAddress: ALA_DELIVERY_OPTION.ON_ADDRESS_SELECT,
    onDateChange: ALA_DELIVERY_OPTION.ON_DATE_CHANGE,
  })
)(SelectAddressStep);
