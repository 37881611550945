import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from '@dpdgroupuk/redux-action-creator';
import type { AsyncDispatch } from '@dpdgroupuk/redux-action-creator';

import { apiService, fetchTimeoutHandler } from '../../services';
import { ParcelActions } from '../orm/Parcel';

export const ACTIONS = createActionTypes('SEARCH_PARCEL', {
  FETCH_SEARCH_PARCEL_BY_REFERENCE: createAsyncActionTypes(
    'FETCH_SEARCH_PARCEL_BY_REFERENCE'
  ),
  LOGIN: createAsyncActionTypes('LOGIN'),
});

export const fetchSearchParcelByReferenceNumber = createAsyncAction(
  (referenceNumber: string, postcode: string, origin: string) =>
    fetchTimeoutHandler(
      apiService.getSearchParcelByReferenceNumber(
        referenceNumber,
        postcode,
        origin
      )
    ).then(({ data }) => data),
  ACTIONS.FETCH_SEARCH_PARCEL_BY_REFERENCE
);

export const login = createAsyncAction(
  (
    parcelCode: string,
    postcode: string,
    recaptchaToken: string,
    origin: string
  ) =>
    fetchTimeoutHandler(
      apiService.login(parcelCode, postcode, recaptchaToken, origin)
    ).then(({ data }) => data),
  ACTIONS.LOGIN
);

export const fetchParcel = (parcelCode: string) =>
  ParcelActions.fetchParcelByCode(parcelCode);

export const loginAndFetchParcel = (
  parcelCode: string,
  postcode: string,
  recaptchaToken: string
) => (dispatch: AsyncDispatch) =>
  dispatch(login(parcelCode, postcode, recaptchaToken)).then(() =>
    dispatch(ParcelActions.fetchParcelByCode(parcelCode))
  );
