import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import * as MESSAGE from '../../constants/message';
import Button from './Button';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  text: {
    fontSize: typography.pxToRem(18),
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.83,
    letterSpacing: typography.pxToRem(0.64),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(14),
      letterSpacing: 0.4,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    whiteSpace: 'pre-line',
  },
  textMargin: {
    marginTop: typography.pxToRem(24),
  },
}));

const DislikedText = ({
  contactNumber,
  showContactNumber,
}: {
  contactNumber: string,
  showContactNumber: boolean,
}) => {
  const styles = useStyles();

  return (
    <>
      <Typography className={styles.text}>{MESSAGE.SORRY}</Typography>
      <Typography className={clsx(styles.text, styles.textMargin)}>
        {MESSAGE.DEPOT_MANAGER_FEEDBACK}
      </Typography>
      {showContactNumber ? (
        <>
          <Typography className={clsx(styles.text, styles.textMargin)}>
            {MESSAGE.PLEASE_CALL_US}
          </Typography>
          <Button
            disableRipple
            disableElevation
            variant="text"
            className={clsx(styles.text, styles.textMargin)}
            component="a"
            href={`tel:${contactNumber}`}
          >
            {contactNumber}
          </Button>
        </>
      ) : null}
    </>
  );
};

export default DislikedText;
