import React from 'react';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';

import { TrackerProvider } from '@dpdgroupuk/react-event-tracker';
import { Provider as DialogProvider } from '@dpdgroupuk/fmx-ui/components/Dialog';
import OverlayProvider from '@dpdgroupuk/fmx-ui/components/Overlay';
import { TouchStartHandler } from '@dpdgroupuk/fmx-ui/hocs';
import { EnvProvider } from '@dpdgroupuk/fmx-ui/env';
import { SnackbarProvider } from 'notistack';

// must be exactly in that order: firebase, analytics
import { initializeRemoteConfig } from './utils/firebase';
import tracker from './utils/analytics';
import Router from './Router';
import store from './store';
import ErrorBoundary from './components/ErrorBoundary';
import { ConfigProvider } from './components/RemoteConfig';
import GlobalCSS from './components/theme';
import { API_HOST, ENTITY_PREFIX } from './constants/app';
import ThemeAndEventProvider from './ThemeAndEventProvider';
import { MetaRobotsNoIndex } from './utils/metaRobots';

const App = () => (
  <>
    <MetaRobotsNoIndex />
    <Provider store={store}>
      <EnvProvider value={{ API_HOST, ENTITY_PREFIX }}>
        <ConfigProvider remoteConfig={initializeRemoteConfig()}>
          <TrackerProvider tracker={tracker}>
            <ThemeAndEventProvider>
              <OverlayProvider position="fixed">
                <TouchStartHandler>
                  <DialogProvider>
                    <GlobalCSS />
                    <CssBaseline />
                    <ErrorBoundary>
                      <SnackbarProvider
                        maxSnack={3}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                      >
                        <Router />
                      </SnackbarProvider>
                    </ErrorBoundary>
                  </DialogProvider>
                </TouchStartHandler>
              </OverlayProvider>
            </ThemeAndEventProvider>
          </TrackerProvider>
        </ConfigProvider>
      </EnvProvider>
    </Provider>
  </>
);

export default App;
