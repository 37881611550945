import * as React from 'react';
import { isMobile } from '@dpdgroupuk/fmx-ui/utils/helpers';
import ArrowsCarousel from '@dpdgroupuk/fmx-ui/components/ArrowsCarousel';
import { useTracker } from '@dpdgroupuk/react-event-tracker';
import { makeStyles, useTheme } from '@material-ui/styles';
import { mapOffsetBottom } from '../../../../ChangeDeliveryOption/pages/CollectFromPickupShop/constants';

const useStyles = makeStyles(({ palette, typography, breakpoints }) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100%',

    '& canvas': {
      height: `calc(100vh - ${mapOffsetBottom}rem) !important`,
      [breakpoints.down(1025)]: {
        height: '46vh !important',
      },
    },
  },
  container: {
    padding: `${typography.pxToRem(11)} ${typography.pxToRem(
      30
    )} 0 ${typography.pxToRem(30)}`,
    background: palette.background.default,
    width: '100%',
    boxSizing: 'border-box',
    height: 'auto',

    [breakpoints.down(1025)]: {
      padding: 0,
      paddingTop: typography.pxToRem(23),
      paddingBottom: typography.pxToRem(200),
      height: typography.pxToRem(500),
      boxSizing: 'content-box',
    },
  },
  flex: {
    display: 'flex',
  },
  status: {
    marginBottom: typography.pxToRem(10),
    color: palette.text.primary,
    fontSize: typography.pxToRem(19),
    lineHeight: 1.78,
    letterSpacing: 0.38,
    textAlign: 'center',
  },
  list: {
    listStyle: 'none',
    padding: `0 ${typography.pxToRem(20)}`,
    height: typography.pxToRem(430),
    overflowY: 'auto',
  },
  listItem: {
    marginBottom: typography.pxToRem(40),
  },
  arrowIcon: {
    width: typography.pxToRem(16),
    height: typography.pxToRem(33),
  },
  noShops: {
    marginTop: typography.pxToRem(-20),
    marginBottom: typography.pxToRem(-20),
    width: '100%',
    backgroundColor: palette.background.modal,
    [breakpoints.down('xs')]: {
      paddingBottom: typography.pxToRem(250),
    },
  },
  carouselWrapper: {
    width: typography.pxToRem(794),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

type Props = {
  items: React.Node,
};

const Carousel = ({ items }: Props) => {
  const tracker = useTracker();
  const activeTheme = useTheme();
  const bottomComponentRef = React.useRef({});
  const [currentCarouselIndex, setCurrentCarouselIndex] = React.useState(0);

  const styles = useStyles({
    footerHeight: bottomComponentRef.current.clientHeight,
  });

  const pickupShopSlides = React.useMemo(() => items, [activeTheme]);

  const onSlideChange = React.useCallback(
    index => {
      setCurrentCarouselIndex(index);
    },
    [items, currentCarouselIndex, tracker]
  );

  return (
    <>
      <ArrowsCarousel
        mouseTrackingEnabled
        touchTrackingEnabled
        dotsDisabled
        buttonsDisabled
        infinite={false}
        items={pickupShopSlides}
        responsive={{
          '0': {
            items: 1,
          },
        }}
        swipeDisabled={!isMobile}
        arrowIconClassName={styles.arrowIcon}
        slideIndex={currentCarouselIndex}
        slideToIndex={currentCarouselIndex}
        onSlideIndexChange={onSlideChange}
      />
    </>
  );
};

export default Carousel;
