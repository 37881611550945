import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import DeliveryInfo from '@dpdgroupuk/fmx-ui/components/DeliveryInfo';
import { ParcelOptionIcon } from '@dpdgroupuk/fmx-ui/components/OptionIcon';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { getAddressWithPostcode } from '@dpdgroupuk/fmx-ui/utils/address';
import { formatOrdinalNumber } from '@dpdgroupuk/fmx-ui/utils/string';
import { fullDateValue, getTimeArray } from '@dpdgroupuk/fmx-ui/utils/date';

import type { AuthorizedParcel } from '../../../../types';
import TrackAnotherParcelButton from '../../components/TrackAnotherParcelButton';
import {
  backToDpdSiteHeader,
  backToGreenSiteHeader,
  getDpdAppHeader,
  goToParcelInfoHeader,
  goToParcelInfoSpeedDeal,
  goBackToDeliveryDetailsSpeedDeal,
  trackAnotherParcelHeader,
  trackAnotherParcelSpeedDeal,
} from '../../../controlActions';
import {
  DELIVERY_OPTION_CONFIRMATION,
  trackable,
} from '../../../../constants/analytics';
import CompleteParcelNumber from '../../components/CompleteParcelNumber';

const useStyles = makeStyles(() => ({
  preciseParcelNumber: {
    marginTop: 0,
  },
}));

type Props = {
  actionCode: string,
  data: {
    day: string,
    diaryTime: string,
  },
  parcel: AuthorizedParcel,
};

const CompleteStepComponent = ({
  actionCode,
  data = { day: '', diaryTime: '' },
  parcel,
}: Props) => {
  const { day, diaryTime } = data;
  const timeArray = getTimeArray(diaryTime);
  const { year, month, dayOfWeek, dayNumber } = fullDateValue(day);
  const title = `As requested, your parcel will now be delivered on ${dayOfWeek} the ${formatOrdinalNumber(
    dayNumber
  )} ${month} ${year} between <b>${timeArray[0]} and ${timeArray[1]}</b>`;

  const subTitle = `${getAddressWithPostcode(
    parcel.deliveryDetails.address
  )}\n `;

  const styles = useStyles();

  return (
    <>
      <DeliveryInfo
        actionCode={actionCode}
        titleHtml={title}
        subTitle={subTitle}
        getOptionIcon={() => ParcelOptionIcon}
      />
      <CompleteParcelNumber
        parcelNumber={parcel.parcelNumber}
        className={styles.preciseParcelNumber}
      />
      <TrackAnotherParcelButton />
    </>
  );
};

const createSpeedDealControlActions = props => [
  trackAnotherParcelSpeedDeal({ ...props, replace: true }),
  goToParcelInfoSpeedDeal({ ...props, replace: true }),
  goBackToDeliveryDetailsSpeedDeal(props),
];

const createHeaderControlActions = props => [
  trackAnotherParcelHeader({ ...props, replace: true }),
  goToParcelInfoHeader({ ...props, replace: true }),
  backToDpdSiteHeader(props),
  backToGreenSiteHeader(props),
  getDpdAppHeader(props),
];

const CompleteStep = compose(
  withRouter,
  withModal,
  withPageControl({
    trackable: trackable(DELIVERY_OPTION_CONFIRMATION),
    speedDealActions: createSpeedDealControlActions,
    headerMenuActions: createHeaderControlActions,
  })
)(CompleteStepComponent);

export default CompleteStep;
