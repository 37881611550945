import * as ENUM from '@dpdgroupuk/redback-enums';
import { trackProps as trackable } from '@dpdgroupuk/react-event-tracker';

export { trackable };

export const HEADER = {
  BACK_TO_SITE: 'dbkrrHaFYFxo24kX',
  CHANGE_THIS_DELIVERY: 'w_crrHaFYFxo24wU',
  DOWN_LOAD_APP: 'LNDrrHaFYFxo240M',
  DROP_DOWN_MENU: '5vh9rHaFYFxo23L_',
  LEARN_MORE_ABOUT_DRIVER: 'R6aaYnaFYFxqaJvV',
  PARCEL_INFO_BUTTON: 'ExKrrHaFYFxo24xP',
  REAL_TIME_DELIVERY_UPDATES: 'EGurrHaFYFxo24zO',
  SHOW_DEPOT_MAP: 'KGqbrHaFYFxo241S',
  SHOW_SHOP_MAP: 'IGWbrHaFYFxo241x',
  TOGGLE_DARK: '6RUFOHaFYFxozxrG',
  TRACK_ANOTHER_PARCEL: 'OZsbrHaFYFxo240t',
  WHAT_HAPPENS_NEXT: 'xAmrrHaFYFxo24yO',
  BACK_TO_DETAILS: 'Da7XCC6GAqAC4TUi',
  BACK_TO_GREEN_SITE: 'DzaXCC6GAqAC4TR9',
  DO_SOMETHING_DIFFERENT: '26VXCC6GAqAC4TTJ',
  CHECK_YOUR_AIR: '5E9d1m6GAqAC4Rs',
};

export const SPEED_DEAL = {
  BACK_TO_SITE: 'fsLe5HaFYFxorjL9',
  CHANGE_THIS_DELIVERY: '2mDh5HaFYFxorjOE',
  LEARN_MORE_ABOUT_DRIVER: '91TaYnaFYFxqaJ6p',
  PARCEL_INFO_BUTTON: 'Agoe5HaFYFxorjJt',
  REAL_TIME_DELIVERY_UPDATES: 'MK.e5HaFYFxorjK7',
  TOGGLE_HIDE_SHOW: 'dchHZHaFYFxorhVd',
  WHAT_HAPPENS_NEXT: 'MY6R5HaFYFxorjPN',
  DO_SOMETHING_DIFFERENT: 'Q9BnCC6GAqAC4TCi',
  BACK_TO_GREEN_SITE: 'JyEh2S6GAqAC4bxp',
  BACK_TO_DETAILS: 'n9HnCC6GAqAC4TO7',
  CLICK_CHAT_ICON: 'nGkuJfaFYFxqpjBk',
  TRACK_ANOTHER_PARCEL: 'NBFvnS6GAqAC4S_y',
};

export const REFERENCE_ENTRY = {
  INTERFACE_ID: 'E75ItdaFYFxqchOe',
  LOAD: 'AJwGtdaFYFxqcgnU',
  DOWNLOAD_APP: 'C5be_LaFYFxoiHiQ',
  ENTER_POSTCODE: '12oe_LaFYFxoiG5p',
  ENTER_REFERENCE: 'oyKu_LaFYFxoiGkJ',
  SEARCH: 'FtifPdaFYFxmocnF',
};

export const DETAILS_INCORRECT_MODAL = {
  INTERFACE_ID: '2M3etdaFYFxqclJ.',
  LOAD: 'gBVBtdaFYFxqcl0p',
  ON_OK: 'mFLhtdaFYFxqcmTJ',
};

export const LITE_VIEW_TRACKING = {
  INTERFACE_ID: 'ipHNoDaFYFxq4g6s',
  LOAD: 'ToUroDaFYFxq4nQh',
  SUBMIT: 'jF77oDaFYFxq4rVB',
};

export const POSTCODE_FAILED_MODAL = {
  INTERFACE_ID: 'y4fVoDaFYFxq4gSf',
  LOAD: 'oNkKi9aC48kID26e',
  ON_OK: 'YtkKi9aC48kID26u',
};

export const SEARCH_RESULTS_LIST = {
  INTERFACE_ID: 'fZD7sraFYFxqXyh1',
  LOAD: 'tYhmcraFYFxqX1Vp',
  SUBMIT: 'ntGecraFYFxqX3Xx',
};

export const COLLECT_FROM_DEPOT = {
  INTERFACE_ID: '9pjZtdaFYFxqcqBT',
  LOAD: 'M82ftdaFYFxqchxT',
  SHOW_DEPOT_MAP: 'k0d2kDaFYFxqNNf2',
  BACK_TO_DPD_SITE: 'fsLe5HaFYFxorjL9',
  LEARN_ABOUT_YOUR_DRIVER: 'k0d2kDaFYFxqNNf2',
};

export const COLLECT_FROM_PICKUP = {
  CLOSE_PICKUP_PASS: 'b9n4yHaFYFxo_4lv',
  PICKUP_PASS_PRINT: 'A6.4yHaFYFxo_4kv',
  VOUCHER_CLOSE: 'HWJYyHaFYFxo_4hC',
  VOUCHER_TAP: '32JYyHaFYFxo_4hL',
  INTERFACE_ID: '9hgFtdaFYFxqcqQa',
  LOAD: 'jCc_tdaFYFxqciRB',
  SHOW_SHOP_MAP: 'DdldbHaFYFxo239P',
  ON_ADD_TO_APPLE_WALLET: 'V.aJx5GGAqCIlZ3t',
  ON_ADD_TO_GOOGLE_WALLET: 'MOAb.5GGAqCIlRKo',
};

export const NO_PICKUP_SHOP = {
  INTERFACE_ID: 'AwGuss6GAqAC4byK',
  LOAD: 'RPKhss6GAqAC4dU0',
  ON_DO_SOMETHING_DIFFERENT: 'EfKhss6GAqAC4dVF',
};

export const COLLECT_FROM_PICKUP_TRANSIT = {
  INTERFACE_ID: 'Gu25tdaFYFxqcqN',
  LOAD: 'dBzftdaFYFxqch8o',
};

export const ESTIMATED_DATE_TIME = {
  INTERFACE_ID: 'YHwltdaFYFxqcqoh',
  LOAD: 'S1MAddaFYFxqcjQq',
  ON_DELIVERY_OPTIONS: 'SJLjZbaFYFxqbF.s',
};

export const DELIVERY_IMAGE = {
  INTERFACE_ID: 'Wa5JtdaFYFxqco_m',
  LOAD: 'L1TdtdaFYFxqcqzZ',
  ON_CLICK_IMAGE: 'BjgSjbaAUAFcLEte',
  ON_CLOSE_IMAGE: 'SP2abbaFYFxqKS6_',
  ON_DELIVERY_OPTIONS: 'OTQ2kDaFYFxqNNLP',
};

export const GENERAL_VIEW = {
  DELIVERY_OPTIONS: '.VlmkDaFYFxqNMsc',
  INTERFACE_ID: 'HZ0JtdaFYFxqcoqj',
  LOAD: 'HM5xtdaFYFxqcoe4',
};

export const ISSUE = {
  INTERFACE_ID: 'ZlVFtdaFYFxqcqnC',
  LOAD: '7lBAddaFYFxqcjlY',
  CHANGE_DELIVERY: '8KSjZbaFYFxqbFpf',
};

export const OUT_FOR_DELIVERY = {
  INTERFACE_ID: 'HqqFtdaFYFxqcqbc',
  LOAD: 'Y.3_tdaFYFxqci5a',
  ON_DRIVER_INFO: '.YSJbXaFYFxqvA7n',
};

export const DELIVERY_OPTIONS = {
  INTERFACE_ID: 'AYSHtdaFYFxqctcx',
  LOAD: 'WyCDonaFYFxqaDhW',
  [ENUM.PARCEL_ACTION_CODE.UPG.toLowerCase()]: 'ted9YnaFYFxqaKLp',
  [ENUM.PARCEL_ACTION_CODE.SFP.toLowerCase()]: 'hpA9YnaFYFxqaKJH',
  [ENUM.PARCEL_ACTION_CODE.RED.toLowerCase()]: 'YLujYnaFYFxqaKPD',
  [ENUM.PARCEL_ACTION_CODE.TBC.toLowerCase()]: 'J9ZNYnaFYFxqaKIA',
  [ENUM.PARCEL_ACTION_CODE.DTN.toLowerCase()]: 'a4h9YnaFYFxqaKK0',
  [ENUM.PARCEL_ACTION_CODE.ALA.toLowerCase()]: 'IEsjYnaFYFxqaKOJ',
  [ENUM.PARCEL_ACTION_CODE.ONE.toLowerCase()]: 'lD9DYnaFYFxqaKNU',
  [ENUM.PARCEL_ACTION_CODE.KMI.toLowerCase()]: 'LQn9YnaFYFxqaKMe',
  GO_TO_TRACKING_MAP: 's9MXonaFYFxqaEHu',
};

export const WHAT_HAPPENS_NEXT = {
  INTERFACE_ID: '7nj58PaFYFxqqEdM',
  LOAD: 'nfqF8PaFYFxqqEyg',
  ON_SAFE_PLACE: 'evEQKPaFYFxqr25T',
  ON_DTN: 'mBqQKPaFYFxqr267',
  ON_CHANGE_THIS_DELIVERY: 'v_qF8PaFYFxqqEyw',
  GO_TO_TRACKING_MAP: 'wOc3CC6GAqAC4TZj',
};

export const DRIVER_PROFILE = {
  INTERFACE_ID: 'TtuVWnaFYFxqb3vl',
  LOAD: 'DXtXWnaFYFxqbyGG',
  GO_TO_TRACKING_MAP: 'ycQ3WnaFYFxqbyHt',
};

export const PARCEL_INFO = {
  INTERFACE_ID: 'c0C6ddaFYFxqctKF',
  LOAD: '2b33VbaFYFxq9B7N',
  VIEW_PARCEL_INFO: 'YmGKD3aFYFxqRr61',
  REAL_TIME_DELIVERY_UPDATES: 'qrohD3aFYFxqRr8F',
  VIEW_TRACKING: 'Q7WkNbaFYFxq9HSG',
};

export const ALA_DELIVERY_OPTION = {
  INTERFACE_ID: 'c0C6ddaFYFxqctKF',
  LOAD: 'cRXpADaFYFxq51i2',
  ON_DATE_SELECT: '63eLM3aFYFxqZ2XH',
  ON_DATE_CHANGE: 'c4hk93aFYFxqRjgq',
  ON_POSTCODE_ENTER: 'BpcbM3aFYFxqZ2jh',
  ON_ADDRESS_SELECT: 'pkFbM3aFYFxqZ2k0',
  ON_SUBMIT: 'iwDVADaFYFxq549O',
};

export const KMI_DELIVERY_OPTION = {
  INTERFACE_ID: '_.1y0naFYFxqhjA4',
  ON_EMAIL: 'aUMQ13aFYFxqIVo6',
  ON_MOBILE: 'GCuQ13aFYFxqIVzu',
  ON_SUBMIT: 'A3k1bXaFYFxqvBXR',
  LOAD: 'wXk1bXaFYFxqvBXI',
};

export const PRECISE_DELIVERY_OPTION = {
  INTERFACE_ID: 'CZx6ddaFYFxqctLV',
  LOAD: 'tx.ZADaFYFxq52Co',
  ON_DATE_CHANGE: 'CBhaRK6FYFxqzNVK',
  ON_SLOT_SELECT: 'd0pEAPaFYFxqUBRx',
  ON_DATE_SELECT: 'mAi4APaFYFxqUBMW',
  ON_SUBMIT: 'dXblADaFYFxq54xh',
};

export const PICKUP_SHOP_DELIVERY_OPTION = {
  INTERFACE_ID: 'AHV6ddaFYFxqctLw',
  ON_CLICK_RIGHT: 'Gtxn_3aFYFxqUE9M',
  ON_CLICK_LEFT: 'FEzn_3aFYFxqUE96',
  ON_SUBMIT: '8SFlADaFYFxq54wJ',
  LOAD: 'uK9ZADaFYFxq52XA',
};

export const PICKUP_SHOP_CONFIRMATION = {
  INTERFACE_ID: '5XLd_3aFYFxqUDu1',
  LOAD: '.4JT_3aFYFxqUEky',
  ON_CONFIRM: '6EJT_3aFYFxqUEk9',
  ON_CANCEL: 'vcon_3aFYFxqUE7P',
};

export const ANOTHER_DAY_DELIVERY_OPTION = {
  INTERFACE_ID: 'TrW6ddaFYFxqctKp',
  LOAD: '8GCZADaFYFxq51ub',
  ON_SUBMIT: 'c7.lADaFYFxq54vC',
  ON_DATE_SELECT: 'TfocHXaFYFxqvAoQ',
};

export const SAFE_PLACE_DELIVERY_OPTION = {
  INTERFACE_ID: '6tX6ddaFYFxqctMi',
  ON_DATE_CHANGE: 'uhs093aFYFxqRj1m',
  LOAD: 'Wfg5ADaFYFxq52rO',
  ON_SUBMIT: 'WRKlADaFYFxq54t5',
  ON_OTHER: 'Hpwg13aFYFxqIUgi',
  ON_DATE_SELECT: 'uRs093aFYFxqRj1y',
  ON_SAFE_PLACE_OPTION: 'Egp223aFYFxquKJL',
};

export const COLLECT_FROM_DEPOT_DELIVERY_OPTION = {
  INTERFACE_ID: 'Y5QGddaFYFxqctM9',
  ON_DATE_CHANGE: 'bb_qRK6FYFxqzNIQ',
  ON_SLOT_SELECT: 'Z8DJD3aFYFxqRtsI',
  ON_DATE_SELECT: 'wqHMD3aFYFxqRrPF',
  ON_SUBMIT: 'O5ElADaFYFxq54su',
  LOAD: 'v7_5ADaFYFxq522G',
};

export const UPGRADE_DELIVERY_OPTION = {
  INTERFACE_ID: 'JO8GddaFYFxqctN3',
  ON_SUBMIT: '7HHFADaFYFxq54lb',
  LOAD: '8iGFADaFYFxq53KY',
  ON_DATE_CHANGE: 'XGo6RK6FYFxqzNZ1',
  ON_SLOT_SELECT: 'dMsy8PaFYFxqqDxl',
  ON_DATE_SELECT: 'YRJE8PaFYFxqqDB3',
};

export const DTN_DELIVERY_OPTION = {
  INTERFACE_ID: 'gUA6ddaFYFxqctD9',
  ON_SELECT_DATE: '07GHTXaFYFxq6YZ7',
  ON_DATE_CHANGE: '9acU93aFYFxqRjsT',
  ON_SELECT_NEIGHBOUR: 'Xrs7TXaFYFxq6YXV',
  LOAD: 'jVNpADaFYFxq51iG',
  ON_SUBMIT: 'SQpVADaFYFxq548J',
};

export const NO_NEIGHBOURS = {
  INTERFACE_ID: 'z7xxrM6GAqAC4SF_',
  LOAD: '2uzFrM6GAqAC4ZNP',
  ON_DO_SOMETHING_DIFFERENT: 'GezFrM6GAqAC4ZNf',
};

export const RATE_DELIVERY = {
  INTERFACE_ID: '4kd6ePaFYFxqd6xK',
  LOAD: '0I66xPaGAqACSaLd',
  ON_POSITIVE: 'BZnfbPaFYFxo7ScJ',
  ON_NEGATIVE: '8Q66xPaGAqACSaK9',
  ON_SKIP: '7xmMzPaFYFxqT7I4',
};

export const RATE_DELIVERY_CALL_ME_OPTION = {
  INTERFACE_ID: 'pRA4h66GAqAC4UUJ',
  LOAD: 'U38Mh66GAqAC4VAV',
  ON_NO: 'mP8Mh66GAqAC4VAj',
  ON_YES: 'Pf8Mh66GAqAC4VBO',
};

export const RATE_DELIVERY_CALL_ME_CONFIRMATION = {
  INTERFACE_ID: 'QFI6h66GAqAC4VZd',
  LOAD: 'lVuGh66GAqAC4Vep',
  ON_HOME: 'F1uGh66GAqAC4Ve2',
};

export const RATE_DELIVERY_CALL_ME_DATE_SELECT = {
  INTERFACE_ID: 'QErvp66GAqAC4TAB',
  LOAD: 'NGyfp66GAqAC4TQ9',
  ON_DATE_SELECTION: '2oY3X66GAqAC4Q33',
  ON_SUBMIT: 'uWyfp66GAqAC4TRX',
};

export const RATE_DELIVERY_CALL_ME_SLOT_SELECT = {
  INTERFACE_ID: 'Ctu_p66GAqAC4T.O',
  LOAD: 'Gk4GZ66GAqAC4XXA',
  ON_CHANGE_DATE: 'wvQZn66GAqAC4V1a',
  ON_SLOT_SELECT: 'SmT_p66GAqAC4UA0',
  ON_SUBMIT: '70DPX66GAqAC4Q9X',
};

export const RATE_DELIVERY_CALL_ME_CONTACT_NUMBER = {
  INTERFACE_ID: 'eOvch66GAqAC4VK0',
  LOAD: 'He6ih66GAqAC4VPx',
  ON_CHANGE_SLOT: 'WypNf66GAqAC4TGT',
  ON_SUBMIT: 'zB6ih66GAqAC4VQL',
};

export const RATE_DELIVERY_CALL_ME_SLOT_CONFIRMATION = {
  INTERFACE_ID: 'GptsZ66GAqAC4ah3',
  LOAD: 'UpesZ66GAqAC4aTT',
  ON_HOME: 'vxesZ66GAqAC4aS7',
};

export const RATE_DELIVERY_CONFIRMATION = {
  INTERFACE_ID: 'V17HePaFYFxqdyr5',
  LOAD: 'ywlOxPaGAqACSX9e',
  ON_HOME: 'tglOxPaGAqACSX9Q',
};

export const RATE_DRIVER = {
  INTERFACE_ID: 'dPIGePaFYFxqd6x7',
  ON_SKIP_COMPLIMENT: 'k.AazPaFYFxqT7Xf',
  ON_CLICK_COMPLIMENT: 'lCnyzPaFYFxqT7PO',
  LOAD: 'f0OmxPaGAqACSRkg',
  ON_CLICK_STAR: 'TUOmxPaGAqACSRkR',
};

export const RATE_DRIVER_CONFIRMATION = {
  INTERFACE_ID: 'Ar0HePaFYFxqdyqM',
  ON_HOME: 'si0WxPaGAqACSXnH',
  LOAD: '.S0WxPaGAqACSXnX',
};

export const RATE_EXPERIENCE = {
  INTERFACE_ID: 'MKzaePaFYFxqd6qz',
  LOAD: '1tw3TPaFYFxqTxob',
  ON_DISLIKE: 'WNPIzPaFYFxqTyN.',
  ON_LIKE: 's1w3TPaFYFxqTxn6',
};

export const RATE_SORRY = {
  INTERFACE_ID: 'x50nePaFYFxqdytH',
  ON_HOME: 'fTXWxPaGAqACSX3x',
  LOAD: '3zXWxPaGAqACSX4A',
  ON_SEND: 'Q1D5zPaFYFxqT8H1',
};

export const RATE_SORRY_CONFIRMATION = {
  INTERFACE_ID: 'UIKQ.PaFYFxqdyw3',
  LOAD: 'RZO2xPaGAqACSX63',
  ON_HOME: 'lpO2xPaGAqACSX6p',
};

export const CONTACT_DETAILS = {
  INTERFACE_ID: 'c0C6ddaFYFxqctKF',
  ON_EMAIL: '84iY0A6FYFxqjH_B',
  ON_PHONE: 'cwZE0A6FYFxqjIIl',
  LOAD: 'xkiY0A6FYFxqjH_h',
  ON_CONTACT: 'EKSn0A6FYFxqjC5D',
};

export const DELIVERY_PREFERENCES = {
  INTERFACE_ID: 'F_oPW3aFYFxquIm1',
  LOAD: '5srI23aFYFxquJp8',
  ON_YES: 'i8_623aFYFxquJ2_',
  ON_NO: 'TcrI23aFYFxquJqV',
};

export const DELIVERY_OPTION_CONFIRMATION = {
  INTERFACE_ID: 'N0lWddaFYFxqcgm8',
  LOAD: 'iIJu23aFYFxquMsT',
  ON_TRACK_ANOTHER_PARCEL: 'E4Ju23aFYFxquMtf',
};

export const DELIVERY_OPTIONS_ERROR = {
  INTERFACE_ID: 'equaVQ6FYFxkbIRH',
  ON_LOAD: 'y2KhVQ6FYFxkbGGH',
  TRACK_ANOTHER_PARCEL: 'mOKhVQ6FYFxkbGGW',
};

export const ERROR_SCREEN = {
  INTERFACE_ID: 'equaVQ6FYFxkbIRH',
  LOAD: 'y2KhVQ6FYFxkbGGH',
};

export const FOOTER = {
  GDPR: 'iBU2_LaFYFxoiB1x',
  PHISHING: 'xS3W_LaFYFxoiBh0',
  PRIVACY: 'Ytfm_LaFYFxoiBDS',
  TERMS: 'xI.m_LaFYFxoiA4S',
};

export const DTY_DELIVERY_OPTION = {
  INTERFACE_ID: 'IZ9KWy6FYFxqwd2i',
  LOAD: 'SQ7aWy6FYFxqwQsX',
  CHEVRON_TOGGLE: 'tKi_ly6GAqAC4U2g',
  DELIVER_PARCEL: 'yXBsly6GAqAC4TBb',
  RETURN_PARCEL: 'PQ9sly6GAqAC4TO3',
  SUBMIT: '1k7aWy6FYFxqwQus',
};

export const DTY_PAYMENT = {
  INTERFACE_ID: 'JqT5Wy6FYFxqwbqI',
  LOAD: 'ROpFWy6FYFxqwceY',
  ON_SUBMIT: 'UJpFWy6FYFxqwcgO',
  CONTACT_US: 'xHqOgq6GAqAC4dVb',
  TERMS_AND_CONDITIONS: '7FpFWy6FYFxqwchZ',
};

export const DTY_CANCELLATION_MODAL = {
  INTERFACE_ID: '4XdBWy6FYFxqwTTG',
  LOAD: 'zjgBWy6FYFxqwS_Q',
  ON_SUBMIT: 'WPgBWy6FYFxqwTBg',
  ON_CANCEL: '9QQBWy6FYFxqwTDA',
};

export const DTY_CANCELLATION_COMPLETE = {
  INTERFACE_ID: '7Xmuny6GAqAC4QUp',
  LOAD: 'P.xUQK6GAqAC4UBh',
};

export const DTY_PAYMENT_COMPLETE = {
  INTERFACE_ID: 'EbSOny6GAqAC4QOO',
  LOAD: 'gj0kQK6GAqAC4S_I',
};
