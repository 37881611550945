import Care from '../../assets/lottie/Rating/Care.json';
import Complaint from '../../assets/lottie/Rating/Complaint.json';
import Damaged from '../../assets/lottie/Rating/Damaged.json';
import ExtraMile from '../../assets/lottie/Rating/ExtraMile.json';
import Friendly from '../../assets/lottie/Rating/Friendly.json';
import Informed from '../../assets/lottie/Rating/Informed.json';
import NotFollow from '../../assets/lottie/Rating/NotFollow.json';
import OnTime from '../../assets/lottie/Rating/OnTime.json';
import NotReceived from '../../assets/lottie/Rating/NotReceived.json';
import RespectedProperty from '../../assets/lottie/Rating/RespectedProperty.json';
import Rude from '../../assets/lottie/Rating/Rude.json';
import Unhappy from '../../assets/lottie/Rating/Unhappy.json';
import WrongPlace from '../../assets/lottie/Rating/WrongPlace.json';
import Instructions from '../../assets/lottie/Rating/Instructions.json';
import { RATE_INFO_NAMES } from './constants';

export const RATE_INFO_ANIMATIONS = {
  [RATE_INFO_NAMES.ON_TIME]: OnTime,
  [RATE_INFO_NAMES.INFORMED]: Informed,
  [RATE_INFO_NAMES.FOLLOWED]: Instructions,
  [RATE_INFO_NAMES.NOT_RECEIVED]: NotReceived,
  [RATE_INFO_NAMES.NOT_GREAT]: Unhappy,
  [RATE_INFO_NAMES.COMPLAINT]: Complaint,
  [RATE_INFO_NAMES.WRONG_PLACE]: WrongPlace,
  [RATE_INFO_NAMES.RESPECT]: RespectedProperty,
  [RATE_INFO_NAMES.CARE]: Care,
  [RATE_INFO_NAMES.EXTRA_MILE]: ExtraMile,
  [RATE_INFO_NAMES.FRIENDLY]: Friendly,
  [RATE_INFO_NAMES.DAMAGED]: Damaged,
  [RATE_INFO_NAMES.RUDE]: Rude,
  [RATE_INFO_NAMES.DIDNT_FOLLOW]: NotFollow,
};
