import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withProps } from 'recompose';
import { slice } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import * as ENUM from '@dpdgroupuk/redback-enums';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes/constants';
import type { Parcel } from '@dpdgroupuk/fmx-ui/types';
import Wizard, { Step } from '@dpdgroupuk/fmx-ui/components/Wizard';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';

import SelectPickupShopStep from './SelectPickupShopStep';
import CompleteStep from './CompleteStep';
import withParcel from '../../../../../HOCs/withParcel';
import { PKU_STEPS } from '../../../steps';
import {
  backToDpdSiteHeader,
  backToGreenSiteHeader,
  doSomethingDifferentHeader,
  doSomethingDifferentSpeedDeal,
  getDpdAppHeader,
  goBackToDeliveryDetailsSpeedDeal,
  trackAnotherParcelHeader,
  whatHappensNextHeader,
  whatHappensNextSpeedDeal,
} from '../../../../controlActions';
import {
  trackable,
  PICKUP_SHOP_DELIVERY_OPTION,
} from '../../../../../constants/analytics';
import * as MESSAGES from '../../../../../constants/message';
import { fetchPickupShops } from '../../../actions';
import { changeCollectFromPickup } from '../actions';
import { withDeliveryUpdate } from '../../../hocs';
import GetContactDetailsStep from '../../../components/GetContactDetailsStep';
import NoPickupShops from './NoPickupShops';
import withFetch from '../../../../../HOCs/withFetch';

const useStyles = makeStyles(({ typography, breakpoints, palette }) => ({
  completeStep: {
    paddingLeft: typography.pxToRem(120),
    paddingRight: typography.pxToRem(120),
    [breakpoints.down('xs')]: {
      paddingLeft: typography.pxToRem(15),
      paddingRight: typography.pxToRem(15),
    },
  },
  selectStep: {
    width: typography.pxToRem(443),
    backgroundColor: palette.background.modal,
    [breakpoints.down('xs')]: {
      marginTop: typography.pxToRem(231),
      marginBottom: typography.pxToRem(-200),
      height: `calc(100vh - ${typography.pxToRem(231)})`,
      width: '100%',
    },
  },
}));

type Props = {
  parcel: Parcel,
  pickupShops: any,
  updateDelivery: Function,
  onSubmitSuccess: Function,
  onSubmitFailure: Function,
};

const SelectPickupShopComponent = ({
  parcel,
  pickupShops = [],
  updateDelivery,
  onSubmitSuccess,
  onSubmitFailure,
}: Props) => {
  const styles = useStyles();
  const pickups = slice(pickupShops, 0, 10);
  const noPickupShops = !pickupShops || pickupShops.length === 0;

  return (
    <Wizard
      initialStep={PKU_STEPS.SELECT_PICKUP_SHOP}
      onSubmit={updateDelivery}
      onSubmitSuccess={onSubmitSuccess}
      onSubmitFailure={onSubmitFailure}
    >
      <Step
        stepId={PKU_STEPS.SELECT_PICKUP_SHOP}
        component={SelectPickupShopStep}
        parcel={parcel}
        pickupShops={pickups}
        fullScreenStep={!noPickupShops}
        wrapperClassName={clsx({ [styles.selectStep]: noPickupShops })}
      />
      <Step
        stepId={PKU_STEPS.GET_CONTACTS}
        component={GetContactDetailsStep}
        parcel={parcel}
      />
      <Step
        stepId={PKU_STEPS.COMPLETE}
        component={CompleteStep}
        parcel={parcel}
        actionCode={ENUM.PARCEL_ACTION_CODE.PKU}
        pickupShops={pickups}
        wrapperClassName={styles.completeStep}
      />
    </Wizard>
  );
};

const createSpeedDealControlActions = props => [
  doSomethingDifferentSpeedDeal(props),
  goBackToDeliveryDetailsSpeedDeal(props),
  whatHappensNextSpeedDeal(props),
];

const createHeaderControlActions = props => [
  doSomethingDifferentHeader(props),
  trackAnotherParcelHeader(props),
  whatHappensNextHeader(props),
  backToDpdSiteHeader(props),
  backToGreenSiteHeader(props),
  getDpdAppHeader(props),
];

const SelectPickupShop = compose(
  withRouter,
  withParcel(),
  withModal,
  withFetch(({ parcel }) => fetchPickupShops(parcel.parcelCode), {
    notFoundComponent: NoPickupShops,
  }),
  withProps(({ fetchedData }) => ({
    pickupShops: fetchedData,
    onSubmitSuccess: ({ goToStep }) => goToStep(PKU_STEPS.COMPLETE),
  })),
  withDeliveryUpdate({
    actionCode: ENUM.PARCEL_ACTION_CODE.PKU,
    changeDeliveryAction: changeCollectFromPickup,
    trackable: PICKUP_SHOP_DELIVERY_OPTION.ON_SUBMIT,
  }),
  withPageControl({
    title: MESSAGES.COLLECT_FROM_PICKUP,
    backgroundImage: IMAGES.MAP,
    trackable: trackable(PICKUP_SHOP_DELIVERY_OPTION),
    speedDealActions: createSpeedDealControlActions,
    headerMenuActions: createHeaderControlActions,
    isFooterHidden: true,
  })
)(SelectPickupShopComponent);

export default SelectPickupShop;
