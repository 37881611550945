import * as React from 'react';
import { compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { Container, Grid } from '@material-ui/core';
import { withProps, withState } from 'recompose';

import type { AuthorizedParcel, Parcel } from '@dpdgroupuk/fmx-ui/types/Parcel';
import { Radio } from '@dpdgroupuk/fmx-ui/components/Icons';
import Button from '@dpdgroupuk/fmx-ui/components/Button';
import {
  useTracker,
  withTrack,
  withTrackProps,
} from '@dpdgroupuk/react-event-tracker';
import { get } from 'lodash-es';
import { PARCEL_ACTION_CODE } from '@dpdgroupuk/redback-enums';

import { YOUR_OPTIONS, SUBMIT } from '../../../../../constants/message';
import withFetch from '../../../../../HOCs/withFetch';
import DutyDetailsPanel from './DutyDetailsPanel';
import DutyDescriptionPanel from './DutyDescriptionPanel';
import ConfirmationDialog from './ConfirmationDialog';
import { DUTY_PAYMENT_OPTIONS, DUTY_PAYMENT_STEPS } from '../constants';
import { prepareTaxValue } from '../utils';
import {
  DTY_CANCELLATION_MODAL,
  DTY_DELIVERY_OPTION,
  trackable,
} from '../../../../../constants/analytics';
import { calculateVat } from '../model';
import { fetchIsDutyPaidAndActions } from '../actions';

const useStyles = makeStyles(({ breakpoints, typography, palette }) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: 0,
    textAlign: 'left',
  },
  sectionHeader: {
    fontSize: typography.pxToRem(20),
    color: '#1d9ad0',
    letterSpacing: typography.pxToRem(0.44),
    marginTop: typography.pxToRem(20),

    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
      marginTop: typography.pxToRem(15),
      paddingLeft: typography.pxToRem(10),
      paddingRight: typography.pxToRem(10),
    },
  },
  sectionMessage: {
    fontSize: typography.pxToRem(18),
    letterSpacing: typography.pxToRem(0.44),
    marginTop: typography.pxToRem(32),
    marginLeft: 'auto',
    marginRight: 'auto',
    '& span': {
      color: palette.text.redWhite,
      fontWeight: 500,
    },

    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
      paddingLeft: typography.pxToRem(10),
      paddingRight: typography.pxToRem(10),
    },
  },
  radioMessage: {
    fontSize: typography.pxToRem(18),
    marginLeft: typography.pxToRem(14),

    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(14),
      marginLeft: typography.pxToRem(4),
    },
  },
  radioItem: {
    marginTop: typography.pxToRem(10),
    flexWrap: 'nowrap',
    [breakpoints.down('xs')]: {
      paddingLeft: typography.pxToRem(10),
      paddingRight: typography.pxToRem(10),
    },
  },
  radioIcon: {
    '& .checkMark': {
      fill: palette.error.main,
    },
  },
  submitButton: {
    alignSelf: 'center',
    marginTop: typography.pxToRem(47),
    [breakpoints.down('xs')]: {
      marginTop: typography.pxToRem(27),
    },
  },
  status: {
    fontSize: typography.pxToRem(18),

    [breakpoints.down('xs')]: {
      paddingLeft: typography.pxToRem(10),
      paddingRight: typography.pxToRem(10),
    },
  },
}));

type Props = {
  parcel: Parcel & AuthorizedParcel,
  isDialogOpen: boolean,
  onSubmitClick: Function,
  onDialogClose: Function,
  checkIfAlreadyPaid: Function,
  option: string,
  onDeliverOptionSelect: Function,
  onReturnOptionSelect: Function,
  onReturnConfirm: Function,
  getCustomsDescription: Object,
  isCustomsDeclarationTypeE: boolean,
};

const GeneralView = ({
  parcel,
  isDialogOpen,
  onSubmitClick,
  onDialogClose,
  checkIfAlreadyPaid,
  option,
  onDeliverOptionSelect,
  onReturnOptionSelect,
  onReturnConfirm,
  getCustomsDescription,
  isCustomsDeclarationTypeE,
}: Props) => {
  const tracker = useTracker();
  const styles = useStyles();
  const [chargesDetailsOpen, setChargesDetailsOpen] = React.useState();
  const customsDescription = getCustomsDescription();

  // Check if already paid for parcel, if so then show processing screen
  checkIfAlreadyPaid(parcel.parcelCode);

  const { duty = {} } = parcel;

  const {
    total,
    taxToPay,
    clearanceFee,
    dutyToPay,
    vatOnClearanceFee,
  } = calculateVat(duty);

  const onPanelOpen = React.useCallback(
    open => {
      setChargesDetailsOpen(open);
      tracker.logEvent(DTY_DELIVERY_OPTION.CHEVRON_TOGGLE);
    },
    [setChargesDetailsOpen, tracker]
  );

  const chargeType = isCustomsDeclarationTypeE ? 'Customs' : 'Import';

  return (
    <>
      <Typography className={styles.status}>
        Your parcel is on its way, please pay your{' '}
        {chargeType.toLocaleLowerCase()} charges so we can deliver it to the
        recipient
      </Typography>
      <Container maxWidth="md" className={styles.container}>
        <Typography className={styles.sectionMessage}>
          {chargeType} charges of <span>{prepareTaxValue(total)}</span> need to
          be paid
        </Typography>
        <>
          {!isCustomsDeclarationTypeE && (
            <>
              <Typography className={styles.sectionHeader}>
                {YOUR_OPTIONS}
              </Typography>
              <Grid
                container
                direction="row"
                alignItems="center"
                className={styles.radioItem}
              >
                <Radio
                  className={styles.radioIcon}
                  selected={option === DUTY_PAYMENT_OPTIONS.DELIVER}
                  onClick={onDeliverOptionSelect}
                />
                <Typography className={styles.radioMessage}>
                  Deliver my parcel, I will pay the {prepareTaxValue(total)}{' '}
                  import charges
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                className={styles.radioItem}
              >
                <Radio
                  className={styles.radioIcon}
                  selected={option === DUTY_PAYMENT_OPTIONS.RETURN}
                  onClick={onReturnOptionSelect}
                />
                <Typography className={styles.radioMessage}>
                  Return my parcel, I won’t pay the {prepareTaxValue(total)}{' '}
                  import charges
                </Typography>
              </Grid>
            </>
          )}
        </>
        {customsDescription && (
          <DutyDescriptionPanel customsDescription={customsDescription} />
        )}
        <DutyDetailsPanel
          onPanelOpen={onPanelOpen}
          total={total}
          dutyToPay={dutyToPay}
          taxToPay={taxToPay}
          clearanceFee={clearanceFee}
          vatOnClearanceFee={vatOnClearanceFee}
          isCustomsDeclarationTypeE={isCustomsDeclarationTypeE}
        />
        {(option || isCustomsDeclarationTypeE) && (
          <Button className={styles.submitButton} onClick={onSubmitClick}>
            {SUBMIT}
          </Button>
        )}
        <ConfirmationDialog
          open={isDialogOpen}
          onClose={onDialogClose}
          onConfirm={onReturnConfirm}
        />
      </Container>
    </>
  );
};

export default compose(
  withState('isDialogOpen', 'setDialogOpen', false),
  withState('option', 'setOption', undefined),
  withFetch(({ parcel }) => fetchIsDutyPaidAndActions(parcel)),
  withProps(
    ({
      setDialogOpen,
      setOption,
      addStepData,
      option,
      goToStep,
      submitWizard,
      fetchedData,
      isCustomsDeclarationTypeE,
    }) => ({
      onSubmitClick: () => {
        if (isCustomsDeclarationTypeE) {
          goToStep(DUTY_PAYMENT_STEPS.CAPTURE);
        } else if (option === DUTY_PAYMENT_OPTIONS.DELIVER) {
          goToStep(DUTY_PAYMENT_STEPS.CAPTURE);
        } else if (option === DUTY_PAYMENT_OPTIONS.RETURN) {
          setDialogOpen(true);
        }
      },
      onDialogClose: () => setDialogOpen(false),
      onDeliverOptionSelect: () => {
        addStepData({
          chargesPaid: true,
        });
        setOption(DUTY_PAYMENT_OPTIONS.DELIVER);
      },
      onReturnOptionSelect: () => {
        addStepData({
          chargesPaid: false,
        });
        setOption(DUTY_PAYMENT_OPTIONS.RETURN);
      },
      onReturnConfirm: () => {
        setDialogOpen(false);
        return submitWizard();
      },
      checkIfAlreadyPaid: () => {
        const { actions, dutyPaid } = fetchedData;
        if (
          get(actions, 'availableActions', []).includes(
            PARCEL_ACTION_CODE.DTY
          ) &&
          dutyPaid &&
          get(dutyPaid, 'status', 0) === 0
        )
          goToStep(DUTY_PAYMENT_STEPS.PAYMENT_PROCESSING);
      },
      getCustomsDescription: () => {
        const { customsDescription } = fetchedData;

        if (!customsDescription) {
          return [];
        }
        return customsDescription;
      },
    })
  ),
  withFetch(() => () => Promise.resolve({}), {
    dataPropMapper: 'data',
  }),
  withTrack(trackable(DTY_DELIVERY_OPTION)),
  withTrackProps({
    onSubmitClick: DTY_DELIVERY_OPTION.SUBMIT,
    onDeliverOptionSelect: DTY_DELIVERY_OPTION.DELIVER_PARCEL,
    onReturnOptionSelect: DTY_DELIVERY_OPTION.RETURN_PARCEL,
    onDialogClose: DTY_CANCELLATION_MODAL.ON_CANCEL,
    onReturnConfirm: DTY_CANCELLATION_MODAL.ON_SUBMIT,
  })
)(GeneralView);
