import { lifecycle } from 'recompose';

import { logFunctionalityId } from '../apis';

const withFunctionalityLog = funcId =>
  lifecycle({
    async componentDidMount() {
      try {
        await logFunctionalityId(funcId);
      } catch (e) {
        // ignore any errors
      }
    },
  });

export default withFunctionalityLog;
