import React from 'react';
import { compose } from 'redux';
import { withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import * as ENUM from '@dpdgroupuk/redback-enums';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes/constants';
import Wizard, { Step } from '@dpdgroupuk/fmx-ui/components/Wizard';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';

import type { AuthorizedParcel } from '../../../../types/Parcel';
import {
  PRECISE_DELIVERY_OPTION,
  trackable,
} from '../../../../constants/analytics';
import {
  whatHappensNextSpeedDeal,
  doSomethingDifferentSpeedDeal,
  trackAnotherParcelHeader,
  backToDpdSiteHeader,
  backToGreenSiteHeader,
  getDpdAppHeader,
  whatHappensNextHeader,
  goBackToDeliveryDetailsSpeedDeal,
  doSomethingDifferentHeader,
} from '../../../controlActions';
import * as MESSAGES from '../../../../constants/message';
import SelectDayStep from './SelectDayStep';
import SelectTimeStep from './SelectTimeStep';
import CompleteStep from './CompleteStep';
import withParcel from '../../../../HOCs/withParcel';
import { fetchActionsDatesByCode } from '../../actions';
import { ONE_STEPS } from '../../steps';
import { withDeliveryUpdate } from '../../hocs';
import { changePrecise } from './actions';
import withFetch from '../../../../HOCs/withFetch';
import EnterCodeStep from '../../components/EnterCodeStep';
import GetContactDetailsNoEmailStep from '../../components/GetContactDetailsNoEmailStep';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  completeStep: {
    paddingLeft: typography.pxToRem(120),
    paddingRight: typography.pxToRem(120),
    [breakpoints.down('xs')]: {
      paddingLeft: typography.pxToRem(15),
      paddingRight: typography.pxToRem(15),
    },
  },
}));

type Props = {
  parcel: AuthorizedParcel,
  dates: Array<string>,
  updateDelivery: Function,
  onSubmitSuccess: Function,
  onSubmitFailure: Function,
};

const PreciseComponent = ({
  parcel,
  dates,
  updateDelivery,
  onSubmitSuccess,
  onSubmitFailure,
}: Props) => {
  const styles = useStyles();

  return (
    <Wizard
      initialStep={ONE_STEPS.SELECT_DAY}
      onSubmit={updateDelivery}
      onSubmitSuccess={onSubmitSuccess}
      onSubmitFailure={onSubmitFailure}
    >
      <Step
        stepId={ONE_STEPS.SELECT_DAY}
        component={SelectDayStep}
        dates={dates}
        parcel={parcel}
      />

      <Step
        stepId={ONE_STEPS.CHOOSE_TIME_SLOT}
        component={SelectTimeStep}
        dates={dates}
        parcel={parcel}
      />

      {/* SMS Authentication */}
      <Step
        stepId={ONE_STEPS.GET_CONTACTS}
        component={GetContactDetailsNoEmailStep}
        parcel={parcel}
        nextStep={ONE_STEPS.ENTER_CODE}
      />
      <Step
        stepId={ONE_STEPS.ENTER_CODE}
        component={EnterCodeStep}
        parcel={parcel}
      />
      {/* / SMS Authentication / */}

      <Step
        stepId={ONE_STEPS.COMPLETE}
        component={CompleteStep}
        parcel={parcel}
        actionCode={ENUM.PARCEL_ACTION_CODE.ONE}
        wrapperClassName={styles.completeStep}
      />
    </Wizard>
  );
};

const createSpeedDealControlActions = props => [
  doSomethingDifferentSpeedDeal(props),
  goBackToDeliveryDetailsSpeedDeal(props),
  whatHappensNextSpeedDeal(props),
];

const createHeaderControlActions = props => [
  doSomethingDifferentHeader(props),
  trackAnotherParcelHeader(props),
  whatHappensNextHeader(props),
  backToDpdSiteHeader(props),
  backToGreenSiteHeader(props),
  getDpdAppHeader(props),
];

const Precise = compose(
  withRouter,
  withParcel(),
  withModal,
  withFetch(({ match }) =>
    fetchActionsDatesByCode(match.params.parcelId, ENUM.PARCEL_ACTION_CODE.ONE)
  ),
  withProps(({ fetchedData }) => ({
    dates: fetchedData,
    onSubmitSuccess: ({ goToStep }) => goToStep(ONE_STEPS.COMPLETE),
  })),
  withPageControl({
    title: MESSAGES.PRECISE,
    backgroundImage: IMAGES.MAP,
    trackable: trackable(PRECISE_DELIVERY_OPTION),
    speedDealActions: createSpeedDealControlActions,
    headerMenuActions: createHeaderControlActions,
  }),
  withDeliveryUpdate({
    actionCode: ENUM.PARCEL_ACTION_CODE.ONE,
    changeDeliveryAction: changePrecise,
    trackable: PRECISE_DELIVERY_OPTION.ON_SUBMIT,
  })
)(PreciseComponent);

export default Precise;
