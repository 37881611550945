import * as React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { mapProps } from 'recompose';
import { makeStyles } from '@material-ui/styles';
import queryString from 'query-string';
import Wizard, { Step } from '@dpdgroupuk/fmx-ui/components/Wizard';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';

import { RATE_STEPS } from './steps';
import { MORE_COOL_STUFF } from '../../constants/message';
import { RATE_DELIVERY, trackable } from '../../constants/analytics';

import RateDelivery from './components/RateDelivery';
import RateDriver from './components/RateDriver';
import RateDriverCompliment from './components/RateDriverCompliment';
import RateDriverComplete from './components/RateDriverComplete';
import DeliveryNegativeComplete from './components/DeliveryNegativeComplete';
import DeliveryNoInvestigationComplete from './components/DeliveryNoInvestigationComplete';
import RateDeliveryCallMeOption from './components/RateDeliveryCallMeOption';
import RateDeliveryCallMeDateSelect from './components/RateDeliveryCallMeDateSelect';
import RateDeliveryCallMeSlotSelect from './components/RateDeliveryCallMeSlotSelect';
import RateDeliveryCallMeContactNumber from './components/RateDeliveryCallMeContactNumber';
import RateDeliveryCallMeConfirmation from './components/RateDeliveryCallMeConfirmation';

import withParcel from '../../HOCs/withParcel';
import withFetch from '../../HOCs/withFetch';

import {
  createHeaderControlActions,
  createRatingSpeedDealControlActions,
} from './controlActions';
import { withDeliveryFeedback } from './hocs';
import { withParcelInfo } from '../Tracking/hocs';
import { fetchRouteAndDriver } from '../../store/actions/rate';
import { RATE_TYPE } from '../../constants/rate';

const useStyles = makeStyles(({ typography }) => ({
  step: {
    paddingBottom: `${typography.pxToRem(10)} !important`,
    backgroundColor: 'transparent',
  },
}));
const RateComponent = ({
  parcel,
  driver,
  onDeliveryRate,
  onSubmitFailure,
  onSubmitSuccess,
  location,
}: any) => {
  const searchParams = queryString.parse(location.search);
  const styles = useStyles();

  return (
    <Wizard
      initialStepId={RATE_STEPS.RATE_DELIVERY}
      initialData={{ deliveryRating: searchParams.type || RATE_TYPE.LIKE }}
      onSubmit={onDeliveryRate}
      onSubmitFailure={onSubmitFailure}
      onSubmitSuccess={onSubmitSuccess}
    >
      <Step
        stepId={RATE_STEPS.RATE_DELIVERY}
        component={RateDelivery}
        parcel={parcel}
        driver={driver}
        fullScreenStep
        wrapperClassName={styles.step}
      />
      <Step
        stepId={RATE_STEPS.RATE_DELIVERY_CALL_ME_OPTION}
        component={RateDeliveryCallMeOption}
        onDeliveryRate={onDeliveryRate}
        fullScreenStep
        wrapperClassName={styles.step}
      />
      <Step
        stepId={RATE_STEPS.RATE_DELIVERY_CALL_ME_DATE_SELECT}
        component={RateDeliveryCallMeDateSelect}
        fullScreenStep
        wrapperClassName={styles.step}
      />
      <Step
        stepId={RATE_STEPS.RATE_DELIVERY_CALL_ME_SLOT_SELECT}
        component={RateDeliveryCallMeSlotSelect}
        fullScreenStep
        wrapperClassName={styles.step}
      />
      <Step
        stepId={RATE_STEPS.RATE_DELIVERY_CALL_ME_CONTACT_NUMBER}
        component={RateDeliveryCallMeContactNumber}
        fullScreenStep
        parcel={parcel}
        wrapperClassName={styles.step}
      />
      <Step
        stepId={RATE_STEPS.RATE_DELIVERY_CALL_ME_CONFIRMATION}
        component={RateDeliveryCallMeConfirmation}
        fullScreenStep
        wrapperClassName={styles.step}
      />
      <Step
        stepId={RATE_STEPS.RATE_DRIVER}
        component={RateDriver}
        driver={driver}
        fullScreenStep
        parcel={parcel}
        wrapperClassName={styles.step}
      />
      <Step
        stepId={RATE_STEPS.RATE_DRIVER_COMPLIMENT}
        component={RateDriverCompliment}
        driver={driver}
        fullScreenStep
        parcel={parcel}
        wrapperClassName={styles.step}
      />
      <Step
        stepId={RATE_STEPS.DRIVER_COMPLETE}
        component={RateDriverComplete}
        driver={driver}
        fullScreenStep
        parcel={parcel}
        wrapperClassName={styles.step}
      />
      <Step
        stepId={RATE_STEPS.DELIVERY_NEGATIVE_COMPLETE}
        component={DeliveryNegativeComplete}
        driver={driver}
        fullScreenStep
        parcel={parcel}
        wrapperClassName={styles.step}
      />
      <Step
        stepId={RATE_STEPS.DELIVERY_NO_INVESTIGATION_COMPLETE}
        component={DeliveryNoInvestigationComplete}
        fullScreenStep
        parcel={parcel}
        wrapperClassName={styles.step}
      />
    </Wizard>
  );
};
const RateWizard = compose(
  withRouter,
  withParcel(),
  withModal,
  withFetch(({ parcel }) => dispatch =>
    dispatch(fetchRouteAndDriver(parcel)).then(result => {
      return {
        driver: result,
      };
    })
  ),
  mapProps(({ fetchedData: { driver }, ...props }) => ({ ...props, driver })),
  withPageControl({
    title: MORE_COOL_STUFF,
    backgroundImage: IMAGES.TRACKING_BG,
    trackable: trackable(RATE_DELIVERY), // TODO: Put correct constant in place
    headerMenuActions: createHeaderControlActions,
    speedDealActions: createRatingSpeedDealControlActions,
    // isMobileMenuShevronVisible: true,
  }),
  withDeliveryFeedback(),
  withParcelInfo({
    paddingLeft: 48,
    paddingRight: 48,
    xsPaddingLeft: 10,
    xsPaddingRight: 10,
    paddingLeft410: 3,
    paddingRight410: 3,
  })
)(RateComponent);
export default RateWizard;
