export const ENTITY_NAME = {
  ACTION: 'Action',
  PARCEL: 'Parcel',
  DEPOT: 'Depot',
  ROUTE: 'Route',
  DRIVER: 'Driver',
  EVENT: 'Event',
  CLAIM: 'Claim',
  PICKUP_LOCATION: 'PickupLocation',
};
