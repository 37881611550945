import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withProps } from 'recompose';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import SubmitButton from '@dpdgroupuk/fmx-ui/components/SubmitButton';
import ReactCodeInput from 'react-code-input';
import Grid from '@material-ui/core/Grid';
import { Button, useTheme } from '@material-ui/core';
import { THEMES } from '@dpdgroupuk/fmx-ui/themes';
import LeftArrow from '@dpdgroupuk/fmx-ui/components/Icons/LeftArrow';
import { useTimer } from 'react-timer-hook';
import moment from 'moment';

import { RED_STEPS } from '../steps';
import withFetch from '../../../HOCs/withFetch';
import { authParcel, sendAuthSms } from '../authentication/actions';
import { apiService } from '../../../services';
import { getDisplaySeconds } from '../helpers/enterCodeStep';
import { enterCodeCountdownTimeMilliseconds } from '../constants';

const useStyles = makeStyles(({ breakpoints, typography, palette }) => ({
  title: {
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(19),
    },
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(21),
    },
    lineHeight: 1.49,
    letterSpacing: 0.44,
    textAlign: 'center',
    marginBottom: typography.pxToRem(20),
  },
  actions: {
    marginTop: typography.pxToRem(24),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  pinButtons: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
    gap: typography.pxToRem(24),
    width: '276px',
    height: '90px',
  },
  submitButton: {
    width: '100%',
    alignItems: 'normal', // Make sure button is width of parent, override alignItems: "centre" in submitButton container
  },
  arrowButton: {
    float: 'left',
    marginRight: 'auto',
    marginLeft: '5px',
  },
  leftArrowIcon: {
    '& .leftArrow': {
      stroke: palette.text.primary,
      strokeWidth: 3,
    },
  },
  error: {
    fontSize: typography.pxToRem(16),
    lineHeight: 1.49,
    fontWeight: 400,
    letterSpacing: 0.44,
    textAlign: 'center',
    color: palette.icon.red,
    marginBottom: typography.pxToRem(20),
  },
  infoMessage: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '31px',
    letterSpacing: '0.44px',
    marginBottom: typography.pxToRem(24),
  },
}));

type Props = {
  goToContacts: Function,
  funcAfterAuth: Function,
  parcel: Object,
  data: Object,
  stepData: Object,
};

const EnterCodeStep = ({
  goToContacts,
  funcAfterAuth,
  parcel,
  data,
}: Props) => {
  const styles = useStyles();
  const [errorMessage, setErrorMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState(
    'A 6-digit pin code has been sent to you via SMS. Please enter the pin below.'
  );
  const [otp, setOtp] = useState('');
  const [resendEnabled, setResendEnabled] = useState(false);
  const [verifyPinEnabled, setVerifyPinEnabled] = useState(false);

  const { seconds, minutes, isRunning, restart } = useTimer({
    autoStart: false,
    expiryTimestamp: new Date(),
  });

  const activeTheme = useTheme();
  const isDarkMode = [THEMES.RED_DARK, THEMES.BLUE_DARK].includes(
    activeTheme.name
  );

  const waitFiveMinuteToResend = () => {
    // Start countdown timer
    restart(
      moment()
        .add(enterCodeCountdownTimeMilliseconds, 'milliseconds')
        .toDate()
    );

    // Wait 5 minutes, then show error message and enable resend button
    setTimeout(() => {
      // Add an error to the error text that states to please check your sms for pin number and enable the resend button
      setErrorMessage(
        'Pin code expired. Please request a new one by clicking RESEND PIN.'
      );
      setResendEnabled(true);
    }, enterCodeCountdownTimeMilliseconds); // Timeout of 5 minutes
  };

  const handleResendClick = () => {
    // Is timer still running? Do nothing. Probably means user edited the page to try and click
    if (isRunning) {
    }

    // Remove error message
    setErrorMessage('');
    // Disable resend button
    setResendEnabled(false);
    // Set info message
    setInfoMessage(
      'Your Pin Has been resent. Please allow 5 minutes before resending'
    );

    // Send request to get new SMS OTP
    apiService.sendAuthSms({
      phoneNumber: data.contactPhone,
      parcelCode: parcel.parcelCode,
    });
    waitFiveMinuteToResend();
  };
  const handleVerifyClick = async () => {
    // Check if the user has entered the full 6 digit otp
    if (otp.toString().length !== 6) {
      setErrorMessage('Please enter the 6 digit pin sent to your device');
      return;
    }

    const result = await authParcel(parcel.parcelCode, otp);

    if (result.success) {
      // PIN OK
      funcAfterAuth({ stepData: data });
    } else {
      if (result.error) {
        if (result.message === 'Pin number attempts exceeded') {
          // Disable verify pin button
          setVerifyPinEnabled(false);
        }

        // NOT OK
        setErrorMessage(result.message);
      } else {
        setErrorMessage('An unknown error occurred');
      }
    }
  };

  const getResendButtonChildren = () =>
    isRunning ? (
      <>
        RESEND PIN •&nbsp;<span>{minutes}</span>:
        <span>{getDisplaySeconds(seconds)}</span>
      </>
    ) : (
      <>RESEND PIN</>
    );

  const otpInputStyles = {
    inputStyle: {
      fontFamily: 'monospace',
      border:
        errorMessage !== ''
          ? '1px solid #dc0032'
          : isDarkMode
          ? '1px solid #1B1E22'
          : '1px solid lightgrey',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 10px 0px',
      margin: '4px',
      paddingLeft: '10px',
      width: '40px',
      height: '40px',
      fontSize: '32px',
      boxSizing: 'border-box',
      color: isDarkMode ? 'white' : 'black',
      backgroundColor: isDarkMode ? '#3B3F44' : 'white',
    },
  };

  useEffect(() => {
    // Only enable verify pin button when length of otp is 6
    setVerifyPinEnabled(otp.toString().length === 6);
  }, [otp]);

  // Wait 5 minutes before allowing to resend
  useEffect(waitFiveMinuteToResend, []);

  return (
    <>
      <Button className={styles.arrowButton} onClick={goToContacts}>
        <LeftArrow className={styles.leftArrowIcon} />
      </Button>

      <Typography className={styles.title}>
        Authenticate Your Details
      </Typography>

      {errorMessage !== '' && (
        <Typography className={styles.error}>{errorMessage}</Typography>
      )}

      {infoMessage !== '' && (
        <Typography className={styles.infoMessage}>{infoMessage}</Typography>
      )}

      <ReactCodeInput
        name="6DigitCodeEnter"
        inputMode="numeric"
        fields={6}
        onChange={value => setOtp(value)}
        {...otpInputStyles}
      />

      <Grid className={styles.actions}>
        <div className={styles.pinButtons}>
          <SubmitButton
            onSubmit={handleVerifyClick}
            children="Verify Pin"
            className={styles.submitButton}
            isSubmitDisabled={!verifyPinEnabled} // Disabled by default
          />
          <SubmitButton
            onSubmit={handleResendClick}
            children={getResendButtonChildren()}
            className={styles.submitButton}
            isSubmitDisabled={!resendEnabled} // Disabled by default
          />
        </div>
      </Grid>
    </>
  );
};

export default compose(
  withFetch(({ data, parcel }) =>
    sendAuthSms(data.contactPhone, parcel.parcelCode)
  ),
  withProps(({ goToStep, data }) => ({
    goToContacts: () => goToStep(RED_STEPS.GET_CONTACTS),
    funcAfterAuth: data.funcAfterAuth,
  }))
)(EnterCodeStep);
