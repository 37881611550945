import { withProps, compose } from 'recompose';
import SelectDay from '@dpdgroupuk/fmx-ui/components/DayPicker';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';

import * as MESSAGE from '../../../../constants/message';
import { SFP_STEPS } from '../../steps';
import { SAFE_PLACE_DELIVERY_OPTION } from '../../../../constants/analytics';

export default compose(
  withProps(({ addStepData, goToStep }) => ({
    title: MESSAGE.DAY_OF_DELIVERY,
    subtitle: MESSAGE.SELECT_UPCOMING_DAY,
    onSelect: day => {
      addStepData({ day });
      goToStep(SFP_STEPS.SELECT_SAFE_PLACE);
    },
  })),
  withTrackProps({
    onSelect: SAFE_PLACE_DELIVERY_OPTION.ON_DATE_SELECT,
  })
)(SelectDay);
