import moment from 'moment-timezone';
import { get, head } from 'lodash';
import { DEFAULT_DATE_TIME_FORMAT } from '@dpdgroupuk/fmx-ui/utils/date';

const EUROPE_LONDON_TIME_ZONE = 'Europe/London';
export const getUKCurrentMomentTime = () =>
  moment().tz(EUROPE_LONDON_TIME_ZONE);

export const getArriveTime = (
  pickupLocationDriverWindow,
  estimatedDeliveryDate
) => {
  return get(
    pickupLocationDriverWindow,
    `[${moment(estimatedDeliveryDate).day()}].pickupLocationDriverWindowEndTime`
  );
};

export const filterAvailableTimeSlots = (date, timeSlots) =>
  moment(date).isAfter(getUKCurrentMomentTime(), 'date')
    ? timeSlots
    : timeSlots.filter(timeSlot =>
        moment(
          `${date} ${head(timeSlot.split(' - '))}`,
          DEFAULT_DATE_TIME_FORMAT.DATE_TIME_FORMAT
        ).isAfter(getUKCurrentMomentTime())
      );

export const isTodayAfter1am = date =>
  moment(date).isSame(getUKCurrentMomentTime(), 'day') &&
  getUKCurrentMomentTime().hour() >= 1;

export const getWeekDay = date => {
  const day = moment(date).day();

  if (day === 0) {
    return 7;
  }

  return day;
};

export const checkEstimatedDate = (
  estimatedDeliveryDate,
  shipmentTargetDate
) => {
  const today = moment().format('YYYY-MM-DD');

  if (estimatedDeliveryDate) {
    if (estimatedDeliveryDate < today) {
      return today;
    }
    return estimatedDeliveryDate;
  }

  if (shipmentTargetDate) {
    if (shipmentTargetDate < today) {
      return today;
    }
    return shipmentTargetDate;
  }

  return null;
};
