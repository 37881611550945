import FetchClient from '@dpdgroupuk/fetch-client';
import { API_HOST } from '../constants/app';

class FmpFetchClient extends FetchClient {
  // pass _ query param to all get request to invalidate cache(IE problem)
  getWithoutCache({ query = {}, ...params }) {
    return super.get({
      ...params,
      query: {
        ...query,
        _: Date.now(),
      },
    });
  }
}

const fetchClient: FmpFetchClient = new FmpFetchClient({
  mode: 'cors',
  credentials: 'include',
  baseUrl: API_HOST,
});

export default fetchClient;
