import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { compose, withProps } from 'recompose';
import {
  SAFE_PLACE_TYPE,
  SAFE_PLACE_NAME,
  USER_TYPE,
} from '@dpdgroupuk/redback-enums';
import Input from '@dpdgroupuk/fmx-ui/components/Input';
import SubmitButton from '@dpdgroupuk/fmx-ui/components/SubmitButton';
import AcceptFullResponsibility from '@dpdgroupuk/fmx-ui/components/AcceptFullResponsibility';
import { withTrackProps, useTracker } from '@dpdgroupuk/react-event-tracker';
import SafePlaceItem, {
  createSafePlace,
} from '@dpdgroupuk/fmx-ui/components/SafePlaceItem';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  SAFE_PLACE_PLACEHOLDER,
  ACCEPT_FULL_RESPONSIBILITY,
  OTHER_SAFE_PLACE,
} from '../../../../constants/message';
import type { SafePlace } from '../../../../components/SafePlaceItem';
import ChangeDeliveryDay from '../../components/ChangeDeliveryDay';
import { SFP_STEPS } from '../../steps';
import { getContactInformation } from '../../../../models/parcel';
import { SAFE_PLACE_DELIVERY_OPTION } from '../../../../constants/analytics';
import withFetch from '../../../../HOCs/withFetch';
import { fetchParcelClaimsDispatch } from '../../actions';

import { ORIGIN } from '../../../../constants/values';
import mapStateToProps from '../../utils/mapStateToProps';

const useStyles = makeStyles(({ breakpoints, typography, palette }) => ({
  list: {
    [breakpoints.down('sm')]: {
      width: typography.pxToRem(240),
      marginBottom: typography.pxToRem(20),
    },
    width: typography.pxToRem(280),
    marginBottom: typography.pxToRem(20),
  },
  title: {
    [breakpoints.down('sm')]: {
      fontSize: typography.pxToRem(18),
      marginTop: typography.pxToRem(30),
      marginBottom: typography.pxToRem(20),
    },
    fontSize: typography.pxToRem(21),
    lineHeight: 1.49,
    letterSpacing: 0.44,
    marginTop: typography.pxToRem(40),
    marginBottom: typography.pxToRem(20),
    color: palette.text.redWhite,
  },
  subtitle: {
    fontSize: typography.pxToRem(12),
    lineHeight: 1.33,
    letterSpacing: 0.44,
    width: typography.pxToRem(312),
    color: palette.text.responsibility,
    textAlign: 'center',
  },
  inputTitle: {
    [breakpoints.down('sm')]: {
      fontSize: typography.pxToRem(16),
    },
    textTransform: 'uppercase',
    fontSize: typography.pxToRem(19),
    lineHeight: 0.84,
    letterSpacing: 0.44,
  },
  input: {
    [breakpoints.down('sm')]: {
      width: typography.pxToRem(240),
      marginTop: typography.pxToRem(10),
      marginBottom: typography.pxToRem(20),
    },
    width: typography.pxToRem(230),
    marginTop: typography.pxToRem(12),
    marginBottom: typography.pxToRem(30),
    '& .MuiInputBase-root': {
      border: `0.0625rem solid ${palette.border.safePlaceInput}`,
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  acceptedResponsibility: {
    [breakpoints.down('xs')]: {
      width: typography.pxToRem(250),
    },
    marginBottom: typography.pxToRem(25),
  },
}));

type Props = {
  data: Object,
  onDateChange: Function,
  onSubmit: Function,
  onSelect: Function,
  handleInputChange: Function,
  safePlaces: Array<SafePlace>,
};

const SelectSafePlace = ({
  data,
  onDateChange,
  onSubmit,
  safePlaces,
  onSelect,
  handleInputChange,
}: Props) => {
  const { safePlace, instruction } = data;
  const styles = useStyles();
  const whitespaceInstruction = !/(.|\s)*\S(.|\s)*/.test(instruction);
  const [acceptedResponsibility, setAcceptedResponsibility] = React.useState(
    false
  );
  const isSafePlaceInvalid =
    !acceptedResponsibility ||
    !safePlace ||
    (safePlace.id === SAFE_PLACE_TYPE.OTH && whitespaceInstruction);

  const tracker = useTracker();

  const handleSelect = React.useCallback(
    safePlace => {
      onSelect(safePlace);
      setAcceptedResponsibility(false);
    },
    [onSelect, setAcceptedResponsibility]
  );

  const handleOtherChange = React.useCallback(
    event => {
      handleInputChange(event.target.value);

      if (acceptedResponsibility) {
        setAcceptedResponsibility(false);
      }
    },
    [handleInputChange, acceptedResponsibility, setAcceptedResponsibility]
  );

  return (
    <>
      <ChangeDeliveryDay day={data.day} onDateChange={onDateChange} />
      <Typography className={styles.title}>
        Select your safe place location
      </Typography>
      <Typography className={styles.subtitle}>
        If you’re not available to sign for your parcel we can leave it in your
        safe place, if allowed by the sender
      </Typography>
      <div className={styles.list}>
        {safePlaces.map((place: SafePlace) => {
          return (
            <SafePlaceItem
              key={place.id}
              place={place}
              onSelect={handleSelect}
              selected={place === safePlace}
            />
          );
        })}
      </div>
      <div>
        <Typography className={styles.inputTitle}>
          {OTHER_SAFE_PLACE}
        </Typography>
        <Input
          value={instruction}
          className={styles.input}
          onChange={handleOtherChange}
          name={SAFE_PLACE_NAME.OTH}
          placeholder={SAFE_PLACE_PLACEHOLDER}
          inputProps={{ maxLength: 256 }}
          onBlur={e => {
            if (!e.currentTarget.contains(e.relatedTarget)) {
              tracker.logEvent(SAFE_PLACE_DELIVERY_OPTION.ON_OTHER);
            }
          }}
        />
      </div>
      <AcceptFullResponsibility
        selected={acceptedResponsibility}
        onSelect={() => setAcceptedResponsibility(!acceptedResponsibility)}
        title={ACCEPT_FULL_RESPONSIBILITY}
        className={styles.acceptedResponsibility}
      />
      <SubmitButton onSubmit={onSubmit} isSubmitHidden={isSafePlaceInvalid} />
    </>
  );
};

SelectSafePlace.defaultProps = {
  data: { day: '' },
  safePlaces: [
    createSafePlace(SAFE_PLACE_TYPE.FPC),
    createSafePlace(SAFE_PLACE_TYPE.GAR),
    createSafePlace(SAFE_PLACE_TYPE.OBL),
    createSafePlace(SAFE_PLACE_TYPE.RPC),
  ],
};

export default compose(
  withRouter,
  withFetch(({ parcel }) => fetchParcelClaimsDispatch({ parcel })),
  connect(mapStateToProps),
  withProps(
    ({
      addStepData,
      parcel,
      submitWizard,
      goToStep,
      isCreateProfilePossible,
      claims,
    }) => {
      const onDeliveryUpdate = submitWizard;

      return {
        handleInputChange: value => {
          addStepData({
            safePlace: value ? createSafePlace(SAFE_PLACE_TYPE.OTH) : null,
            instruction: value,
            ...getContactInformation(parcel),
          });
        },
        onSelect: safePlace => {
          addStepData({
            safePlace,
            instruction: '',
            ...getContactInformation(parcel),
          });
        },
        onSubmit: () => {
          const { origin, userType } = claims;

          if (origin === ORIGIN.FMP && userType === USER_TYPE.RECEIVER) {
            addStepData({
              funcAfterAuth: isCreateProfilePossible
                ? () => goToStep(SFP_STEPS.DELIVERY_PREFERENCES)
                : onDeliveryUpdate,
            });

            // Go to contact page before sms auth
            return goToStep(SFP_STEPS.GET_CONTACTS);
          }

          return isCreateProfilePossible
            ? goToStep(SFP_STEPS.DELIVERY_PREFERENCES)
            : onDeliveryUpdate();
        },
        onDateChange: () => {
          addStepData({ safePlace: '' });
          goToStep(SFP_STEPS.SELECT_DAY);
        },
      };
    }
  ),
  withTrackProps({
    onSelect: SAFE_PLACE_DELIVERY_OPTION.ON_SAFE_PLACE_OPTION,
    onDateChange: SAFE_PLACE_DELIVERY_OPTION.ON_DATE_CHANGE,
  })
)(SelectSafePlace);
