import { compose } from 'redux';
import { withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import type { AuthorizedParcel, Route } from '@dpdgroupuk/fmx-ui/types';
import { isMobile } from '@dpdgroupuk/fmx-ui/utils/helpers';
import { OFxDesktop, OFxMobile } from '@dpdgroupuk/fmx-ui/components/OFx';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';

import {
  OUT_FOR_DELIVERY,
  trackable,
} from '../../../../../constants/analytics';
import { fetchRouteAndDriver } from '../actions';

import { getParcelProgressData } from '../model';
import {
  backToDpdSiteHeader,
  changeDeliveryHeader,
  changeDeliverySpeedDeal,
  getDpdAppHeader,
  goToPollutrackHeader,
  goToParcelInfoHeader,
  learnAboutYourDriverHeader,
  learnAboutYourDriverSpeedDeal,
  trackAnotherParcelHeader,
  whatHappensNextHeader,
  whatHappensNextSpeedDeal,
} from '../../../../controlActions';
import withFetch from '../../../../../HOCs/withFetch';
import { getDriverArriveTimeText } from '../../../../../models/parcel';
import { MORE_COOL_STUFF } from '../../../../../constants/message';

type Props = {
  parcel: AuthorizedParcel,
  route: Route,
  driver: any,
};

const createHeaderControlActions = props => {
  const generalHeaderControlActions = [
    goToParcelInfoHeader(props),
    whatHappensNextHeader(props),
    changeDeliveryHeader(props),
    learnAboutYourDriverHeader(props),
    trackAnotherParcelHeader(props),
    backToDpdSiteHeader(props),
    getDpdAppHeader(props),
  ];

  if (props.parcel.airDiagActive) {
    return [...generalHeaderControlActions, goToPollutrackHeader(props)];
  }

  return generalHeaderControlActions;
};

const createSpeedDealControlActions = props => [
  learnAboutYourDriverSpeedDeal(props),
  changeDeliverySpeedDeal(props),
  whatHappensNextSpeedDeal(props),
];

export default compose(
  withRouter,
  withModal,
  withFetch(fetchRouteAndDriver, {
    dataPropMapper: ([route, driver]) => ({
      route,
      driver,
    }),
  }),
  withProps(({ parcel, route, driver }: Props) => ({
    additionalInfo: getDriverArriveTimeText(parcel),
    ...getParcelProgressData(parcel, route, driver),
  })),
  withPageControl({
    title: MORE_COOL_STUFF,
    backgroundImage: IMAGES.TRACKING_BG,
    trackable: trackable(OUT_FOR_DELIVERY),
    isFooterHidden: true,
    headerMenuActions: createHeaderControlActions,
    speedDealActions: createSpeedDealControlActions,
    // isMobileMenuShevronVisible: true,
  })
)(isMobile ? OFxMobile : OFxDesktop);
