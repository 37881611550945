import { get } from 'lodash';
import React from 'react';
import { addDays } from '@dpdgroupuk/fmx-ui/utils/date';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import {
  isTodayAfter1am,
  getWeekDay,
  checkEstimatedDate,
} from '../../../../utils/date';
import * as MESSAGE from '../../../../constants/message';
import { WEEK_DAYS } from './constants';
import typeof { AuthorizedParcel } from '../../../../types/Parcel';

export const getSelectedPickupShopIndex = ({
  pickupShops = [],
  selectedShopCode,
}: {
  pickupShops: any,
  selectedShopCode: string,
}) =>
  pickupShops.findIndex(
    ({ pickupLocation: { pickupLocationCode } }) =>
      pickupLocationCode === selectedShopCode
  );

export const getSelectedPickupShop = ({
  pickupShops = [],
  pickupLocationCode,
}: {
  pickupShops: any,
  pickupLocationCode: any,
}) =>
  pickupShops.find(
    ({ pickupLocation }) =>
      pickupLocation.pickupLocationCode === pickupLocationCode
  );

export const getParcelAvailableDate = (
  parcel: AuthorizedParcel,
  selectedShop: any
) => {
  const { estimatedDeliveryDate, shipmentTargetDate } = parcel;
  const parcelEstimatedDate = checkEstimatedDate(
    estimatedDeliveryDate,
    shipmentTargetDate
  );

  const parcelDate = parcelEstimatedDate || new Date();

  return selectedShop.pickupDate || parcelDate;
};

export const getPickupShopName = (pickupShop: any) =>
  get(pickupShop, 'pickupLocation, shortName') ||
  get(pickupShop, 'pickupLocation.address.organisation');

export const getParcelPickupStatus = ({
  customerName,
  date,
  boldStyles,
  containerStyles,
}: {
  customerName: string,
  date: string,
  boldStyles: any,
  containerStyles: any,
}) => {
  const message = MESSAGE.SELECT_PICKUP_SHOP_TEXT.replace(
    '{{customerName}}',
    customerName
  );

  return (
    <div className={clsx(containerStyles)}>
      <Typography
        className={clsx(boldStyles)}
        dangerouslySetInnerHTML={{ __html: message }}
      />
      {MESSAGE.YOUR_PARCEL_WILL_BE_AVAILABLE_TEXT} from {date}
    </div>
  );
};

export const getParcelPickupStatusModal = ({
  shopName,
  date,
  kind,
}: {
  shopName: string,
  date: string,
  kind: string,
}) => {
  const message = MESSAGE.PARCEL_AVAILABLE_MODAL.replace(
    '{{pickupShopName}}',
    shopName
  ).replace('{{pickupShopKind}}', kind === 'LKR' ? 'Locker' : 'Shop');

  return (
    <div>
      {message} on <br /> {date}
    </div>
  );
};

export const calculateMapFitBounds = ({
  parcelDestination,
  pickupShops = [],
}: any) => {
  const parcelLatitude = get(parcelDestination, 'latitude');
  const parcelLongitude = get(parcelDestination, 'longitude');
  let latitudeBoundMin = parcelLatitude;
  let latitudeBoundMax = parcelLatitude;
  let longitudeBoundMin = parcelLongitude;
  let longitudeBoundMax = parcelLongitude;

  pickupShops.forEach(
    ({
      pickupLocation: {
        addressPoint: { latitude, longitude },
      },
    }) => {
      // Latitude bounds
      latitudeBoundMin =
        latitude < latitudeBoundMin ? latitude : latitudeBoundMin;
      latitudeBoundMax =
        latitude > latitudeBoundMax ? latitude : latitudeBoundMax;
      // Longitude bounds
      longitudeBoundMin =
        longitude < longitudeBoundMin ? longitude : longitudeBoundMin;
      longitudeBoundMax =
        longitude > longitudeBoundMax ? longitude : longitudeBoundMax;
    }
  );

  // Bounds format: [[south, west], [north, east]]
  const bounds = [
    [longitudeBoundMax, latitudeBoundMin],
    [longitudeBoundMin, latitudeBoundMax],
  ];

  return bounds;
};

export const mapOpeningHours = (openingHours: any) => {
  const openingHoursMapped = openingHours.map(
    ({ pickupLocationOpenWindowDay: day }) => {
      const sameDayData = openingHours.filter(
        ({ pickupLocationOpenWindowDay }) => pickupLocationOpenWindowDay === day
      );

      let times = '';

      if (sameDayData[0]) {
        times = `${sameDayData[0].pickupLocationOpenWindowStartTime} - ${sameDayData[0].pickupLocationOpenWindowEndTime}`;

        if (sameDayData[1]) {
          if (
            sameDayData[0].pickupLocationOpenWindowEndTime ===
            sameDayData[1].pickupLocationOpenWindowStartTime
          ) {
            times = `${sameDayData[0].pickupLocationOpenWindowStartTime} - ${sameDayData[1].pickupLocationOpenWindowEndTime}`;
          } else {
            times =
              times +
              ', ' +
              `${sameDayData[1].pickupLocationOpenWindowStartTime} - ${sameDayData[1].pickupLocationOpenWindowEndTime}`;
          }
        }
      }

      return {
        day: WEEK_DAYS[day - 1],
        time: times,
      };
    }
  );

  return openingHoursMapped.reduce((total, current) => {
    if (total && total.findIndex(({ day }) => day === current.day) === -1) {
      return [...total, { ...current }];
    }

    return [...total];
  }, []);
};

export const getExpiryDateText = expiryDateString => {
  return MESSAGE.PARCEL_COLLECTED_BEFORE_TEXT.replace(
    '{{date}}',
    expiryDateString
  );
};
