import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';

import FormWithSubmit from '@dpdgroupuk/fmx-ui/components/FormWithSubmit';
import { InputFormField } from '@dpdgroupuk/fmx-ui/components/Input';
import {
  FIELD_VALUE_TOO_LONG,
  REQUIRED_FIELD,
} from '@dpdgroupuk/fmx-ui/constants/validation';

import * as MESSAGE from '../../../constants/message';

const useStyles = makeStyles(({ typography }) => {
  return {
    fieldContainer: {
      flex: 1,
    },
    field: {
      height: typography.pxToRem(29),
      '& .MuiInputBase-root': {
        borderRadius: '0.28125rem',
        border: 'solid 0.5px #979797',
        fontSize: '0.65rem',
        height: '1.8125rem',
        boxSizing: 'border-box',
        padding: '0.5rem 0.375rem',
        letterSpacing: 0.37,
      },
    },
  };
});

const validate = values => {
  const errors = {};
  if (!values.driverNotes || !values.driverNotes.length) {
    errors.driverNotes = REQUIRED_FIELD;
    return errors;
  }

  if (values.driverNotes.length > 1024) {
    errors.driverNotes = FIELD_VALUE_TOO_LONG;
  }

  return errors;
};

function RateDriverNotes() {
  const styles = useStyles();
  return (
    <FormWithSubmit>
      <Grid container direction="row">
        <Grid className={styles.fieldContainer}>
          <Field
            name="driverNotes"
            component={InputFormField}
            placeholder={MESSAGE.SOMETHING_ELSE}
            fullWidth
            className={styles.field}
          />
        </Grid>
      </Grid>
    </FormWithSubmit>
  );
}
export default reduxForm({ form: 'driverNotes', validate })(RateDriverNotes);
