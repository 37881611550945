import React from 'react';

const AddToAppleWallet = props => (
  <svg
    version="1.1"
    id="Artwork"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="110.739px"
    height="35.016px"
    viewBox="0 0 110.739 35.016"
    style={{ enableBackground: 'new 0 0 110.739 35.016' }}
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <g>
        <path
          fill="#A6A6A6"
          d="M100.211,0H9.533C9.166,0,8.804,0,8.439,0.002C8.132,0.004,7.829,0.01,7.52,0.015
			C6.854,0.031,6.181,0.072,5.516,0.191C4.847,0.309,4.225,0.509,3.616,0.818C3.017,1.125,2.47,1.523,1.997,1.997
			C1.519,2.47,1.122,3.019,0.819,3.618C0.508,4.226,0.311,4.851,0.194,5.521C0.074,6.183,0.032,6.852,0.015,7.522
			C0.006,7.829,0.005,8.136,0,8.443c0,0.362,0,0.725,0,1.092v14.948c0,0.369,0,0.73,0,1.094c0.005,0.31,0.006,0.611,0.015,0.922
			c0.017,0.67,0.059,1.34,0.179,2.002c0.117,0.67,0.314,1.298,0.625,1.904c0.303,0.596,0.7,1.144,1.178,1.614
			c0.473,0.477,1.02,0.875,1.618,1.178c0.609,0.312,1.231,0.51,1.901,0.631c0.664,0.119,1.338,0.158,2.004,0.177
			c0.309,0.007,0.613,0.011,0.919,0.011c0.366,0.002,0.728,0.002,1.095,0.002h90.678c0.359,0,0.724,0,1.084-0.002
			c0.305,0,0.617-0.004,0.922-0.011c0.67-0.018,1.342-0.058,2-0.177c0.67-0.121,1.293-0.318,1.908-0.631
			c0.597-0.304,1.144-0.701,1.617-1.178c0.476-0.47,0.873-1.018,1.181-1.614c0.307-0.606,0.506-1.234,0.619-1.904
			c0.123-0.662,0.162-1.332,0.186-2.002c0.004-0.311,0.004-0.611,0.004-0.922c0.008-0.363,0.008-0.724,0.008-1.094V9.534
			c0-0.366,0-0.729-0.008-1.092c0-0.307,0-0.614-0.004-0.921c-0.023-0.67-0.062-1.34-0.186-2.002
			c-0.113-0.67-0.312-1.295-0.619-1.903c-0.309-0.599-0.705-1.147-1.181-1.621c-0.473-0.474-1.019-0.872-1.617-1.179
			c-0.615-0.31-1.238-0.51-1.908-0.627c-0.658-0.119-1.33-0.16-2-0.177c-0.305-0.005-0.617-0.011-0.922-0.013
			C100.936,0,100.571,0,100.211,0L100.211,0z"
        />
      </g>
      <path
        d="M100.211,0.65c0.358,0,0.722,0,1.08,0.002c0.246,0.002,0.498,0.006,0.746,0.01l0.164,0.003
		c0.529,0.013,1.222,0.044,1.904,0.167c0.619,0.108,1.184,0.294,1.723,0.565c0.539,0.277,1.028,0.633,1.455,1.061
		c0.423,0.42,0.78,0.91,1.059,1.452c0.271,0.539,0.454,1.101,0.56,1.729c0.117,0.63,0.153,1.288,0.175,1.891
		c0.004,0.304,0.004,0.609,0.004,0.926c0.008,0.358,0.008,0.716,0.008,1.078v14.948c0,0.364,0,0.721-0.008,1.094
		c0,0.308,0,0.606-0.004,0.899c-0.022,0.617-0.058,1.275-0.177,1.916c-0.105,0.622-0.288,1.184-0.555,1.713
		c-0.284,0.549-0.641,1.037-1.066,1.456c-0.423,0.427-0.91,0.782-1.449,1.056c-0.531,0.27-1.097,0.456-1.729,0.571
		c-0.655,0.119-1.329,0.151-1.898,0.167c-0.3,0.007-0.607,0.011-0.911,0.011c-0.358,0.002-0.722,0.002-1.08,0.002H9.533
		c-0.365,0-0.726,0-1.095-0.002c-0.301,0-0.6-0.004-0.901-0.01c-0.572-0.016-1.245-0.048-1.906-0.167
		c-0.633-0.114-1.196-0.301-1.722-0.571c-0.539-0.273-1.027-0.629-1.457-1.063c-0.422-0.415-0.777-0.901-1.055-1.447
		c-0.268-0.523-0.453-1.086-0.564-1.724c-0.124-0.681-0.155-1.374-0.169-1.904c-0.006-0.208-0.009-0.411-0.011-0.616L0.65,25.576
		v-1.094V9.534l0-1.081l0.004-0.299C0.656,7.95,0.659,7.746,0.665,7.539c0.013-0.528,0.045-1.221,0.17-1.907
		c0.11-0.63,0.294-1.192,0.564-1.721c0.275-0.543,0.63-1.032,1.058-1.455C2.883,2.029,3.373,1.673,3.91,1.398
		C4.446,1.125,5.008,0.94,5.631,0.831C6.315,0.708,7.009,0.678,7.53,0.664l0.198-0.003c0.239-0.004,0.476-0.008,0.714-0.009
		C8.806,0.65,9.168,0.65,9.533,0.65H100.211"
      />
      <g>
        <defs>
          <path
            id="SVGID_1_"
            d="M33.265,23.054l0,0.265c0,0.127,0,0.254-0.001,0.381c-0.001,0.107-0.002,0.214-0.005,0.321
				c-0.006,0.233-0.02,0.468-0.061,0.698c-0.042,0.234-0.111,0.452-0.219,0.664c-0.106,0.209-0.245,0.4-0.411,0.565
				c-0.166,0.166-0.357,0.304-0.565,0.411c-0.212,0.108-0.43,0.177-0.664,0.219c-0.23,0.041-0.465,0.055-0.698,0.061
				c-0.107,0.003-0.214,0.004-0.321,0.005c-0.127,0.001-0.254,0.001-0.381,0.001l-1.459-0.001l1.358,0.001H10.985l1.358-0.001
				l-1.459,0.001c-0.127,0-0.254,0-0.381-0.001c-0.107-0.001-0.214-0.002-0.321-0.005c-0.233-0.006-0.468-0.02-0.698-0.061
				c-0.234-0.042-0.451-0.111-0.664-0.219c-0.209-0.106-0.4-0.245-0.565-0.411c-0.166-0.166-0.304-0.357-0.411-0.565
				c-0.108-0.212-0.177-0.43-0.219-0.664c-0.041-0.23-0.055-0.465-0.061-0.698C7.561,23.914,7.56,23.807,7.56,23.7
				c-0.001-0.127-0.001-0.254-0.001-0.381l0-0.208V10.899l0,1.25l0-1.458c0-0.127,0-0.254,0.001-0.381
				c0.001-0.107,0.002-0.214,0.005-0.321c0.006-0.233,0.02-0.468,0.061-0.698c0.042-0.234,0.111-0.452,0.219-0.664
				c0.106-0.209,0.245-0.4,0.411-0.565c0.166-0.166,0.357-0.304,0.565-0.411c0.212-0.108,0.43-0.177,0.664-0.219
				c0.23-0.041,0.465-0.055,0.698-0.061c0.107-0.003,0.214-0.004,0.321-0.005c0.127-0.001,0.254-0.001,0.381-0.001l1.458,0h17.496
				l0.1,0c0.127,0,0.254,0,0.381,0.001c0.107,0.001,0.214,0.002,0.321,0.005c0.233,0.006,0.468,0.02,0.698,0.061
				c0.234,0.042,0.451,0.111,0.664,0.219c0.209,0.106,0.4,0.245,0.565,0.411c0.166,0.166,0.304,0.357,0.411,0.565
				c0.108,0.212,0.177,0.43,0.219,0.664c0.041,0.23,0.055,0.465,0.061,0.698c0.003,0.107,0.004,0.214,0.005,0.321
				c0.001,0.127,0.001,0.254,0.001,0.381l0,0.265V23.054z"
          />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
        </clipPath>
        <rect
          x="8.352"
          y="8.169"
          clipPath="url(#SVGID_2_)"
          fill="#DEDBCE"
          width="24.099"
          height="16.78"
        />
        <rect
          x="8.63"
          y="8.436"
          clipPath="url(#SVGID_2_)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#40A5D9"
          width="23.564"
          height="9.997"
        />
        <g clipPath="url(#SVGID_2_)">
          <image
            style={{ overflow: 'visible', opacity: '0.17' }}
            width="109"
            height="52"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHEAAAA6CAYAAACH4bZ/AAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAArlJREFUeNrsnG1vEkEUhXeXBSpU
FCW+6zdN/P8/qGk00WhFY6CWsqD3JmfS6bpLoWGXNnlOckJ32NkPPNw7MzT3JglCCKE9KN3zfWh/
+rsPiGn0Ghs1D6/sW0H08czckXNdZ4BsHODavDIXel3dBDOvGAuwuuYj8wO5r/uJyGYBOryF+Vy+
0Fhtis1rItCBHZvH5qfmx7ruKzJZJ5tZ/wLA3+Yz+ZdgFttATKMIdGDPzW/lFwJ6JIgAbAZkIWAO
75P5RO95hP6pS6vlSOwI1FjwPprfm18pGoHYDsRv5pHGF/IyWh9rIWa6Hpgn5ncC+MH80vzQ3GNN
bBTiSmvgSDzm5p9KqXOB/C8a89IxIqRSh/ja/EZROFEU5gBsVGttIjOlz+/mz+YvArnQPbUQO4q0
Y21mngneSA/u6eGo2Wh0FkMtXxOxGEWbyuWmdBrWQ5/wRB5FEcgZsR2FYAogx1rK4v1IGkdjVorE
vib45EdaH7sAbE1picVAPIYCW7mpzCrS6VATB5smokZBhk1mTzC7m35oyUrfgFwT+noAafTwEdkp
pdGkDuLOE1FrIG/850N224no7ogjAxAREBEQERCBiICIgIiACEQERAREBEQgIiAiICIgAhEBEQER
ARGICIgIiAiIQET3RXnN+M4NcdDetPNnn1c8YJ1cb4gTeqhQl9EexPC5ryIeWzUjCgC9nNiL/73Q
fxbdB8R2Ic7E4EJMakHmJYCXmjg1fxU4Lzal0LRdiB6B52IwFZPLOpB5ib53bPBGOKca934qoVYc
tafQCmUqFmdiU2wDcSZwiR7gnTS6CbWKh9jYLMXjh5jMIojXQMbtMP244SXe3u5kmFw1XSCVHi6l
LpVW54rEypSalv4OBf/B1OsfFuQ62qkWdWtiWnFNufc9OzNualqL7hZMhBBCzeqfAAMARUSzZ7uC
WKkAAAAASUVORK5CYII="
            transform="matrix(0.24 0 0 0.24 7.4439 9.1078)"
          />
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFB003"
              d="M32.194,12.688c0-0.081,0-0.161,0-0.242c0-0.068-0.001-0.136-0.003-0.204
					c-0.004-0.148-0.013-0.297-0.039-0.443c-0.027-0.148-0.07-0.286-0.139-0.421c-0.067-0.132-0.155-0.254-0.26-0.359
					c-0.105-0.105-0.226-0.193-0.359-0.261c-0.135-0.069-0.273-0.112-0.421-0.139c-0.146-0.026-0.295-0.035-0.443-0.039
					c-0.068-0.002-0.136-0.003-0.204-0.003c-0.081,0-0.161,0-0.242,0l-0.066,0H11.665l-0.925,0c-0.081,0-0.161,0-0.242,0
					c-0.068,0-0.136,0.001-0.204,0.003c-0.148,0.004-0.297,0.013-0.443,0.039c-0.148,0.027-0.286,0.07-0.421,0.139
					c-0.132,0.067-0.254,0.155-0.359,0.261c-0.105,0.105-0.193,0.226-0.26,0.359c-0.069,0.135-0.112,0.273-0.139,0.421
					c-0.026,0.146-0.035,0.295-0.039,0.443c-0.002,0.068-0.003,0.136-0.003,0.204c0,0.081,0,0.161,0,0.242l0,0.925l0-0.727v7.689
					h23.564v-7.64L32.194,12.688z"
            />
          </g>
        </g>
        <g clipPath="url(#SVGID_2_)">
          <image
            style={{ overflow: 'visible', opacity: '0.16' }}
            width="109"
            height="52"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHEAAAA6CAYAAACH4bZ/AAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAqFJREFUeNrsnNtu00AURW3HdkJK
UxAX8YAqUN/4//9BCCQQlIBQUtrGSZkj7ZGmxpNLZU9baS1pK7Xr8UNWzxkn1UyWAQBAD+Q9XQP9
c9OHxLwjkEZeOwdL9MJGSqnXApFJBG5c1i6NXte7ZJYRgXZ+4jJVxjqHyOEFmrwrlwvlUueiLbZL
Yilxz1xeKrNAJPPkcPOfF/jH5Vz5LZnNPhJ9C51I4FuXdy6nEjnV9QgcTmQjYSbvs8tH/c4q9G+s
rXZJNFmvXN67fHA5c3mNxKQSv6v7ZapMyyqYHzsl5prvKpcjVd6pBJ7peCLJSBxO4lpz4Ew+li6/
1FKXEvlfNZaR+fC5qu+NqtLaa60bw3BY23yi99na5w+XLy5fJfJK19xsq8RaEk8kbqbjWlVKFQ5f
jbm6oX+wfBE8WI5UjdF2OpKspxo0019FFbRRJA6P9+BFWlc8bk1neViNRWvwWBJPgoElApMRftEy
Vhc8ltA69kxSdFTiVCKn2wbCoCILFU8tmdW2Yio6vqmp9hkIySpytGs6K+46EJKJ3PkPiOKuA+Hh
wOc+JAISAYmARCQCEgGJgEQkAhIBiYBEJAISAYmARCQCEgGJgEQkAhIBiYBEJMJjoYycP3hDHOiN
g9/7suMGm+z2hjh+DxXWZaST6N/3deBjr82IvEBbTmyL/22h/yK4DolpJS7k4FJOoiLLlsBrDZy7
fJM4W2zKQtO0Eq0CL+RgLifXMZFly77t2GAb4XzSedtPxa8Vh3T4rVDmcnEuN80+EhcSl+kGtuzb
75pBJaZ9sFnJx085WQQSb4nMg1e/BYrtmHGkNlrRSu+1pa7UVpeqxM6Wmrd+9gv+fdhV8X5FboIn
1SY2J+Ydxyz3fmSfGWOSkPfwZAIAwLD8E2AAOBuy81Gw3f4AAAAASUVORK5CYII="
            transform="matrix(0.24 0 0 0.24 7.4439 11.2678)"
          />
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#40C740"
              d="M32.194,14.83c0-0.081,0-0.161,0-0.242c0-0.068-0.001-0.136-0.003-0.204
					c-0.004-0.148-0.013-0.297-0.039-0.443c-0.027-0.148-0.07-0.286-0.139-0.421c-0.067-0.132-0.155-0.254-0.26-0.359
					c-0.105-0.105-0.226-0.193-0.359-0.261c-0.135-0.069-0.273-0.112-0.421-0.139c-0.146-0.026-0.295-0.035-0.443-0.039
					c-0.068-0.002-0.136-0.003-0.204-0.003c-0.081,0-0.161,0-0.242,0l-0.067,0H11.665l-0.925,0c-0.081,0-0.161,0-0.242,0
					c-0.068,0-0.136,0.001-0.204,0.003c-0.148,0.004-0.297,0.013-0.443,0.039c-0.148,0.027-0.286,0.07-0.421,0.139
					c-0.132,0.067-0.254,0.155-0.359,0.261c-0.105,0.105-0.193,0.226-0.26,0.359c-0.069,0.135-0.112,0.273-0.139,0.421
					c-0.026,0.146-0.035,0.295-0.039,0.443c-0.002,0.068-0.003,0.136-0.003,0.204c0,0.081,0,0.161,0,0.242l0,0.925l0-0.727v7.689
					h23.564v-7.64L32.194,14.83z"
            />
          </g>
        </g>
        <g clipPath="url(#SVGID_2_)">
          <image
            style={{ overflow: 'visible', opacity: '0.17' }}
            width="109"
            height="52"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHEAAAA6CAYAAACH4bZ/AAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAvBJREFUeNrsnFtv00AQhX1Ngkkv
EYW+ICQET/z//4NASEBbUi5JyMVOmJHOiMF4mzRJnVQ6RzrazdbrB3+and1KO1FEURRFUZQqvscz
caBP7aYV3NTfGaKBSlybwokbp3YDuIQreOnGV5vAjO+AZ8BycSbuwF20GSNyLxFYiufiGdo5xhYO
7J0wswBAHe+JC/FTtNbvo98B5IggtwIYAZBCm4hH4jH6E9efAmpwiW2CmAHSufhC/Fw8EJ+JT9H2
EZEpAe4EskIEKsAf4p9ob8XX4hvxd8AsN4FoS2gPAF+KX4tfiV9gTCGeuEhkXtw9H1ok/gJEhXYl
/ih+j2f1ud+hZbUJYoHoU4DvxG/FlwD4BJCZE/efE6cApSC/IlgiROrM5cggRNt95sh7F4hABfgG
vwu3yUkJcK8gK7eZmSBdxciLt4jOMf7+XzRmgXw4wBJ6CYDnyIGJg0eA+wNpu/8l2hhRqXnxk/gz
QM7c0SMYiR1APHM5sABALp8P+w+XFRhEWA1tY/kMHGwjuai/IKnlww5C+dTlwLwWgdTDwfQcDOQA
+bEXSmOJ66eg3Uck2kRG4GFAdrEKngBoJ3Ska4rEouFAT4Dtgkxcnuy6DWW8DqJtbPJNJlKtRWS6
7jSQbDuRag3k2tNAsu1E6niU8BMQIkWIFCFShEiIFCFShEgRIiFShEgRIkWIhEgRIkWIFCESIkWI
FCFShEiIFCFShEgRIiFSj0RZYHzVYKod3fvbZw0v8BWOyuhvDRXey2gPon33yvHYqBiRAdTrxFrJ
QS/6j9xzhNguRCtONAWTIMisBnCOiUPxF4DTy6a8aNouRI3ACRgMwWQeApnV6GvFBq1i9AHjWhTH
7opT7alCBA7B4gZsyk0gjgAuwgv02nce8a7iITY2C/D4BiYjB/EfkL5+qZVA0YoZVpQv51J60CXV
ihONEYmNS2pc69uFfzNrtx0W5NLtVMtQTowbfvO69yM7M4YgEd7xwaQo6qj1R4ABAM5x0/sxgGqp
AAAAAElFTkSuQmCC"
            transform="matrix(0.24 0 0 0.24 7.4439 13.4278)"
          />
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F26D5F"
              d="M32.194,16.972c0-0.081,0-0.161,0-0.242c0-0.068-0.001-0.136-0.003-0.204
					c-0.004-0.148-0.013-0.297-0.039-0.443c-0.027-0.148-0.07-0.286-0.139-0.421c-0.067-0.132-0.155-0.254-0.26-0.359
					c-0.105-0.105-0.226-0.193-0.359-0.261c-0.135-0.069-0.273-0.112-0.421-0.139c-0.146-0.026-0.295-0.035-0.443-0.039
					c-0.068-0.002-0.136-0.003-0.204-0.003c-0.081,0-0.161,0-0.242,0l-0.067,0H11.665l-0.925,0c-0.081,0-0.161,0-0.242,0
					c-0.068,0-0.136,0.001-0.204,0.003c-0.148,0.004-0.297,0.013-0.443,0.039c-0.148,0.027-0.286,0.07-0.421,0.139
					c-0.132,0.067-0.254,0.155-0.359,0.261c-0.105,0.105-0.193,0.226-0.26,0.359c-0.069,0.135-0.112,0.273-0.139,0.421
					c-0.026,0.146-0.035,0.295-0.039,0.443c-0.002,0.068-0.003,0.136-0.003,0.204c0,0.081,0,0.161,0,0.242l0,0.925l0-0.727v7.689
					h23.564v-7.64L32.194,16.972z"
            />
          </g>
        </g>
        <path
          clipPath="url(#SVGID_2_)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D9D6CC"
          d="M7.202,7.008v11.068H8.63v-6.605l0-0.925c0-0.081,0-0.161,0-0.242c0-0.068,0.001-0.136,0.003-0.204
			c0.004-0.148,0.013-0.297,0.039-0.443c0.027-0.148,0.07-0.286,0.139-0.421c0.067-0.132,0.155-0.254,0.26-0.359
			C9.177,8.773,9.298,8.685,9.43,8.618c0.135-0.069,0.273-0.112,0.421-0.139c0.146-0.026,0.295-0.035,0.443-0.039
			c0.068-0.002,0.136-0.003,0.204-0.003c0.081,0,0.161,0,0.242,0l0.925,0h18.348l0.071,0c0.081,0,0.161,0,0.242,0
			c0.068,0,0.136,0.001,0.204,0.003c0.148,0.004,0.297,0.013,0.443,0.039c0.148,0.027,0.286,0.07,0.421,0.139
			c0.132,0.067,0.254,0.155,0.359,0.261c0.105,0.105,0.193,0.226,0.26,0.359c0.069,0.135,0.112,0.273,0.139,0.421
			c0.026,0.146,0.035,0.295,0.039,0.443c0.002,0.068,0.003,0.136,0.003,0.204c0,0.081,0,0.161,0,0.242l0,0.925v6.605h1.428V7.008
			H7.202z"
        />
        <g clipPath="url(#SVGID_2_)">
          <image
            style={{ overflow: 'visible', opacity: '0.14' }}
            width="121"
            height="53"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH0AAAA6CAYAAACd3VbxAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABMZJREFUeNrsnQtz0kAUhTfJ8iog
2LdI7VhH6/j//5DjjLa1D22hBQrEXT13uOwECBSTEO6ZObNIKWbvt3v3EdgqJRKJRCKRKA/y1vSa
JBTmLK6pxc1bcOGu07roMOJxlhqC58RMpRwzHqvQjZs3pxK+sWb2U6iEvdAxPILpuTCiQaQF2mdl
APspxYzHbchMMYu8ILr4onHFuGq8Y1xAZbyEW6q94IFxH+WQNQD+eJwQfKo/B6wRnwLiVkKpU+jx
IeLxbPxo3DV+Quz+gtczKqUBfN/40HjPuIZKJVEJAjfCxdLF27LHGsET3EMlR/8ZPNU9QCzKiBN5
B52khsdFvDapcZ46io1Fx/jG+Mr4GsCfF0GvAfhH41PjXVQySLjF9lGBe5SPAP1g/Mv4DmUH8KdS
2ZqB05BXRnyaiIstG8Z1lK/w81LC2ZE6io3DrfFXPNdDHG1svDg93QL/bPwGrTdIsNWOWU8n4GQL
+qfxd/gSzz2iRa8TPAEvIAYW8pFxCz4E/Doz9fQkx3XqKDYGP/DcHeJEQ43SC8b0KipzbPwWrVcn
nKqGgEhpfYCefo/U9Q29i2ehdYJ3gduhrm383vjM+ATQm/h5JcUxneLVweMrMJxqfDpGRctsnKon
CJ2DHzkm8AfGr1kq9dnvdgH+JWO8xzIfAX9n/Mn4HNBbuIYdBlqzjJj0JG6Ix1WwK7jZRsdo4T6b
oeqEoVNFdMQSroaKVdjYqZzl3Zgt81aFHiB4TfRwC/wLyjYagru6SWtvI2RcZy4b9RKtPa3KeE6F
AtYQNFs28dl+n83mexGbOsuk9RImZsfo2ecAfgrg1YjelOamzEJOWm2OvAUVi5r0DSI2dJYdx6uA
a8fuD/CJs4z1Vba2Xudqk6AvAkPLuy6Wc138e8TWrnHAu++7izR+BrfxXHUTgW869Hk9kvf0sTOx
G89I994SPXxjgecB+qyxt89SO80DbiJSfhix01Z0lmZ8pn6M/6O0qcDzAj1qls334gNAqmCXqst2
p3ij0HgdpfSWM3Fr473LKeyyCfQ5kzxaT5MoA9CW6SV2qB6wzqd1fAGNoo419xHW42fYhOFLszRu
ogj0mDtnivX+BnbNLtS/mw+32NHr4XVlpG3bw/fZDmQLDaDJ1uL+JgPPG/SoiV3AxvoDwL5B+Rtj
vALQBqDvsbKJLFFW6X2mQKAvCd5nqbuBXsvv2PGeXlOTu2P0GYJyxE6bEujZH+P57dC6muzY9Z0x
vaQmN0oKanorU+UFeJ6hc0j8/kER8N1P2/BPwQQsW+QK9jZAd+HTnj2l/Kh1uspTGt9m6G4DcG/g
qLz2aIE+OwNsnXwlEugigS4S6CKBLhLoIoEuEugigS4S6CKBLhLoIoEuEugigS4S6CKBLhLo26g4
H5eaefKgKHOKxUoveAN+WiOdPGjlSXwzC504jRi/MA50Ak6nOtlvenbY7wj0bEPvgBkdqjgFXs8B
PsAv2u99XQB0kufIiVaDTufIXajJV7MHHLye01rsV3ntNzzp1EF7JlmSJ0aKVpN7YuQ1WA7jQO8A
tMIbJHk2rOhlEzn3bNgOgx5m+RRo0ctS/MxToLN+3rtodfBLnffOwWfhLzuI1rxm36S/4SJaHb5o
2/VHgAEAKq1w/7wLXkMAAAAASUVORK5CYII="
            transform="matrix(0.24 0 0 0.24 6.0039 15.3478)"
          />
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#DEDBCE"
              d="M26.985,17.005c-0.153,0-0.307,0-0.46,0.001c-0.129,0.001-0.258,0.002-0.387,0.006
					c-0.281,0.008-0.565,0.024-0.843,0.074c-0.282,0.051-0.545,0.134-0.801,0.264c-0.033,0.017-0.738,0.337-1.372,1.323
					c-0.481,0.749-1.417,1.543-2.727,1.543s-2.246-0.794-2.727-1.543c-0.667-1.039-1.428-1.351-1.373-1.323
					c-0.256-0.131-0.519-0.213-0.801-0.264c-0.278-0.05-0.562-0.067-0.843-0.074c-0.129-0.003-0.258-0.005-0.387-0.006
					c-0.153-0.001-0.307-0.001-0.46-0.001l-6.6,0v9.996h26.42v-9.996L26.985,17.005z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M41.23,17.661h1.351l2.926,8.057h-1.312l-0.737-2.177h-3.105l-0.743,2.177h-1.312L41.23,17.661z
				 M40.688,22.536h2.429l-1.2-3.54h-0.022L40.688,22.536z"
          />
          <path
            fill="#FFFFFF"
            d="M46.28,19.844h1.206v1.011h0.028c0.352-0.698,1-1.122,1.837-1.122c1.497,0,2.457,1.173,2.457,3.048v0.006
				c0,1.871-0.966,3.048-2.44,3.048c-0.832,0-1.508-0.424-1.854-1.105h-0.028v2.943H46.28V19.844z M50.58,22.787v-0.006
				c0-1.251-0.598-2.015-1.541-2.015c-0.916,0-1.558,0.793-1.558,2.015v0.006c0,1.218,0.648,2.01,1.558,2.01
				C49.987,24.797,50.58,24.026,50.58,22.787z"
          />
          <path
            fill="#FFFFFF"
            d="M52.999,19.844h1.206v1.011h0.028c0.352-0.698,0.999-1.122,1.837-1.122c1.497,0,2.457,1.173,2.457,3.048
				v0.006c0,1.871-0.966,3.048-2.44,3.048c-0.832,0-1.508-0.424-1.854-1.105h-0.028v2.943h-1.206V19.844z M57.298,22.787v-0.006
				c0-1.251-0.598-2.015-1.541-2.015c-0.916,0-1.558,0.793-1.558,2.015v0.006c0,1.218,0.648,2.01,1.558,2.01
				C56.706,24.797,57.298,24.026,57.298,22.787z"
          />
          <path fill="#FFFFFF" d="M59.59,17.661h1.207v8.057H59.59V17.661z" />
          <path
            fill="#FFFFFF"
            d="M61.97,22.803v-0.006c0-1.837,1.061-3.065,2.709-3.065s2.658,1.183,2.658,2.948v0.408h-4.159
				c0.022,1.111,0.631,1.758,1.591,1.758c0.715,0,1.19-0.368,1.341-0.809l0.017-0.045h1.144l-0.011,0.062
				c-0.189,0.932-1.095,1.781-2.518,1.781C63.015,25.835,61.97,24.657,61.97,22.803z M63.194,22.234h2.948
				c-0.101-1.016-0.67-1.513-1.457-1.513C63.902,20.721,63.299,21.251,63.194,22.234z"
          />
          <path
            fill="#FFFFFF"
            d="M71.171,17.661h1.301l1.503,6.315h0.022l1.703-6.315h1.183l1.703,6.315h0.028l1.502-6.315h1.302
				l-2.178,8.057h-1.2l-1.731-6.075H76.28l-1.737,6.075h-1.201L71.171,17.661z"
          />
          <path
            fill="#FFFFFF"
            d="M81.761,24.048v-0.011c0-1.022,0.794-1.647,2.184-1.731l1.596-0.095V21.77c0-0.647-0.419-1.039-1.167-1.039
				c-0.698,0-1.128,0.33-1.228,0.799l-0.012,0.051h-1.138l0.005-0.062c0.084-1.011,0.961-1.787,2.407-1.787
				c1.435,0,2.345,0.76,2.345,1.937v4.048h-1.212v-0.927h-0.022c-0.341,0.631-1.021,1.033-1.798,1.033
				C82.549,25.824,81.761,25.104,81.761,24.048z M84.05,24.859c0.848,0,1.491-0.575,1.491-1.341V23.06l-1.435,0.09
				c-0.725,0.044-1.128,0.362-1.128,0.859v0.012C82.978,24.534,83.403,24.859,84.05,24.859z"
          />
          <path fill="#FFFFFF" d="M88.074,17.661h1.206v8.057h-1.206V17.661z" />
          <path fill="#FFFFFF" d="M90.979,17.661h1.206v8.057h-1.206V17.661z" />
          <path
            fill="#FFFFFF"
            d="M93.245,22.803v-0.006c0-1.837,1.06-3.065,2.708-3.065c1.648,0,2.659,1.183,2.659,2.948v0.408h-4.16
				c0.022,1.111,0.631,1.758,1.592,1.758c0.715,0,1.189-0.368,1.34-0.809l0.018-0.045h1.144l-0.011,0.062
				c-0.19,0.932-1.095,1.781-2.519,1.781C94.289,25.835,93.245,24.657,93.245,22.803z M94.468,22.234h2.949
				c-0.101-1.016-0.67-1.513-1.458-1.513C95.177,20.721,94.574,21.251,94.468,22.234z"
          />
          <path
            fill="#FFFFFF"
            d="M100.142,24.171v-3.367h-0.844v-0.961h0.844v-1.53h1.233v1.53h1.1v0.961h-1.1v3.294
				c0,0.598,0.269,0.776,0.776,0.776c0.129,0,0.234-0.012,0.324-0.022v0.932c-0.14,0.022-0.368,0.05-0.614,0.05
				C100.7,25.835,100.142,25.344,100.142,24.171z"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M42.193,12.483h-2.408l-0.608,1.733h-0.793l2.232-6.054h0.747l2.232,6.054h-0.793L42.193,12.483z
					 M40.008,11.841h1.964l-0.949-2.702h-0.067L40.008,11.841z"
            />
            <path
              fill="#FFFFFF"
              d="M44.074,11.954c0-1.418,0.751-2.341,1.901-2.341c0.629,0,1.162,0.298,1.418,0.793h0.063V7.897h0.722v6.318
					h-0.688v-0.722h-0.067c-0.285,0.503-0.822,0.801-1.448,0.801C44.817,14.295,44.074,13.376,44.074,11.954z M44.821,11.954
					c0,1.057,0.495,1.691,1.322,1.691c0.822,0,1.33-0.646,1.33-1.691c0-1.036-0.512-1.691-1.33-1.691
					C45.32,10.263,44.821,10.901,44.821,11.954z"
            />
            <path
              fill="#FFFFFF"
              d="M49.342,11.954c0-1.418,0.751-2.341,1.901-2.341c0.629,0,1.162,0.298,1.418,0.793h0.063V7.897h0.722v6.318
					h-0.688v-0.722H52.69c-0.285,0.503-0.822,0.801-1.448,0.801C50.084,14.295,49.342,13.376,49.342,11.954z M50.088,11.954
					c0,1.057,0.495,1.691,1.322,1.691c0.822,0,1.33-0.646,1.33-1.691c0-1.036-0.512-1.691-1.33-1.691
					C50.587,10.263,50.088,10.901,50.088,11.954z"
            />
            <path
              fill="#FFFFFF"
              d="M58.274,8.522v1.171h1.008v0.604h-1.008v2.56c0,0.533,0.202,0.759,0.672,0.759
					c0.13,0,0.201-0.004,0.336-0.017v0.608c-0.144,0.025-0.281,0.042-0.424,0.042c-0.931,0-1.305-0.344-1.305-1.208v-2.744h-0.729
					V9.693h0.729V8.522H58.274z"
            />
            <path
              fill="#FFFFFF"
              d="M60.072,11.954c0-1.456,0.798-2.341,2.086-2.341s2.085,0.885,2.085,2.341c0,1.451-0.797,2.341-2.085,2.341
					S60.072,13.405,60.072,11.954z M63.496,11.954c0-1.074-0.483-1.691-1.339-1.691c-0.856,0-1.339,0.617-1.339,1.691
					c0,1.07,0.482,1.691,1.339,1.691C63.013,13.645,63.496,13.024,63.496,11.954z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default AddToAppleWallet;
