import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as React from 'react';
import { get } from 'lodash';
import { withProps } from 'recompose';
import { makeStyles, withStyles } from '@material-ui/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import * as MESSAGE from '../../../../../constants/message';
import { mapOpeningHours } from '../../../../ChangeDeliveryOption/pages/CollectFromPickupShop/utils';

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    background: 'transparent',
    border: 'none',
    width: '14.375rem',
    textAlign: 'center',

    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
    },

    '@media (max-width: 1024px)': {
      marginTop: '0.3125rem',
      marginLeft: 0,
      '&$expanded': {
        marginTop: '0.3125rem',
        marginLeft: 0,
      },
    },
    '@media (max-width: 599px)': {
      marginTop: '-0.625rem',
      '&$expanded:first-child': {
        marginTop: '-0.625rem',
      },
    },
    '@media (max-width: 440px)': {
      width: '100%',
    },
  },
  expanded: {
    margin: 0,
    marginLeft: '9rem',

    '@media (max-width: 1024px)': {
      marginTop: '0.3125rem',
      marginLeft: 0,
    },
  },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles(({ palette }) => ({
  root: {
    minHeight: 'unset',
    padding: 0,
    '&$expanded': {
      minHeight: 'unset',
    },
  },
  content: {
    margin: 0,
    fontSize: '0.74375rem',
    lineHeight: 1.5,
    letterSpacing: 0.3,
    color: palette.primary.main,
    '@media (max-width: 440px)': {
      justifyContent: 'center',
    },
    '&$expanded': {
      margin: 0,
      minHeight: 'unset',
    },
  },
  expandIcon: {
    padding: 0,
    marginRight: 0,
    color: palette.primary.main,
    left: '-5rem',
    '@media (max-width: 440px)': {
      left: '-3.125rem',
    },
  },
  expanded: {
    margin: 0,
    minHeight: 'unset',
  },
}))(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(({}) => ({
  root: {
    padding: 0,
    width: '100%',
  },
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  days: {
    listStyle: 'none',
    padding: 0,
    width: '100%',
    marginTop: typography.pxToRem(14),
    marginBottom: typography.pxToRem(30),
    [breakpoints.down(1025)]: {
      marginBottom: 0,
      marginTop: typography.pxToRem(9),
    },
  },
  dayItem: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: typography.pxToRem(13),
    lineHeight: 1.38,
    marginTop: typography.pxToRem(8),
  },
}));

type Props = {
  pickupLocationCode: string,
  openingHours: any,
  containerClassName: string,
};

const CustomOpeningHoursPanel = ({
  pickupLocationCode,
  openingHours,
  containerClassName,
}: Props) => {
  const listComponentRef = React.useRef({});
  const styles = useStyles();
  const [expandedMobile, setExpandedMobile] = React.useState(false);
  const [openedPanel, setOpenedPanel] = React.useState('');

  const openPanel = React.useCallback(
    id => {
      setOpenedPanel(id);
    },
    [setOpenedPanel]
  );

  const onPanelChange = (event, expanded) => {
    if (expanded) {
      openPanel(pickupLocationCode);
    } else {
      openPanel(null);
    }
  };

  const onExpandedChange = (event, expanded) => {
    if (expanded) {
      setExpandedMobile(true);
    } else {
      setExpandedMobile(false);
    }
  };

  // If no opening hours, render nothing
  if (get(openingHours, 'length', 0) === 0) {
    return null;
  }

  const openingHoursMapped = mapOpeningHours(openingHours);

  return (
    <div className={containerClassName}>
      <ExpansionPanel
        onChange={window.innerWidth < 1025 ? onExpandedChange : onPanelChange}
        expanded={
          window.innerWidth < 1025
            ? expandedMobile
            : !!window.innerWidth < 768
            ? openedPanel === pickupLocationCode
            : openedPanel
        }
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          {MESSAGE.SEE_OPENING_HOURS}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <ul className={styles.days} ref={listComponentRef}>
            {openingHoursMapped.map(({ day, time }, index) => (
              <li key={index} className={styles.dayItem}>
                <div>{day}</div> <div className={styles.time}>{time}</div>
              </li>
            ))}
          </ul>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default withProps(({ pickupShopInfo }: any) => ({
  openingHours: get(
    pickupShopInfo,
    'pickupLocation.pickupLocationAvailability.pickupLocationOpenWindow',
    []
  ),
  pickupLocationCode: get(
    pickupShopInfo,
    'pickupLocation.pickupLocationCode',
    []
  ),
}))(CustomOpeningHoursPanel);
