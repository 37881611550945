import type { AuthorizedParcel } from '@dpdgroupuk/fmx-ui/types/Parcel';
import { getPodParcelDriverCode } from '../../models/parcel';
import { getDriverData } from '../orm/Driver/selectors';
import { DriverActions } from '../orm/Driver';
import { fetchDriverFeedback } from '../orm/Driver/actions';

export const fetchRouteAndDriver = (parcel: AuthorizedParcel) => async (
  dispatch,
  getState
) => {
  const driverId = getPodParcelDriverCode(parcel);

  return (
    getDriverData(getState(), driverId) || {
      ...(await dispatch(DriverActions.fetchDriver(driverId))),
      ...(await dispatch(fetchDriverFeedback(driverId))),
    }
  );
};
