import * as React from 'react';
import Button from '@dpdgroupuk/fmx-ui/components/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  title: {
    fontSize: typography.pxToRem(20),
    marginBottom: typography.pxToRem(70),
    maxWidth: typography.pxToRem(380),
    textAlign: 'center',
    [breakpoints.down('xs')]: {
      maxWidth: typography.pxToRem(330),
      fontSize: typography.pxToRem(17),
    },
  },
}));

const TimeSlotErrorComponent = ({ onSubmit }: { onSubmit: Function }) => {
  const styles = useStyles();
  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Typography className={styles.title}>
        Unfortunately there are no times slots available on your chosen day,
        please choose a different day
      </Typography>
      <Button type="submit" onClick={onSubmit}>
        Change day
      </Button>
    </Grid>
  );
};

export default TimeSlotErrorComponent;
