import clsx from 'clsx';
import ParcelLogo from '@dpdgroupuk/fmx-ui/components/ParcelLogo';
import { Link, Typography, useTheme } from '@material-ui/core';
import Car from '@dpdgroupuk/fmx-ui/components/Icons/Car';
import Accessibility from '@dpdgroupuk/fmx-ui/components/Icons/Accessibility';
import Parking from '@dpdgroupuk/fmx-ui/components/Icons/Parking';
import Button from '@dpdgroupuk/fmx-ui/components/Button';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { withProps } from 'recompose';
import { get } from 'lodash';
import { copyDivToClipboard } from '@dpdgroupuk/fmx-ui/utils/helpers';
import CarLight from '@dpdgroupuk/fmx-ui/components/Icons/CarLight';
import AccessibilityLight from '@dpdgroupuk/fmx-ui/components/Icons/AccessibilityLight';
import ParkingLight from '@dpdgroupuk/fmx-ui/components/Icons/ParkingLight';
import OpenLate from '@dpdgroupuk/fmx-ui/components/Icons/OpenLate';
import OpenSaturday from '@dpdgroupuk/fmx-ui/components/Icons/OpenSaturday';
import OpenSunday from '@dpdgroupuk/fmx-ui/components/Icons/OpenSunday';
import { THEMES } from '@dpdgroupuk/fmx-ui/themes';

import * as MESSAGE from '../../../../../constants/message';
import TextWithCopyAction from './TextWithCopyAction';
import { getPickupAddressString } from '../../../../../models/pickupLocation';
import ArrowBadge from './ArrowBadge';
import { getShopImage } from '../../../../../utils/images';

const useStyles = makeStyles(({ palette, typography, breakpoints }) => ({
  logo: {
    height: typography.pxToRem(56),
    width: typography.pxToRem(114),
    boxSizing: 'border-box',

    [breakpoints.down('xs')]: {
      height: typography.pxToRem(48),
      width: typography.pxToRem(97),
    },
  },
  pickupCardTopWrapper: {
    alignItems: 'center',
  },
  pickupCardTopInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: typography.pxToRem(30),
    width: '100%',
  },
  flex: {
    display: 'flex',
  },
  distance: {
    fontSize: typography.pxToRem(14),
    marginLeft: typography.pxToRem(10),
    marginRight: typography.pxToRem(23),
    verticalAlign: 'middle',
    color: palette.text.primary,

    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(12),
    },
    [breakpoints.down(400)]: {
      marginRight: typography.pxToRem(10),
    },
  },
  info: {
    marginTop: typography.pxToRem(5),
    alignItems: 'center',
    [breakpoints.down(500)]: {
      marginTop: typography.pxToRem(10),
    },
  },
  logoBadge: {
    left: `calc(50% - ${typography.pxToRem(37)} / 2)`,
    top: typography.pxToRem(5),
    position: 'relative',
  },
  logoWrapper: {
    position: 'relative',
    marginTop: typography.pxToRem(12),
  },
  serviceIconMargin: {
    marginRight: typography.pxToRem(15),
    [breakpoints.down('xs')]: {
      marginRight: typography.pxToRem(10),
    },
  },
  selectButton: {
    position: 'absolute',
    top: typography.pxToRem(150),
    right: typography.pxToRem(5),
    height: typography.pxToRem(30),
    [breakpoints.down(1025)]: {
      position: 'relative',
      width: typography.pxToRem(116),
      top: 10,
      right: 0,
      left: `calc(100% - ${typography.pxToRem(147)})`,
    },
    [breakpoints.down(500)]: {
      padding: `${typography.pxToRem(11)} ${typography.pxToRem(20)}`,
      width: typography.pxToRem(96),
      left: `calc(100% - ${typography.pxToRem(107)})`,
      top: typography.pxToRem(30),
    },
    [breakpoints.down(400)]: {
      left: `calc(100% - ${typography.pxToRem(96)})`,
      top: typography.pxToRem(30),
    },
  },
  pickupCardWrapper: {
    position: 'relative',
  },
  icon: {
    color: palette.text.primary,
    height: '100%',
  },
  title: {
    color: palette.text.primary,
    [breakpoints.up('sm')]: {
      width: typography.pxToRem(430),
    },
  },
  imageKindText: {
    fontFamily: 'PlutoSansRegular',
    fontStyle: 'normal',
    fontSize: '16px',
    textAlign: 'center',
    margin: typography.pxToRem(5),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(13),
    },
  },
  copyButton: {
    fontSize: typography.pxToRem(12),
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: typography.pxToRem(0.44),
    cursor: 'pointer',
    textAlign: 'left',
    width: '100px',
    margin: `${typography.pxToRem(20)} 0 ${typography.pxToRem(20)}`,
    [breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

type Props = {
  index: number,
  pickupShopInfo: any,
  pickupShopName: string,
  onLogoClick: () => {},
  selected: boolean,
  onSelectClick: () => {},
  pointChosen: boolean,
};

const PickupShopCardShopInfo = ({
  index = 1,
  pickupShopInfo,
  pickupShopName,
  onLogoClick,
  selected,
  onSelectClick,
  pointChosen = false,
}: Props) => {
  const styles = useStyles();
  const textRef = React.useRef({});

  const [isDarkMode, setIsDarkMode] = useState(false);

  const onCopyClick = event => {
    event.stopPropagation();

    if (textRef.current) {
      copyDivToClipboard(textRef.current);
    }
  };

  const pickupShopLogo = React.useMemo(() => getShopImage(pickupShopName), [
    pickupShopName,
  ]);

  const activeTheme = useTheme();

  useEffect(() => {
    setIsDarkMode(
      [THEMES.RED_DARK, THEMES.BLUE_DARK].includes(activeTheme.name)
    );
  }, [activeTheme.name]);

  return (
    <div className={clsx(styles.flex, styles.pickupCardTopWrapper)}>
      <div className={styles.logoWrapper} onClick={onLogoClick}>
        {!pointChosen && (
          <ArrowBadge
            className={styles.logoBadge}
            color={selected ? 'blue' : 'red'}
          >
            {index + 1}
          </ArrowBadge>
        )}
        <ParcelLogo
          image={pickupShopLogo}
          className={styles.logo}
          shouldFillContainer
        />
        <Typography className={styles.imageKindText}>
          {pickupShopInfo.pickupLocation.kind === 'LKR' ? 'Locker' : 'Shop'}
        </Typography>
      </div>
      <div className={styles.pickupCardTopInfo}>
        <TextWithCopyAction className={styles.title}>
          <span ref={textRef}>
            {getPickupAddressString(pickupShopInfo.pickupLocation.address)}
          </span>
        </TextWithCopyAction>
        <div className={clsx(styles.flex, styles.info)}>
          <div className={styles.flex}>
            <div className={styles.flex}>
              {pickupShopInfo.distance && (
                <>
                  {isDarkMode ? (
                    <CarLight className={styles.icon} id="distance" />
                  ) : (
                    <Car className={styles.icon} id="distance" />
                  )}
                  <Typography className={styles.distance}>
                    {pickupShopInfo.distance.toFixed(2)} {MESSAGE.MILES}
                  </Typography>
                </>
              )}
            </div>
            {pickupShopInfo.pickupLocation.disabledAccess &&
              (isDarkMode ? (
                <AccessibilityLight
                  className={clsx(styles.icon, styles.serviceIconMargin)}
                  id="disabled-access"
                />
              ) : (
                <Accessibility
                  className={clsx(styles.icon, styles.serviceIconMargin)}
                  id="disabled-access"
                />
              ))}
            {pickupShopInfo.pickupLocation.parkingAvailable &&
              (isDarkMode ? (
                <ParkingLight
                  className={clsx(styles.icon, styles.serviceIconMargin)}
                  id="parking-available"
                />
              ) : (
                <Parking
                  className={clsx(styles.icon, styles.serviceIconMargin)}
                  id="parking-available"
                />
              ))}
            {pickupShopInfo.pickupLocation.openLate && (
              <OpenLate
                className={clsx(styles.icon, styles.serviceIconMargin)}
              />
            )}
            {pickupShopInfo.pickupLocation.openSaturday && (
              <OpenSaturday
                className={clsx(styles.icon, styles.serviceIconMargin)}
              />
            )}
            {pickupShopInfo.pickupLocation.openSunday && (
              <OpenSunday
                className={clsx(styles.icon, styles.serviceIconMargin)}
              />
            )}
          </div>
        </div>
        <div className={styles.flex}>
          <Link
            component="button"
            onClick={onCopyClick}
            className={styles.copyButton}
          >
            {MESSAGE.COPY}
          </Link>
        </div>
        {!pointChosen && (
          <Button
            onClick={onSelectClick}
            classes={{ root: styles.selectButton }}
          >
            {MESSAGE.CONFIRM}
          </Button>
        )}
      </div>
    </div>
  );
};

export default withProps(({ pickupShopInfo }: any) => ({
  pickupShopName:
    get(pickupShopInfo, 'pickupLocation, shortName') ||
    get(pickupShopInfo, 'pickupLocation.address.organisation'),
}))(PickupShopCardShopInfo);
