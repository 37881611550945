import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useTracker } from '@dpdgroupuk/react-event-tracker';

import { isAuthorizedParcel } from '../../models/parcel';

import DeliveryOptions from '../DeliveryOptions';
import DriverProfile from '../DriverProfile';
import ParcelEvents from '../ParcelEvents';
import TrackingFull from '../Tracking/components/TrackingFull';
import TrackingLight from '../Tracking/pages/LightViewTracking';
import WhatHappensNext from '../WhatHappensNext/components/WhatHappensNext';
import { getLocationTracking } from './actions/location';
import { ROUTES } from './constants';
import { getSessionId } from '../../utils/session';
import RateExperience from '../RateExperience';

type ProtectedRouteProps = {
  parcel: any,
  path: string,
  component: any,
  publicComponent?: any,
};

const ProtectedRoute = ({
  parcel,
  path,
  component,
  publicComponent,
  ...props
}: ProtectedRouteProps) => {
  const isAuthorized = isAuthorizedParcel(parcel);
  const anotherComponent =
    publicComponent ||
    (() => (
      <Redirect to={getLocationTracking({ parcelId: parcel.parcelCode })} />
    ));

  return (
    <Route
      path={path}
      component={isAuthorized ? component : anotherComponent}
      {...props}
    />
  );
};

type RouteProps = {
  parcel: any,
};

const Routes = ({ parcel }: RouteProps) => {
  const tracker = useTracker();

  React.useEffect(() => {
    tracker.setContext({
      parcelCode: parcel.parcelCode,
      sessionId: getSessionId(),
    });

    return () => {
      tracker.setContext({
        parcelCode: null,
        sessionId: null,
      });
    };
  }, [parcel.parcelCode, tracker]);

  return (
    <Switch>
      <ProtectedRoute
        parcel={parcel}
        path={ROUTES.DELIVERY_OPTIONS}
        component={DeliveryOptions}
      />
      <ProtectedRoute
        path={ROUTES.DRIVER_PROFILE}
        parcel={parcel}
        component={DriverProfile}
      />
      <ProtectedRoute
        path={ROUTES.WHAT_HAPPENS_NEXT}
        component={WhatHappensNext}
        parcel={parcel}
      />
      <ProtectedRoute
        path={ROUTES.EVENTS}
        parcel={parcel}
        component={ParcelEvents}
      />
      <ProtectedRoute
        parcel={parcel}
        path={ROUTES.RATE}
        component={RateExperience}
      />
      <ProtectedRoute
        path={ROUTES.TRACKING}
        parcel={parcel}
        component={TrackingFull}
        publicComponent={TrackingLight}
      />
      <Route
        children={() => (
          <Redirect to={getLocationTracking({ parcelId: parcel.parcelCode })} />
        )}
      />
    </Switch>
  );
};

export default Routes;
