import { debounce } from 'lodash';

import api from './api';

export const getDeliveryActions = debounce(
  (parcelCode: string) => {
    return api.get({
      path: `/parcels/${parcelCode}/actions`,
    });
  },
  1000,
  { leading: true, trailing: false }
);

export const getActionsDates = (parcelCode: string, actionCode: string) => {
  return api.get({
    path: `/parcels/${parcelCode}/actions/${actionCode}/dates`,
  });
};

export type ChangeDeliveryOptionOptions = {
  token: string,
  actionCode: string,
  createProfileLink: boolean,
};

export const changeDeliveryOption = (
  parcelCode: string,
  body: any,
  { actionCode, createProfileLink }: ChangeDeliveryOptionOptions
): Promise<any> => {
  return api.post({
    path: `/parcels/${parcelCode}/actions/${actionCode.toLowerCase()}`,
    query: { createProfileLink },
    body,
  });
};

export const getAlternativeTimes = (parcelCode: string, diaryDate: string) => {
  return api.get({
    path: `/parcels/${parcelCode}/dates/${encodeURIComponent(diaryDate)}/times`,
  });
};

export const getPreciseTimes = (
  parcelCode: string,
  date: string,
  postcode: string,
  businessUnit: string
) => {
  return api.get({
    path: `/parcels/${parcelCode}/slots`,
    query: { date, postcode, businessUnit },
  });
};

export const getPaymentToken = (parcelCode: string) =>
  api.get({
    path: `/parcels/${parcelCode}/GenerateBrainTreeToken`,
  });

export const getDutyPaymentToken = (parcelCode: string) =>
  api.get({
    path: `/parcels/${parcelCode}/GenerateDutyBrainTreeToken`,
  });
