import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOverlay } from '@dpdgroupuk/fmx-ui/components/Overlay';

import * as parcelSelectors from '../../store/selectors/searchParcels';
import { fetchSearchParcelByReferenceNumber } from '../../store/actions/parcels';

export const useSearchParcels = ({ query }: { query: Object }) => {
  const dispatch = useDispatch();

  const { showWhile } = useOverlay();
  const parcelData = useSelector(parcelSelectors.selectParcelsData);

  return useCallback(
    showWhile(async () => {
      const { reference, postcode } = query;

      if (parcelData.length) {
        return Promise.resolve(parcelData);
      }

      return dispatch(fetchSearchParcelByReferenceNumber(reference, postcode));
    }),
    [query, parcelData, dispatch]
  );
};
