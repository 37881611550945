import { StatusCodes } from 'http-status-codes';
import {
  HOME,
  CLOSE_TO_TRY_AGAIN,
  NOT_AUTHORISED_MESSAGE,
  REQUEST_TIMEOUT_MESSAGE,
  PROBLEM_COMPLITING_REQUEST,
  RATE_CONFLICT_MESSAGE,
  CLOSE,
  PRECISE_SLOT_FULL,
  PAGE_NOT_FOUND_MESSAGE,
  CONFLICT,
} from '../constants/error';
import sentry from '../services/sentry';
import { goToReferenceEntry } from '../pages/historyActions';
import { MESSAGING, INSTALLATIONS } from '../constants/firebaseErrorCode';

export const catchRequestError = ({ error, modal, history }) => {
  switch (error.statusCode) {
    case StatusCodes.FORBIDDEN:
      return modal.showModal({
        title: NOT_AUTHORISED_MESSAGE,
        description: null,
        buttonText: HOME,
        onConfirm: () => {
          modal.hideModal();
          goToReferenceEntry(history);
        },
      });
    case StatusCodes.REQUEST_TIMEOUT:
      return modal.showModal({
        title: REQUEST_TIMEOUT_MESSAGE,
        buttonText: CLOSE_TO_TRY_AGAIN,
        description: null,
      });
    case StatusCodes.CONFLICT:
      return modal.showModal({
        title: RATE_CONFLICT_MESSAGE,
        buttonText: CLOSE,
        description: null,
      });
    case StatusCodes.PRECONDITION_FAILED:
      return modal.showModal({
        title: PRECISE_SLOT_FULL,
        buttonText: CLOSE,
        description: null,
      });
    default: {
      // log in sentry only if it's unknown error
      sentry.captureException(error);
      modal.showModal({
        title: PROBLEM_COMPLITING_REQUEST,
        buttonText: CLOSE_TO_TRY_AGAIN,
        description: null,
      });
    }
  }
};

export const getStatusMessage = status => {
  switch (parseInt(status)) {
    case StatusCodes.UNAUTHORIZED:
    case StatusCodes.FORBIDDEN:
      return NOT_AUTHORISED_MESSAGE;
    case StatusCodes.NOT_FOUND:
      return PAGE_NOT_FOUND_MESSAGE;
    case StatusCodes.REQUEST_TIMEOUT:
      return REQUEST_TIMEOUT_MESSAGE;
    case StatusCodes.CONFLICT:
      return CONFLICT;
    case StatusCodes.PRECONDITION_FAILED:
      return PRECISE_SLOT_FULL;
    default: {
      return PROBLEM_COMPLITING_REQUEST;
    }
  }
};

export const isIgnorableFirebaseError = e =>
  [
    MESSAGING.PERMISSION_BLOCKED,
    INSTALLATIONS.APP_OFFLINE,
    MESSAGING.UNSUPPORTED_BROWSER,
  ].some(err => err === e.code);

export const handle404Error = error => {
  if (error && error.statusCode === StatusCodes.NOT_FOUND) {
    return [];
  }
  throw new Error(error);
};
