import firebase from 'firebase/app';
import 'firebase/remote-config';
import 'firebase/analytics';
import 'firebase/messaging';
import storage from 'local-storage-fallback';
import { postPushToken, deletePushToken } from '../apis/messaging';
import { getParcelCodeFromString } from '../models/parcel';
import { UNSUPPORTED_BROWSER } from '../constants/firebaseErrorCode';
import { noop } from './function';

const {
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_MESSAGING_ID,
  REACT_APP_PUBLIC_VAPID_KEY,
  REACT_APP_FIREBASE_REMOTE_CONFIG_FETCH_INTERVAL,
} = process.env;

firebase.initializeApp({
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  apiKey: REACT_APP_FIREBASE_API_KEY,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_ID,
});

const REMOTE_CONFIG_SETTINGS_OVERRIDE = {
  minimumFetchIntervalMillis: parseInt(
    REACT_APP_FIREBASE_REMOTE_CONFIG_FETCH_INTERVAL
  ),
};

export const initializeRemoteConfig = () => {
  const remoteConfig = firebase.remoteConfig();
  remoteConfig.settings = {
    ...remoteConfig.settings,
    ...REMOTE_CONFIG_SETTINGS_OVERRIDE,
  };
  return remoteConfig;
};
let messaging = null;

const createFirebaseUnsupportedBrowser = moduleName => {
  const error = new Error(`Browser doesn't support ${moduleName}`);
  error.code = UNSUPPORTED_BROWSER(moduleName);
  return error;
};

export const isUnsupportedBrowserError = err =>
  (err.code || '').indexOf('unsupported-browser') > -1;

export const createMessagingHandler = fn => async (...args) => {
  if (!messaging && isMessagingSupported()) {
    messaging = firebase.messaging();
  }
  if (!messaging) {
    throw createFirebaseUnsupportedBrowser('messaging');
  }
  return fn(...args);
};

const initializeMessaging = createMessagingHandler(() => {
  messaging.usePublicVapidKey(REACT_APP_PUBLIC_VAPID_KEY);

  messaging.onTokenRefresh(async () => {
    const currentToken = await messaging.getToken();
    const parcelCode = getParcelCodeFromString(window.location.pathname);
    if (parcelCode && currentToken) {
      setTokenSentToServer(parcelCode, false);
      await postPushToken(parcelCode, currentToken);
      setTokenSentToServer(parcelCode, true);
    }
  });
});

initializeMessaging().catch(noop);

export { messaging };

export function requestPermission() {
  return Notification.requestPermission().then(permission => {
    if (permission !== 'granted') {
      throw new Error('Unable to get permission to notify.');
    }
  });
}

export function isTokenSentToServer(parcelCode) {
  return storage.getItem(`pushTokenSentToServer:${parcelCode}`) === '1';
}

export function setTokenSentToServer(parcelCode, sent) {
  storage.setItem(`pushTokenSentToServer:${parcelCode}`, sent ? '1' : '0');
}

export function isMessagingSupported() {
  return firebase.messaging.isSupported() && Notification;
}

export const requestToken = createMessagingHandler(async parcelCode => {
  let currentToken = await messaging.getToken();
  if (!currentToken) {
    setTokenSentToServer(parcelCode, false);
    await requestPermission();
    currentToken = await messaging.getToken();
  }

  await postPushToken(parcelCode, currentToken);
  setTokenSentToServer(parcelCode, true);
});

export async function deleteToken(parcelCode) {
  await deletePushToken(parcelCode);
  setTokenSentToServer(parcelCode, false);
}

export default firebase;
