import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import { Loadable } from '@dpdgroupuk/fmx-ui/components/Loader';
import { useTracker } from '@dpdgroupuk/react-event-tracker';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import type { SearchParcel } from '@dpdgroupuk/fmx-ui/types';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes/constants';
import { useGoogleReCaptcha } from '@dpdgroupuk/react-google-recaptcha-v3';
import { isMobile } from '@dpdgroupuk/fmx-ui/utils/helpers';

import SearchResultsMobileTable from './SearchResultsMobileTable';
import { SEARCH_RESULTS_LIST, trackable } from '../../../constants/analytics';
import SearchResultsTable from './SearchResultsTable';
import { useSearchParcels } from '../hooks';
import { login } from '../../../store/actions/parcels';
import {
  backToDpdSiteHeader,
  getDpdAppHeader,
  backToDpdSiteSpeedDeal,
  trackAnotherParcelHeader,
  getDpdAppSpeedDeal,
  backToGreenSiteSpeedDeal,
  backToGreenSiteHeader,
} from '../../controlActions';
import { getLocationTracking } from '../../Parcel/actions/location';
import { getBackgroundImage } from '../../../utils/images';
import ErrorScreen from '../../ErrorScreen';
import { ACTION } from '../../../constants/recaptcha';
import { MORE_COOL_STUFF } from '../../../constants/message';

const useStyles = makeStyles(({ breakpoints, typography, palette }) => ({
  container: {
    [breakpoints.down('sm')]: {
      paddingTop: typography.pxToRem(110),
    },
    paddingTop: typography.pxToRem(166),
  },
  title: {
    [breakpoints.down('sm')]: {
      fontSize: typography.pxToRem(16),
      paddingBottom: typography.pxToRem(30),
      paddingLeft: 0,
    },
    fontSize: typography.pxToRem(20),
    letterSpacing: 0.44,
    color: palette.text.secondary,
    paddingLeft: typography.pxToRem(30),
  },
}));

type Props = {
  parcels: Array<SearchParcel>,
};

let SearchResultsContent = ({ parcels }: Props) => {
  const styles = useStyles();
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { executeRecaptchaAsync } = useGoogleReCaptcha();
  const query = queryString.parse(search);

  const tracker = useTracker();
  const onSelectParcel = useCallback(
    async parcelCode => {
      if (query.postcode) {
        const token = await executeRecaptchaAsync(ACTION.REFERENCE);
        await dispatch(login(parcelCode, query.postcode, token));
      }
      history.push(getLocationTracking({ parcelId: parcelCode }));
    },
    [query, history, dispatch, executeRecaptchaAsync]
  );

  const onSelectParcelTrackable = React.useCallback(
    (...args) => {
      tracker.logEvent(SEARCH_RESULTS_LIST.SUBMIT);
      return onSelectParcel(...args);
    },
    [onSelectParcel, tracker]
  );

  return (
    <Container maxWidth="md" className={styles.container}>
      <Typography className={styles.title}>
        Click on a parcel number to view more details
      </Typography>
      {isMobile ? (
        <SearchResultsMobileTable
          parcels={parcels}
          onSelectParcel={onSelectParcelTrackable}
        />
      ) : (
        <SearchResultsTable
          parcelData={parcels}
          onSelectParcel={onSelectParcelTrackable}
        />
      )}
    </Container>
  );
};

const createControlActions = props => [
  trackAnotherParcelHeader(props),
  backToDpdSiteHeader(props),
  backToGreenSiteHeader(props),
  getDpdAppHeader(props),
];

const createSpeedDealControlActions = props => [
  backToDpdSiteSpeedDeal(props),
  getDpdAppSpeedDeal(props),
  backToGreenSiteSpeedDeal(props),
];

SearchResultsContent = withPageControl({
  title: MORE_COOL_STUFF,
  backgroundImage: getBackgroundImage({
    desktopImage: IMAGES.DRIVER_BG,
    mobileImage: IMAGES.DRIVER_BG_MOBILE,
  }),
  backgroundOpacity: 0.87,
  trackable: trackable(SEARCH_RESULTS_LIST),
  headerMenuActions: createControlActions,
  speedDealActions: createSpeedDealControlActions,
  // isMobileMenuShevronVisible: true,
})(SearchResultsContent);

const SearchResultsPage = () => {
  const { search } = useLocation();
  const query = queryString.parse(search);
  const searchParcels = useSearchParcels({ query });

  return (
    <Loadable
      asyncDataProps={{
        promiseFn: searchParcels,
      }}
      errorScreen={ErrorScreen}
    >
      {data => <SearchResultsContent parcels={data} />}
    </Loadable>
  );
};

export default SearchResultsPage;
