import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import clsx from 'clsx';
import { reduxForm, Field } from 'redux-form';
import { InputFormField } from '@dpdgroupuk/fmx-ui/components/Input';
import Button from '@dpdgroupuk/fmx-ui/components/Button';
import {
  required,
  validatePostcodeWithMessage,
} from '@dpdgroupuk/fmx-ui/utils/validation';
import CircularProgress from '@material-ui/core/CircularProgress';

import AddressComponent from './AddressComponent';
import { Address } from './types';

const useStyles = makeStyles(({ palette, breakpoints, typography }) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: typography.pxToRem(20),
    paddingBottom: typography.pxToRem(40),
    [breakpoints.down('xs')]: {
      paddingBottom: typography.pxToRem(10),
    },
  },
  address: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actions: {
    width: typography.pxToRem(300),
    marginBottom: typography.pxToRem(35),
    marginTop: typography.pxToRem(30),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  searchAddressContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  postcode: {
    marginRight: typography.pxToRem(20),
  },
  title: {
    fontSize: typography.pxToRem(21),
    lineHeight: 1.5,
    letterSpacing: 0.44,
    marginTop: typography.pxToRem(30),
    fontWeight: 300,
    color: palette.text.redWhite,
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(18),
    },
  },
  subtitle: {
    maxWidth: typography.pxToRem(300),
    textAlign: 'center',
    color: '#b7b9bd',
    [breakpoints.down('sm')]: {
      fontSize: typography.pxToRem(15),
    },
  },
  error: {
    marginTop: typography.pxToRem(5),
    textTransform: 'uppercase',
    color: palette.error.secondary,
    fontSize: 11,
    opacity: 0.75,
    letterSpacing: 0.2,
    width: 'inherit',
  },
  gridList: {
    display: 'block',
    paddingLeft: typography.pxToRem(30),
    paddingRight: typography.pxToRem(30),
    [breakpoints.up('sm')]: {
      height: typography.pxToRem(300),
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: 'rgba(170, 170, 170, 0.3) transparent',
      '-ms-overflow-style': '-ms-autohiding-scrollbar',
      '&::-webkit-scrollbar': {
        width: typography.pxToRem(4),
        borderRadius: typography.pxToRem(10),
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(170, 170, 170, 0.3)',
        borderRadius: typography.pxToRem(10),
      },
    },
    [breakpoints.down('sm')]: {
      height: typography.pxToRem(200),
    },
  },
}));

type Props = {
  addresses: Address[],
  selectedAddress: Address,
  valid: Boolean,
  onSelect: (address: Address) => void,
  onSubmit: (postcode: string) => void,
  handleSubmit: Function,
  onChange: Function,
  error: Object,
  submitting: boolean,
};

const validatePostcode = [required(), validatePostcodeWithMessage];

const SearchAddress = ({
  onSelect,
  addresses,
  onSubmit,
  handleSubmit,
  valid,
  error,
  onChange,
  selectedAddress,
  submitting,
}: Props) => {
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <Typography className={styles.title}>Delivery address</Typography>
      <Typography className={clsx(styles.title, styles.subtitle)}>
        Enter your postcode to set your delivery address
      </Typography>
      <Grid className={styles.actions}>
        <div className={styles.searchAddressContainer}>
          <Field
            component={InputFormField}
            name="postcode"
            placeholder="Postcode"
            validate={validatePostcode}
            className={styles.postcode}
            onChange={onChange}
          />
          <Button onClick={handleSubmit(onSubmit)} disabled={!valid}>
            Search
          </Button>
        </div>
        {error && <Typography className={styles.error}>{error}</Typography>}
      </Grid>
      {submitting && <CircularProgress />}
      {addresses && (
        <GridList className={styles.gridList}>
          {addresses.map((address: Address, key: number) => (
            <AddressComponent
              key={key}
              address={address}
              onSelect={onSelect}
              isActive={
                !!selectedAddress && address.udprn === selectedAddress.udprn
              }
            />
          ))}
        </GridList>
      )}
    </div>
  );
};

export default reduxForm({ form: 'alternateAddressForm' })(SearchAddress);
