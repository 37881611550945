// @flow
import React from 'react';
import { withProps } from 'recompose';
import { compose } from 'redux';
import moment from 'moment-timezone';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withTrack, withTrackProps } from '@dpdgroupuk/react-event-tracker';
import { formatMessage } from '@dpdgroupuk/fmx-ui//utils/string';
import { formatReadableDate } from '@dpdgroupuk/fmx-ui/utils/date';
import CallMePhoneIcon from '@dpdgroupuk/fmx-ui/components/Icons/CallMePhoneIcon';

import Button from '../../../components/Rate/Button';
import ContactNumberInputForm from '../../../components/Rate/ContactNumberInputForm';
import * as MESSAGE from '../../../constants/message';
import { RATE_STEPS } from '../steps';
import {
  RATE_DELIVERY_CALL_ME_CONTACT_NUMBER,
  trackable,
} from '../../../constants/analytics';

const useStyles = makeStyles(({ typography, palette, breakpoints }) => ({
  changeSlotButton: {
    marginTop: typography.pxToRem(20),
  },
  teamWillCallText: {
    fontSize: typography.pxToRem(20),
    letterSpacing: typography.pxToRem(0.7),
    lineHeight: 1.6,
    color: palette.text.primary,
    marginTop: typography.pxToRem(30),
    maxWidth: '60%',
    [breakpoints.down('xs')]: {
      marginTop: typography.pxToRem(47),
      maxWidth: '80%',
    },
  },
  callMePhoneIcon: {
    height: typography.pxToRem(63),
    width: typography.pxToRem(63),
  },
  contactNumberBlock: {
    marginTop: typography.pxToRem(30),
  },
}));

const formatTime = time => {
  return moment(time, ['hA']).format('H:mm');
};

type Props = {
  parcel: AuthorizedParcel,
  data: Object,
  onSubmit: Function,
  onChangeSlot: Function,
};

const RateDeliveryCallMeContactNumber = ({
  parcel,
  data,
  onSubmit,
  onChangeSlot,
}: Props) => {
  const styles = useStyles();
  const {
    deliveryDetails: {
      notificationDetails: { mobile },
    },
  } = parcel;
  const { selectedTime, selectedDate } = data;

  return (
    <Grid>
      <CallMePhoneIcon className={styles.callMePhoneIcon} />

      <Typography className={styles.teamWillCallText}>
        {formatMessage(
          MESSAGE.TEAM_WILL_CALL,
          `${formatTime(selectedTime.startTime)} and ${formatTime(
            selectedTime.endTime
          )}`,
          formatReadableDate(selectedDate)
        )}
      </Typography>

      <Button className={styles.changeSlotButton} onClick={onChangeSlot}>
        {MESSAGE.CHANGE_SLOT}
      </Button>

      <div className={styles.contactNumberBlock}>
        <ContactNumberInputForm
          onSubmit={onSubmit}
          originalContactNumber={mobile}
        />
      </div>
    </Grid>
  );
};

export default compose(
  withProps<any, any>(({ goToStep, submitWizard, data }: any) => ({
    onSubmit: contactNumber =>
      submitWizard({
        stepData: {
          ...data,
          contactNumber,
          bookCallMe: true,
          selectedTime: data.selectedTime.key,
        },
      }),
    onChangeSlot: () => goToStep(RATE_STEPS.RATE_DELIVERY_CALL_ME_SLOT_SELECT),
  })),
  withTrack(trackable(RATE_DELIVERY_CALL_ME_CONTACT_NUMBER)),
  withTrackProps({
    onSubmit: RATE_DELIVERY_CALL_ME_CONTACT_NUMBER.ON_SUBMIT,
    onChangeSlot: RATE_DELIVERY_CALL_ME_CONTACT_NUMBER.ON_CHANGE_SLOT,
  })
)(RateDeliveryCallMeContactNumber);
