import * as React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import MaterialTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';

import {
  Accessibility,
  Parking,
  Car,
} from '@dpdgroupuk/fmx-ui/components/Icons';
import type { PickupLocation } from '@dpdgroupuk/fmx-ui/types';
import VoucherLogo from '@dpdgroupuk/fmx-ui/components/VoucherLogo';
import { getStreetWithPostcode } from '@dpdgroupuk/fmx-ui/utils/string';

import { openTimeList } from '../../../../../utils/format';
import { getShopImage } from '../../../../../utils/images';

const useStyles = makeStyles(({ palette, typography, breakpoints }) => ({
  dialogContainer: {
    maxWidth: typography.pxToRem(375),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: typography.pxToRem(22),
    position: 'relative',
    marginBottom: typography.pxToRem(50),
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: palette.border.iconBlock,
  },
  closeIcon: {
    fill: palette.icon.blockIcon,
    fontSize: typography.pxToRem(22),
  },
  cell: {
    fontSize: typography.pxToRem(14),
    fontWeight: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    padding: `${typography.pxToRem(5)} ${typography.pxToRem(20)}`,
    borderBottom: 0,
    color: '#808285',
  },
  alignRight: {
    textAlign: 'right',
  },
  row: {
    height: typography.pxToRem(20),
  },
  infoContainer: {
    [breakpoints.down('xs')]: {
      maxWidth: typography.pxToRem(110),
    },
    [breakpoints.up('sm')]: {
      maxWidth: typography.pxToRem(190),
    },
  },
  blueIcon: {
    color: '#1d9ad0',
    fontSize: typography.pxToRem(20),
    marginLeft: typography.pxToRem(5),
  },
  borderContainer: {
    borderBottom: `${typography.pxToRem(1)} solid rgba(230, 231, 232, 0.47)`,
    padding: typography.pxToRem(18),
    paddingBottom: typography.pxToRem(10),
    marginBottom: typography.pxToRem(5),
  },
  addressText: {
    fontSize: typography.pxToRem(13),
    letterSpacing: 0.44,
    lineHeight: 1.92,
    color: '#b7b9bd',
    textAlign: 'left',
  },
  voucher: {
    alignSelf: 'flex-start',
    marginRight: typography.pxToRem(30),
    marginBottom: typography.pxToRem(10),
  },
  titleText: {
    fontSize: typography.pxToRem(14),
    letterSpacing: 0.44,
    lineHeight: 1.92,
  },
}));

type Props = {
  pickupLocation: PickupLocation,
};

const ShopDetails = ({
  pickupLocation: {
    pickupLocationAvailability,
    disabledAccess,
    parkingAvailable,
    shortName,
    address,
  },
}: Props) => {
  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  const timeList = openTimeList(
    pickupLocationAvailability.pickupLocationOpenWindow
  );
  const shopAdress = getStreetWithPostcode(address);
  const styles = useStyles();
  const { goBack } = useHistory();
  return (
    <div className={styles.dialogContainer}>
      <IconButton
        aria-label="close"
        size="small"
        className={styles.closeButton}
        onClick={goBack}
      >
        <CloseIcon className={styles.closeIcon} />
      </IconButton>
      <Grid>
        <Grid className={styles.borderContainer}>
          <Grid container direction="row" alignItems="center">
            <Grid className={styles.voucher}>
              <VoucherLogo logo={getShopImage(address.organisation)} />
            </Grid>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-start"
              className={styles.infoContainer}
            >
              <Typography className={styles.titleText}>
                {shortName || address.organisation}
              </Typography>
              <Typography className={styles.addressText}>
                {shopAdress}
              </Typography>
              <Grid container direction="row">
                <Typography className={styles.addressText}>
                  {address.postcode}
                </Typography>
                <Car className={styles.blueIcon} />
                {disabledAccess && (
                  <Accessibility className={styles.blueIcon} />
                )}
                {parkingAvailable && <Parking className={styles.blueIcon} />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <MaterialTable>
          <TableBody>
            {timeList.map((element, i) => {
              return (
                <TableRow classes={{ root: styles.row }} key={i}>
                  <TableCell className={styles.cell}>{days[i]}</TableCell>
                  <TableCell className={clsx(styles.cell, styles.alignRight)}>
                    {element}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </MaterialTable>
      </Grid>
    </div>
  );
};

export default ShopDetails;
