import React from 'react';
import { withProps } from 'recompose';
import { compose } from 'redux';

import ContactDetailsFormNoEmail from './SMSAuthContactDetailsForm';
import { getInitialValues } from '../helpers/getContactDetails';

type Props = {
  parcel: Object,
  data: Object,
};

const GetContactDetailsNoEmailStep = (props: Props) => {
  return <ContactDetailsFormNoEmail {...props} />;
};

export default compose(
  withProps(
    ({ parcel, addStepData, submitWizard, goToStep, nextStep, data }) => ({
      title:
        'Provide your contact information and we will keep you updated on any changes to your delivery',
      initialValues: getInitialValues(parcel, data),
      onChange: ({ contactName, contactPhone }) => {
        addStepData({
          contactName,
          contactPhone,
        });
      },
      onSubmit: nextStep
        ? () => {
            goToStep(nextStep);
          }
        : submitWizard, // If the next step is provided, go to it. If not then submit the wizard
    })
  )
)(GetContactDetailsNoEmailStep);
