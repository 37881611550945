import { getParcelNotificationDetails } from '../../../models/parcel';

export const getInitialValues = (parcel, data) => {
  const notiDetails = getParcelNotificationDetails(parcel);
  return {
    contactName: (data && data.contactName) || notiDetails.contactName, // Take info from data, if no data then take from parcel notification
    contactPhone: (data && data.contactPhone) || notiDetails.mobile,
  };
};

export const setReadOnlyFieldsValue = (values, setFunc) => {
  const { contactName, contactPhone } = values;
  setFunc(!!contactName && !!contactPhone);
};
