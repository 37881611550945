import * as React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import DeliveryInfo from '@dpdgroupuk/fmx-ui/components/DeliveryInfo';
import { ParcelOptionIcon } from '@dpdgroupuk/fmx-ui/components/OptionIcon';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { getAddressWithPostcode } from '@dpdgroupuk/fmx-ui/utils/address';
import { formatOrdinalNumber } from '@dpdgroupuk/fmx-ui/utils/string';
import { fullDateValue } from '@dpdgroupuk/fmx-ui/utils/date';

import TrackAnotherParcelButton from '../../components/TrackAnotherParcelButton';
import {
  backToDpdSiteHeader,
  backToGreenSiteHeader,
  getDpdAppHeader,
  goToParcelInfoHeader,
  goToParcelInfoSpeedDeal,
  goBackToDeliveryDetailsSpeedDeal,
  trackAnotherParcelHeader,
  trackAnotherParcelSpeedDeal,
} from '../../../controlActions';
import type { AuthorizedParcel } from '../../../../types';
import {
  DELIVERY_OPTION_CONFIRMATION,
  trackable,
} from '../../../../constants/analytics';
import CompleteParcelNumber from '../../components/CompleteParcelNumber';

type Props = {
  actionCode?: string,
  data?: Object,
  parcel: AuthorizedParcel,
};

const CompleteStepComponent = ({
  actionCode,
  data = { address: '', day: '' },
  parcel,
}: Props) => {
  const { day, address } = data;
  const { year, month, dayOfWeek, dayNumber } = fullDateValue(
    day || parcel.estimatedDeliveryDate
  );
  const title =
    'As requested, your parcel will now be delivered to your neighbour';
  const subTitle = (
    <>
      {`${getAddressWithPostcode(address)}`}
      {(day || parcel.estimatedDeliveryDate) && (
        <>
          <br />
          on {dayOfWeek} the {formatOrdinalNumber(dayNumber)} {month} {year}
        </>
      )}
    </>
  );

  return (
    <>
      <DeliveryInfo
        actionCode={actionCode}
        title={title}
        subTitle={subTitle}
        getOptionIcon={() => ParcelOptionIcon}
      />
      <CompleteParcelNumber parcelNumber={parcel.parcelNumber} />
      <TrackAnotherParcelButton />
    </>
  );
};

const createSpeedDealControlActions = props => [
  trackAnotherParcelSpeedDeal({ ...props, replace: true }),
  goToParcelInfoSpeedDeal({ ...props, replace: true }),
  goBackToDeliveryDetailsSpeedDeal(props),
];

const createHeaderControlActions = props => [
  trackAnotherParcelHeader({ ...props, replace: true }),
  goToParcelInfoHeader({ ...props, replace: true }),
  backToDpdSiteHeader(props),
  backToGreenSiteHeader(props),
  getDpdAppHeader(props),
];

const CompleteStep = compose(
  withRouter,
  withModal,
  withPageControl({
    trackable: trackable(DELIVERY_OPTION_CONFIRMATION),
    speedDealActions: createSpeedDealControlActions,
    headerMenuActions: createHeaderControlActions,
  })
)(CompleteStepComponent);

export default CompleteStep;
