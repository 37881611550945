import * as React from 'react';
import { compose } from 'redux';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ErrorOutline } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

import type { AuthorizedParcel, Parcel } from '@dpdgroupuk/fmx-ui/types/Parcel';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import * as ENUM from '@dpdgroupuk/redback-enums';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';
import { separateSentence } from '@dpdgroupuk/fmx-ui/utils/string';

import {
  ESTIMATED_DATE_TIME,
  trackable,
} from '../../../../../constants/analytics';
import TrackingStatus from '../../../../../components/TrackingStatus';
import {
  backToDpdSiteHeader,
  changeDeliveryHeader,
  getDpdAppHeader,
  goToParcelInfoHeader,
  goToParcelInfoSpeedDeal,
  goBackToDeliveryDetailsSpeedDeal,
  trackAnotherParcelHeader,
  whatHappensNextHeader,
  whatHappensNextSpeedDeal,
} from '../../../../controlActions';
import * as MESSAGE from '../../../../../constants/message';
import { withParcelInfo } from '../../../hocs';

const useStyles = makeStyles(({ breakpoints, typography }) => ({
  text: {
    fontSize: typography.pxToRem(15),
    fontWeight: 400,
    lineHeight: 1.53,
    letterSpacing: 0.44,
    textAlign: 'center',
    [breakpoints.down('xs')]: {
      marginTop: typography.pxToRem(28),
      maxWidth: typography.pxToRem(306),
    },
    [breakpoints.up('sm')]: {
      maxWidth: typography.pxToRem(577),
    },
  },
  container: {
    [breakpoints.up('sm')]: {
      marginTop: typography.pxToRem(28),
    },
  },
  margitTop: {
    [breakpoints.up('sm')]: {
      marginTop: typography.pxToRem(20),
    },
  },
  instructionWithImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1.7375rem',
  },
  image: {
    marginRight: typography.pxToRem(10),
    width: typography.pxToRem(36),
  },
}));

type Props = {
  parcel: Parcel & AuthorizedParcel,
};

const EstimatedDateTime = ({ parcel }: Props) => {
  const styles = useStyles();
  const {
    consumerPreference,
    consumerPreferenceText,
    parcelStatusInfo,
    outForDeliveryDetails,
  } = parcel;
  const estimatedInstructions = React.useMemo(
    () => separateSentence(parcel.estimatedInstructions),
    [parcel.estimatedInstructions]
  );
  const sfpPreference = consumerPreference === ENUM.PARCEL_ACTION_CODE.SFP;
  const dtnPreference = consumerPreference === ENUM.PARCEL_ACTION_CODE.DTN;
  const noDeliveryDetails =
    !outForDeliveryDetails || !outForDeliveryDetails.outForDelivery;
  return (
    <>
      <TrackingStatus status={parcel.parcelStatusHtml} />
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={styles.container}
      >
        {noDeliveryDetails &&
          estimatedInstructions.map((el, i) => (
            <Typography key={i} className={styles.text}>
              {el}
            </Typography>
          ))}
        {(sfpPreference || dtnPreference) && (
          <Typography className={clsx(styles.text, styles.margitTop)}>
            {consumerPreferenceText}
          </Typography>
        )}
        {parcelStatusInfo && (
          <Typography
            className={clsx(styles.text, styles.instructionWithImage)}
          >
            <ErrorOutline className={styles.image} />
            {parcelStatusInfo}
          </Typography>
        )}
      </Grid>
    </>
  );
};

const createHeaderMenuControlActions = props => [
  goToParcelInfoHeader(props),
  whatHappensNextHeader(props),
  changeDeliveryHeader(props),
  trackAnotherParcelHeader(props),
  backToDpdSiteHeader(props),
  getDpdAppHeader(props),
];

const createSpeedDealControlActions = props => [
  goToParcelInfoSpeedDeal(props),
  goBackToDeliveryDetailsSpeedDeal(props),
  whatHappensNextSpeedDeal(props),
];

export default compose(
  withRouter,
  withModal,
  withPageControl({
    title: MESSAGE.MORE_COOL_STUFF,
    backgroundImage: IMAGES.TRACKING_BG,
    trackable: trackable(ESTIMATED_DATE_TIME),
    headerMenuActions: createHeaderMenuControlActions,
    speedDealActions: createSpeedDealControlActions,
    // isMobileMenuShevronVisible: true,
  }),
  withParcelInfo()
)(EstimatedDateTime);
