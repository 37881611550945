import * as React from 'react';
import { Route, Switch, useRouteMatch, withRouter } from 'react-router-dom';
import { withProps } from 'recompose';
import { compose } from 'redux';

import BackToTrackingMap from '@dpdgroupuk/fmx-ui/components/BackToTrackingMap';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';

import ChangeDeliveryOption from '../ChangeDeliveryOption';
import DeliveryOptions from './components/DeliveryOptions';
import { goToTrackingReplace, goToTracking } from '../historyActions';
import withParcel from '../../HOCs/withParcel';
import { DELIVERY_OPTIONS } from '../../constants/analytics';

const Routes = ({ goToTracking, goToTrackingReplace }: Function) => (
  <Switch>
    <Route
      exact
      path={useRouteMatch().path}
      render={props => (
        <BackToTrackingMap onClick={goToTracking}>
          <DeliveryOptions {...props} />
        </BackToTrackingMap>
      )}
    />
    <Route
      render={props => (
        <ChangeDeliveryOption {...props} goToTracking={goToTrackingReplace} />
      )}
    />
  </Switch>
);

export default compose(
  withRouter,
  withParcel(),
  withProps(props => ({
    goToTrackingReplace: () => goToTrackingReplace(props),
    goToTracking: () => goToTracking(props),
  })),
  withTrackProps({
    goToTracking: DELIVERY_OPTIONS.GO_TO_TRACKING_MAP,
  })
)(Routes);
