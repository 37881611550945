import React from 'react';
import { KMI_STEPS } from '../../../steps';

type Props = {
  addStepData: Function,
  submitWizard: Function,
  goToStep: Function,
};

// Used for adding step data as this inflight does not have a step before entering contact info
export default ({ addStepData, submitWizard, goToStep }: Props) => {
  addStepData({
    funcAfterAuth: submitWizard,
  });
  goToStep(KMI_STEPS.CONTACT_INFO);
  return <></>;
};
