import { Helmet } from 'react-helmet';

const React = require('react');

export const MetaRobotsIndex = () => (
  <Helmet>
    <meta name="robots" content="index" />
  </Helmet>
);
export const MetaRobotsNoIndex = () => (
  <Helmet>
    <meta name="robots" content="noindex" />
  </Helmet>
);
