import { formatDefaultDate } from '@dpdgroupuk/fmx-ui/utils/date';

import { changeDeliveryOption } from '../../../../../store/orm/Parcel/actions';

type UpdateDetails = {
  day: string,
  paymentNonce: string,
  vendorRef: string,
  time: Object,
  contactName: string,
  contactEmail: string,
  contactPhone: string,
};

export const upgradeDelivery = (
  parcelCode: string,
  upgradeDetails: UpdateDetails,
  options: any
) => (dispatch: Function) =>
  dispatch(
    changeDeliveryOption(
      parcelCode,
      {
        diaryDate: formatDefaultDate(upgradeDetails.day),
        paymentNonce: upgradeDetails.paymentNonce,
        vendorRef: upgradeDetails.vendorRef,
        upgradeServiceCode: upgradeDetails.time.upgradeId,
        contactName: upgradeDetails.contactName,
        contactEmail: upgradeDetails.contactEmail,
        contactPhone: upgradeDetails.contactPhone,
      },
      options
    )
  );
