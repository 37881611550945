import { compose, withProps } from 'recompose';
import { StatusCodes } from 'http-status-codes';
import { connect } from 'react-redux';
import queryString from 'query-string';

import {
  formatPostcodeForServer,
  getReferenceEntryError,
  parseRefNumberAndPostcode,
} from './models';
import {
  fetchSearchParcelByReferenceNumber,
  login,
} from '../../store/actions/parcels';
import { PARCELS, REFERENCE_ENTRY } from '../../constants/routes';
import { getLocationTracking } from '../Parcel/actions/location';
import { ACTION } from '../../constants/recaptcha';
import { OK } from '../../constants/message';
import { formatReferenceNumberForServer } from '../../models/parcel';
import { DETAILS_INCORRECT_MODAL } from '../../constants/analytics';
import { postpone } from '../../utils/promise';

export const withSearchSubmitHandler = compose(
  connect(
    null,
    {
      login,
      search: fetchSearchParcelByReferenceNumber,
    }
  ),
  withProps(
    ({ modal, overlay, history, location, reCaptchaConfig, login, search }) => {
      const onSearchSubmitFailure = e => {
        modal.showModal({
          title: getReferenceEntryError(e),
          description: null,
          buttonText: OK,
          analytics: {
            loadId: DETAILS_INCORRECT_MODAL.LOAD,
            interfaceId: DETAILS_INCORRECT_MODAL.INTERFACE_ID,
            confirm: DETAILS_INCORRECT_MODAL.ON_OK,
            modal: true,
          },
        });
      };
      return {
        onSearchSubmitFailure,
        onSearchSubmit: overlay.showWhile(async (values: Object) => {
          const { postcode, referenceNumber } = parseRefNumberAndPostcode(
            values
          );

          const formattedNumber = formatReferenceNumberForServer(
            referenceNumber
          );
          const formattedPostcode = formatPostcodeForServer(postcode);

          const { origin } = queryString.parse(location.search);

          const token = await reCaptchaConfig.executeRecaptchaAsync(
            ACTION.REFERENCE
          );
          try {
            const foundParcels = await search(
              formattedNumber,
              formattedPostcode,
              origin
            );
            if (foundParcels.length === 1) {
              const [parcelDto] = foundParcels;
              formattedPostcode &&
                (await login(
                  parcelDto.parcelCode,
                  formattedPostcode,
                  token,
                  origin
                ));
              history.push(
                getLocationTracking({ parcelId: parcelDto.parcelCode })
              );
            } else
              history.push({
                pathname: PARCELS,
                search: `reference=${formattedNumber}&postcode=${formattedPostcode}`,
                state: {
                  from: REFERENCE_ENTRY,
                },
              });
          } catch (e) {
            if (e.statusCode === StatusCodes.FORBIDDEN && e.redirectUrl) {
              window.location.assign(e.redirectUrl);
              await postpone();
            } else onSearchSubmitFailure(e);
          }
        }),
      };
    }
  )
);
