import * as React from 'react';
import { compose } from 'redux';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import Identity from '@dpdgroupuk/fmx-ui/components/Icons/Identity';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import BlockDocument from '@dpdgroupuk/fmx-ui/components/BlockDocument';
import PickupPass from '@dpdgroupuk/fmx-ui/components/Icons/PickupPass';
import type {
  AuthorizedParcel,
  Parcel,
  PickupLocation,
} from '@dpdgroupuk/fmx-ui/types';
import CollectionInfo from '@dpdgroupuk/fmx-ui/components/CollectionInfo';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes/constants';
import PickupTextBlock from '@dpdgroupuk/fmx-ui/components/PickupTextBlock';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { formatDayOfWeekDayMonth } from '@dpdgroupuk/fmx-ui/utils/date';

import {
  COLLECT_FROM_PICKUP_TRANSIT,
  trackable,
} from '../../../../../constants/analytics';
import { getPickupAddressString } from '../../../../../models/pickupLocation';
import TrackingStatus from '../../../../../components/TrackingStatus';
import { fetchPickupLocationWithVouchers } from '../../actions';

import {
  backToDpdSiteHeader,
  backToDpdSiteSpeedDeal,
  getDpdAppHeader,
  goToParcelInfoHeader,
  goToParcelInfoSpeedDeal,
  showShopMapHeader,
  showShopMapSpeedDeal,
  trackAnotherParcelHeader,
} from '../../../../controlActions';
import {
  LET_YOU_KNOW_TIME_MESSAGE,
  PICKUP_PASS_LOW_VALUE_INFO,
  MORE_COOL_STUFF,
} from '../../../../../constants/message';
import { PARCEL_VALUE_HIGH } from '../../../../../constants/values';
import { getBackgroundImage } from '../../../../../utils/images';
import withFetch from '../../../../../HOCs/withFetch';
import { withParcelInfo } from '../../../hocs';
import PickupShopCardShopInfo from '../../../../ChangeDeliveryOption/pages/CollectFromPickupShop/components/PickupShopCardShopInfo';
import CustomOpeningHoursPanel from './CustomOpeningHoursPanel';
import { getParcelAvailableDate } from '../../../../ChangeDeliveryOption/pages/CollectFromPickupShop/utils';
import { checkEstimatedDate } from '../../../../../utils/date';

const useStyles = makeStyles(({ palette, breakpoints, typography }) => ({
  wrapper: {
    marginBottom: typography.pxToRem(18),
  },
  topContainer: {
    marginTop: typography.pxToRem(20),
  },
  title: {
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(14.4),
      marginBottom: typography.pxToRem(10),
    },
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(16),
      marginBottom: typography.pxToRem(18),
    },
    letterSpacing: 0.57,
    textAlign: 'center',
    fontWeight: 400,
  },
  title15: {
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(14),
      letterSpacing: 0.5,
      lineHeight: 1.16,
      marginBottom: typography.pxToRem(28),
      textAlign: 'center ',
    },
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(15),
      letterSpacing: 0.57,
      marginBottom: typography.pxToRem(18),
      textAlign: 'left ',
    },

    fontWeight: 'normal',
  },
  identity: {
    [breakpoints.down('xs')]: {
      height: typography.pxToRem(28),
      width: typography.pxToRem(43),
    },
    [breakpoints.up('sm')]: {
      height: typography.pxToRem(32),
      width: typography.pxToRem(48),
    },
  },
  pickupPass: {
    [breakpoints.down('xs')]: {
      height: typography.pxToRem(41.4),
      width: typography.pxToRem(22.5),
    },
    [breakpoints.up('sm')]: {
      height: typography.pxToRem(46),
      width: typography.pxToRem(25),
    },
  },
  icon: {
    marginBottom: typography.pxToRem(5),
    '& path': {
      fill: palette.icon.blockIcon,
    },
    '& #RED_IMPORTANT': {
      fill: '#E30238',
    },
  },
  collectionDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'column',
    marginTop: typography.pxToRem(23),
  },
  text: {
    fontSize: typography.pxToRem(15),
    letterSpacing: 0.17,
  },
  voucherText: {
    [breakpoints.down('xs')]: {
      maxWidth: typography.pxToRem(160),
      textAlign: 'left',
    },
    [breakpoints.up('sm')]: {
      maxWidth: typography.pxToRem(525),
      textAlign: 'center',
    },
    fontSize: typography.pxToRem(12),
    lineHeight: 1.42,
    letterSpacing: 0.44,
    fontWeight: 'normal',
  },
  voucherContainer: {
    [breakpoints.down('xs')]: {
      alignSelf: 'flex-start',
    },
    marginTop: typography.pxToRem(26),
  },
  plus: {
    [breakpoints.down('xs')]: {
      marginLeft: typography.pxToRem(5),
      marginRight: typography.pxToRem(5),
      fontSize: typography.pxToRem(22),
    },
    [breakpoints.up('sm')]: {
      marginLeft: typography.pxToRem(7),
      marginRight: typography.pxToRem(7),
      fontSize: typography.pxToRem(24),
    },
    color: palette.text.default,
  },
  voucherAndDate: {
    marginBottom: typography.pxToRem(10),
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    [breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    '& #logo': {
      marginRight: typography.pxToRem(24),
      marginTop: typography.pxToRem(24),
      [breakpoints.down('xs')]: {
        marginRight: 0,
        alignSelf: 'flex-start',
      },
    },
  },
  upperCaseContainer: {
    [breakpoints.down('xs')]: {
      marginTop: typography.pxToRem(15),
    },
    [breakpoints.up('sm')]: {
      marginTop: typography.pxToRem(24),
    },
  },
  upperCaseTitle: {
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(13.5),
      letterSpacing: 0.44,
      width: typography.pxToRem(236),
      height: typography.pxToRem(36),
    },
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(15),
      letterSpacing: 0.54,
      width: typography.pxToRem(262),
      height: typography.pxToRem(40),
    },
    textAlign: 'center',
    fontWeight: 400,
    color: palette.icon.redWhite,
    textTransform: 'uppercase',
  },
  textLowValue: {
    marginTop: typography.pxToRem(10),
    color: palette.text.hint,
    textAlign: 'center',
  },
  shopCardContainer: {
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    [breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& #logo': {
      marginRight: typography.pxToRem(24),
      [breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
    direction: 'row',
    justify: 'flex-start',
    alignItems: 'center',
    [breakpoints.up('sm')]: {
      maxWidth: typography.pxToRem(550),
    },
  },
}));

type Props = {
  parcel: Parcel & AuthorizedParcel,
  pickupLocation: PickupLocation,
  vouchers: Array<?string>,
};

const CollectFromPickupTransit = ({
  parcel,
  pickupLocation,
  vouchers,
}: Props) => {
  const styles = useStyles();
  const { estimatedDeliveryDate, shipmentTargetDate } = parcel;

  const parcelEstimatedDate = checkEstimatedDate(
    estimatedDeliveryDate,
    shipmentTargetDate
  );

  const date = parcelEstimatedDate || new Date();

  const dateFormatted = formatDayOfWeekDayMonth(date);
  const pickupAddress = getPickupAddressString(pickupLocation.address);

  return (
    <>
      <TrackingStatus status={parcel.parcelStatusHtml} />
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={styles.wrapper}
      >
        <Grid className={styles.topContainer}>
          <Typography className={styles.title}>
            To collect your parcel, you’ll need:
          </Typography>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <BlockDocument directionRow title="Your DPD Pickup Pass">
            <PickupPass className={clsx(styles.icon, styles.pickupPass)} />
          </BlockDocument>
          {parcel.pickupValueGoods === PARCEL_VALUE_HIGH &&
          pickupLocation.kind === 'SHP' ? (
            <>
              <AddIcon className={styles.plus} />
              <BlockDocument
                title="Photo ID"
                subtitle="* Valid passport or driver’s license"
              >
                <Identity className={clsx(styles.icon, styles.identity)} />
              </BlockDocument>
            </>
          ) : (
            <PickupTextBlock text={PICKUP_PASS_LOW_VALUE_INFO} />
          )}
        </Grid>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={styles.upperCaseContainer}
        >
          {parcel.pickupValueGoods === PARCEL_VALUE_HIGH && (
            <Typography className={clsx(styles.text, styles.textLowValue)}>
              {PICKUP_PASS_LOW_VALUE_INFO}
            </Typography>
          )}
        </Grid>
        <Grid className={styles.topContainer}>
          <Grid className={styles.collectionDetailsContainer}>
            <Typography className={styles.text}>
              Your parcel will be ready for collection:
            </Typography>
            <>
              <Grid container className={styles.voucherAndDate}>
                <CollectionInfo
                  collectionDate={dateFormatted}
                  depotAddress={pickupAddress}
                  noTimeMessage={LET_YOU_KNOW_TIME_MESSAGE}
                  shouldShowAddress={false}
                />
              </Grid>
              <span className={styles.shopCardContainer}>
                <>
                  <PickupShopCardShopInfo
                    pickupShopInfo={{ pickupLocation }}
                    pointChosen
                  />
                  <CustomOpeningHoursPanel
                    pickupShopInfo={{ pickupLocation }}
                  />
                </>
              </span>
            </>
          </Grid>
        </Grid>
        {vouchers[0] && (
          <Grid className={styles.voucherContainer}>
            <Typography className={styles.voucherText}>
              {`You will receive a ${pickupLocation.shortName} Discount Voucher when your parcel arrives `}
              at your Pickup Point
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const createHeaderControlActions = props => [
  showShopMapHeader(props),
  goToParcelInfoHeader(props),
  trackAnotherParcelHeader(props),
  backToDpdSiteHeader(props),
  getDpdAppHeader(props),
];

const createSpeedDealControlActions = props => [
  backToDpdSiteSpeedDeal(props),
  goToParcelInfoSpeedDeal(props),
  showShopMapSpeedDeal(props),
];

export default compose(
  withRouter,
  withModal,
  withFetch(fetchPickupLocationWithVouchers, {
    dataPropMapper: ([pickupLocation, vouchers]) => ({
      pickupLocation,
      vouchers,
    }),
  }),
  withPageControl({
    title: MORE_COOL_STUFF,
    backgroundImage: getBackgroundImage({
      desktopImage: IMAGES.COLLECT_FROM_PICKUP,
      mobileImage: IMAGES.COLLECT_FROM_PICKUP_MOBILE,
    }),
    backgroundOpacity: 0.8,
    trackable: trackable(COLLECT_FROM_PICKUP_TRANSIT),
    headerMenuActions: createHeaderControlActions,
    speedDealActions: createSpeedDealControlActions,
    // isMobileMenuShevronVisible: true,
  }),
  withParcelInfo()
)(CollectFromPickupTransit);
