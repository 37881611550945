import React from 'react';
import { compose } from 'redux';
import { withProps } from 'recompose';
import { Route, Switch } from 'react-router-dom';
import ENUM from '@dpdgroupuk/redback-enums';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes/constants';

import type { AuthorizedParcel } from '../../../types';
import {
  CollectFromDepot,
  CollectFromPickup,
  CollectFromPickupTransit,
  DeliveryImage,
  EstimatedDateTime,
  GeneralView,
  Issue,
  OutForDelivery,
  MapInfo,
  RateExperience,
} from '../pages';
import { ROUTES } from '../../Parcel/constants';
import withParcel from '../../../HOCs/withParcel';
import withFunctionalityLog from '../../../HOCs/withFunctionalityLog';
import { getBackgroundImage } from '../../../utils/images';

type Props = {
  parcel: AuthorizedParcel,
};

const TrackingComponents = compose(
  withParcel(),
  withFunctionalityLog('KavCYKpddihAeYPMs8rR')
)(({ parcel }: Props) => {
  switch (parcel.parcelStatusType) {
    case ENUM.PARCEL_STATUS.NO_FOCUS_BOX:
      return <GeneralView parcel={parcel} />;
    case ENUM.PARCEL_STATUS.PICKUP_DELIVERY:
      return <CollectFromPickupTransit parcel={parcel} />;
    case ENUM.PARCEL_STATUS.PICKUP_DELIVERY_COLLECTED:
      return <CollectFromPickup parcel={parcel} />;
    case ENUM.PARCEL_STATUS.COLLECT_FROM_DEPOT_INSTRUCTIONS:
      return <CollectFromDepot parcel={parcel} />;
    case ENUM.PARCEL_STATUS.OUT_FOR_DELIVERY:
      return <OutForDelivery parcel={parcel} />;
    case ENUM.PARCEL_STATUS.ISSUE:
      return <Issue parcel={parcel} />;
    case ENUM.PARCEL_STATUS.ESTIMATED_DATE:
      return <EstimatedDateTime parcel={parcel} />;
    case ENUM.PARCEL_STATUS.DELIVERY_IMAGE:
      return <DeliveryImage parcel={parcel} />;
    case ENUM.PARCEL_STATUS.APP_SHIPPING:
      return <RateExperience parcel={parcel} />;
    default:
      return <GeneralView parcel={parcel} />;
  }
});

const TrackingMapInfo = compose(
  withParcel(),
  withProps(props => {
    const isParcelCollectFromDepot =
      props.parcel.parcelStatusType ===
      ENUM.PARCEL_STATUS.COLLECT_FROM_DEPOT_INSTRUCTIONS;

    return {
      backgroundImage: isParcelCollectFromDepot
        ? getBackgroundImage({
            desktopImage: IMAGES.COLLECT_FROM_DEPOT,
            mobileImage: IMAGES.COLLECT_FROM_DEPOT_MOBILE,
          })
        : getBackgroundImage({
            desktopImage: IMAGES.COLLECT_FROM_PICKUP,
            mobileImage: IMAGES.COLLECT_FROM_PICKUP_MOBILE,
          }),
    };
  })
)(MapInfo);

const TrackingFull = () => (
  <Switch>
    <Route path={`${ROUTES.MAP_INFO}`} component={TrackingMapInfo} />
    <Route component={TrackingComponents} />
  </Switch>
);

export default TrackingFull;
