// @flow
import * as React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import RateInfoButton from './RateInfoButton';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  marginLeft: {
    marginLeft: typography.pxToRem(18),
    [breakpoints.down('xs')]: {
      marginLeft: typography.pxToRem(10),
    },
  },
}));

const RateInfoButtonsBlock = ({
  buttons,
  active,
  setActive,
  onComplete,
}: any) => {
  const styles = useStyles();

  return (
    <>
      <Grid container justify="center">
        {buttons.map(
          ({ icon, comment, commentClassName, className }, index) => (
            <RateInfoButton
              key={icon}
              icon={icon}
              onClick={() => setActive(icon, comment)}
              comment={comment}
              className={clsx({ [styles.marginLeft]: index !== 0 }, className)}
              commentClassName={commentClassName}
              active={active === icon}
              animation
              onComplete={onComplete}
            />
          )
        )}
      </Grid>
    </>
  );
};

export default RateInfoButtonsBlock;
