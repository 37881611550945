import { get } from 'lodash';
import type { AuthorizedParcel, Route, Driver } from '@dpdgroupuk/fmx-ui/types';
import {
  chooseSmallerDate,
  formatInternalTime,
  formatTime,
  getCurrentDateTimeInDefaultFormat,
  calculatePositionInPeriod,
} from '@dpdgroupuk/fmx-ui/utils/date';

const getProgressStatus = (parcel, route, driver) => {
  const driverName =
    get(route, 'driverDisplayName') || get(driver, 'driverName');
  const totalCompletedStops =
    route.completedCollectionStops + route.completedDeliveryStops;

  const myStop = parcel.deliveryDepot.route.stop.stopNumber || 0;

  const currentStop =
    totalCompletedStops >= myStop && myStop > 0
      ? myStop - 1
      : totalCompletedStops;

  if (currentStop) {
    return `${driverName ||
      'Driver'} is currently making delivery number ${currentStop}, you are delivery number ${myStop}`;
  }
  return null;
};

export const getParcelProgressData = (
  parcel: AuthorizedParcel,
  route: Route,
  driver: Driver
) => {
  const deliveryWindowFrom = get(
    parcel,
    'deliveryDepot.route.stop.deliveryWindowFrom',
    ''
  );
  const deliveryWindowTo = get(
    parcel,
    'deliveryDepot.route.stop.deliveryWindowTo',
    ''
  );

  const progressStatus = getProgressStatus(parcel, route, driver);

  const routeTimeInSeconds = route.routeTime;
  const routeDateTime = routeTimeInSeconds
    ? formatInternalTime(routeTimeInSeconds)
    : null;

  const driverStartTime = chooseSmallerDate(routeDateTime, deliveryWindowFrom);

  let currentPosition = calculatePositionInPeriod(
    getCurrentDateTimeInDefaultFormat(),
    driverStartTime,
    deliveryWindowTo
  );
  // add min value as 5 to avoid negative values in progress
  currentPosition = Math.max(currentPosition, 5);

  const startPosition = calculatePositionInPeriod(
    deliveryWindowFrom,
    driverStartTime,
    deliveryWindowTo
  );

  const progressData = {
    showProgressBar: currentPosition < 100,
    progressStatus,
    currentPosition,
    startPosition,
    windowStartTime: formatTime(deliveryWindowFrom),
    windowEndTime: formatTime(deliveryWindowTo),
    vehicleTypeCode: get(driver, 'vehicleTypeCode'),
  };

  return {
    code: parcel.parcelCode,
    customerImageLogo: parcel.customerImageLogo,
    deliveryNumber: `Parcel No. ${parcel.parcelNumber}`,
    statusHtml: parcel.parcelStatusHtml,
    query: 'parcelCode',
    electricMessage: 'Proudly delivering in an all-electric vehicle',
    isVehicleElectric: !!get(parcel, 'deliveryDetails.onRouteElectric'),
    showIdMessage: !!get(parcel, 'parcelStatusInfo'),
    messageId: get(parcel, 'parcelStatusInfo'),
    progressData,
  };
};
