// @flow
import * as React from 'react';
import { withProps } from 'recompose';
import { compose } from 'redux';
import moment from 'moment-timezone';
import { keys, map } from 'lodash';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withTrack, withTrackProps } from '@dpdgroupuk/react-event-tracker';
import SubmitButton from '@dpdgroupuk/fmx-ui/components/SubmitButton';
import HeadingWithAction from '@dpdgroupuk/fmx-ui/components/HeadingWithAction';
import { getDateString } from '@dpdgroupuk/fmx-ui/utils/string';
import CallMePhoneIcon from '@dpdgroupuk/fmx-ui/components/Icons/CallMePhoneIcon';

import * as MESSAGE from '../../../constants/message';
import { RATE_STEPS } from '../steps';
import {
  RATE_DELIVERY_CALL_ME_SLOT_SELECT,
  trackable,
} from '../../../constants/analytics';
import CallMeTimeslotList from '../../../components/Rate/CallMeTimeslotList';

const useStyles = makeStyles(({ typography, palette, breakpoints }) => ({
  submitButton: {
    marginTop: typography.pxToRem(30),
  },
  container: {
    width: '100%',
  },
  chooseTimeSlotText: {
    fontSize: typography.pxToRem(20),
    letterSpacing: typography.pxToRem(0.38),
    lineHeight: 1.56,
    color: palette.text.primary,
    marginBottom: typography.pxToRem(36),
    marginTop: typography.pxToRem(40),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(17),
      marginTop: typography.pxToRem(47),
      marginBottom: typography.pxToRem(19),
    },
  },
  changeDateWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: typography.pxToRem(30),
  },
  callMePhoneIcon: {
    height: typography.pxToRem(63),
    width: typography.pxToRem(63),
  },
  submitText: {
    fontSize: typography.pxToRem(13.5),
    lineHeight: 1.2,
    letterSpacing: typography.pxToRem(0.9),
  },
}));

const formatTimeslotsToList = slotsObj =>
  map(keys(slotsObj), slot => {
    const slotTime = moment(slot, '[hour]H');
    return {
      startTime: slotTime.clone().format('ha'),
      endTime: slotTime
        .clone()
        .add(1, 'hour')
        .format('ha'),
      disabled: !slotsObj[slot],
      key: slotTime.clone().format('HH:mm'),
    };
  });

type Props = {
  data: Object,
  onSubmit: Function,
  onChangeDate: Function,
  onSelectTime: Function,
};

const RateDeliveryCallMeSlotSelect = ({
  data,
  onSubmit,
  onChangeDate,
  onSelectTime,
}: Props) => {
  const styles = useStyles();
  const { selectedDate, selectedTime = {}, slotsData = {} } = data;
  const timeSlots = formatTimeslotsToList(slotsData);

  return (
    <Grid className={styles.container}>
      <CallMePhoneIcon className={styles.callMePhoneIcon} />

      {selectedDate && (
        <div className={styles.changeDateWrapper}>
          <HeadingWithAction
            title={getDateString(selectedDate)}
            buttonText={MESSAGE.CHANGE_DATE}
            onClick={onChangeDate}
            testId="change-date"
          />
        </div>
      )}

      <Typography className={styles.chooseTimeSlotText}>
        {MESSAGE.CHOOSE_SUITABLE_TIME_SLOT}
      </Typography>

      <CallMeTimeslotList
        items={timeSlots}
        onSelect={onSelectTime}
        format={el => `${el.startTime} - ${el.endTime}`}
        active={selectedTime.key}
      />

      <SubmitButton
        onSubmit={onSubmit}
        isSubmitHidden={!selectedTime.key}
        className={styles.submitButton}
      >
        <span className={styles.submitText}>Submit</span>
      </SubmitButton>
    </Grid>
  );
};

export default compose(
  withProps<any, any>(({ addStepData, goToStep }: any) => ({
    onSelectTime: selectedTime => addStepData({ selectedTime }),
    onSubmit: () => goToStep(RATE_STEPS.RATE_DELIVERY_CALL_ME_CONTACT_NUMBER),
    onChangeDate: () => goToStep(RATE_STEPS.RATE_DELIVERY_CALL_ME_DATE_SELECT),
  })),
  withTrack(trackable(RATE_DELIVERY_CALL_ME_SLOT_SELECT)),
  withTrackProps({
    onSubmit: RATE_DELIVERY_CALL_ME_SLOT_SELECT.ON_SUBMIT,
    onSelectTime: RATE_DELIVERY_CALL_ME_SLOT_SELECT.ON_SLOT_SELECT,
    onChangeDate: RATE_DELIVERY_CALL_ME_SLOT_SELECT.ON_CHANGE_DATE,
  })
)(RateDeliveryCallMeSlotSelect);
