import * as React from 'react';
import { compose, withProps } from 'recompose';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import SelectDay from '@dpdgroupuk/fmx-ui/components/DayPicker';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';

import * as MESSAGE from '../../../../constants/message';
import { TBC_STEPS } from '../../steps';
import StepLayout from '../../components/StepLayout';
import MapContainer from '../../components/MapContainer';
import type { AuthorizedParcel } from '../../../../types';

import { COLLECT_FROM_DEPOT_DELIVERY_OPTION } from '../../../../constants/analytics';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  title: {
    fontSize: typography.pxToRem(19),
    lineHeight: 1.45,
    letterSpacing: typography.pxToRem(0.44),
    marginBottom: typography.pxToRem(54),
    textAlign: 'center',
    whiteSpace: 'pre',
    color: '#b7b9bd',
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
    },
  },
  container: {
    marginBottom: typography.pxToRem(70),
  },
}));

type Props = {
  onSelect: Function,
  dates: Array<string>,
  depotAddress: string,
  showMap: boolean,
  parcel: AuthorizedParcel,
  toggleMap: Function,
};

const SelectDayComponent = ({
  onSelect,
  dates,
  depotAddress,
  showMap,
  parcel,
  toggleMap,
}: Props) => {
  const styles = useStyles();

  return (
    <StepLayout title="Your Collection depot" copyText={depotAddress}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={styles.container}
      >
        <MapContainer showMap={showMap} parcel={parcel} toggleMap={toggleMap}>
          <Typography className={styles.title}>
            {MESSAGE.SELECT_NEW_COLLECTION_DAY}
          </Typography>
          <SelectDay
            title={MESSAGE.DAY_OF_DELIVERY}
            onSelect={onSelect}
            dates={dates}
          />
        </MapContainer>
      </Grid>
    </StepLayout>
  );
};

export default compose(
  withProps(({ addStepData, goToStep }) => ({
    onSelect: day => {
      goToStep(TBC_STEPS.CHOOSE_TIME_SLOT);
      addStepData({ day });
    },
  })),
  withTrackProps({
    onSelect: COLLECT_FROM_DEPOT_DELIVERY_OPTION.ON_DATE_SELECT,
  })
)(SelectDayComponent);
