import {
  MERCHANT_NAME,
  PAYMENT_CURRENCY_CODE,
} from '../pages/ChangeDeliveryOption/pages/Upgrade/constants';

export const makePaymentParams = (token, upgradeCost) => ({
  authorization: token,
  paypal: {
    flow: 'checkout',
    amount: upgradeCost,
    currency: PAYMENT_CURRENCY_CODE,
  },
  applePay: {
    displayName: MERCHANT_NAME,
    paymentRequest: {
      total: {
        label: MERCHANT_NAME,
        amount: upgradeCost,
      },
    },
  },
});
