const COMPLETE = 'Complete';
const SELECT_DAY = 'SelectDay';
const DELIVERY_PREFERENCES = 'DeliveryPreferences';
const CHOOSE_TIME_SLOT = 'ChooseTimeSlot';
const PAYMENT = 'Payment';
const SELECT_PICKUP_SHOP = 'SelectPickupShop';
const GET_CONTACTS = 'GetContacts';
const GET_CONTACTS_NO_AUTH = 'GetContactsNoAuth';
const AUTHENTICATION = 'Authentication';
const ENTER_CODE = 'AuthEnterCode';

export const RED_STEPS = {
  SELECT_DAY,
  AUTHENTICATION,
  GET_CONTACTS,
  GET_CONTACTS_NO_AUTH,
  ENTER_CODE,
  COMPLETE,
};

export const DTN_STEPS = {
  SELECT_DAY,
  SELECT_NEIGHBOUR: 'SelectNeighbour',
  DELIVERY_PREFERENCES,
  GET_CONTACTS,
  ENTER_CODE,
  GET_CONTACTS_NO_AUTH,
  COMPLETE,
};

export const SFP_STEPS = {
  SELECT_DAY,
  SELECT_SAFE_PLACE: 'SelectSafePlace',
  DELIVERY_PREFERENCES,
  GET_CONTACTS,
  ENTER_CODE,
  GET_CONTACTS_NO_AUTH,
  COMPLETE,
};

export const ALA_STEPS = {
  SELECT_DAY,
  SELECT_ADDRESS: 'SelectAddress',
  COMPLETE,
};

export const KMI_STEPS = {
  STARTER_ELEMENT: 'StarterElement',
  CONTACT_INFO: 'ContactInfo',
  ENTER_CODE,
  COMPLETE,
};

export const UPG_STEPS = {
  SELECT_DAY,
  CHOOSE_TIME_SLOT,
  PAYMENT,
  GET_CONTACTS,
  COMPLETE,
};

export const TBC_STEPS = {
  SELECT_DAY,
  CHOOSE_TIME_SLOT,
  GET_CONTACTS,
  GET_CONTACTS_NO_AUTH,
  ENTER_CODE,
  COMPLETE,
};

export const ONE_STEPS = {
  SELECT_DAY,
  GET_CONTACTS,
  ENTER_CODE,
  CHOOSE_TIME_SLOT,
  COMPLETE,
};

export const PKU_STEPS = {
  SELECT_PICKUP_SHOP,
  DELIVERY_PREFERENCES,
  GET_CONTACTS,
  COMPLETE,
};
