import {
  joinStringsWith,
  joinStringsWithComma,
} from '@dpdgroupuk/fmx-ui/utils/string';

const getALAPropertyStreetString = (property, street) =>
  joinStringsWith([property, street], ' ').trim();

export const getALAStreetString = ({ property, street, locality }) =>
  joinStringsWithComma([
    getALAPropertyStreetString(property, street),
    locality,
  ]);
