import * as React from 'react';
import { Route, Switch, useRouteMatch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import type {
  Parcel,
  PickupLocationParcel,
} from '@dpdgroupuk/fmx-ui/types/Parcel';
import type {
  PickupLocation,
  VouchersInfo,
} from '@dpdgroupuk/fmx-ui/types/PickupLocation';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes/constants';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { makeStyles } from '@material-ui/styles';

import {
  COLLECT_FROM_PICKUP,
  trackable,
} from '../../../../../constants/analytics';
import TrackingStatus from '../../../../../components/TrackingStatus';
import PickupPass from './PickupPass';
import CollectFromPickupInfo from './CollectFromPickupInfo';
import {
  fetchPickupLocationWithVouchersInfo,
  fetchRelatedParcelNumbers,
} from '../../actions';
import ShopDetails from './ShopDetails';
import withFetch from '../../../../../HOCs/withFetch';

import {
  backToDpdSiteHeader,
  getDpdAppHeader,
  goToParcelInfoHeader,
  goToParcelInfoSpeedDeal,
  trackAnotherParcelHeader,
  backToDpdSiteSpeedDeal,
  showShopMapHeader,
  showShopMapSpeedDeal,
  printPickupPass,
} from '../../../../controlActions';
import { getBackgroundImage } from '../../../../../utils/images';
import { withParcelInfo } from '../../../hocs';
import { MORE_COOL_STUFF } from '../../../../../constants/message';

const useStyles = makeStyles(({ typography }) => ({
  wrapper: { marginBottom: typography.pxToRem(18) },
}));

type Props = {
  parcel: Parcel & PickupLocationParcel,
  pickupLocation: PickupLocation,
  vouchersInfo: VouchersInfo,
  relatedParcels: string[],
};

const CollectFromPickup = ({
  parcel,
  pickupLocation,
  vouchersInfo,
  relatedParcels,
}: Props) => {
  const styles = useStyles();
  const { path } = useRouteMatch();

  return (
    <>
      <TrackingStatus status={parcel.parcelStatusHtml} />
      <div className={styles.wrapper}>
        <Switch>
          <Route
            path={`${path}/pickupPass`}
            render={() => (
              <PickupPass
                parcel={parcel}
                pickupLocation={pickupLocation}
                relatedParcels={relatedParcels}
              />
            )}
          />
          <Route
            path={`${path}/shopDetails`}
            render={() => <ShopDetails pickupLocation={pickupLocation} />}
          />
          <Route
            render={() => (
              <CollectFromPickupInfo
                parcel={parcel}
                vouchersInfo={vouchersInfo}
                pickupLocation={pickupLocation}
              />
            )}
          />
        </Switch>
      </div>
    </>
  );
};

const createHeaderControlActions = props => [
  showShopMapHeader(props),
  goToParcelInfoHeader(props),
  trackAnotherParcelHeader(props),
  backToDpdSiteHeader(props),
  getDpdAppHeader(props),
];

const createSpeedDealControlActions = props => [
  backToDpdSiteSpeedDeal(props),
  props.location.pathname.includes('/pickupPass')
    ? printPickupPass(COLLECT_FROM_PICKUP.PICKUP_PASS_PRINT)
    : goToParcelInfoSpeedDeal(props),
  showShopMapSpeedDeal(props),
];

export default compose(
  withRouter,
  withModal,
  withFetch(fetchPickupLocationWithVouchersInfo, {
    dataPropMapper: ([pickupLocation, vouchersInfo]) => ({
      pickupLocation,
      vouchersInfo,
    }),
  }),
  withFetch(fetchRelatedParcelNumbers, {
    dataPropMapper: relatedParcels => ({
      relatedParcels,
    }),
  }),
  withPageControl({
    title: MORE_COOL_STUFF,
    backgroundImage: getBackgroundImage({
      desktopImage: IMAGES.COLLECT_FROM_PICKUP,
      mobileImage: IMAGES.COLLECT_FROM_PICKUP_MOBILE,
    }),
    backgroundOpacity: 0.8,
    trackable: trackable(COLLECT_FROM_PICKUP),
    headerMenuActions: createHeaderControlActions,
    speedDealActions: createSpeedDealControlActions,
    // isMobileMenuShevronVisible: true,
  }),
  withParcelInfo()
)(CollectFromPickup);
