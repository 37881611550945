import { attr, Model } from 'redux-orm';
import type { PayloadAction } from '@dpdgroupuk/redux-action-creator';

import { ACTIONS } from './actions';
import { ENTITY_NAME } from '../constants';

export class Event extends Model {
  static reducer(action: PayloadAction, Event: any) {
    if (action.type === ACTIONS.FETCH_EVENT.SUCCESS) {
      // session.Parcel.withId(action.payload.parcelCode).events.add(action.payload.items);
      Event.create({
        ...action.payload,
      });
    }
  }

  static modelName = ENTITY_NAME.EVENT;

  static fields = {
    parcelCode: attr(),
    items: attr(),
  };

  static options = {
    idAttribute: 'parcelCode',
  };
}
