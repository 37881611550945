import * as React from 'react';
import { withProps, compose } from 'recompose';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import SelectDay from '@dpdgroupuk/fmx-ui/components/DayPicker';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { USER_TYPE } from '@dpdgroupuk/redback-enums';

import * as MESSAGE from '../../../../constants/message';
import { ONE_STEPS } from '../../steps';
import { PRECISE_DELIVERY_OPTION } from '../../../../constants/analytics';
import withFetch from '../../../../HOCs/withFetch';
import { fetchParcelClaimsDispatch } from '../../actions';
import mapStateToProps from '../../utils/mapStateToProps';
import { ORIGIN } from '../../../../constants/values';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  title: {
    fontSize: typography.pxToRem(19),
    lineHeight: 1.45,
    letterSpacing: typography.pxToRem(0.44),
    marginBottom: typography.pxToRem(54),
    textAlign: 'center',
    whiteSpace: 'pre',
    color: '#b7b9bd',
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
    },
  },
  container: {
    marginBottom: typography.pxToRem(70),
  },
}));

type Props = {
  onSelect: Function,
  dates: Array<string>,
};

const SelectDayComponent = ({ onSelect, dates }: Props) => {
  const styles = useStyles();

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={styles.container}
    >
      <Typography className={styles.title}>
        {MESSAGE.SELECT_DAY_AND_HOUR_TIME}
      </Typography>
      <SelectDay
        title={MESSAGE.DAY_OF_DELIVERY}
        onSelect={onSelect}
        dates={dates}
      />
    </Grid>
  );
};

export default compose(
  withProps(({ addStepData, goToStep }) => ({
    onSelect: day => {
      goToStep(ONE_STEPS.CHOOSE_TIME_SLOT);
      addStepData({ day });
    },
  })),
  withTrackProps({
    onSelect: PRECISE_DELIVERY_OPTION.ON_DATE_SELECT,
  })
)(SelectDayComponent);
