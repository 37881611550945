import { fetchDeliveryActions } from '../DeliveryOptions/actions';
import { fetchParcelClaims } from '../../store/orm/Claim/actions';

export const fetchActionsNeighboursAndDriver = ({ parcel }) => dispatch => {
  return Promise.all([
    dispatch(fetchDeliveryActions({ parcel })),
    dispatch(fetchParcelClaims(parcel.parcelCode)),
  ]).then(([deliveryActions]) => ({
    deliveryActions,
  }));
};
