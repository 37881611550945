// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Lottie from 'lottie-react-web';
import { isMobile } from '@dpdgroupuk/fmx-ui/utils/helpers';

import { RATE_INFO_ICONS } from '../constants';
import { RATE_INFO_ANIMATIONS } from '../animations';

const useStyles = makeStyles(({ typography, palette, breakpoints }) => ({
  rateInfoButton: {
    width: typography.pxToRem(81),
    height: typography.pxToRem(81),
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    '& img': {
      maxWidth: typography.pxToRem(81),
      maxHeight: typography.pxToRem(81),
    },
    '&:focus': {
      outline: 0,
    },
    [breakpoints.down('xs')]: {
      width: typography.pxToRem(69),
      height: typography.pxToRem(69),
      '& img': {
        maxWidth: typography.pxToRem(69),
        maxHeight: typography.pxToRem(69),
      },
    },
  },
  comment: {
    fontSize: typography.pxToRem(12),
    color: palette.text.primary,
    letterSpacing: typography.pxToRem(0.64),
    marginTop: typography.pxToRem(16),
  },
  wrapper: {
    width: typography.pxToRem(81),
  },
  disabled: {
    cursor: 'default',
  },
  active: {
    color: palette.text.redBlue,
  },
}));

const RateInfoButton = ({
  icon,
  onClick,
  comment,
  className,
  commentClassName,
  active = false,
  animation = false,
  onComplete = () => null,
}: any) => {
  const styles = useStyles();
  const animationSize = isMobile ? '4.1rem' : '5.1rem';

  return (
    <Grid className={clsx(styles.wrapper, className)}>
      <button
        className={clsx(styles.rateInfoButton, {
          [styles.disabled]: !onClick,
        })}
        onClick={onClick}
        disabled={!onClick}
      >
        {animation && active ? (
          <Lottie
            width={animationSize}
            height={animationSize}
            isStopped={!active}
            eventListeners={[
              {
                eventName: 'complete',
                callback: onComplete,
              },
            ]}
            style={{
              position: 'relative',
              margin: 'auto 0',
            }}
            options={{
              animationData: RATE_INFO_ANIMATIONS[icon],
              loop: false,
              autoplay: active,
            }}
          />
        ) : (
          RATE_INFO_ICONS[icon]
        )}
      </button>
      {comment ? (
        <Typography
          className={clsx(
            styles.comment,
            commentClassName,
            active && styles.active
          )}
        >
          {comment}
        </Typography>
      ) : null}
    </Grid>
  );
};

export default RateInfoButton;
