import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import RateInfoButtonsBlock from './RateInfoButtonsBlock';
import { RATE_INFO_NAMES } from '../constants';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  damaged: {
    width: typography.pxToRem(75),
  },
  didntFollow: {
    width: typography.pxToRem(73),
  },
  rateInfoButton: {
    height: typography.pxToRem(190),
  },
  mobRateButton: {
    [breakpoints.down(400)]: {
      marginLeft: typography.pxToRem(10),
      width: `calc(50% - ${typography.pxToRem(10)})`,
      marginTop: typography.pxToRem(10),
    },
  },
}));

type Props = {
  active: string,
  setActive: Function,
  onComplete: Function,
};

export function PositiveCompliments({ active, setActive, onComplete }: Props) {
  const styles = useStyles();

  const buttons = React.useMemo(() => {
    return [
      {
        icon: RATE_INFO_NAMES.CARE,
        comment: 'Delivered with care',
        className: styles.mobRateButton,
      },
      {
        icon: RATE_INFO_NAMES.EXTRA_MILE,
        comment: 'Went the extra mile',
        className: styles.mobRateButton,
      },
      {
        icon: RATE_INFO_NAMES.FRIENDLY,
        comment: 'Friendly manner',
        className: styles.mobRateButton,
      },
      {
        icon: RATE_INFO_NAMES.RESPECT,
        comment: 'Respected my property',
        className: styles.mobRateButton,
      },
    ];
  }, [styles.mobRateButton]);

  return (
    <RateInfoButtonsBlock
      buttons={buttons}
      active={active}
      setActive={setActive}
      onComplete={onComplete}
    />
  );
}
export function NegativeCompliments({ active, setActive, onComplete }: Props) {
  const styles = useStyles();

  const buttons = React.useMemo(() => {
    return [
      {
        icon: RATE_INFO_NAMES.DAMAGED,
        comment: 'Not delivered carefully',
        commentClassName: styles.damaged,
        className: styles.rateInfoButton,
      },
      {
        icon: RATE_INFO_NAMES.RUDE,
        comment: 'Driver was rude',
        className: styles.rateInfoButton,
      },
      {
        icon: RATE_INFO_NAMES.DIDNT_FOLLOW,
        comment: `Didn’t\nfollow my instructions`,
        commentClassName: styles.didntFollow,
        className: styles.rateInfoButton,
      },
    ];
  }, [styles.damaged, styles.didntFollow, styles.rateInfoButton]);

  return (
    <RateInfoButtonsBlock
      buttons={buttons}
      active={active}
      setActive={setActive}
      onComplete={onComplete}
    />
  );
}
