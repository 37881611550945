import * as React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { Grid, useTheme } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { THEMES } from '@dpdgroupuk/fmx-ui/themes';
import { getAddressWithPostcode } from '@dpdgroupuk/fmx-ui/utils/address';

import Table from '@dpdgroupuk/fmx-ui/components/Table';
import type { Parcel, ParcelEvent } from '@dpdgroupuk/fmx-ui/types';
import { useModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import {
  formatDayMonthYear,
  formatDayMonthYearTime,
} from '@dpdgroupuk/fmx-ui/utils/date';

import {
  WHERE_HAS_MY_PARCEL_BEEN,
  ADDITIONAL_INFO,
} from '../../../constants/message';
import { getSenderRefs } from '../../../models/parcel';

const useStyles = makeStyles(({ typography, palette }) => ({
  container: {
    paddingTop: typography.pxToRem(105),
  },
  containerGuest: {
    paddingTop: typography.pxToRem(50),
    boxShadow: `0 ${typography.pxToRem(1)} ${typography.pxToRem(
      6
    )} 0 rgba(0, 0, 0, 0.16)`,
    background: 'rgb(255,255,255)',
    marginTop: typography.pxToRem(15),
    paddingBottom: typography.pxToRem(15),
  },
  containerGuestDark: {
    paddingTop: typography.pxToRem(50),
    boxShadow: `0 ${typography.pxToRem(1)} ${typography.pxToRem(
      6
    )} 0 rgba(0, 0, 0, 0.16)`,
    background: 'rgba(26, 29, 33, 0.7)',
    marginTop: typography.pxToRem(15),
    paddingBottom: typography.pxToRem(15),
  },
  width: {
    maxWidth: typography.pxToRem(960),
  },
  eventsTable: {
    marginTop: typography.pxToRem(15),
    marginBottom: typography.pxToRem(80),
  },
  eventsTableGuest: {
    marginTop: typography.pxToRem(15),
    marginBottom: typography.pxToRem(40),
  },
  title: {
    fontSize: typography.pxToRem(20),
    letterSpacing: 0.44,
    lineHeight: 0.86,
    color: '#b7b9bd',
    paddingLeft: typography.pxToRem(30),
  },
  locationCellHeader: {
    paddingLeft: typography.pxToRem(45),
  },
  column: {
    minWidth: typography.pxToRem(200),
  },
  cellTitle: {
    width: typography.pxToRem(161),
    fontSize: typography.pxToRem(20),
    letterSpacing: 0.44,
    lineHeight: 0.86,
    color: '#b7b9bd',
  },
  cellSubtitle: {
    marginLeft: typography.pxToRem(15),
    fontSize: typography.pxToRem(15),
    letterSpacing: 0.44,
    lineHeight: 1.07,
  },
  cellContainer: {
    marginTop: typography.pxToRem(30),
    paddingLeft: typography.pxToRem(30),
  },
  cellContainerPartner: {
    marginTop: typography.pxToRem(30),
    paddingLeft: typography.pxToRem(30),
    paddingBottom: typography.pxToRem(30),
  },
  topContainer: {
    marginBottom: typography.pxToRem(40),
  },
  link: {
    color: '#1d9ad0',
    textDecoration: 'underline',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
  },
  icon: {
    color: palette.icon.red,
  },
  modal: {
    textAlign: 'center',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
  },
}));

type Props = {
  parcel: Parcel,
  events: Array<ParcelEvent>,
  guest: Boolean,
};

const ParcelEventsDesktop = ({ parcel, events, guest }: Props) => {
  const styles = useStyles();
  const { showModal } = useModal();

  const activeTheme = useTheme();
  const isDarkMode = [THEMES.RED_DARK, THEMES.BLUE_DARK].includes(
    activeTheme.name
  );

  const getClassName = (guest, isDarkMode) => {
    if (!guest) {
      return styles.container;
    }

    if (guest && isDarkMode) {
      return styles.containerGuestDark;
    }

    return styles.containerGuest;
  };

  return (
    <Container
      maxWidth="md"
      className={getClassName(guest, isDarkMode)}
      classes={{ maxWidthMd: styles.width }}
    >
      {!guest && (
        <Grid className={styles.topContainer}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            className={styles.cellContainer}
          >
            <Typography className={styles.cellTitle}>
              Delivery Address:
            </Typography>
            <Typography className={styles.cellSubtitle}>
              {getAddressWithPostcode(parcel.deliveryDetails.address)}
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            className={styles.cellContainer}
          >
            <Typography className={styles.cellTitle}>Parcel Number:</Typography>
            <Typography className={styles.cellSubtitle}>
              {parcel.parcelNumber}
            </Typography>
          </Grid>
          {parcel?.partnerRef[0]?.carrierRef &&
            parcel?.partnerRef[0]?.carrierURL && (
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                className={styles.cellContainer}
              >
                <Typography className={styles.cellTitle}>
                  Delivery Partner:
                </Typography>
                <Typography className={styles.cellSubtitle}>
                  <button
                    className={styles.link}
                    onClick={() => {
                      showModal({
                        type: 'CONFIRMATION',
                        title: (
                          <div className={styles.modal}>
                            <ReportProblemOutlinedIcon
                              fontSize="large"
                              className={styles.icon}
                            />
                            <Typography variant="h6">
                              You're leaving our website
                            </Typography>
                            <Typography>
                              Please note we are not responsible for the content
                              of external websites.
                            </Typography>
                          </div>
                        ),
                        confirmLabel: 'Continue',
                        rejectLabel: 'Stay',
                        onConfirm: () => {
                          window.open(parcel.partnerRef[0].carrierURL);
                        },
                      });
                    }}
                  >
                    {parcel.partnerRef[0].carrierRef}
                  </button>
                </Typography>
              </Grid>
            )}
        </Grid>
      )}

      {guest &&
        parcel?.partnerRef[0]?.carrierRef &&
        parcel?.partnerRef[0]?.carrierURL && (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            className={styles.cellContainerPartner}
          >
            <Typography className={styles.cellTitle}>
              Delivery Partner:
            </Typography>
            <Typography className={styles.cellSubtitle}>
              <button
                className={styles.link}
                onClick={() => {
                  showModal({
                    type: 'CONFIRMATION',
                    title: (
                      <div className={styles.modal}>
                        <ReportProblemOutlinedIcon
                          fontSize="large"
                          className={styles.icon}
                        />
                        <Typography variant="h6">
                          You're leaving our website
                        </Typography>
                        <Typography>
                          Please note we are not responsible for the content of
                          external websites.
                        </Typography>
                      </div>
                    ),
                    confirmLabel: 'Continue',
                    rejectLabel: 'Stay',
                    onConfirm: () => {
                      window.open(parcel.partnerRef[0].carrierURL);
                    },
                  });
                }}
              >
                {parcel.partnerRef[0].carrierRef}
              </button>
            </Typography>
          </Grid>
        )}
      <Typography className={styles.title}>
        {WHERE_HAS_MY_PARCEL_BEEN}
      </Typography>
      <Table
        className={styles.eventsTable}
        data={events}
        titles={[
          { value: 'Date & Time' },
          { value: 'Location', className: styles.locationCellHeader },
          { value: 'Description' },
        ]}
        rowMap={(row: ParcelEvent) => [
          {
            className: styles.column,
            value: formatDayMonthYearTime(row.eventDate),
          },
          {
            className: styles.column,
            value: row.eventLocation,
          },
          {
            value: row.eventText,
          },
        ]}
      />
      <Typography className={styles.title}>{ADDITIONAL_INFO}</Typography>
      <Table
        className={guest ? styles.eventsTableGuest : styles.eventsTable}
        data={[parcel]}
        titles={[
          {
            value: 'Consignment Number',
          },
          {
            value: 'Sender`s Reference',
          },
          {
            value: 'Shipped Date',
          },
        ]}
        rowMap={(row: Parcel) => [
          {
            value: row.consignmentNumber,
          },
          {
            value: getSenderRefs(row),
          },
          {
            value: formatDayMonthYear(row.shipmentDate),
          },
        ]}
      />
    </Container>
  );
};

export default ParcelEventsDesktop;
