import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import Radio from '@dpdgroupuk/fmx-ui/components/Icons/Radio';

const useStyles = makeStyles(({ typography, breakpoints, palette }) => ({
  radioIcon: {
    '& .checkMark': {
      fill: palette.primary.main,
    },
  },
  row: {
    cursor: 'pointer',
    height: `${typography.pxToRem(50)}!important`,
  },
  listContainer: {
    marginBottom: typography.pxToRem(30),
    width: '45%',
    [breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  text: {
    fontSize: typography.pxToRem(19),
    lineHeight: 0.85,
    letterSpacing: typography.pxToRem(0.44),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
    },
  },
  secondaryText: {
    color: '#1d9ad0',
  },
}));

type Props = {
  items: Array<any>,
  active: any,
  onSelect: Function,
  format?: Function,
  formatSecondary?: Function,
  classes?: {
    listContainer?: string,
    row?: string,
    radioIcon?: string,
    text?: string,
    secondaryText?: string,
  },
};

const TimeSlotList = ({
  items,
  active,
  onSelect,
  format = item => item,
  formatSecondary,
  classes = {},
}: Props) => {
  const styles = useStyles();

  return (
    <Grid className={clsx(styles.listContainer, classes.listContainer)}>
      <GridList cols={1} cellHeight="auto">
        {items.map((el, i) => {
          return (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              key={i}
              className={clsx(styles.row, classes.row)}
              onClick={() => onSelect(el)}
            >
              <Typography className={clsx(styles.text, classes.text)}>
                {format(el)}
              </Typography>
              {formatSecondary ? (
                <Typography
                  className={clsx(
                    styles.text,
                    styles.secondaryText,
                    classes.text,
                    classes.secondaryText
                  )}
                >
                  {formatSecondary(el)}
                </Typography>
              ) : null}
              <Radio
                selected={el === active}
                className={clsx(styles.radioIcon, classes.radioIcon)}
              />
            </Grid>
          );
        })}
      </GridList>
    </Grid>
  );
};

export default TimeSlotList;
