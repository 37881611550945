import { attr, many, Model } from 'redux-orm';
import type { PayloadAction } from '@dpdgroupuk/redux-action-creator';

import { ACTIONS } from './actions';
import { ENTITY_NAME } from '../constants';

export class Parcel extends Model {
  // eslint-disable-next-line no-use-before-define
  static reducer(action: PayloadAction, Parcel: Parcel) {
    if (action.type === ACTIONS.FETCH_PARCEL_BY_CODE.SUCCESS) {
      Parcel.create({
        ...action.payload,
      });
    }
  }

  static modelName = ENTITY_NAME.PARCEL;

  static fields = {
    parcelCode: attr(),
    events: many(ENTITY_NAME.EVENT),
  };

  static options = {
    idAttribute: 'parcelCode',
  };
}
