import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { fetchCacheableParcel } from './actions/parcel';
import withParcel from '../../HOCs/withParcel';
import ParcelRoutes from './routes';
import withFetch from '../../HOCs/withFetch';
import withQueryParams from '../../HOCs/withQueryParams';

export default compose(
  withRouter,
  withQueryParams(),
  withFetch(fetchCacheableParcel),
  withParcel()
)(ParcelRoutes);
