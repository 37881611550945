export const ROAD_MAP_STEP_TYPE = {
  TITLE: 'TITLE',
  ITEM: 'ITEM',
};

export const NEXT_STEPS = {
  NO_NEXT_STEP: 'N',
  FIRST: '1',
  FIRST_SHOP: '1S',
  FIRST_SECOND: '12',
  FIRST_SECOND_SHOP: '12S',
  SECOND: '2',
  SECOND_SHOP: '2S',
};
