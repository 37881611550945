import api from './api';

export const submitDutyPayment = (
  parcelCode: string,
  body: {
    paymentNonce: string,
    paymentReference: string,
    total: string,
    contactEmail: string,
  }
) => {
  return api.post({
    path: `/parcels/${parcelCode}/actions/dty`,
    body,
  });
};

export const submitCancellationPayment = (parcelCode: string) => {
  return api.post({
    path: `/parcels/${parcelCode}/actions/dty/refuseDuty`,
  });
};

export const getParcelDuty = (parcelCode: string) => {
  return api.get({
    path: `/parcels/${parcelCode}/actions/dty`,
  });
};

export const getCustomsDescription = (
  parcelCode: string,
  declaredConsignmentNumber: string,
  dataDate: string
) => {
  return api.get({
    path: `/parcels/${parcelCode}/customsDescription`,
    query: { declaredConsignmentNumber, dataDate },
  });
};
