import type { ChangeDeliveryOptionOptions } from '../../../../../apis';
import { changeDeliveryOption } from '../../../../../store/orm/Parcel/actions';

export type ContactInfoData = {
  contactEmail: string,
  contactPhone: string,
};

export type ContactDetails = {
  contactDetails: ContactInfoData,
};

export const sendContactInfo = (
  parcelCode: string,
  contactDetails: ContactDetails,
  options: ChangeDeliveryOptionOptions
) => (dispatch: Function) =>
  dispatch(changeDeliveryOption(parcelCode, contactDetails, options));
