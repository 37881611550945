import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import pickBy from 'lodash/pickBy';
import isNil from 'lodash/isNil';
import negate from 'lodash/negate';

import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { withOverlay } from '@dpdgroupuk/fmx-ui/components/Overlay';

import { fetchParcel } from '../../store/actions/parcels';
import { sendDeliveryFeedback, bookCallMeSlot } from './actions';
import { RATE_TYPE } from '../../constants/rate';
import { catchRequestError } from '../../utils/errorHandling';
import { RATE_STEPS } from './steps';
import { submitFeedbackForm } from './api';
import { goToTracking } from '../historyActions';
import { DELIVERY_COMPLIMENTS } from './constants';
import { withRemoteConfig } from '../../components/RemoteConfig/ConfigContext';

const rateDelivery = async (data, parcel, params, driver, dispatch) => {
  const body = {
    driverCode: null,
    experienceComplimentType: null,
    driverComplimentType: null,
    driverRating: null,
    driverNotes: null,
    like: data.deliveryRating === RATE_TYPE.LIKE,
  };

  const res = await dispatch(
    sendDeliveryFeedback(
      parcel.parcelCode,
      pickBy(
        {
          ...body,
          ...data,
          ...params,
          driverCode: driver.driverCode,
        },
        negate(isNil)
      )
    )
  );
  dispatch(fetchParcel(parcel.parcelCode));

  return res;
};

const bookCallMe = async (data, parcel, dispatch) => {
  return dispatch(
    bookCallMeSlot({
      parcelCode: parcel.parcelCode,
      slot: data.selectedDate,
      slotTime: data.selectedTime,
      contactNumber: data.contactNumber,
      rating: {
        like: data.deliveryRating === RATE_TYPE.LIKE,
        experienceComplimentType: data.experienceComplimentType,
      },
    })
  );
};

export const withDeliveryFeedback = () =>
  compose(
    withRouter,
    withModal,
    withOverlay,
    withRemoteConfig(),
    connect(
      null,
      (
        dispatch,
        {
          overlay,
          parcel,
          driver,
          modal,
          history,
          remoteConfig: { useSalesforceForm },
        }
      ) => ({
        onSubmitFailure: error => catchRequestError({ error, modal, history }),
        onSubmitSuccess: ({
          data: {
            deliveryRating,
            experienceComplimentType,
            driverRating,
            salesforceValues,
            recaptchaToken,
            driverComplimentType,
            fromNegativeCallMe,
            bookCallMe,
          },
          goToStep,
        }) => {
          const dislikeButReceived =
            deliveryRating === RATE_TYPE.DISLIKE &&
            experienceComplimentType !==
              DELIVERY_COMPLIMENTS.HAVE_NOT_GOT_MY_PARCEL;
          const dislikedNotReceivedNoForm =
            deliveryRating === RATE_TYPE.DISLIKE &&
            experienceComplimentType ===
              DELIVERY_COMPLIMENTS.HAVE_NOT_GOT_MY_PARCEL &&
            !useSalesforceForm;

          if (fromNegativeCallMe || bookCallMe) {
            goToStep(RATE_STEPS.RATE_DELIVERY_CALL_ME_CONFIRMATION);
          } else if (
            dislikeButReceived ||
            dislikedNotReceivedNoForm ||
            (deliveryRating === RATE_TYPE.LIKE && !driverRating)
          ) {
            goToStep(RATE_STEPS.DELIVERY_NO_INVESTIGATION_COMPLETE);
          } else if (driverComplimentType) {
            goToStep(RATE_STEPS.DRIVER_COMPLETE);
          } else if (salesforceValues && recaptchaToken) {
            submitFeedbackForm({
              ...salesforceValues,
              recaptcha: recaptchaToken,
            }).then(() => goToTracking({ history, parcel }));
          }
        },
        onDeliveryRate: overlay.showWhile(async (data, params) => {
          if (data.bookCallMe) {
            return bookCallMe(data, parcel, dispatch);
          }

          return rateDelivery(data, parcel, params, driver, dispatch);
        }),
      })
    )
  );
