import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Link from '@material-ui/core/Link';
import type { SearchParcel } from '@dpdgroupuk/fmx-ui/types';
import Table from '@dpdgroupuk/fmx-ui/components/Table';
import { formatDayShortMonthYear } from '@dpdgroupuk/fmx-ui/utils/date';

const useStyles = makeStyles(({ palette, typography }) => ({
  eventsTable: {
    marginTop: typography.pxToRem(15),
  },
  column: {
    minWidth: typography.pxToRem(200),
  },
  link: {
    fontWeight: 300,
    lineHeight: 1.07,
    letterSpacing: 0.44,
    color: palette.text.primary,
  },
}));

type Props = {
  parcelData: Array<SearchParcel>,
  onSelectParcel: (parcelCode: string) => void,
};

const SearchResultsTable = ({ parcelData, onSelectParcel }: Props) => {
  const styles = useStyles();

  return (
    <Table
      className={styles.eventsTable}
      data={parcelData}
      titles={[
        { value: 'Parcel No.' },
        { value: 'Collection Date' },
        { value: 'Delivery Status' },
      ]}
      rowMap={(row: SearchParcel) => [
        {
          className: styles.column,
          value: (
            <Link
              variant="button"
              component="button"
              className={styles.link}
              onClick={() => onSelectParcel(row.parcelCode)}
            >
              {row.parcelNumber}
            </Link>
          ),
        },
        {
          className: styles.column,
          value: row.collectionDate
            ? formatDayShortMonthYear(row.collectionDate)
            : '',
        },

        {
          className: styles.column,
          value: row.parcelStatus,
        },
      ]}
    />
  );
};

export default SearchResultsTable;
