import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FallBackImage from '@dpdgroupuk/fmx-ui/components/FallBackImage';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  container: ({ boxShadow }) => ({
    position: 'relative',
    [breakpoints.down('xs')]: {
      minWidth: typography.pxToRem(100),
      minHeight: typography.pxToRem(100),
      border: '3px solid #fff',
    },
    [breakpoints.up('sm')]: {
      minWidth: typography.pxToRem(100),
      minHeight: typography.pxToRem(100),
      border: '5px solid #fff',
      boxShadow: boxShadow && '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    },
  }),
  img: {
    display: 'flex',
    [breakpoints.down('xs')]: {
      maxWidth: '100%',
      maxHeight: typography.pxToRem(220),
    },
    [breakpoints.up('sm')]: {
      maxWidth: typography.pxToRem(580),
      maxHeight: typography.pxToRem(350),
    },
  },
  closeButton: {
    position: 'absolute',
    right: 2,
    top: 2,
    [breakpoints.down('xs')]: {
      paddingLeft: typography.pxToRem(20),
      paddingBottom: typography.pxToRem(20),
    },
  },
  closeIcon: {
    fill: '#000',
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
    },
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(20),
    },
  },
}));

type Props = {
  mapSrc: string,
  onClose: Function,
  boxShadow?: boolean,
  className?: string,
};

const Mapbox = ({ mapSrc, onClose, boxShadow = true, className }: Props) => {
  const styles = useStyles({ boxShadow });

  return (
    <Grid className={clsx(styles.container, className)}>
      <IconButton
        aria-label="close"
        size="small"
        className={styles.closeButton}
        onClick={onClose}
      >
        <CloseIcon className={styles.closeIcon} />
      </IconButton>
      <FallBackImage className={styles.img} src={mapSrc} alt="" />
    </Grid>
  );
};

export default Mapbox;
