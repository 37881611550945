import React from 'react';
import { compose } from 'redux';
import { useTracker } from '@dpdgroupuk/react-event-tracker';
import { withRouter } from 'react-router-dom';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import type { Parcel } from '@dpdgroupuk/fmx-ui/types/Parcel';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { useEnhancedReCaptchaBadge as useGoogleReCaptchaVisibleToggle } from '@dpdgroupuk/fmx-ui/components/GoogleRecaptcha';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';

import {
  LITE_VIEW_TRACKING,
  trackable,
} from '../../../../../constants/analytics';
import TrackingPostcodeForm from './TrackingPostcodeForm';
import TrackingStatus from '../../../../../components/TrackingStatus';
import {
  backToDpdSiteHeader,
  backToDpdSiteSpeedDeal,
  getDpdAppHeader,
  goToParcelInfoHeader,
  backToGreenSiteSpeedDeal,
  getDpdAppSpeedDeal,
  trackAnotherParcelHeader,
  backToGreenSiteHeader,
} from '../../../../controlActions';
import { withParcelInfo } from '../../../hocs';
import { usePostcodeSubmitCallback } from '../../../hooks';
import withParcel from '../../../../../HOCs/withParcel';
import withFunctionalityLog from '../../../../../HOCs/withFunctionalityLog';
import { MORE_COOL_STUFF } from '../../../../../constants/message';

type Props = {
  parcel: Parcel,
};

const TrackingLight = ({ parcel }: Props) => {
  useGoogleReCaptchaVisibleToggle();
  const tracker = useTracker();
  const onSubmit = usePostcodeSubmitCallback();

  const onFormTrackableSubmit = React.useCallback(
    (...args) => {
      tracker.logEvent(LITE_VIEW_TRACKING.SUBMIT);
      return onSubmit(...args);
    },
    [onSubmit, tracker]
  );

  return (
    <>
      <TrackingStatus status={parcel.parcelStatusHtml} />
      <TrackingPostcodeForm onPostcodeSubmit={onFormTrackableSubmit} />
    </>
  );
};
const createHeaderControlActions = props => [
  goToParcelInfoHeader(props),
  trackAnotherParcelHeader(props),
  backToDpdSiteHeader(props),
  backToGreenSiteHeader(props),
  getDpdAppHeader(props),
];

const createSpeedDealControlActions = props => [
  backToDpdSiteSpeedDeal(props),
  getDpdAppSpeedDeal(props),
  backToGreenSiteSpeedDeal(props),
];

export default compose(
  withRouter,
  withModal,
  withParcel(),
  withPageControl({
    title: MORE_COOL_STUFF,
    backgroundImage: IMAGES.TRACKING_BG,
    trackable: trackable(LITE_VIEW_TRACKING),
    headerMenuActions: createHeaderControlActions,
    speedDealActions: createSpeedDealControlActions,
  }),
  withParcelInfo({
    paddingLeft410: 10,
    paddingRight410: 10,
    withParcelEvents: true,
  }),
  withFunctionalityLog('j8wb8LnqleXQiBK0BRTN')
)(TrackingLight);
