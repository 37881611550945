import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import { InputFormField } from '@dpdgroupuk/fmx-ui/components/Input';
import Button from '@dpdgroupuk/fmx-ui/components/Button';
import FormWithSubmit from '@dpdgroupuk/fmx-ui/components/FormWithSubmit';
import { INVALID_POSTCODE } from '@dpdgroupuk/fmx-ui/constants/validation';
import { maxLength16, required } from '@dpdgroupuk/fmx-ui/utils/validation';

import {
  TRACKING_POSTCODE_HEADER,
  SUBMIT,
} from '../../../../../constants/message';

const useStyles = makeStyles(({ breakpoints, typography }) => ({
  container: {
    width: '100%',
    padding: `${typography.pxToRem(40)} ${typography.pxToRem(25)} 0`,
    [breakpoints.down('sm')]: {
      padding: `${typography.pxToRem(40)} ${typography.pxToRem(25)} 0`,
    },
    [breakpoints.down('xs')]: {
      padding: `${typography.pxToRem(40)} ${typography.pxToRem(5)} 0`,
    },
  },
  title: {
    marginBottom: typography.pxToRem(45),
    fontSize: typography.pxToRem(20),
    lineHeight: 1.5,
    letterSpacing: 0.45,
    fontWeight: 400,
    textAlign: 'center',
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
      maxHeight: typography.pxToRem(50),
      maxWidth: typography.pxToRem(296),
      lineHeight: 1.69,
      marginBottom: typography.pxToRem(35),
    },
  },
  postcode: {
    flex: 1,
    marginRight: typography.pxToRem(20),
    [breakpoints.down('xs')]: {
      marginBottom: typography.pxToRem(14),
    },
    [breakpoints.down(330)]: {
      marginRight: 0,
      marginBottom: '1.2rem',
    },
  },
  form: {
    [breakpoints.down(330)]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}));

type Props = {
  handleSubmit: Function => Function,
  onPostcodeSubmit: Function,
  valid: boolean,
  submitting: boolean,
};

const validatePostcode = [required(INVALID_POSTCODE), maxLength16];

const TrackingPostcodeForm = ({
  handleSubmit,
  valid,
  submitting,
  onPostcodeSubmit,
}: Props) => {
  const styles = useStyles();

  return (
    <Grid container justify="center" className={styles.container}>
      <Grid>
        <Typography component="h2" className={styles.title}>
          {TRACKING_POSTCODE_HEADER}
        </Typography>
      </Grid>
      <Grid>
        <FormWithSubmit
          onSubmit={handleSubmit(onPostcodeSubmit)}
          className={styles.form}
        >
          <Field
            name="postcode"
            component={InputFormField}
            placeholder="Postcode"
            validate={validatePostcode}
            className={styles.postcode}
            inputProps={{
              maxLength: 16,
            }}
          />
          <Button type="submit" disabled={!valid || submitting}>
            {SUBMIT}
          </Button>
        </FormWithSubmit>
      </Grid>
    </Grid>
  );
};

export default reduxForm({ form: 'trackingPostcode' })(TrackingPostcodeForm);
