import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { startCase } from 'lodash';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes/constants';
import { isMobile } from '@dpdgroupuk/fmx-ui/utils/helpers';

import { PARCEL_INFO, trackable } from '../../../constants/analytics';
import {
  goBackToDeliveryDetailsSpeedDeal,
  backToDpdSiteSpeedDeal,
  trackAnotherParcelSpeedDeal,
  getDpdAppSpeedDeal,
  backToGreenSiteSpeedDeal,
  trackAnotherParcelHeader,
  backToDpdSiteHeader,
  backToGreenSiteHeader,
  getDpdAppHeader,
} from '../../../pages/controlActions';
import { fetchParcelEvents } from '../actions';
import { getParcelData } from '../../../store/orm/Parcel/selectors';
import { getParcelEvents } from '../../../store/orm/Event/selectors';
import { getBackgroundImage } from '../../../utils/images';
import withFetch from '../../../HOCs/withFetch';
import ParcelEventsMobile from './ParcelEventsMobile';
import ParcelEventsDesktop from './ParcelEventsDesktop';
import * as MESSAGE from '../../../constants/message';

const createSpeedDealControlActions = props => {
  if (props.guest) {
    return [
      backToDpdSiteSpeedDeal(props),
      getDpdAppSpeedDeal(props),
      backToGreenSiteSpeedDeal(props),
    ];
  }

  return [
    backToDpdSiteSpeedDeal(props),
    trackAnotherParcelSpeedDeal(props),
    goBackToDeliveryDetailsSpeedDeal(props),
  ];
};
const mapStateToProps = (state, { match }) => {
  const parcelId = match.params.parcelId;

  return {
    events: getParcelEvents(state, parcelId),
    parcel: getParcelData(state, parcelId),
  };
};

const createHeaderControlActions = props => [
  trackAnotherParcelHeader(props),
  backToDpdSiteHeader(props),
  backToGreenSiteHeader(props),
  getDpdAppHeader(props),
];

const ParcelEvents = compose(
  withRouter,
  withFetch(fetchParcelEvents),
  connect(mapStateToProps),
  withPageControl({
    title: startCase(MESSAGE.PARCEL_INFO),
    backgroundImage: getBackgroundImage({
      desktopImage: IMAGES.DRIVER_BG,
      mobileImage: IMAGES.DRIVER_BG_MOBILE,
    }),
    backgroundOpacity: 0.87,
    trackable: trackable(PARCEL_INFO),
    speedDealActions: createSpeedDealControlActions,
    headerMenuActions: createHeaderControlActions,
  })
)(isMobile ? ParcelEventsMobile : ParcelEventsDesktop);

export default ParcelEvents;
