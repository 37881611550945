import React from 'react';
import { makeStyles } from '@material-ui/core';

import EmptyPageComponent from '../../../components/EmptyPageComponent';
import { NO_DELIVERY_OPTIONS } from '../../../constants/message';

const useStyles = makeStyles(({ typography }) => {
  return {
    wrapper: {
      marginTop: typography.pxToRem(100),
    },
  };
});

const NoDeliveryOptions = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <EmptyPageComponent value={NO_DELIVERY_OPTIONS} />
    </div>
  );
};

export default NoDeliveryOptions;
