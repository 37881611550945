// @flow
import React from 'react';
import clsx from 'clsx';
import { withProps } from 'recompose';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTracker, withTrackProps } from '@dpdgroupuk/react-event-tracker';
import CallMePhoneIcon from '@dpdgroupuk/fmx-ui/components/Icons/CallMePhoneIcon';

import Button from '../../../components/Rate/Button';
import { goToReferenceEntry } from '../../historyActions';
import {
  RATE_DELIVERY_CALL_ME_CONFIRMATION,
  RATE_DELIVERY_CALL_ME_SLOT_CONFIRMATION,
} from '../../../constants/analytics';
import * as MESSAGE from '../../../constants/message';

const useStyles = makeStyles(({ typography, palette, breakpoints }) => ({
  homeButtonBlock: {
    marginTop: typography.pxToRem(60),
    [breakpoints.down('xs')]: {
      marginTop: typography.pxToRem(40),
    },
  },
  text: {
    fontSize: typography.pxToRem(20),
    letterSpacing: typography.pxToRem(0.64),
    color: palette.text.primary,
    marginBottom: typography.pxToRem(20),
    marginTop: typography.pxToRem(30),
  },
  chatSoonText: {
    letterSpacing: typography.pxToRem(0.71),
  },
  dislikeText: {
    maxWidth: '55%',
    [breakpoints.down('xs')]: {
      maxWidth: '80%',
    },
  },
  callMePhoneIcon: {
    height: typography.pxToRem(109),
    width: typography.pxToRem(109),
  },
}));

type Props = {
  onHome: Function,
  data: Object,
};

const RateDeliveryCallMeConfirmation = ({ onHome, data }: Props) => {
  const styles = useStyles();
  const { fromNegativeCallMe } = data;

  const track = useTracker();
  React.useEffect(() => {
    if (fromNegativeCallMe) {
      track.setInterfaceId(RATE_DELIVERY_CALL_ME_CONFIRMATION.INTERFACE_ID);
      track.logEvent(RATE_DELIVERY_CALL_ME_CONFIRMATION.LOAD);
    } else {
      track.setInterfaceId(
        RATE_DELIVERY_CALL_ME_SLOT_CONFIRMATION.INTERFACE_ID
      );
      track.logEvent(RATE_DELIVERY_CALL_ME_SLOT_CONFIRMATION.LOAD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid>
      <CallMePhoneIcon className={styles.callMePhoneIcon} />

      <Typography className={styles.text}>{MESSAGE.THANKS}</Typography>

      <Typography
        className={clsx(styles.text, styles.chatSoonText, {
          [styles.dislikeText]: fromNegativeCallMe,
        })}
      >
        {fromNegativeCallMe ? MESSAGE.DEPOT_MANAGER : MESSAGE.CHAT_SOON}
      </Typography>

      <div className={styles.homeButtonBlock}>
        <Button onClick={onHome}>{MESSAGE.HOME}</Button>
      </div>
    </Grid>
  );
};

export default compose(
  withRouter,
  withProps<any, any>(({ history }: any) => ({
    onHome: () => goToReferenceEntry(history),
  })),
  withTrackProps(({ data: { fromNegativeCallMe } }) => ({
    onHome: fromNegativeCallMe
      ? RATE_DELIVERY_CALL_ME_CONFIRMATION.ON_HOME
      : RATE_DELIVERY_CALL_ME_SLOT_CONFIRMATION.ON_HOME,
  }))
)(RateDeliveryCallMeConfirmation);
