import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withSnackbar } from 'notistack';
import { lifecycle } from 'recompose';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core';
import GoogleReCaptchaProvider from '@dpdgroupuk/react-google-recaptcha-v3';
import { scrollToTop } from '@dpdgroupuk/fmx-ui/utils/helpers';
import Page from '@dpdgroupuk/fmx-ui/components/Page';
import { withTrackProps, useTracker } from '@dpdgroupuk/react-event-tracker';
import { isOldBrowser } from '@dpdgroupuk/fmx-ui/utils/getBrowserVersion';

import { ReferenceEntry } from './pages/ReferenceEntry';
import SearchResult from './pages/SearchResults';
import * as ROUTES from './constants/routes';
import Parcel from './pages/Parcel';
import { FOOTER, HEADER, SPEED_DEAL } from './constants/analytics';
import ErrorPage from './pages/Error';
import { OLD_BROWSER_ERROR } from './constants/error';

export const REACT_APP_RECAPTURE_SITE_KEY: string =
  process.env.REACT_APP_RECAPTURE_SITE_KEY || '';

const Routes = ({ onSwitchTheme, onOpenCloseSpeedDeals }) => {
  const { pathname } = useLocation();
  const tracker = useTracker();

  React.useEffect(scrollToTop, [pathname]);

  tracker.setContext({
    userAgent: navigator.userAgent,
  });

  return (
    <Page
      analytics={FOOTER}
      pathname={pathname}
      onSwitchTheme={onSwitchTheme}
      onOpenCloseSpeedDeals={onOpenCloseSpeedDeals}
    >
      <Switch>
        <Route exact path={ROUTES.REFERENCE_ENTRY} component={ReferenceEntry} />
        <Route path={ROUTES.SEARCH} component={ReferenceEntry} />
        <Route exact path={ROUTES.PARCELS} component={SearchResult} />
        <Route path={ROUTES.PARCEL} component={Parcel} />
        <Route path={ROUTES.ERROR} component={ErrorPage} />
        <Redirect
          to={{
            pathname: '/error',
            search: '?status=404',
          }}
        />
      </Switch>
    </Page>
  );
};

Routes.propTypes = {
  onSwitchTheme: PropTypes.func,
  onOpenCloseSpeedDeals: PropTypes.func,
};

const TrackableRoutes = withTrackProps({
  onSwitchTheme: themeName => ({ actionId: HEADER.TOGGLE_DARK, themeName }),
  onOpenCloseSpeedDeals: open => ({
    actionId: SPEED_DEAL.TOGGLE_HIDE_SHOW,
    open,
  }),
})(Routes);

const RoutesWrapper = () => {
  const theme = useTheme();

  return (
    <Grid container direction="column" alignContent="stretch" wrap="nowrap">
      <GoogleReCaptchaProvider
        sitekey={REACT_APP_RECAPTURE_SITE_KEY}
        visibility="hidden"
        theme={theme.palette.type}
        version="v2"
      >
        <BrowserRouter>
          <TrackableRoutes />
        </BrowserRouter>
      </GoogleReCaptchaProvider>
    </Grid>
  );
};

export default compose(
  withSnackbar,
  lifecycle({
    async componentDidMount() {
      if (isOldBrowser()) {
        this.props.enqueueSnackbar(OLD_BROWSER_ERROR, {
          variant: 'error',
          autoHideDuration: 20000,
        });
      }
    },
  })
)(RoutesWrapper);
