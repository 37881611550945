import React from 'react';
import { compose } from 'redux';
import { withProps } from 'recompose';
import { makeStyles } from '@material-ui/styles';

import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes/constants';
import * as ENUM from '@dpdgroupuk/redback-enums';
import Wizard, { Step } from '@dpdgroupuk/fmx-ui/components/Wizard';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';

import {
  KMI_DELIVERY_OPTION,
  trackable,
} from '../../../../constants/analytics';
import {
  whatHappensNextSpeedDeal,
  doSomethingDifferentSpeedDeal,
  trackAnotherParcelHeader,
  backToDpdSiteHeader,
  backToGreenSiteHeader,
  getDpdAppHeader,
  whatHappensNextHeader,
  goBackToDeliveryDetailsSpeedDeal,
  doSomethingDifferentHeader,
} from '../../../controlActions';
import * as MESSAGES from '../../../../constants/message';
import CompleteStep from './components/CompleteStep';
import type { AuthorizedParcel } from '../../../../types';
import withParcel from '../../../../HOCs/withParcel';
import { KMI_STEPS } from '../../steps';
import { withDeliveryUpdate } from '../../hocs';
import { sendContactInfo } from './actions/keepMeInformed';
import GetContactDetailsNoEmailStep from '../../components/GetContactDetailsNoEmailStep';
import EnterCodeStep from '../../components/EnterCodeStep';
import StarterElement from './components/StarterElement';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  completeStep: {
    paddingLeft: typography.pxToRem(120),
    paddingRight: typography.pxToRem(120),
    [breakpoints.down('xs')]: {
      paddingLeft: typography.pxToRem(15),
      paddingRight: typography.pxToRem(15),
    },
  },
}));

type Props = {
  parcel: AuthorizedParcel,
  updateDelivery: Function,
  onSubmitSuccess: Function,
  onSubmitFailure: Function,
};

const KeepMeInformedComponent = ({
  parcel,
  updateDelivery,
  onSubmitSuccess,
  onSubmitFailure,
}: Props) => {
  const styles = useStyles();

  return (
    <Wizard
      initialStep={KMI_STEPS.STARTER_ELEMENT}
      onSubmit={updateDelivery}
      onSubmitSuccess={onSubmitSuccess}
      onSubmitFailure={onSubmitFailure}
    >
      <Step
        stepId={KMI_STEPS.STARTER_ELEMENT}
        parcel={parcel}
        component={StarterElement}
      />
      <Step
        stepId={KMI_STEPS.CONTACT_INFO}
        parcel={parcel}
        component={GetContactDetailsNoEmailStep}
        nextStep={KMI_STEPS.ENTER_CODE}
      />
      <Step
        stepId={KMI_STEPS.ENTER_CODE}
        component={EnterCodeStep}
        parcel={parcel}
      />
      <Step
        stepId={KMI_STEPS.COMPLETE}
        parcel={parcel}
        component={CompleteStep}
        actionCode={ENUM.PARCEL_ACTION_CODE.KMI}
        wrapperClassName={styles.completeStep}
      />
    </Wizard>
  );
};

const createSpeedDealControlActions = props => [
  doSomethingDifferentSpeedDeal(props),
  goBackToDeliveryDetailsSpeedDeal(props),
  whatHappensNextSpeedDeal(props),
];

const createHeaderControlActions = props => [
  doSomethingDifferentHeader(props),
  trackAnotherParcelHeader(props),
  whatHappensNextHeader(props),
  backToDpdSiteHeader(props),
  backToGreenSiteHeader(props),
  getDpdAppHeader(props),
];

const KeepMeInformed = compose(
  withParcel(),
  withModal,
  withDeliveryUpdate({
    actionCode: ENUM.PARCEL_ACTION_CODE.KMI,
    changeDeliveryAction: sendContactInfo,
    trackable: KMI_DELIVERY_OPTION.ON_SUBMIT,
  }),
  withProps(() => ({
    onSubmitSuccess: ({ goToStep }) => goToStep(KMI_STEPS.COMPLETE),
  })),
  withPageControl({
    title: MESSAGES.KEEP_ME_INFORMED,
    // @TODO: set proper bg image
    backgroundImage: IMAGES.MAP,
    trackable: trackable(KMI_DELIVERY_OPTION),
    speedDealActions: createSpeedDealControlActions,
    headerMenuActions: createHeaderControlActions,
  })
)(KeepMeInformedComponent);

export default KeepMeInformed;
