import type { ControlAction } from '@dpdgroupuk/fmx-ui/components/Page';

import * as ROUTES from '../constants/routes';
import * as MESSAGE from '../constants/message';
import * as EXTERNAL_ROUTES from '../constants/externalRoutes';
import { SPEED_DEAL, HEADER } from '../constants/analytics';
import { getDriverId } from '../models/driver';
import { pathToAction } from '../utils/path';
import {
  getLocationDeliveryOptions,
  getLocationDriverProfile,
  getLocationEvents,
  getLocationTracking,
  getLocationWhatHappendNext,
  getLocationShowMap,
} from './Parcel/actions/location';
import {
  deleteToken,
  isMessagingSupported,
  isTokenSentToServer,
  requestToken,
} from '../utils/firebase';
import { isIgnorableFirebaseError } from '../utils/errorHandling';
import { API_HOST } from '../constants/app';

export const printPickupPass = actionId => [
  MESSAGE.PRINT_PICKUP_PASS,
  window.print,
  actionId,
];

export const showTbcMap = ({ actionId, showMap, toggleMap }) => [
  showMap ? MESSAGE.BACK_TO_DETAILS : MESSAGE.SHOW_MAP,
  toggleMap,
  actionId,
];

export const learnAboutYourDriver = (
  { parcel, route }: any,
  actionId: string | object
) => [
  MESSAGE.LEARN_ABOUT_YOUR_DRIVER,
  getLocationDriverProfile({
    parcelId: parcel.parcelCode,
    driverId: getDriverId(route),
  }),
  actionId,
];

export const learnAboutYourDriverHeader = props =>
  learnAboutYourDriver(props, HEADER.LEARN_MORE_ABOUT_DRIVER);

export const learnAboutYourDriverSpeedDeal = props =>
  pathToAction(
    props,
    learnAboutYourDriver(props, SPEED_DEAL.LEARN_MORE_ABOUT_DRIVER)
  );

export const changeDelivery = ({ parcel }: any, actionId: string | object) => [
  MESSAGE.CHANGE_DELIVERY,
  getLocationDeliveryOptions({
    parcelId: parcel.parcelCode,
  }),
  actionId,
];

export const doSomethingDifferent = (
  { parcel }: any,
  actionId: string | object
) => [
  MESSAGE.DO_SOMETHING_DIFFERENT,
  getLocationDeliveryOptions({
    parcelId: parcel.parcelCode,
  }),
  actionId,
];

export const changeDeliveryHeader = props =>
  changeDelivery(props, HEADER.CHANGE_THIS_DELIVERY);

export const doSomethingDifferentSpeedDeal = props =>
  pathToAction(
    props,
    doSomethingDifferent(props, SPEED_DEAL.DO_SOMETHING_DIFFERENT)
  );

export const doSomethingDifferentHeader = props =>
  doSomethingDifferent(props, HEADER.DO_SOMETHING_DIFFERENT);

export const changeDeliverySpeedDeal = props =>
  pathToAction(props, changeDelivery(props, SPEED_DEAL.CHANGE_THIS_DELIVERY));

export const whatHappensNext = ({ parcel }: any, actionId: string | object) => [
  MESSAGE.WHAT_HAPPENS_NEXT_PAGE_HEADER,
  getLocationWhatHappendNext({
    parcelId: parcel.parcelCode,
  }),
  actionId,
];

export const whatHappensNextHeader = props =>
  whatHappensNext(props, HEADER.WHAT_HAPPENS_NEXT);

export const whatHappensNextSpeedDeal = props =>
  pathToAction(props, whatHappensNext(props, SPEED_DEAL.WHAT_HAPPENS_NEXT));

export const backToDpdSite = (props, actionId: string | object) => [
  MESSAGE.BACK_TO_DPD_SITE,
  {
    href: props.theme.dpdSiteUrl,
    target: '_blank',
  },
  actionId,
];

export const backToGreenSite = (props, actionId: string | object) => [
  MESSAGE.CLEAN_GREEN_DELIVERY,
  {
    href: EXTERNAL_ROUTES.DPD_GREEN_SITE_HTTP,
    target: '_blank',
  },
  actionId,
];

export const backToGreenSiteHeader = props =>
  backToGreenSite(props, HEADER.BACK_TO_GREEN_SITE);

export const backToGreenSiteSpeedDeal = props =>
  pathToAction(props, backToGreenSite(props, SPEED_DEAL.BACK_TO_GREEN_SITE));

export const backToDpdSiteHeader = props =>
  backToDpdSite(props, HEADER.BACK_TO_SITE);

export const backToDpdSiteSpeedDeal = props =>
  pathToAction(props, backToDpdSite(props, SPEED_DEAL.BACK_TO_SITE));

export const showMap = ({ parcel }: any, actionId: string | object) => [
  MESSAGE.SHOW_MAP,
  getLocationShowMap({
    parcelId: parcel.parcelCode,
  }),
  actionId,
];

export const showShopMapHeader = props => showMap(props, HEADER.SHOW_SHOP_MAP);

export const showShopMapSpeedDeal = props =>
  pathToAction(props, showShopMapHeader(props));

export const showDepotMapHeader = props =>
  showMap(props, HEADER.SHOW_DEPOT_MAP);

export const showDepotMapSpeedDeal = props =>
  pathToAction(props, showMap(props, HEADER.SHOW_DEPOT_MAP));

export const goToParcelInfo: ControlAction = (
  { parcel, replace }: any,
  actionId: string | object
) => [
  MESSAGE.PARCEL_INFO,
  getLocationEvents({
    parcelId: parcel.parcelCode,
  }),
  actionId,
  replace,
];

export const goToParcelInfoHeader = props =>
  props.replace
    ? pathToAction(props, goToParcelInfo(props, SPEED_DEAL.PARCEL_INFO_BUTTON))
    : goToParcelInfo(props, HEADER.PARCEL_INFO_BUTTON);

export const goToParcelInfoSpeedDeal = props =>
  pathToAction(props, goToParcelInfo(props, SPEED_DEAL.PARCEL_INFO_BUTTON));

export const goBackToDetails = ({ parcel }: any, actionId: string | object) => [
  MESSAGE.BACK_TO_DETAILS,
  getLocationTracking({
    parcelId: parcel.parcelCode,
  }),
  actionId,
];

export const goBackToDeliveryDetails = (
  { parcel }: any,
  actionId: string | object
) => [
  MESSAGE.DELIVERY_DETAILS,
  getLocationTracking({
    parcelId: parcel.parcelCode,
  }),
  actionId,
];

export const goBackToDeliveryDetailsHeader = props =>
  goBackToDeliveryDetails(props, HEADER.BACK_TO_DETAILS);

export const goBackToDeliveryDetailsSpeedDeal = props =>
  pathToAction(
    props,
    goBackToDeliveryDetails(props),
    SPEED_DEAL.BACK_TO_DETAILS
  );

export const viewTracking = ({ parcel }: any, actionId: string | object) => [
  MESSAGE.VIEW_TRACKING,
  getLocationTracking({
    parcelId: parcel.parcelCode,
  }),
  actionId,
];

export const viewTrackingSpeedDeal = props =>
  pathToAction(props, viewTracking(props));

export const getDpdApp = (_: any, actionId: string | object) => [
  MESSAGE.GET_DPD_APP,
  {
    href: EXTERNAL_ROUTES.DPD_LP_YOURDPD,
    target: '_blank',
  },
  actionId,
];

export const getDpdAppHeader = props => getDpdApp(props, HEADER.DOWN_LOAD_APP);

export const getDpdAppSpeedDeal = props =>
  pathToAction(props, getDpdApp(props));

export const realTimeDeliveryUpdate = (
  props: any,
  actionId: string | object
) => [
  MESSAGE.REAL_TIME_DELIVERY_UPDATE,
  async () => {
    if (!isMessagingSupported()) {
      await props.modal.showModal({
        title: MESSAGE.REAL_TIME_DELIVERY_UPDATE,
        description: MESSAGE.BROWSER_NOT_SUPPORTED,
        buttonText: 'OK',
      });
      return;
    }
    const parcelCode = props.parcel.parcelCode;
    if (!isTokenSentToServer(parcelCode)) {
      const modal = await props.modal.showModal({
        title: MESSAGE.REAL_TIME_DELIVERY_UPDATE,
        description: MESSAGE.PUSH_ALLOW_CONFIRM,
        buttonText: 'OK',
      });

      if (modal.reason) {
        return;
      }
      try {
        await requestToken(parcelCode);
      } catch (e) {
        if (!isIgnorableFirebaseError(e)) {
          // propagate error to see the details in sentry as unhandled promise rejection
          throw e;
        }
      }
    } else {
      const modal = await props.modal.showModal({
        title: MESSAGE.REAL_TIME_DELIVERY_UPDATE,
        description: MESSAGE.PUSH_DISALLOW_CONFIRM,
        buttonText: 'YES, OPT OUT',
      });
      if (modal.reason) {
        return;
      }
      await deleteToken(parcelCode);
    }
  },
  actionId,
];

export const realTimeDeliveryUpdateHeader = props =>
  realTimeDeliveryUpdate(props, HEADER.REAL_TIME_DELIVERY_UPDATES);

export const realTimeDeliveryUpdateSpeedDeal = props =>
  realTimeDeliveryUpdate(props, SPEED_DEAL.REAL_TIME_DELIVERY_UPDATES);

export const trackAnotherParcel = (
  { replace }: any,
  actionId: string | object
) => [
  MESSAGE.TRACK_ANOTHER_PARCEL,
  {
    pathname: ROUTES.REFERENCE_ENTRY,
  },
  actionId,
  replace,
];

export const trackAnotherParcelHeader = props => {
  const trackAnotherParcelAction = trackAnotherParcel(
    props,
    HEADER.TRACK_ANOTHER_PARCEL
  );
  return props.replace
    ? pathToAction(props, trackAnotherParcelAction)
    : trackAnotherParcelAction;
};

export const goBackToDetailsHeader = props =>
  props.replace
    ? pathToAction(props, goBackToDetails(props, HEADER.BACK_TO_DETAILS))
    : goBackToDetails(props, HEADER.BACK_TO_DETAILS);

export const trackAnotherParcelSpeedDeal = props =>
  pathToAction(
    props,
    trackAnotherParcel(props, SPEED_DEAL.TRACK_ANOTHER_PARCEL)
  );

export const goBackToDetailsSpeedDeal = props =>
  pathToAction(props, goBackToDetails(props, SPEED_DEAL.BACK_TO_DETAILS));

export const goToPollutrack = (props, actionId: string | object) => [
  MESSAGE.CHECK_YOUR_AIR,
  {
    href: `${API_HOST}/postcodes/${props.parcel.deliveryDetails.address.postcode}`,
    target: '_blank',
  },
  actionId,
];

export const goToPollutrackHeader = props =>
  goToPollutrack(props, HEADER.CHECK_YOUR_AIR);
