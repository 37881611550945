import * as React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { mapProps, withProps } from 'recompose';
import { makeStyles } from '@material-ui/styles';

import SelectNeighbourList from '@dpdgroupuk/fmx-ui/components/SelectNeighbourList';
import {
  withPageContext,
  withPageControl,
} from '@dpdgroupuk/fmx-ui/components/Page';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';
import {
  getAddressLine,
  sortAddresses,
} from '@dpdgroupuk/fmx-ui/utils/address';
import Button from '@dpdgroupuk/fmx-ui/components/Button';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { USER_TYPE } from '@dpdgroupuk/redback-enums';

import ChangeDeliveryDay from '../../components/ChangeDeliveryDay';
import { DTN_STEPS } from '../../steps';
import type { Address } from '../../../../components/SearchAddress/types';
import { filterNeighborsAddresses } from './model';
import {
  getContactInformation,
  hasParcelNotificationDetails,
} from '../../../../models/parcel';
import NoNeighbours from './NoNeighbours';
import withFetch from '../../../../HOCs/withFetch';
import { DTN_DELIVERY_OPTION } from '../../../../constants/analytics';
import { SUBMIT } from '../../../../constants/message';
import {
  doSomethingDifferentSpeedDeal,
  goBackToDeliveryDetailsSpeedDeal,
  whatHappensNextSpeedDeal,
} from '../../../controlActions';
import { ORIGIN } from '../../../../constants/values';
import { fetchParcelClaims } from '../../../../store/orm/Claim/actions';
import { fetchLocationsByPostcode } from '../../actions';

const useStyles = makeStyles(({ palette, typography, breakpoints }) => ({
  stepWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: palette.background.dialogSecondary,
    width: '100%',
    height: '100%',
    paddingBottom: typography.pxToRem(40),
    paddingTop: typography.pxToRem(17),
    [breakpoints.down('xs')]: {
      paddingBottom: typography.pxToRem(13),
    },
  },
  emptyNeighbourWrapper: {
    marginTop: typography.pxToRem(-20),
    marginBottom: typography.pxToRem(-20),
    backgroundColor: palette.background.modal,
    width: typography.pxToRem(443),
    [breakpoints.down('xs')]: {
      marginTop: typography.pxToRem(60),
      width: '100%',
      marginBottom: typography.pxToRem(-200),
      paddingBottom: typography.pxToRem(235),
    },
  },
  button: {
    [breakpoints.down('sm')]: {
      marginTop: typography.pxToRem(20),
    },
    marginTop: typography.pxToRem(60),
  },
}));

type Props = {
  data: Object,
  onDateChange: Function,
  onSubmit: Function,
  fetchedData: Array<Address>,
  onSelectAddress: Function,
};

const SelectNeighbourStep = ({
  data,
  onSubmit,
  onDateChange,
  fetchedData = [],
  onSelectAddress,
}: Props) => {
  const styles = useStyles();
  const { address, day } = data;

  if (fetchedData.length === 0) {
    return <NoNeighbours wrapperClassName={styles.emptyNeighbourWrapper} />;
  }

  return (
    <div className={styles.stepWrapper}>
      <ChangeDeliveryDay day={day} onDateChange={onDateChange} />
      <SelectNeighbourList
        title="Choose a neighbour for us to deliver to"
        addresses={fetchedData}
        onSelect={onSelectAddress}
        getAddressLabel={getAddressLine}
        selectedAddress={address}
      />
      <Button
        onClick={onSubmit}
        classes={{ root: styles.button }}
        style={{ visibility: address ? 'visible' : 'hidden' }}
      >
        {SUBMIT}
      </Button>
    </div>
  );
};

SelectNeighbourStep.defaultProps = {
  data: { address: null, day: '' },
};

const createSpeedDealControlActions = props => [
  doSomethingDifferentSpeedDeal(props),
  goBackToDeliveryDetailsSpeedDeal(props),
  whatHappensNextSpeedDeal(props),
];

export default compose(
  withRouter,
  withModal,
  withFetch(({ parcel }) => fetchParcelClaims(parcel.parcelCode), {
    dataPropMapper: claims => ({
      claims,
    }),
  }),
  withFetch(
    ({ parcel }) =>
      fetchLocationsByPostcode(parcel.deliveryDetails.address.postcode),
    {
      notFoundComponent: NoNeighbours,
    }
  ),
  mapProps(({ fetchedData, parcel, ...props }) => ({
    fetchedData: sortAddresses(filterNeighborsAddresses(parcel, fetchedData)),
    parcel,
    ...props,
  })),
  withProps(
    ({
      addStepData,
      parcel,
      submitWizard,
      goToStep,
      isCreateProfilePossible,
      claims,
    }) => {
      const onDeliveryUpdate = hasParcelNotificationDetails(parcel)
        ? submitWizard
        : () => goToStep(DTN_STEPS.GET_CONTACTS);

      return {
        onSelectAddress: address =>
          addStepData({ address, ...getContactInformation(parcel) }),
        onSubmit: () => {
          const { origin, userType } = claims;

          if (origin === ORIGIN.FMP && userType === USER_TYPE.RECEIVER) {
            addStepData({
              funcAfterAuth: isCreateProfilePossible
                ? () => goToStep(DTN_STEPS.DELIVERY_PREFERENCES)
                : submitWizard,
            });

            // Go to contact page before sms auth
            return goToStep(DTN_STEPS.GET_CONTACTS);
          }

          return isCreateProfilePossible
            ? goToStep(DTN_STEPS.DELIVERY_PREFERENCES)
            : onDeliveryUpdate();
        },
        onDateChange: () => {
          addStepData({ address: '' });
          goToStep(DTN_STEPS.SELECT_DAY);
        },
      };
    }
  ),
  withTrackProps({
    onSelectAddress: DTN_DELIVERY_OPTION.ON_SELECT_NEIGHBOUR,
    onDateChange: DTN_DELIVERY_OPTION.ON_DATE_CHANGE,
  }),
  withPageContext,
  withPageControl({
    speedDealActions: createSpeedDealControlActions,
    isFooterHidden: props =>
      !props.fetchedData || props.fetchedData.length === 0,
  })
)(SelectNeighbourStep);
