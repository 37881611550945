import * as React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@dpdgroupuk/fmx-ui/components/Button';

const useStyles = makeStyles(({ palette, typography }) => ({
  label: {
    fontSize: typography.pxToRem(21),
    lineHeight: 1.35,
    letterSpacing: 0.44,
    color: palette.text.primary,
  },
  text: {
    maxWidth: typography.pxToRem(270),
    fontSize: typography.pxToRem(19),
    fontWeight: 300,
    lineHeight: 1.45,
    letterSpacing: 0.44,
    color: palette.text.hint,
    marginTop: typography.pxToRem(40),
    marginBottom: typography.pxToRem(45),
    textAlign: 'center',
  },
  button: {
    width: typography.pxToRem(235),
    height: typography.pxToRem(40),
    paddingRight: 0,
    paddingLeft: 0,
    marginBottom: typography.pxToRem(25),
    background: palette.gradient.reject,
  },
  confirmButton: {
    background: palette.gradient.confirm,
  },
  rejectButtonLabel: {
    fontSize: typography.pxToRem(10),
    letterSpacing: 1,
  },
  confirmButtonLabel: {
    fontSize: typography.pxToRem(18),
    letterSpacing: 1,
  },
}));

type Props = {|
  onSubmit: (setPreferences: boolean) => Promise<any>,
  disabled: boolean,
|};

const DeliveryPreferences = ({ onSubmit, disabled }: Props) => {
  const styles = useStyles();

  return (
    <>
      <Typography className={styles.label}>Delivery Preferences</Typography>
      <Typography className={styles.text}>
        Would you like to set delivery preferences for all of your future
        deliveries?
      </Typography>
      <Button
        classes={{ root: styles.button, label: styles.rejectButtonLabel }}
        disabled={disabled}
        onClick={() => onSubmit(false)}
      >
        NO, SAVE FOR THIS DELIVERY ONLY
      </Button>
      <Button
        classes={{
          root: clsx(styles.button, styles.confirmButton),
          label: styles.confirmButtonLabel,
        }}
        disabled={disabled}
        onClick={() => onSubmit(true)}
      >
        YES
      </Button>
    </>
  );
};

DeliveryPreferences.defaultProps = {
  disabled: false,
};

export default DeliveryPreferences;
