import type { PickupLocationParcel } from '@dpdgroupuk/fmx-ui/types';
import { PickupLocationsActions } from '../../../store/orm/PickupLocation';

export const fetchPickupLocationWithVouchers = ({
  parcel,
}: {
  parcel: PickupLocationParcel,
}) => async dispatch => {
  const pickupLocation = await dispatch(
    PickupLocationsActions.fetchPickupLocationByLocationId(
      parcel.pickupLocationCode
    )
  );
  const { voucherArray } = await dispatch(
    PickupLocationsActions.fetchVouchers(parcel.pickupLocationCode)
  );
  return [pickupLocation, voucherArray];
};

export const fetchRelatedParcelNumbers = ({
  parcel,
}: {
  parcel: PickupLocationParcel,
}) => async dispatch => {
  const parcelNumbers = await dispatch(
    PickupLocationsActions.fetchRelatedParcelNumbers(
      parcel.pickupLocationCode,
      parcel.parcelCode
    )
  );

  return Promise.all(
    parcelNumbers.map(async parcelNumber => {
      return dispatch(
        PickupLocationsActions.fetchParcelDataInfo(
          parcel.parcelCode,
          parcel.pickupLocationCode,
          parcelNumber
        )
      );
    })
  );
};

export const fetchPickupLocationWithVouchersInfo = ({
  parcel,
}: {
  parcel: PickupLocationParcel,
}) => async dispatch => {
  const [pickupLocation, vouchers] = await dispatch(
    fetchPickupLocationWithVouchers({ parcel })
  );
  const voucher = vouchers[0];

  if (voucher) {
    const { vouchersInfo } = await dispatch(
      PickupLocationsActions.fetchVouchersInfoByVoucherCode(
        parcel.pickupLocationCode,
        voucher
      )
    );
    return [pickupLocation, vouchersInfo];
  }

  return [pickupLocation, {}];
};
