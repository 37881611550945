import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { InputFormField } from '@dpdgroupuk/fmx-ui/components/Input';
import ReferenceEntryWrapper from '@dpdgroupuk/fmx-ui/components/ReferenceEntryWrapper';
import {
  maxLength16,
  required,
  validateParcelReferenceNumberWithMessage,
} from '@dpdgroupuk/fmx-ui/utils/validation';

import {
  REFERENCE_ENTRY_FORM_HEADER,
  REFERENCE_ENTRY_FORM_NUMBER_PLACEHOLDER,
  REFERENCE_ENTRY_FORM_POSTCODE_PLACEHOLDER,
  SUBMIT,
} from '../../../constants/message';
import { normalizeReferenceNumber } from '../../../models/parcel';

type Props = {
  onReferenceSubmit: () => Promise<any>,
  handleSubmit: Function => Function,
  valid: boolean,
};

const validateReference = [
  required(),
  validateParcelReferenceNumberWithMessage,
];
const validatePostcode = [maxLength16];

const ReferenceEntryForm = ({
  onReferenceSubmit,
  handleSubmit,
  valid,
}: Props) => (
  <ReferenceEntryWrapper
    title={REFERENCE_ENTRY_FORM_HEADER}
    buttonText={SUBMIT}
    onSubmit={handleSubmit(onReferenceSubmit)}
    valid={valid}
    referenceNumberInput={
      <Field
        component={InputFormField}
        name="referenceNumber"
        placeholder={REFERENCE_ENTRY_FORM_NUMBER_PLACEHOLDER}
        validate={validateReference}
        normalize={normalizeReferenceNumber}
        fullWidth
      />
    }
    postcodeInput={
      <Field
        fullWidth
        component={InputFormField}
        name="postcode"
        placeholder={REFERENCE_ENTRY_FORM_POSTCODE_PLACEHOLDER}
        validate={validatePostcode}
        inputProps={{
          maxLength: 16,
        }}
      />
    }
  />
);

export default reduxForm({ form: 'reference' })(ReferenceEntryForm);
