import React from 'react';
import PropTypes from 'prop-types';
import Page from '@dpdgroupuk/fmx-ui/components/Page';

import ErrorScreen from '../../pages/ErrorScreen';
import Sentry from '../../services/sentry';

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  state = {
    error: null,
  };

  componentDidCatch(error, info) {
    this.setState({ error });

    if (Sentry) {
      Sentry.withScope(scope => {
        scope.setExtra('reactStack', info);
        Sentry.captureException(error);
      });
    }
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (this.state.error) {
      return (
        <Sentry.ErrorBoundary
          fallback={
            <Page>
              <ErrorScreen error={error} />
            </Page>
          }
        >
          {children}
        </Sentry.ErrorBoundary>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
