import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import HeadingWithAction from '@dpdgroupuk/fmx-ui/components/HeadingWithAction';
import Typography from '@material-ui/core/Typography';
import { copyStringToClipboard } from '@dpdgroupuk/fmx-ui/utils/helpers';

const useStyles = makeStyles(({ breakpoints, typography }) => ({
  title: {
    fontSize: typography.pxToRem(19),
    lineHeight: 0.84,
    letterSpacing: typography.pxToRem(0.41),
    textTransform: 'uppercase',
    marginBottom: typography.pxToRem(19),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(18),
    },
  },
}));

type Props = {
  children: React.node,
  copyText: string,
  title: string,
};

const StepLayout = ({ children, copyText, title }: Props) => {
  const styles = useStyles();
  const onCopy = copyStringToClipboard(copyText);

  return (
    <>
      {children}
      <Typography className={styles.title}>{title}</Typography>
      <HeadingWithAction buttonText="COPY" onClick={onCopy} title={copyText} />
    </>
  );
};

export default StepLayout;
