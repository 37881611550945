import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from '@dpdgroupuk/redux-action-creator';
import { apiService, fetchTimeoutHandler } from '../../services';
import { getParcelDepotCode } from '../../models/parcel';
import { getParcelData } from '../../store/orm/Parcel/selectors';
import { filterBadWords } from '../../utils/string';

export const ACTIONS = createActionTypes('ACTION', {
  RATE_DELIVERY: createAsyncActionTypes('RATE_DELIVERY'),
  FETCH_CALL_ME_TIME_SLOTS: createAsyncActionTypes('FETCH_CALL_ME_TIME_SLOTS'),
  BOOK_CALL_ME_TIME_SLOT: createAsyncActionTypes('BOOK_CALL_ME_TIME_SLOT'),
});

export const rateDelivery = createAsyncAction(
  (parcelCode: string, data: any) =>
    fetchTimeoutHandler(apiService.rateDelivery(parcelCode, data)).then(
      ({ data }) => data
    ),
  ACTIONS.RATE_DELIVERY
);

export const sendDeliveryFeedback = (
  parcelCode: string,
  {
    driverCode,
    experienceComplimentType,
    driverComplimentType,
    driverRating,
    driverNotes,
    like,
  }: {
    driverCode: string,
    experienceComplimentType: string | null,
    driverComplimentType: string | null,
    driverRating: number | null,
    driverNotes: string | null,
    like: boolean,
  }
) => (dispatch: Function, getState: Function) => {
  const parcel = getParcelData(getState(), parcelCode);
  const depotCode = getParcelDepotCode(parcel);
  const filteredDriverNotes = filterBadWords(driverNotes);

  return dispatch(
    rateDelivery(parcelCode, {
      driverCode,
      experienceComplimentType,
      driverComplimentType,
      driverRating,
      driverNotes: filteredDriverNotes,
      like,
      depotCode,
    })
  );
};

export const fetchCallMeSlots = createAsyncAction(
  () =>
    fetchTimeoutHandler(apiService.fetchCallMeSlots()).then(({ data }) => data),
  ACTIONS.FETCH_CALL_ME_TIME_SLOTS
);

const bookCallMe = createAsyncAction(
  (data: any) =>
    fetchTimeoutHandler(apiService.bookCallMeSlot(data)).then(
      ({ data }) => data
    ),
  ACTIONS.BOOK_CALL_ME_TIME_SLOT
);

export const bookCallMeSlot = data => (dispatch: Function) => {
  return dispatch(bookCallMe(data));
};
