// @flow
import React from 'react';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withProps } from 'recompose';
import { compose } from 'redux';
import { withTrack, withTrackProps } from '@dpdgroupuk/react-event-tracker';
import Button from '@dpdgroupuk/fmx-ui/components/Button';
import Dislike from '@dpdgroupuk/fmx-ui/images/Rating/Dislike.png';

import RateInfoButton from './RateInfoButton';
import { RATE_STEPS } from '../steps';
import * as MESSAGE from '../../../constants/message';
import {
  RATE_DELIVERY_CALL_ME_OPTION,
  trackable,
} from '../../../constants/analytics';

const useStyles = makeStyles(({ typography, palette, breakpoints }) => ({
  submitButton: {
    marginTop: typography.pxToRem(30),
    width: typography.pxToRem(170),
  },
  friendlyCallMessage: {
    fontSize: typography.pxToRem(16),
    letterSpacing: typography.pxToRem(0.7),
    lineHeight: 1.69,
    color: palette.text.primary,
    width: '55%',
    [breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  experienceComment: {
    fontSize: typography.pxToRem(12),
    color: palette.text.primary,
    letterSpacing: typography.pxToRem(0.64),
    marginTop: typography.pxToRem(16),
    width: '30%',
    [breakpoints.down('xs')]: {
      width: '40%',
    },
  },
  sorryToHearMessage: {
    fontSize: typography.pxToRem(20),
    letterSpacing: typography.pxToRem(0.7),
    color: palette.text.primary,
    marginBottom: typography.pxToRem(20),
    marginTop: typography.pxToRem(50),
    maxWidth: '90%',
  },
  button: {
    width: typography.pxToRem(153),
    [breakpoints.down('xs')]: {
      width: typography.pxToRem(170),
    },
  },
  buttonLabel: {
    fontSize: typography.pxToRem(11.3),
    lineHeight: 1.2,
    letterSpacing: typography.pxToRem(0.4),
  },
  noButton: {
    background: palette.gradient.reject,
  },
  yesButton: {
    background: palette.gradient.confirm,
  },
  buttonContainer: {
    marginTop: typography.pxToRem(50),
    display: 'flex',
    width: '100%',
    flexFlow: 'row wrap-reverse',
    justifyContent: 'center',
    alignItems: 'center',
  },
  column: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    padding: typography.pxToRem(10),
  },
  thumbsIcon: {
    maxWidth: typography.pxToRem(81),
    maxHeight: typography.pxToRem(81),
    [breakpoints.down('xs')]: {
      maxWidth: typography.pxToRem(69),
      maxHeight: typography.pxToRem(69),
    },
  },
}));

type Props = {
  data: Object,
  onSelect: Function,
  onSelectNegative: Function,
};

const RateDeliveryCallMeOption = ({
  data,
  onSelect,
  onSelectNegative,
}: Props) => {
  const styles = useStyles();
  const { experienceComplimentIcon = '', experienceComment = '' } = data;

  return (
    <Grid>
      {experienceComplimentIcon && (
        <Grid container direction="row" justify="center" spacing={2}>
          <RateInfoButton icon={experienceComplimentIcon} />
          <Typography className={styles.experienceComment}>
            {experienceComment}
          </Typography>
        </Grid>
      )}

      {!experienceComplimentIcon && (
        <img src={Dislike} alt="" className={styles.thumbsIcon} />
      )}

      <Typography className={styles.sorryToHearMessage}>
        {MESSAGE.SORRY_TO_HEAR_MESSAGE}
      </Typography>

      <Typography
        className={styles.friendlyCallMessage}
        dangerouslySetInnerHTML={{ __html: MESSAGE.FRIENDLY_CALL_MESSAGE }}
      />

      <div className={styles.buttonContainer}>
        <div className={styles.column}>
          <Button
            onClick={onSelectNegative}
            classes={{
              root: clsx(styles.button, styles.noButton),
              label: styles.buttonLabel,
            }}
          >
            {MESSAGE.NO_THANKS}
          </Button>
        </div>
        <div className={styles.column}>
          <Button
            onClick={onSelect}
            classes={{
              root: clsx(styles.button, styles.yesButton),
              label: styles.buttonLabel,
            }}
          >
            {MESSAGE.YES_PLEASE_CALL_ME}
          </Button>
        </div>
      </div>
    </Grid>
  );
};

export default compose(
  withProps<any, any>(
    ({
      addStepData,
      goToStep,
      handleWizardSubmit,
      onDeliveryRate,
      data,
    }: any) => ({
      onSelect: () => goToStep(RATE_STEPS.RATE_DELIVERY_CALL_ME_DATE_SELECT),
      onSelectNegative: () => {
        addStepData({
          fromNegativeCallMe: true,
        });
        handleWizardSubmit(onDeliveryRate, data).then(() =>
          goToStep(RATE_STEPS.RATE_DELIVERY_CALL_ME_CONFIRMATION)
        );
      },
    })
  ),
  withTrack(trackable(RATE_DELIVERY_CALL_ME_OPTION)),
  withTrackProps({
    onSelect: RATE_DELIVERY_CALL_ME_OPTION.ON_YES,
    onSelectNegative: RATE_DELIVERY_CALL_ME_OPTION.ON_NO,
  })
)(RateDeliveryCallMeOption);
