import { get, has } from 'lodash-es';

import { FAILED_STATUSES } from '../constants/threeDSecure';

export const isLiabilityShifted = paymentResult => {
  const liabilityShifted = get(
    paymentResult,
    'threeDSecureInfo.liabilityShifted'
  );

  return liabilityShifted;
};

export const isLiabilityShiftPossible = paymentResult => {
  const isLiabilityShiftPossible = get(
    paymentResult,
    'threeDSecureInfo.liabilityShiftPossible'
  );
  const liabilityShifted = isLiabilityShifted(paymentResult);

  return isLiabilityShiftPossible && !liabilityShifted;
};

export const onProcessPaymentResult = (
  paymentResult,
  successCallback,
  failureCallback
) => {
  const has3DSecureVerification = has(paymentResult, 'threeDSecureInfo');
  const threeDSecureStatus =
    has3DSecureVerification && get(paymentResult, 'threeDSecureInfo.status');
  const hasFailedStatus =
    threeDSecureStatus && FAILED_STATUSES.includes(threeDSecureStatus);
  if (
    !paymentResult ||
    hasFailedStatus ||
    (has3DSecureVerification && !isLiabilityShifted(paymentResult))
  ) {
    return failureCallback({
      braintreeStatus: paymentResult
        ? paymentResult.threeDSecureInfo.status
        : null,
    });
  }
  return successCallback();
};
