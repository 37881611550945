import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from '@dpdgroupuk/redux-action-creator';

import { apiService, fetchTimeoutHandler } from '../../../services';

export const ACTIONS = createActionTypes('DEPOT', {
  FETCH_DEPOT_BY_CODE: createAsyncActionTypes('FETCH_DEPOT_BY_CODE'),
});

export const fetchDepotByCode = createAsyncAction(
  (depotCode: string) =>
    fetchTimeoutHandler(apiService.getDepotByDepotCode(depotCode)).then(
      ({ data }) => data
    ),
  ACTIONS.FETCH_DEPOT_BY_CODE
);
