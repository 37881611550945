import { formatESGDate, getTimeArray } from '@dpdgroupuk/fmx-ui/utils/date';

import type { ChangeDeliveryOptionOptions } from '../../../../apis';
import { changeDeliveryOption } from '../../../../store/orm/Parcel/actions';

export const changePrecise = (
  parcelCode: string,
  { day, diaryTime }: { day: string, diaryTime: string },
  options: ChangeDeliveryOptionOptions
) => (dispatch: Function) =>
  dispatch(
    changeDeliveryOption(
      parcelCode,
      {
        date: formatESGDate(day),
        timeSlot: getTimeArray(diaryTime)[0],
      },
      options
    )
  );
