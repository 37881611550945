import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import HeadingWithAction from '@dpdgroupuk/fmx-ui/components/HeadingWithAction';
import { getDateString } from '@dpdgroupuk/fmx-ui/utils/string';

import { CHANGE } from '../../../constants/message';

const useStyles = makeStyles(({ palette }) => ({
  title: {
    color: palette.text.headline,
  },
}));

type Props = {
  day: string,
  onDateChange: Function,
};

export default ({ day, onDateChange }: Props) => {
  const styles = useStyles();

  return day ? (
    <HeadingWithAction
      title={getDateString(day)}
      buttonText={CHANGE}
      onClick={onDateChange}
      titleClassName={styles.title}
    />
  ) : null;
};
