import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import Lottie from 'lottie-react-web';
import { withProps } from 'recompose';

import { withTrackProps } from '@dpdgroupuk/react-event-tracker';
import SubmitButton from '@dpdgroupuk/fmx-ui/components/SubmitButton';
import { formatESGDate } from '@dpdgroupuk/fmx-ui/utils/date';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { USER_TYPE } from '@dpdgroupuk/redback-enums';

import ChangeDeliveryDay from '../../components/ChangeDeliveryDay';
import { ONE_STEPS } from '../../steps';
import EcoTimeSlotList from '../../components/EcoTimeSlotList';
import TimeSlotErrorComponent from '../../components/TimeSlotErrorComponent';
import electricCar from '../../../../assets/lottie/greenDeliveryDriverAnimation';
import { fetchParcelClaimsDispatch, fetchPreciseTimes } from '../../actions';
import withFetch from '../../../../HOCs/withFetch';
import { PRECISE_DELIVERY_OPTION } from '../../../../constants/analytics';
import { CHOOSE_TIME_SLOT } from '../../../../constants/message';
import mapStateToProps from '../../utils/mapStateToProps';
import { ORIGIN } from '../../../../constants/values';

const useStyles = makeStyles(({ typography, breakpoints, palette }) => ({
  wrapper: {
    paddingBottom: typography.pxToRem(10),
  },
  title: {
    fontSize: typography.pxToRem(21),
    textAlign: 'center',
    color: palette.text.redWhite,
    textTransform: 'uppercase',
    marginTop: typography.pxToRem(25),
    marginBottom: typography.pxToRem(26),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(18),
      marginTop: typography.pxToRem(40),
      marginBottom: typography.pxToRem(16),
    },
  },
  errorContainer: {
    marginBottom: typography.pxToRem(70),
  },
  greenContainer: {
    height: typography.pxToRem(30),
    marginTop: typography.pxToRem(36),
    [breakpoints.down('xs')]: {
      marginTop: typography.pxToRem(31),
    },
  },
  greenText: {
    fontSize: typography.pxToRem(12),
    color: '#1ca521',
    marginLeft: typography.pxToRem(20),
    [breakpoints.down('xs')]: {
      marginLeft: typography.pxToRem(10),
      maxWidth: '85%',
    },
  },
}));

type Props = {
  data: Object,
  goToStep: Function,
  onDateChange: Function,
  fetchedData: any,
  onSubmit: Function,
  onSelect: Function,
};

const ChooseTimeSlotComponent = ({
  data,
  goToStep,
  onDateChange,
  fetchedData,
  onSubmit,
  onSelect,
}: Props) => {
  const styles = useStyles();

  const showGreenContainer =
    fetchedData &&
    fetchedData.preciseSlot.some(el => {
      return el.greenSlot;
    });

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={styles.wrapper}
    >
      <ChangeDeliveryDay day={data.day} onDateChange={onDateChange} />
      <Typography className={styles.title}>{CHOOSE_TIME_SLOT}</Typography>
      {fetchedData && fetchedData.preciseSlot.length ? (
        <>
          <EcoTimeSlotList
            items={fetchedData.preciseSlot}
            onSelect={onSelect}
          />
          <SubmitButton onSubmit={onSubmit} isSubmitHidden={!data.diaryTime} />
        </>
      ) : (
        <Grid className={styles.errorContainer}>
          <TimeSlotErrorComponent
            onSubmit={() => goToStep(ONE_STEPS.SELECT_DAY)}
          />
        </Grid>
      )}
      {showGreenContainer && (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={styles.greenContainer}
        >
          <Lottie
            width="1.44rem"
            height="1.44rem"
            style={{
              position: 'relative',
              margin: 'auto 0',
            }}
            options={{ animationData: electricCar, autoPlay: true }}
          />
          <Typography className={styles.greenText}>
            Go Green - Choose the slot when your driver is in the area
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default compose(
  withRouter,
  withFetch(({ parcel }) => fetchParcelClaimsDispatch({ parcel })),
  connect(mapStateToProps),
  withProps(({ addStepData, submitWizard, goToStep, claims }) => ({
    onSelect: time => addStepData({ diaryTime: time }),
    onSubmit: () => {
      const { origin, userType } = claims;

      if (origin === ORIGIN.FMP && userType === USER_TYPE.RECEIVER) {
        addStepData({
          funcAfterAuth: submitWizard,
        });

        // Go to contact page before sms auth
        return goToStep(ONE_STEPS.GET_CONTACTS);
      }

      return submitWizard();
    },
    onDateChange: () => {
      addStepData({ diaryTime: '' });
      goToStep(ONE_STEPS.SELECT_DAY);
    },
  })),
  withTrackProps({
    onSelect: PRECISE_DELIVERY_OPTION.ON_SLOT_SELECT,
    onDateChange: PRECISE_DELIVERY_OPTION.ON_DATE_CHANGE,
  }),
  withFetch(({ data, parcel }) =>
    fetchPreciseTimes(
      parcel.parcelCode,
      formatESGDate(data.day),
      parcel.deliveryDetails.address.postcode,
      parcel.businessUnit
    )
  )
)(ChooseTimeSlotComponent);
