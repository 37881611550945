import * as React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Trackable, withTrackProps } from '@dpdgroupuk/react-event-tracker';

import Button from '@dpdgroupuk/fmx-ui/components/Button';
import * as MESSAGE from '../../../../../constants/message';
import { PICKUP_SHOP_CONFIRMATION } from '../../../../../constants/analytics';

const useStyles = makeStyles(({ palette, typography, breakpoints }) => ({
  dialog: {
    maxWidth: typography.pxToRem(433),
    backgroundColor: palette.background.modal,
  },
  dialogTitle: {
    padding: `${typography.pxToRem(54)} ${typography.pxToRem(
      60
    )} ${typography.pxToRem(42)}`,
    color: palette.text.primary,
    textAlign: 'center',
    [breakpoints.down('xs')]: {
      padding: `${typography.pxToRem(29)} ${typography.pxToRem(
        66
      )} ${typography.pxToRem(36)}`,
    },
    [breakpoints.down(375)]: {
      padding: `${typography.pxToRem(29)} ${typography.pxToRem(
        46
      )} ${typography.pxToRem(36)}`,
    },
  },
  dialogTitleText: {
    fontSize: typography.pxToRem(20.5),
    fontWeight: 300,
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
    },
  },
  dialogActions: {
    padding: `0 ${typography.pxToRem(60)} ${typography.pxToRem(34)}`,
    flexDirection: 'column',
    [breakpoints.down('xs')]: {
      padding: `0 ${typography.pxToRem(66)} ${typography.pxToRem(30)}`,
    },
    [breakpoints.down(375)]: {
      padding: `0 ${typography.pxToRem(36)} ${typography.pxToRem(30)}`,
    },
  },
  dialogActionsSpacing: {
    '& > :not(:first-child)': {
      marginLeft: 0,
    },
  },
  closeButton: {
    position: 'absolute',
    top: typography.pxToRem(3),
    right: typography.pxToRem(3),
    color: '#808285',
  },
  button: {
    width: '100%',
    height: typography.pxToRem(48),
    paddingRight: 0,
    paddingLeft: 0,
    marginBottom: typography.pxToRem(24),
    background: palette.gradient.reject,
    [breakpoints.down('xs')]: {
      height: typography.pxToRem(37),
    },
  },
  rejectButton: {
    [breakpoints.down('xs')]: {
      paddingTop: typography.pxToRem(13),
    },
  },
  confirmButton: {
    background: palette.gradient.confirm,
  },
  rejectButtonLabel: {
    fontSize: typography.pxToRem(12.8),
    letterSpacing: typography.pxToRem(1),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(11),
    },
  },
  confirmButtonLabel: {
    fontSize: typography.pxToRem(18),
    letterSpacing: typography.pxToRem(1),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15.2),
    },
  },
}));

type Props = {
  open: boolean,
  onClose: Function,
  title: string,
  onConfirmClick: Function,
};

const ConfirmationDialog = ({
  open,
  onClose,
  title,
  onConfirmClick,
}: Props) => {
  const styles = useStyles();

  const handleConfirm = React.useCallback(() => {
    onConfirmClick();
    onClose();
  }, [onConfirmClick, onClose]);

  return (
    <Dialog
      open={open}
      classes={{ paperWidthSm: styles.dialog }}
      onClose={onClose}
    >
      <Trackable
        loadId={PICKUP_SHOP_CONFIRMATION.LOAD}
        interfaceId={PICKUP_SHOP_CONFIRMATION.INTERFACE_ID}
        modal
      >
        <DialogTitle classes={{ root: styles.dialogTitle }}>
          <Typography className={styles.dialogTitleText}>{title}</Typography>
          <Typography className={styles.dialogTitleText}>
            {MESSAGE.LET_YOU_KNOW_TIME_MESSAGE}
          </Typography>
          <IconButton
            aria-label="close"
            className={styles.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogActions
          classes={{
            root: styles.dialogActions,
            spacing: styles.dialogActionsSpacing,
          }}
        >
          <Button
            classes={{
              root: clsx(styles.button, styles.confirmButton),
              label: styles.confirmButtonLabel,
            }}
            onClick={handleConfirm}
          >
            {MESSAGE.YES}
          </Button>
          <Button
            classes={{
              root: [styles.button, styles.rejectButton],
              label: styles.rejectButtonLabel,
            }}
            onClick={onClose}
          >
            {MESSAGE.DECLINE}
          </Button>
        </DialogActions>
      </Trackable>
    </Dialog>
  );
};

export default withTrackProps({
  onClose: PICKUP_SHOP_CONFIRMATION.ON_CANCEL,
  onConfirmClick: PICKUP_SHOP_CONFIRMATION.ON_CONFIRM,
})(ConfirmationDialog);
