import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import * as ENUM from '@dpdgroupuk/redback-enums';
import type { Parcel } from '@dpdgroupuk/fmx-ui/types';
import { useTracker } from '@dpdgroupuk/react-event-tracker';

import { goToChangeDelivery } from '../../historyActions';
import { ROAD_MAP_STEP_TYPE } from '../constants';
import {
  NO_NEIGHBOUR,
  CHANGE,
  CHOOSE,
  NO_SAFE_PLACE,
} from '../../../constants/message';
import { WHAT_HAPPENS_NEXT } from '../../../constants/analytics';

type Preference = {
  type: string,
  text: string,
  value: string,
  action: string,
  actionText: String,
};

type Props = {
  preferences: Array<Preference>,
  parcel: Parcel,
  availableActions: Array<string>,
};

const useStyles = makeStyles(({ breakpoints, palette, typography }) => ({
  sectionHeader: {
    fontSize: typography.pxToRem(21.8),
    color: '#1d9ad0',

    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(17),
    },
  },
  sectionMarginTop: {
    marginTop: typography.pxToRem(35),
  },
  step: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginTop: typography.pxToRem(10),
    marginLeft: typography.pxToRem(30),
  },
  stepContent: {
    display: 'flex',
    alignItems: 'center',
    width: typography.pxToRem(390),
  },
  stepText: {
    fontSize: typography.pxToRem(17.4),
    lineHeight: 1.7,
    color: palette.text.primary,
    whiteSpace: 'pre-line',

    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(14),
      maxWidth: `calc(100% - ${typography.pxToRem(75)})`,
      whiteSpace: 'normal',
    },
  },
  stepName: {
    fontWeight: 300,
  },
  link: {
    color: '#1d9ad0',
    fontSize: typography.pxToRem(13),
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.44,
    textAlign: 'center',
    cursor: 'pointer',
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(9),
    },
  },
  withGreenOption: {
    marginBottom: typography.pxToRem(60),
    [breakpoints.up('sm')]: {
      marginBottom: typography.pxToRem(85),
    },
  },
  greenPlanet: {
    [breakpoints.down('sm')]: {
      top: typography.pxToRem(50),
      left: typography.pxToRem(70),
    },
    position: 'absolute',
    top: typography.pxToRem(70),
    left: typography.pxToRem(100),
  },
}));

const NextSteps = ({ preferences, parcel, availableActions }: Props) => {
  const styles = useStyles();
  const history = useHistory();
  const tracker = useTracker();

  return preferences.map(
    ({ type, text, value, action, greenOption }, index) => {
      const actionText =
        value !== NO_SAFE_PLACE && value !== NO_NEIGHBOUR ? CHANGE : CHOOSE;
      const isActionAllowed = availableActions.includes(action);

      if (type === ROAD_MAP_STEP_TYPE.TITLE) {
        return (
          <Typography
            key={index}
            className={clsx(styles.sectionHeader, {
              [styles.sectionMarginTop]: index !== 0,
            })}
          >
            {text}
          </Typography>
        );
      }

      const onClick = () => {
        if (action === ENUM.PARCEL_ACTION_CODE.DTN) {
          tracker.logEvent(WHAT_HAPPENS_NEXT.ON_DTN);
        } else if (action === ENUM.PARCEL_ACTION_CODE.SFP) {
          tracker.logEvent(WHAT_HAPPENS_NEXT.ON_SAFE_PLACE);
        }

        goToChangeDelivery({
          history,
          parcel,
          actionCode: action.toLowerCase(),
        });
      };

      return (
        <div
          className={clsx(styles.step, greenOption && styles.withGreenOption)}
          key={index}
        >
          <div className={styles.stepContent}>
            <Typography className={styles.stepText}>
              <span className={styles.stepName}>{text}</span> {value}
            </Typography>
          </div>
          {action && isActionAllowed && (
            <Link component="button" onClick={onClick} className={styles.link}>
              {actionText}
            </Link>
          )}
        </div>
      );
    }
  );
};

export default NextSteps;
