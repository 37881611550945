import { withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import ErrorScreen from '@dpdgroupuk/fmx-ui/components/ErrorScreen';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes/constants';
import { NoItems } from '@dpdgroupuk/fmx-ui/components/Icons';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';

import withParcel from '../../../../../HOCs/withParcel';
import { goToDeliveryOptions } from '../../../../historyActions';
import {
  NO_SHOPS,
  COLLECT_FROM_PICKUP,
  DO_SOMETHING_DIFFERENT,
} from '../../../../../constants/message';
import { NO_PICKUP_SHOP, trackable } from '../../../../../constants/analytics';

export default compose(
  withRouter,
  withParcel(),
  withPageControl({
    title: COLLECT_FROM_PICKUP,
    backgroundImage: IMAGES.MAP,
    trackable: trackable(NO_PICKUP_SHOP),
  }),
  withProps(props => ({
    title: NO_SHOPS,
    buttonText: DO_SOMETHING_DIFFERENT.toUpperCase(),
    onClick: () => goToDeliveryOptions(props),
    icon: NoItems,
  })),
  withTrackProps({
    onClick: NO_PICKUP_SHOP.ON_DO_SOMETHING_DIFFERENT,
  })
)(ErrorScreen);
