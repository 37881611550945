import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { createReducer } from 'redux-orm';

import type { State as ParcelState } from './searchParcels';
import searchParcels from './searchParcels';
import orm from '../orm/orm';

export type State = {|
  searchParcels: ParcelState,
|};

export default combineReducers({
  form,
  searchParcels,
  orm: createReducer(orm),
});
