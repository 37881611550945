import { withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import ErrorScreen from '@dpdgroupuk/fmx-ui/components/ErrorScreen';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes/constants';
import { NoItems } from '@dpdgroupuk/fmx-ui/components/Icons';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';

import withParcel from '../../../../HOCs/withParcel';
import { goToDeliveryOptions } from '../../../historyActions';
import {
  NO_NEIGHBOURS_FOUND,
  DELIVER_TO_NEIGHBOUR,
  DO_SOMETHING_DIFFERENT,
} from '../../../../constants/message';
import { NO_NEIGHBOURS, trackable } from '../../../../constants/analytics';

export default compose(
  withRouter,
  withParcel(),
  withPageControl({
    title: DELIVER_TO_NEIGHBOUR,
    backgroundImage: IMAGES.MAP,
    trackable: trackable(NO_NEIGHBOURS),
  }),
  withProps(props => ({
    title: NO_NEIGHBOURS_FOUND,
    buttonText: DO_SOMETHING_DIFFERENT.toUpperCase(),
    onClick: () => goToDeliveryOptions(props),
    icon: NoItems,
  })),
  withTrackProps({
    onClick: NO_NEIGHBOURS.ON_DO_SOMETHING_DIFFERENT,
  })
)(ErrorScreen);
