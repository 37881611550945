import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import GridListTile from '@material-ui/core/GridListTile';

import Radio from '@dpdgroupuk/fmx-ui/components/Icons/Radio';
import { getAddressLine } from '@dpdgroupuk/fmx-ui/utils/address';

import { Address } from './types';
import { noop } from '../../utils/function';

const useStyles = makeStyles(({ breakpoints, palette, typography }) => ({
  container: {
    cursor: 'pointer',
    '& .MuiGridListTile-tile': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  address: {
    marginRight: typography.pxToRem(60),
    marginBottom: typography.pxToRem(15),
    textAlign: 'left',
    fontSize: typography.pxToRem(19),
    lineHeight: 1.3,
    letterSpacing: 0.44,

    [breakpoints.down('xs')]: {
      marginRight: typography.pxToRem(30),
      fontSize: typography.pxToRem(15),
    },
  },
  radioIcon: {
    '& .checkMark': {
      fill: palette.primary.main,
    },
  },
}));

type Props = {
  address: Address,
  isActive: boolean,
  onSelect: (address: Address) => void,
};

const AddressComponent = ({ isActive, onSelect, address }: Props) => {
  const styles = useStyles();

  return (
    <GridListTile
      cols={1}
      className={styles.container}
      onClick={() => onSelect(address)}
    >
      <Typography className={styles.address}>
        {getAddressLine(address)}
      </Typography>
      <Radio selected={isActive} className={styles.radioIcon} />
    </GridListTile>
  );
};

AddressComponent.defaultProps = {
  onSelect: noop,
};

export default AddressComponent;
