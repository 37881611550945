// @flow
import * as React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withProps } from 'recompose';
import { compose } from 'redux';
import { withRouter, Redirect } from 'react-router-dom';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';

import SubmitButton from '@dpdgroupuk/fmx-ui/components/SubmitButton';
import ENUM from '@dpdgroupuk/redback-enums';

import RateButtons from '../../../components/Rate/RateButtons';
import { RATE_INFO_NAMES, DELIVERY_COMPLIMENTS } from '../constants';
import RateInfoButtonsBlock from './RateInfoButtonsBlock';
import * as MESSAGE from '../../../constants/message';
import { RATE_STEPS } from '../steps';
import Button from '../../../components/Rate/Button';
import { withRemoteConfig } from '../../../components/RemoteConfig/ConfigContext';
import { getLocationTracking } from '../../Parcel/actions/location';

import { RATE_DELIVERY } from '../../../constants/analytics';
import { goToRating } from '../../historyActions';
import { RATE_TYPE } from '../../../constants/rate';

const useStyles = makeStyles(({ typography, palette, breakpoints }) => ({
  rateInfoText: {
    fontSize: typography.pxToRem(16),
    letterSpacing: typography.pxToRem(0.57),
    color: palette.text.primary,
    marginBottom: typography.pxToRem(36),
    marginTop: typography.pxToRem(69),
    [breakpoints.down('xs')]: {
      marginTop: typography.pxToRem(47),
      marginBottom: typography.pxToRem(19),
    },
  },
  skipBlock: {
    marginTop: typography.pxToRem(10),
  },

  onTime: {
    width: typography.pxToRem(70),
  },
  notGreat: {
    width: typography.pxToRem(85),
  },
  rateInfoButton: {
    height: typography.pxToRem(170),
  },
  mobRateButton: {
    [breakpoints.down(400)]: {
      marginLeft: typography.pxToRem(10),
      width: `calc(50% - ${typography.pxToRem(10)})`,
      marginTop: typography.pxToRem(10),
    },
  },
  submitButton: {
    marginTop: typography.pxToRem(30),
  },
}));

const RateDelivery = ({
  data,
  onSelect,
  onSelectNegative,
  onSwitch,
  onSkip,
  parcel,
  addStepData,
}: any) => {
  const styles = useStyles();
  const { deliveryRating, experienceComplimentIcon = '' } = data;
  const [submitVisible, setSubmitVisible] = React.useState(false);

  const switchDeliveryRate = rating => () => {
    onSwitch(rating)();
    setSubmitVisible(false);
  };
  const setActive = (value, comment) =>
    addStepData({
      experienceComplimentType: DELIVERY_COMPLIMENTS[value],
      experienceComplimentIcon: value,
      experienceComment: comment,
    });

  const positiveButtons = React.useMemo(
    () => [
      {
        icon: RATE_INFO_NAMES.ON_TIME,
        comment: MESSAGE.ON_TIME,
        commentClassName: styles.onTime,
        className: styles.rateInfoButton,
      },
      {
        icon: RATE_INFO_NAMES.INFORMED,
        comment: MESSAGE.INFORMED,
        className: styles.rateInfoButton,
      },
      {
        icon: RATE_INFO_NAMES.FOLLOWED,
        comment: MESSAGE.FOLLOWED,
        className: styles.rateInfoButton,
      },
    ],
    [styles.onTime, styles.rateInfoButton]
  );

  const negativeButtons = React.useMemo(
    () => [
      {
        icon: RATE_INFO_NAMES.NOT_RECEIVED,
        comment: MESSAGE.NOT_RECEIVED,
        className: [styles.mobRateButton, styles.rateInfoButton],
      },
      {
        icon: RATE_INFO_NAMES.NOT_GREAT,
        comment: MESSAGE.NOT_GREAT,
        commentClassName: styles.notGreat,
        className: [styles.mobRateButton, styles.rateInfoButton],
      },
      {
        icon: RATE_INFO_NAMES.COMPLAINT,
        comment: MESSAGE.COMPLAINT,
        className: [styles.mobRateButton, styles.rateInfoButton],
      },
      {
        icon: RATE_INFO_NAMES.WRONG_PLACE,
        comment: MESSAGE.WRONG_PLACE,
        className: [styles.mobRateButton, styles.rateInfoButton],
      },
    ],
    [styles.mobRateButton, styles.notGreat, styles.rateInfoButton]
  );

  const negative = React.useMemo(() => deliveryRating === RATE_TYPE.DISLIKE, [
    deliveryRating,
  ]);
  const positive = React.useMemo(() => deliveryRating === RATE_TYPE.LIKE, [
    deliveryRating,
  ]);
  const onSubmit = () =>
    positive
      ? onSelect(experienceComplimentIcon)
      : onSelectNegative(experienceComplimentIcon);

  if (parcel.parcelStatusType !== ENUM.PARCEL_STATUS.APP_SHIPPING) {
    return (
      <Redirect
        to={getLocationTracking({
          parcelId: parcel.parcelCode,
        })}
      />
    );
  }

  return (
    <Grid>
      <RateButtons
        negativePreselected={negative}
        positivePreselected={positive}
        onPositiveClick={switchDeliveryRate(RATE_TYPE.LIKE)}
        onNegativeClick={switchDeliveryRate(RATE_TYPE.DISLIKE)}
      />
      <Typography className={styles.rateInfoText}>
        {positive ? MESSAGE.POSITIVE_DELIVERY : MESSAGE.NEGATIVE_DELIVERY}
      </Typography>
      <RateInfoButtonsBlock
        buttons={positive ? positiveButtons : negativeButtons}
        active={experienceComplimentIcon}
        setActive={setActive}
        onComplete={() => setSubmitVisible(true)}
      />
      <SubmitButton
        onSubmit={onSubmit}
        isSubmitHidden={!experienceComplimentIcon || !submitVisible}
        className={styles.submitButton}
      />
      <div className={styles.skipBlock}>
        <Button onClick={onSkip(deliveryRating)}>{MESSAGE.SKIP}</Button>
      </div>
    </Grid>
  );
};

export default compose(
  withRouter,
  withRemoteConfig(),
  withProps<any, any>(
    ({
      addStepData,
      goToStep,
      submitWizard,
      history,
      data,
      parcel,
      remoteConfig: { useSalesforceForm, dislikeCallMe },
    }: any) => ({
      onSelect: () => {
        goToStep(RATE_STEPS.RATE_DRIVER);
      },
      onSelectNegative: type => {
        if (dislikeCallMe) {
          goToStep(RATE_STEPS.RATE_DELIVERY_CALL_ME_OPTION);
        } else if (type === RATE_INFO_NAMES.NOT_RECEIVED && useSalesforceForm) {
          goToStep(RATE_STEPS.DELIVERY_NEGATIVE_COMPLETE);
        } else {
          submitWizard({
            stepData: data,
          });
        }
      },
      onSwitch: deliveryRating => () => {
        addStepData({
          deliveryRating,
          experienceComplimentType: '',
          experienceComplimentIcon: '',
        });
        goToRating({ parcel, history, deliveryRating });
      },
      onSkip: deliveryRating => () => {
        if (deliveryRating === RATE_TYPE.LIKE) {
          addStepData({
            experienceComplimentType: DELIVERY_COMPLIMENTS.NOT_SET,
            experienceComplimentIcon: '',
          });
          goToStep(RATE_STEPS.RATE_DRIVER);
        } else {
          addStepData({
            experienceComplimentType: DELIVERY_COMPLIMENTS.NOT_SET,
            experienceComplimentIcon: '',
          });

          if (dislikeCallMe) {
            return goToStep(RATE_STEPS.RATE_DELIVERY_CALL_ME_OPTION);
          }

          submitWizard({
            stepData: {
              ...data,
              experienceComplimentType: DELIVERY_COMPLIMENTS.NOT_SET,
            },
          });
        }
      },
    })
  ),
  withTrackProps({
    onSelectNegative: RATE_DELIVERY.ON_NEGATIVE,
    onSelect: RATE_DELIVERY.ON_POSITIVE,
    onSkip: RATE_DELIVERY.ON_SKIP,
  })
)(RateDelivery);
