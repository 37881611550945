// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';

import Like from '@dpdgroupuk/fmx-ui/images/Rating/Like.png';
import Dislike from '@dpdgroupuk/fmx-ui/images/Rating/Dislike.png';
import * as MESSAGE from '../../constants/message';

const useStyles = makeStyles(({ typography, palette }) => ({
  rateButton: {
    width: typography.pxToRem(61),
    height: typography.pxToRem(61),
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  marginLeft: {
    marginLeft: typography.pxToRem(20.5),
  },
  header: {
    fontWeight: 300,
    fontSize: typography.pxToRem(16),
    color: palette.text.primary,
    letterSpacing: typography.pxToRem(0.57),
    marginBottom: typography.pxToRem(25),
  },
  inactive: {
    opacity: palette.background.rateButtonOpacity,
  },
  disabled: {
    cursor: 'default',
  },
  thumbsDown: {
    overflow: 'initial',
  },
}));

type Props = {
  onPositiveClick: Function,
  onNegativeClick: Function,
  positivePreselected?: boolean,
  negativePreselected?: boolean,
};

const RateButtons = ({
  onPositiveClick,
  onNegativeClick,
  positivePreselected,
  negativePreselected,
}: Props) => {
  const styles = useStyles();

  return (
    <div>
      <Typography className={styles.header}>{MESSAGE.RATE_DELIVERY}</Typography>
      <button
        className={clsx(styles.rateButton, {
          [styles.inactive]: negativePreselected,
          [styles.disabled]: positivePreselected,
        })}
        onClick={onPositiveClick}
        disabled={positivePreselected}
      >
        <img src={Like} alt="" />
      </button>
      <button
        className={clsx(styles.rateButton, styles.marginLeft, {
          [styles.inactive]: positivePreselected,
          [styles.disabled]: negativePreselected,
        })}
        onClick={onNegativeClick}
        disabled={negativePreselected}
      >
        <img src={Dislike} alt="" />
      </button>
    </div>
  );
};

export default RateButtons;
