export const createLoadAsyncAction = (selectorExist, actionAsyncFetch) => (
  ...args
) => (dispatch, getState) => {
  if (selectorExist(getState(), ...args)) {
    return Promise.resolve();
  } else {
    return dispatch(actionAsyncFetch(...args));
  }
};

export const createBulkLoadAsyncActions = (
  existingSelectors,
  asyncFetchActions
) => (...args) => (dispatch, getState) => {
  const promises = existingSelectors.map((selectorExist, i) => {
    if (selectorExist(getState(), ...args)) {
      return Promise.resolve();
    } else {
      return dispatch(asyncFetchActions[i](...args));
    }
  });

  return Promise.all(promises);
};
