import { formatDefaultDate } from '@dpdgroupuk/fmx-ui/utils/date';

import type { SafePlace } from '../../../../components/SafePlaceItem';
import { changeDeliveryOption } from '../../../../store/orm/Parcel/actions';
import type { ChangeDeliveryOptionOptions } from '../../../../apis';

type DeliveryToSafePlaceData = {
  day?: string,
  safePlace: SafePlace,
  instruction?: string,
  contactName: string,
  contactEmail: string,
  contactPhone: string,
};

type DeliveryAlternativeAddressBody = {
  diaryDate?: string,
  safePlaceCode: string,
  safePlaceInstructions: ?string,
  contactName: string,
  contactEmail: string,
  contactPhone: string,
};

export const changeDeliverToSafePlace = (
  parcelCode: string,
  {
    safePlace,
    instruction,
    day,
    contactName,
    contactEmail,
    contactPhone,
  }: DeliveryToSafePlaceData,
  options: ChangeDeliveryOptionOptions
) => (dispatch: Function) => {
  const data: DeliveryAlternativeAddressBody = {
    ...(day && { diaryDate: formatDefaultDate(day) }),
    safePlaceCode: safePlace.id,
    safePlaceInstructions: instruction || safePlace.name,
    contactName,
    contactEmail,
    contactPhone,
  };

  return dispatch(changeDeliveryOption(parcelCode, data, options));
};
