import React from 'react';
import { compose } from 'redux';
import { withProps } from 'recompose';
import { withRouter } from 'react-router-dom';

import SelectDay from '@dpdgroupuk/fmx-ui/components/DayPicker';
import SubmitButton from '@dpdgroupuk/fmx-ui/components/SubmitButton';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';
import { connect } from 'react-redux';
import { USER_TYPE } from '@dpdgroupuk/redback-enums';

import { RED_STEPS } from '../../steps';
import {
  getContactInformation,
  hasParcelNotificationDetails,
} from '../../../../models/parcel';
import * as MESSAGE from '../../../../constants/message';
import { ANOTHER_DAY_DELIVERY_OPTION } from '../../../../constants/analytics';
import withFetch from '../../../../HOCs/withFetch';
import { fetchParcelClaimsDispatch } from '../../actions';
import { ORIGIN } from '../../../../constants/values';
import mapStateToProps from '../../utils/mapStateToProps';

type Props = {
  data: Object,
  dates: Array<string>,
  onSelect: Function,
  onSubmit: Function,
};

const SelectDayStep = ({ data, dates, onSelect, onSubmit }: Props) => {
  return (
    <>
      <SelectDay
        title={MESSAGE.DAY_OF_DELIVERY}
        subtitle={MESSAGE.SELECT_UPCOMING_DAY}
        selectedDate={data.day}
        onSelect={onSelect}
        dates={dates}
      />
      <SubmitButton onSubmit={onSubmit} isSubmitHidden={!data.day} />
    </>
  );
};

export default compose(
  withRouter,
  withFetch(({ parcel }) => fetchParcelClaimsDispatch({ parcel })),
  connect(mapStateToProps),
  withProps(({ addStepData, goToStep, parcel, submitWizard, claims }) => ({
    onSelect: day => addStepData({ day, ...getContactInformation(parcel) }),
    onSubmit: () => {
      const { origin, userType } = claims;

      if (origin === ORIGIN.FMP && userType === USER_TYPE.RECEIVER) {
        addStepData({
          funcAfterAuth: submitWizard,
        });

        // Go to contact page before sms auth
        return goToStep(RED_STEPS.GET_CONTACTS);
      }

      return hasParcelNotificationDetails(parcel)
        ? submitWizard()
        : goToStep(RED_STEPS.GET_CONTACTS_NO_AUTH);
    },
  })),
  withTrackProps({
    onSelect: ANOTHER_DAY_DELIVERY_OPTION.ON_DATE_SELECT,
  })
)(SelectDayStep);
