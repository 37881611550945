import * as React from 'react';
import { usePageSpeedDealActions } from '@dpdgroupuk/fmx-ui/components/Page/hooks';
import {
  doSomethingDifferentSpeedDeal,
  showTbcMap,
  whatHappensNextSpeedDeal,
  goToParcelInfoSpeedDeal,
  backToDpdSiteSpeedDeal,
} from '../../../controlActions';

const createSpeedDealControlActions = props => {
  return [
    props.showMap
      ? goToParcelInfoSpeedDeal(props)
      : doSomethingDifferentSpeedDeal(props),
    showTbcMap(props),
    props.showMap
      ? backToDpdSiteSpeedDeal(props)
      : whatHappensNextSpeedDeal(props),
  ];
};

export const useCollectFromDepotControlActions = ({
  showMap,
  history,
  parcel,
  theme,
  toggleMap,
}) => {
  const actions = React.useMemo(
    () =>
      createSpeedDealControlActions({
        showMap,
        history,
        theme,
        parcel: { parcelCode: parcel.parcelCode },
        toggleMap,
      }),
    [history, parcel.parcelCode, toggleMap, showMap, theme]
  );
  usePageSpeedDealActions(actions);
};
