import { ORM } from 'redux-orm';
import { Action } from './Action/models';
import { Parcel } from './Parcel/models';
import { Depot } from './Depot/models';
import { Route } from './Route/models';
import { Driver } from './Driver/models';
import { Event } from './Event/models';
import { Claim } from './Claim/models';
import { PickupLocation } from './PickupLocation/models';

const orm = new ORM({
  stateSelector: state => state.orm,
});

orm.register(
  Action,
  Parcel,
  Depot,
  Route,
  Driver,
  Event,
  PickupLocation,
  Claim
);

export default orm;
