import { orderBy } from 'lodash';
export const openTimeList = openTimes => {
  // Group opening times by day
  const newOpeningTimes = openTimes.reduce((accumulator, openingTime) => {
    const key = openingTime.pickupLocationOpenWindowDay;

    if (!accumulator[key]) accumulator[key] = [];
    accumulator[key].push(openingTime);

    return accumulator;
  }, []);

  // Order times for each day
  const orderedOpeningTimes = newOpeningTimes.map(dayOpeningTimes =>
    orderBy(dayOpeningTimes, 'pickupLocationOpenWindowStartTime')
  );

  // Combine opening times (if end time is same as next start time)
  const timeStrings = [];
  orderedOpeningTimes.forEach(dayOpeningTimes => {
    const dayTimes = [];
    const time = {};

    dayOpeningTimes.forEach((times, index) => {
      const {
        pickupLocationOpenWindowDay,
        pickupLocationOpenWindowEndTime,
        pickupLocationOpenWindowStartTime,
      } = times;

      if (index === 0) {
        time.opening = pickupLocationOpenWindowStartTime;
        time.closing = pickupLocationOpenWindowEndTime;
      } else {
        if (time.closing !== pickupLocationOpenWindowStartTime) {
          dayTimes.push({ ...time });
          time.opening = pickupLocationOpenWindowStartTime;
          time.closing = pickupLocationOpenWindowEndTime;
        } else {
          time.closing = pickupLocationOpenWindowEndTime;
        }
      }

      if (index === dayOpeningTimes.length - 1) {
        dayTimes.push({ ...time });
        timeStrings.push({
          day: pickupLocationOpenWindowDay - 1,
          times: dayTimes,
        });
      }
    });
  });
  const openingTimesStrings = Array(7).fill('Closed');

  // Store the times for each of the days
  timeStrings.forEach(dayTimes => {
    const { day, times } = dayTimes;
    const timesStringsArray = times.map(
      ({ opening, closing }) => `${opening} - ${closing}`
    );

    openingTimesStrings[day] = timesStringsArray.join(', ');
  });

  return openingTimesStrings;
};
