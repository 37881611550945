import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from '@dpdgroupuk/redux-action-creator';

import { apiService, fetchTimeoutHandler } from '../../services';
import { handle404Error } from '../../utils/errorHandling';
import { fetchParcelClaims } from '../../store/orm/Claim/actions';

export const ACTIONS = createActionTypes('ACTION', {
  FETCH_ACTIONS_DATES_BY_CODE: createAsyncActionTypes(
    'FETCH_ACTIONS_DATES_BY_CODE'
  ),
  FETCH_LOCATIONS_BY_POSTCODE: createAsyncActionTypes(
    'FETCH_LOCATIONS_BY_POSTCODE'
  ),
  CHECK_DEPOT_COVERAGE_BY_POSTCODE: createAsyncActionTypes(
    'CHECK_DEPOT_COVERAGE_BY_POSTCODE'
  ),
  FETCH_DEPOT_TIME_SLOTS: createAsyncActionTypes('FETCH_DEPOT_TIME_SLOTS'),
  FETCH_ALTERNATIVE_TIMES: createAsyncActionTypes('FETCH_ALTERNATIVE_TIMES'),
  FETCH_PRECISE_TIMES: createAsyncActionTypes('FETCH_PRECISE_TIMES'),
  FETCH_PAYMENT_TOKEN: createAsyncActionTypes('FETCH_PAYMENT_TOKEN'),
  FETCH_DUTY_PAYMENT_TOKEN: createAsyncActionTypes('FETCH_DUTY_PAYMENT_TOKEN'),
  FETCH_PICKUP_SHOPS_BY_PARCEL: createAsyncActionTypes(
    'FETCH_PICKUP_SHOPS_BY_PARCEL'
  ),
});

export const fetchActionsDatesByCode = createAsyncAction(
  (parcelCode: string, actionCode: string) =>
    fetchTimeoutHandler(
      apiService.getActionsDates(parcelCode, actionCode)
    ).then(({ data }) => data),
  ACTIONS.FETCH_ACTIONS_DATES_BY_CODE
);

export const fetchLocationsByPostcode = createAsyncAction(
  (postcode: string) =>
    fetchTimeoutHandler(apiService.getLocations(postcode)).then(
      ({ data }) => data
    ),
  ACTIONS.FETCH_LOCATIONS_BY_POSTCODE
);

export const checkDepotCoverageByPostcode = createAsyncAction(
  (depotCode: string, postcode: string) =>
    fetchTimeoutHandler(
      apiService.getDepotCoverageByPostcode(depotCode, postcode)
    ).then(({ data }) => data),
  ACTIONS.CHECK_DEPOT_COVERAGE_BY_POSTCODE
);

export const fetchDepotTimeSlots = createAsyncAction(
  (
    parcelCode: string,
    depotCode: string,
    diaryDate: string,
    outForDeliveryDate: string
  ) =>
    fetchTimeoutHandler(
      apiService.getDepotTimeSlots(
        parcelCode,
        depotCode,
        diaryDate,
        outForDeliveryDate
      )
    ).then(({ data }) => data, handle404Error),
  ACTIONS.FETCH_DEPOT_TIME_SLOTS
);

export const fetchAlternativeTimes = createAsyncAction(
  (parcelCode: string, diaryDate: string) => {
    return fetchTimeoutHandler(
      apiService.getAlternativeTimes(parcelCode, diaryDate)
    ).then(({ data }) => data, handle404Error);
  },
  ACTIONS.FETCH_ALTERNATIVE_TIMES
);

export const fetchPreciseTimes = createAsyncAction(
  (
    parcelCode: string,
    date: string,
    postcode: string,
    businessUnit: string
  ) => {
    return fetchTimeoutHandler(
      apiService.getPreciseTimes(parcelCode, date, postcode, businessUnit)
    ).then(({ data }) => data);
  },
  ACTIONS.FETCH_PRECISE_TIMES
);

export const fetchPaymentToken = createAsyncAction(
  (parcelCode: string) =>
    fetchTimeoutHandler(apiService.getPaymentToken(parcelCode)).then(
      ({ data }) => data
    ),
  ACTIONS.FETCH_PAYMENT_TOKEN
);

export const fetchDutyPaymentToken = createAsyncAction(
  (parcelCode: string) =>
    fetchTimeoutHandler(apiService.getDutyPaymentToken(parcelCode)).then(
      ({ data }) => data
    ),
  ACTIONS.FETCH_DUTY_PAYMENT_TOKEN
);

export const fetchPickupShops = createAsyncAction(
  (parcelCode: string) =>
    fetchTimeoutHandler(apiService.getPickupShops(parcelCode)).then(
      ({ data }) => data
    ),
  ACTIONS.FETCH_PICKUP_SHOPS_BY_PARCEL
);

export const fetchParcelClaimsDispatch = ({ parcel }) => dispatch => {
  return dispatch(fetchParcelClaims(parcel.parcelCode));
};

export const fetchLocationsByPostcodeWithClaims = ({
  parcel,
}) => async dispatch => {
  const locationsByPostcode = await dispatch(
    fetchLocationsByPostcode(parcel.deliveryDetails.address.postcode)
  );
  const claims = await dispatch(fetchParcelClaims(parcel.parcelCode));

  return [locationsByPostcode, claims];
};
