import * as React from 'react';

import { ConfigContext, defaultContextValue } from './ConfigContext';

const parse = config =>
  Object.keys(config).reduce((acc, key) => {
    const valueAsString = config[key].asString();
    let value;
    try {
      value = JSON.parse(valueAsString);
    } catch (e) {
      value = valueAsString;
    }
    acc[key] = value;
    return acc;
  }, {});

type Props = {
  remoteConfig: any,
  children: React.Node,
};

const fetchConfig = async remoteConfig => {
  await remoteConfig.fetchAndActivate();

  return parse(remoteConfig.getAll());
};

export const ConfigProvider = ({ remoteConfig, children }: Props) => {
  const [config, setConfig] = React.useState(defaultContextValue);

  React.useEffect(() => {
    async function fetchRemoteConfig() {
      const data = await fetchConfig(remoteConfig);
      setConfig({ ...defaultContextValue, ...data });
    }
    fetchRemoteConfig();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};
