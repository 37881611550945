import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { compose, withProps } from 'recompose';

import SubmitButton from '@dpdgroupuk/fmx-ui/components/SubmitButton';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';
import { formatDefaultDate } from '@dpdgroupuk/fmx-ui/utils/date';

import { fetchAlternativeTimes } from '../../actions';
import TimeSlotList from '../../components/TimeSlotList';
import ChangeDeliveryDay from '../../components/ChangeDeliveryDay';
import { UPG_STEPS } from '../../steps';
import TimeSlotErrorComponent from '../../components/TimeSlotErrorComponent';
import withFetch from '../../../../HOCs/withFetch';

import { UPGRADE_DELIVERY_OPTION } from '../../../../constants/analytics';
import { CHOOSE_TIME_SLOT } from '../../../../constants/message';

const useStyles = makeStyles(({ typography, palette, breakpoints }) => ({
  title: {
    fontSize: typography.pxToRem(21),
    textAlign: 'center',
    color: palette.text.redWhite,
    textTransform: 'uppercase',
    marginTop: typography.pxToRem(73),
    marginBottom: typography.pxToRem(40),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(18),
      marginTop: typography.pxToRem(40),
      marginBottom: typography.pxToRem(30),
    },
  },
}));

type Props = {
  fetchedData: Array,
  data: {
    day: string,
    time: Object,
  },
  goToStep: Function,
  onDateChange: Function,
  onSelect: Function,
};

function SelectTimeStep({
  fetchedData,
  data,
  goToStep,
  onDateChange,
  onSelect,
}: Props) {
  const styles = useStyles();
  return (
    <>
      <ChangeDeliveryDay day={data.day} onDateChange={onDateChange} />
      <Typography className={styles.title}>{CHOOSE_TIME_SLOT}</Typography>
      {fetchedData.length ? (
        <>
          <TimeSlotList
            items={fetchedData}
            onSelect={onSelect}
            format={el => `${el.startTime} - ${el.endTime}`}
            active={data.time}
            formatSecondary={el => `£${el.upgradeCost}`}
          />
          <SubmitButton
            onSubmit={() => {
              goToStep(UPG_STEPS.PAYMENT);
            }}
            isSubmitHidden={!data.time}
          >
            PAY NOW
          </SubmitButton>
        </>
      ) : (
        <TimeSlotErrorComponent
          onSubmit={() => goToStep(UPG_STEPS.SELECT_DAY)}
        />
      )}
    </>
  );
}

export default compose(
  withFetch(({ data, parcel }) =>
    fetchAlternativeTimes(parcel.parcelCode, formatDefaultDate(data.day))
  ),
  withProps(({ addStepData, goToStep }) => ({
    onSelect: time => {
      addStepData({ time });
    },
    onDateChange: () => {
      addStepData({ time: null });
      goToStep(UPG_STEPS.SELECT_DAY);
    },
  })),
  withTrackProps({
    onSelect: UPGRADE_DELIVERY_OPTION.ON_SLOT_SELECT,
    onDateChange: UPGRADE_DELIVERY_OPTION.ON_DATE_CHANGE,
  })
)(SelectTimeStep);
