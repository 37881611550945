import * as React from 'react';

import { isMobile } from '@dpdgroupuk/fmx-ui/utils/helpers';
import PickupShopCard from './components/PickupShopCard';

type Props = {
  pickupShops: any,
  selectedShopCode: string,
  openedPanel: string,
  onSelectClick: Function,
  openPanel: Function,
  onCardClick: Function,
};

const getPickupShopSlides = ({
  pickupShops = [],
  selectedShopCode,
  onSelectClick,
  openedPanel,
  onCardClick,
}: Props) => {
  const shops = pickupShops.slice(0, pickupShops.length);
  const selectedShopChecker = ({ pickupLocation: { pickupLocationCode } }) =>
    pickupLocationCode === selectedShopCode;
  const selectedShopIndex = shops.findIndex(selectedShopChecker);
  const selectedShop = shops.find(selectedShopChecker);

  if (isMobile && !!selectedShopIndex && selectedShopIndex !== -1) {
    shops.splice(selectedShopIndex, 1);
    shops.unshift(selectedShop);
  }

  return shops.map(pickupShop => {
    const {
      pickupLocation: { pickupLocationCode },
    } = pickupShop;
    const index = pickupShops.findIndex(
      ({ pickupLocation }) =>
        pickupLocation.pickupLocationCode === pickupLocationCode
    );

    return (
      <PickupShopCard
        key={pickupLocationCode}
        pickupShopInfo={pickupShop}
        index={index}
        selected={pickupLocationCode === selectedShopCode}
        onSelectClick={onSelectClick(pickupLocationCode)}
        onCardClick={onCardClick(pickupLocationCode)}
        opened={
          window.innerWidth < 768
            ? openedPanel === pickupLocationCode
            : openedPanel
        }
      />
    );
  });
};

export default getPickupShopSlides;
