export const DUTY_PAYMENT_STEPS = {
  DUE: 'DUE',
  CAPTURE: 'CAPTURE',
  PAYMENT_PROCESSING: 'PAYMENT_PROCESSING',
  PAYMENT_CONFIRMATION: 'PAYMENT_CONFIRMATION',
  CANCELLED_CONFIRMATION: 'CANCELLED_CONFIRMATION',
};

export const DUTY_PAYMENT_OPTIONS = {
  DELIVER: 'deliver',
  RETURN: 'return',
};
