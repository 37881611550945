import { isEmpty } from 'lodash';
import React from 'react';
import { compose } from 'redux';
import { withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import * as ENUM from '@dpdgroupuk/redback-enums';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes/constants';
import Wizard, { Step } from '@dpdgroupuk/fmx-ui/components/Wizard';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';

import type { AuthorizedParcel } from '../../../../types';
import SelectDayStep from './SelectDayStep';
import SelectAddressStep from './SelectAddressStep';
import CompleteStep from './CompleteStep';
import {
  ALA_DELIVERY_OPTION,
  trackable,
} from '../../../../constants/analytics';
import {
  whatHappensNextSpeedDeal,
  doSomethingDifferentSpeedDeal,
  trackAnotherParcelHeader,
  backToDpdSiteHeader,
  backToGreenSiteHeader,
  getDpdAppHeader,
  whatHappensNextHeader,
  goBackToDeliveryDetailsSpeedDeal,
  doSomethingDifferentHeader,
} from '../../../controlActions';
import * as MESSAGES from '../../../../constants/message';
import withParcel from '../../../../HOCs/withParcel';
import { fetchActionsDatesByCode } from '../../actions';
import { ALA_STEPS } from '../../steps';
import { changeAlternativeAddress } from './actions';
import { withDeliveryUpdate } from '../../hocs';
import withFetch from '../../../../HOCs/withFetch';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  completeStep: {
    paddingLeft: typography.pxToRem(120),
    paddingRight: typography.pxToRem(120),
    [breakpoints.down('xs')]: {
      paddingLeft: typography.pxToRem(15),
      paddingRight: typography.pxToRem(15),
    },
  },
}));

type Props = {|
  parcel: AuthorizedParcel,
  dates: Array<string>,
  updateDelivery: Function,
  onSubmitSuccess: Function,
  onSubmitFailure: Function,
|};

const AlternateAddress = ({
  parcel,
  dates,
  updateDelivery,
  onSubmitSuccess,
  onSubmitFailure,
}: Props) => {
  const styles = useStyles();

  return (
    <Wizard
      parcelCode={parcel.parcelCode}
      initialStep={
        isEmpty(dates) ? ALA_STEPS.SELECT_ADDRESS : ALA_STEPS.SELECT_DAY
      }
      onSubmit={updateDelivery}
      onSubmitSuccess={onSubmitSuccess}
      onSubmitFailure={onSubmitFailure}
    >
      <Step
        stepId={ALA_STEPS.SELECT_DAY}
        dates={dates}
        component={SelectDayStep}
      />
      <Step
        stepId={ALA_STEPS.SELECT_ADDRESS}
        parcel={parcel}
        component={SelectAddressStep}
      />
      <Step
        stepId={ALA_STEPS.COMPLETE}
        parcel={parcel}
        component={CompleteStep}
        actionCode={ENUM.PARCEL_ACTION_CODE.ALA}
        wrapperClassName={styles.completeStep}
      />
    </Wizard>
  );
};

const createSpeedDealControlActions = props => [
  doSomethingDifferentSpeedDeal(props),
  goBackToDeliveryDetailsSpeedDeal(props),
  whatHappensNextSpeedDeal(props),
];

const createHeaderControlActions = props => [
  doSomethingDifferentHeader(props),
  trackAnotherParcelHeader(props),
  whatHappensNextHeader(props),
  backToDpdSiteHeader(props),
  backToGreenSiteHeader(props),
  getDpdAppHeader(props),
];

export default compose(
  withRouter,
  withParcel(),
  withModal,
  withFetch(({ match }) =>
    fetchActionsDatesByCode(match.params.parcelId, ENUM.PARCEL_ACTION_CODE.ALA)
  ),
  withProps(props => ({
    dates: props.fetchedData,
    onSubmitSuccess: ({ goToStep }) => goToStep(ALA_STEPS.COMPLETE),
  })),
  withDeliveryUpdate({
    actionCode: ENUM.PARCEL_ACTION_CODE.ALA,
    changeDeliveryAction: changeAlternativeAddress,
    trackable: ALA_DELIVERY_OPTION.ON_SUBMIT,
  }),
  withPageControl({
    title: MESSAGES.SELECT_ALTERNATE_ADDRESS,
    backgroundImage: IMAGES.MAP,
    trackable: trackable(ALA_DELIVERY_OPTION),
    speedDealActions: createSpeedDealControlActions,
    headerMenuActions: createHeaderControlActions,
  })
)(AlternateAddress);
