import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PAYMENT_PROCESSING } from '../../../../../constants/message';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  text: {
    fontSize: typography.pxToRem(20),
    maxWidth: '68%',
    marginBottom: typography.pxToRem(35),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
      marginBottom: typography.pxToRem(15),
    },
  },
}));

const PaymentConfirmation = () => {
  const styles = useStyles();

  return (
    <>
      <Typography className={styles.text}>{PAYMENT_PROCESSING}</Typography>
    </>
  );
};

export default PaymentConfirmation;
