import React from 'react';
import { createMuiTheme } from '@material-ui/core';
import ThemeProvider from '@dpdgroupuk/fmx-ui/components/Theme';

import { withRemoteConfig } from './components/RemoteConfig/ConfigContext';

const ThemeAndEventProvider = ({ children, remoteConfig }: any) => {
  const theme = createMuiTheme({ eventName: remoteConfig.eventName });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default withRemoteConfig()(ThemeAndEventProvider);
