import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from '@dpdgroupuk/redux-action-creator';

import { apiService, fetchTimeoutHandler } from '../../../services';

export const ACTIONS = createActionTypes('EVENT', {
  FETCH_EVENT: createAsyncActionTypes('FETCH_EVENT'),
});

export const fetchEvents = createAsyncAction(
  (parcelCode: string) =>
    fetchTimeoutHandler(apiService.getEventsByParcelCode(parcelCode)).then(
      ({ data }) => ({ parcelCode, items: data })
    ),
  ACTIONS.FETCH_EVENT
);
