import React, { useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { ISSUE_CODE } from '@dpdgroupuk/redback-enums';
import {
  CivilUnrestIcon,
  FireIcon,
  FlightDelayIcon,
  FloodIcon,
  LocalEventIcon,
  NoSailingIcon,
  OlympicIcon,
  SnowflakeIcon,
  TrafficIcon,
  UnexpectedIcon,
  WindIcon,
} from '@dpdgroupuk/fmx-ui/components/Icons';

import { Coronavirus } from '../../../../../components/Icons';

const useStyles = makeStyles(({ typography }) => ({
  icon: {
    fill: '#71c4ea',
    height: typography.pxToRem(35),
    width: typography.pxToRem(35),
  },
}));
type Props = {
  issueCode: ?string,
  className?: string,
};

const icons = {
  [ISSUE_CODE.UNEXPECTED_DELAY]: UnexpectedIcon,
  [ISSUE_CODE.FIRE]: FireIcon,
  [ISSUE_CODE.FLOOD]: FloodIcon,
  [ISSUE_CODE.SNOW]: SnowflakeIcon,
  [ISSUE_CODE.CIVIL_UNREST]: CivilUnrestIcon,
  [ISSUE_CODE.NO_SAILING]: NoSailingIcon,
  [ISSUE_CODE.TRAFFIC_INCIDENT]: TrafficIcon,
  [ISSUE_CODE.HIGH_WINDS]: WindIcon,
  [ISSUE_CODE.FLIGHT_DELAY]: FlightDelayIcon,
  [ISSUE_CODE.OLYMPIC_DELAY]: OlympicIcon,
  [ISSUE_CODE.LOCAL_EVENT]: LocalEventIcon,
  [ISSUE_CODE.CORONAVIRUS]: Coronavirus,
};

const IssueIcon = ({ issueCode, className, ...rest }: Props) => {
  const styles = useStyles();
  const iconClass = clsx(styles.icon, className);
  const IconElement = useMemo(
    () => icons[issueCode] || icons[ISSUE_CODE.UNEXPECTED_DELAY],
    [issueCode]
  );
  /* $FlowFixMe This comment suppresses an error found when upgrading Flow to
   * v0.112.0. To view the error, delete this comment and run Flow. */
  return <IconElement className={iconClass} {...rest} />;
};

export default IssueIcon;
