import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const createStyles = makeStyles(({ typography, breakpoints }) => ({
  text: {
    textAlign: 'center',
    letterSpacing: 0.44,
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
      lineHeight: 1.72,
    },
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(20),
      lineHeight: 1.69,
    },
    '@media print': {
      display: 'none',
    },
  },
}));

type Props = {
  status: string,
  className?: string,
};
const TrackingStatus = ({ status, className }: Props) => {
  const styles = createStyles();

  return (
    <Typography
      className={clsx(styles.text, className)}
      dangerouslySetInnerHTML={{ __html: status }}
    />
  );
};

export default TrackingStatus;
