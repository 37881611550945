import * as React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';
import { copyDivToClipboard } from '@dpdgroupuk/fmx-ui/utils/helpers';

import * as MESSAGE from '../../../../../constants/message';

type Props = {
  children: any,
  className?: String,
  buttonClass: any,
};

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  title: {
    fontSize: typography.pxToRem(14.5),
    fontWeight: 400,
    lineHeight: 1.68,
    letterSpacing: typography.pxToRem(0.4),
    textAlign: 'left',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(13),
    },
  },
  button: {
    fontSize: typography.pxToRem(13),
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: typography.pxToRem(0.44),
    textAlign: 'right',
    cursor: 'pointer',
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const TextWithCopyAction = ({ children, className, buttonClass }: Props) => {
  const styles = useStyles();
  const textRef = React.useRef({});

  const onCopyClick = event => {
    event.stopPropagation();

    if (textRef.current) {
      copyDivToClipboard(textRef.current);
    }
  };

  return (
    <Typography className={clsx(styles.title, className)}>
      <span ref={textRef}>{children}</span>
      <Link
        component="button"
        onClick={onCopyClick}
        className={buttonClass || styles.button}
      >
        {MESSAGE.COPY}
      </Link>
    </Typography>
  );
};

export default TextWithCopyAction;
