export const calculateVat = ({ adminFee, adminFeeVat, amount, tax, vat }) => {
  return {
    // multiply and then devide to avoid floating point error
    dutyToPay: ((amount * 100 + tax * 100) / 100).toFixed(2),
    taxToPay: vat.toFixed(2),
    clearanceFee: adminFee.toFixed(2),
    vatOnClearanceFee: adminFeeVat.toFixed(2),
    // multiply and then devide to avoid floating point error
    total: (
      (adminFee * 100 +
        adminFeeVat * 100 +
        amount * 100 +
        tax * 100 +
        vat * 100) /
      100
    ).toFixed(2),
  };
};
