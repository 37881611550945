import api from './api';

export const getDepotByDepotCode = (depotCode: string) => {
  return api.get({
    path: `/depots/${depotCode}`,
  });
};

export const getDepotCoverageByPostcode = (
  depotCode: string,
  postcode: string
) => {
  return api.get({
    path: `/depots/${depotCode}/postcode/${postcode}/covered`,
  });
};

export const getDepotTimeSlots = (
  parcelCode: string,
  depotCode: string,
  diaryDate: string,
  outForDeliveryDate: string
) => {
  return api.get({
    path: `/depots/${depotCode}/dates/${diaryDate}/times`,
    query: { outForDeliveryDate, parcelCode },
  });
};
