import { get } from 'lodash';
import * as ENUM from '@dpdgroupuk/redback-enums';
import { createPropsSelectorOrm } from '../utils';

export const getDeliveryActionsByDepotCode = createPropsSelectorOrm(
  (session, parcelCode) => {
    const actions = get(session.Action.withId(parcelCode), 'ref');

    const dtyUnavailableActionsIndex = actions.unavailableActions.findIndex(
      item => item === ENUM.PARCEL_ACTION_CODE.DTY
    );

    return {
      ...actions,
      unavailableActions: actions.unavailableActions.filter(
        item => item !== ENUM.PARCEL_ACTION_CODE.DTY
      ),
      unavailableActionsName: actions.unavailableActionsName.filter(
        (_, index) => index !== dtyUnavailableActionsIndex
      ),
    };
  }
);
