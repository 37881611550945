import CycleRepublicLogo from '../assets/images/PickupShops/cycleRepublicLogo.png';
import DoddleLogo from '../assets/images/PickupShops/Doddle.png';
import HalfordsLogo from '../assets/images/PickupShops/Halfords.png';
import MatalanLogo from '../assets/images/PickupShops/Matalan.png';
import SainsburysLogo from '../assets/images/PickupShops/Sainsburys.png';

export const shopLogos = {
  CycleRepublic: CycleRepublicLogo,
  Doddle: DoddleLogo,
  Halfords: HalfordsLogo,
  Matalan: MatalanLogo,
  Sainsburys: SainsburysLogo,
};
