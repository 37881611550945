import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { get } from 'lodash';
import { withProps } from 'recompose';
import { compose } from 'redux';
import Like from '@dpdgroupuk/fmx-ui/images/Rating/Like.png';
import { withTrack, withTrackProps } from '@dpdgroupuk/react-event-tracker';

import Button from '../../../components/Rate/Button';
import type { Driver } from '../../../types';
import RateInfoButton from './RateInfoButton';
import { RATE_STEPS } from '../steps';
import RateWithPhoto from '../../../components/Rate/RateWithPhoto';
import * as MESSAGE from '../../../constants/message';
import { RATE_DRIVER, trackable } from '../../../constants/analytics';

const useStyles = makeStyles(({ typography, breakpoints, palette }) => {
  return {
    text: {
      marginTop: typography.pxToRem(41),
      fontSize: typography.pxToRem(16),
      letterSpacing: typography.pxToRem(0.57),
    },
    ratingBlock: {
      marginTop: typography.pxToRem(39),
      [breakpoints.down('xs')]: {
        marginTop: 0,
      },
    },
    skipBlock: {
      marginTop: typography.pxToRem(70),
      marginBottom: typography.pxToRem(90),
    },
    icon: {
      [breakpoints.down('xs')]: {
        marginTop: typography.pxToRem(22),
      },
    },
    thumbsUp: {
      width: typography.pxToRem(81),
      height: typography.pxToRem(81),
      backgroundColor: palette.background.rateButton,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [breakpoints.down('xs')]: {
        marginTop: typography.pxToRem(20),
        width: typography.pxToRem(69),
        height: typography.pxToRem(69),
      },
    },
    thumbsUpIcon: {
      fontSize: typography.pxToRem(30),
      width: typography.pxToRem(81),
      height: typography.pxToRem(81),
    },
  };
});

type Props = {
  driver: Driver,
  data: Object,
  onSkip: Function,
  onSelectRating: Function,
};

const RateDriver = ({
  driver,
  data: { driverRating, experienceComplimentIcon },
  onSkip,
  onSelectRating,
}: Props) => {
  const styles = useStyles();

  const driverName = get(driver, 'driverName');

  return (
    <>
      {experienceComplimentIcon ? (
        <RateInfoButton
          icon={experienceComplimentIcon}
          className={styles.icon}
        />
      ) : (
        <div className={styles.thumbsUp}>
          <img src={Like} alt="" className={styles.thumbsUpIcon} />
        </div>
      )}
      <Typography className={styles.text}>
        {MESSAGE.DRIVER_FEEDBACK_QUESTION.replace('{{driverName}}', driverName)}
      </Typography>
      <div className={styles.ratingBlock}>
        <RateWithPhoto
          driver={driver}
          onChange={onSelectRating}
          value={driverRating}
        />
      </div>
      <div className={styles.skipBlock}>
        <Button onClick={onSkip}>{MESSAGE.SKIP}</Button>
      </div>
    </>
  );
};

export default compose(
  withTrack(trackable(RATE_DRIVER)),
  withProps(({ goToStep, addStepData, submitWizard }) => ({
    onSkip: () => submitWizard(),
    onSelectRating: (e, value) => {
      addStepData({
        driverRating: value,
      });
      goToStep(RATE_STEPS.RATE_DRIVER_COMPLIMENT);
    },
  })),
  withTrackProps({
    onSkip: RATE_DRIVER.ON_SKIP_COMPLIMENT,
    onSelectRating: RATE_DRIVER.ON_CLICK_STAR,
  })
)(RateDriver);
