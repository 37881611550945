export const HOME = 'HOME';
export const CHECK_DETAILS_AND_TRY_AGAIN =
  'Please check your details and try again.';
export const POSTCODE_MUST_BE_WITHIN_DEPOT_AREA =
  'Sorry, the alternative address must be within the delivery depots area. To arrange delivery to this address please contact sender.';
export const NO_ADDRESSES_FOR_CURRENT_POSTCODE =
  'Sorry, there are no addresses for your postcode';
export const REFERENCE_NUMBER_COULD_NOT_BE_FOUND =
  'Your reference number could not be found';
export const SOMETHING_WENT_WRONG = 'Oops, something went wrong';
export const PAGE_NOT_FOUND = 'Page not found';
export const NOT_AUTHORISED = 'Not Authorised';
export const ERROR = 'Error';
export const REQUEST_TIMEOUT = 'Request Timeout';
export const PROBLEM_COMPLITING_REQUEST =
  'Oops, there was a problem completing your request';
export const REQUEST_TIMEOUT_MESSAGE =
  'This request took longer than expected, please try again';
export const CONFLICT =
  'The request could not be completed due to a conflict with the current state of the target resource';
export const RATE_CONFLICT_MESSAGE =
  'You have already rated this delivery experience, thanks for the feedback';
export const NOT_AUTHORISED_MESSAGE =
  'Whoa there, you are not authorised to perform that request';
export const PAGE_NOT_FOUND_MESSAGE =
  'Oops, the page you are looking for has not been found';
export const TRY_AGAIN = 'CLICK HERE TO TRY AGAIN';
export const CLOSE_TO_TRY_AGAIN = 'CLOSE TO TRY AGAIN';
export const CLOSE = 'CLOSE';
export const PRECISE_SLOT_FULL = 'Sorry, the requested precise slot is full';
export const OLD_BROWSER_ERROR =
  'The content on this page may not be displayed correctly due to your browser';
export const BRAINTREE_PAYMENT_PROBLEM =
  'Please contact your payment vendor as there has been a problem with your payment';
export const BRAINTREE_PAYMENT_CANCEL = 'Payment cancelled';
export const UNEXPECTED_END_OF_JSON = 'Unexpected end of JSON input';
export const TRY_AGAIN_LATER =
  'Oops, something went wrong. Please try again later.';
export const PAYMENT_FAILED_TRY_ANOTHER =
  'Payment failed, please try another form of payment';
