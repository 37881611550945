import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from '@dpdgroupuk/redux-action-creator';
import { apiService, fetchTimeoutHandler } from '../../../services';

export const ACTIONS = createActionTypes('ACTION', {
  FETCH_ACTIONS_BY_CODE: createAsyncActionTypes('FETCH_ACTIONS_BY_CODE'),
});

export const fetchDeliveryActionsByCode = createAsyncAction(
  (parcelCode: string) =>
    fetchTimeoutHandler(apiService.getDeliveryActions(parcelCode)).then(
      ({ data }) => ({ ...data, parcelCode })
    ),
  ACTIONS.FETCH_ACTIONS_BY_CODE
);
