import * as React from 'react';
import { compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import type { AuthorizedParcel } from '@dpdgroupuk/fmx-ui/types/Parcel';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';

import { ISSUE, trackable } from '../../../../../constants/analytics';
import TrackingStatus from '../../../../../components/TrackingStatus';
import { useParcelIssue } from '../hooks';

import IssueIcon from './IssueIcon';
import {
  backToDpdSiteHeader,
  changeDeliveryHeader,
  changeDeliverySpeedDeal,
  getDpdAppHeader,
  goToParcelInfoHeader,
  goToParcelInfoSpeedDeal,
  trackAnotherParcelHeader,
  whatHappensNextHeader,
  whatHappensNextSpeedDeal,
} from '../../../../controlActions';
import withFetch from '../../../../../HOCs/withFetch';
import { withParcelInfo } from '../../../hocs';
import { MORE_COOL_STUFF } from '../../../../../constants/message';

const useStyles = makeStyles(({ breakpoints, typography }) => ({
  textWrapper: {
    marginTop: typography.pxToRem(16),
    marginBottom: typography.pxToRem(23),
  },
  subTextWrapper: {
    [breakpoints.up('sm')]: {
      padding: `0 ${typography.pxToRem(70)}`,
    },
  },
  text: {
    [breakpoints.down('xs')]: {
      marginTop: typography.pxToRem(10),
      '& + &': {
        marginTop: typography.pxToRem(18),
      },
    },
  },
  subText: {
    fontSize: typography.pxToRem(15),
    fontWeight: 300,
    lineHeight: 1.5,
    letterSpacing: 0.44,
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(13),
      fontWeight: 400,
      lineHeight: 1.6,
    },
  },
  wrapper: {
    marginTop: typography.pxToRem(10),
  },
}));

type Props = {
  parcel: AuthorizedParcel,
};

const Issue = ({ parcel }: Props) => {
  const styles = useStyles();
  const { parcelIssueText, parcelIssueSubText, issueCode } = useParcelIssue(
    parcel
  );

  return (
    <>
      <TrackingStatus status={parcel.parcelStatusHtml} />
      <div className={styles.wrapper}>
        <Grid item>
          <IssueIcon issueCode={issueCode} id={`issueIcon${issueCode}`} />
        </Grid>
        <Grid className={styles.textWrapper} item>
          {parcelIssueText.map(text => (
            <TrackingStatus key={text} status={text} className={styles.text} />
          ))}
        </Grid>
        <Grid className={styles.subTextWrapper} item>
          <Typography className={styles.subText}>
            {parcelIssueSubText}
          </Typography>
        </Grid>
      </div>
    </>
  );
};

const createHeaderControlActions = props => [
  goToParcelInfoHeader(props),
  whatHappensNextHeader(props),
  changeDeliveryHeader(props),
  trackAnotherParcelHeader(props),
  backToDpdSiteHeader(props),
  getDpdAppHeader(props),
];

const createSpeedDealControlActions = props => [
  goToParcelInfoSpeedDeal(props),
  changeDeliverySpeedDeal(props),
  whatHappensNextSpeedDeal(props),
];

export default compose(
  withRouter,
  withModal,
  withFetch(() => () => Promise.resolve({}), {
    dataPropMapper: 'data',
  }),
  withPageControl({
    title: MORE_COOL_STUFF,
    backgroundImage: IMAGES.TRACKING_BG,
    trackable: trackable(ISSUE),
    headerMenuActions: createHeaderControlActions,
    speedDealActions: createSpeedDealControlActions,
    // isMobileMenuShevronVisible: true,
  }),
  withParcelInfo()
)(Issue);
