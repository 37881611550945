import * as React from 'react';
import { withProps, compose } from 'recompose';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import SelectDay from '@dpdgroupuk/fmx-ui/components/DayPicker';
import { formatMessage } from '@dpdgroupuk/fmx-ui//utils/string';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';

import { withRemoteConfig } from '../../../../components/RemoteConfig/ConfigContext';
import * as MESSAGE from '../../../../constants/message';
import { UPG_STEPS } from '../../steps';
import { UPGRADE_DELIVERY_OPTION } from '../../../../constants/analytics';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  title: {
    fontSize: typography.pxToRem(19),
    lineHeight: 1.45,
    letterSpacing: typography.pxToRem(0.44),
    marginBottom: typography.pxToRem(54),
    textAlign: 'center',
    whiteSpace: 'pre',
    color: '#b7b9bd',
    fontWeight: 300,
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
    },
  },
  text: {
    whiteSpace: 'pre-line',
    fontWeight: 500,
  },
}));

type Props = {
  onSelect: Function,
  dates: Array<string>,
  vip: Object,
};
function SelectDayStep({ onSelect, dates, vip }: Props) {
  const styles = useStyles();

  return (
    <>
      <Typography className={styles.title}>
        {MESSAGE.SELECT_NEW_DELIVERY_DAY}
      </Typography>
      <Typography className={clsx(styles.title, styles.text)}>
        {formatMessage(
          MESSAGE.AFTER_AND_BEFORE_NOON_$,
          vip.after12,
          vip.before12
        )}
      </Typography>
      <SelectDay
        title={MESSAGE.DAY_OF_DELIVERY}
        onSelect={onSelect}
        dates={dates}
      />
    </>
  );
}

export default compose(
  withRemoteConfig(),
  withProps(({ addStepData, goToStep, remoteConfig: { vip } }) => ({
    vip,
    onSelect: day => {
      addStepData({ day });
      goToStep(UPG_STEPS.CHOOSE_TIME_SLOT);
    },
  })),
  withTrackProps({
    onSelect: UPGRADE_DELIVERY_OPTION.ON_DATE_SELECT,
  })
)(SelectDayStep);
