import { compose, withProps } from 'recompose';
import { withTrack, withTrackProps } from '@dpdgroupuk/react-event-tracker';

import DeliveryPreferences from './DeliveryPreferences';
import { DELIVERY_PREFERENCES, trackable } from '../../../constants/analytics';
import { openInNewTab } from '../../../utils/location';

export default wizardSteps =>
  compose(
    withProps(({ handleWizardSubmit, updateDelivery, goToStep }) => ({
      onSubmit: async (createProfileLink: boolean) => {
        const { profileLink } = await handleWizardSubmit(updateDelivery, {
          createProfileLink,
        });
        if (createProfileLink && profileLink) {
          openInNewTab(profileLink);
        }
        goToStep(wizardSteps.COMPLETE);
      },
    })),
    withTrack(trackable(DELIVERY_PREFERENCES)),
    withTrackProps({
      onSubmit: createProfileLink =>
        createProfileLink
          ? DELIVERY_PREFERENCES.ON_YES
          : DELIVERY_PREFERENCES.ON_NO,
    })
  )(DeliveryPreferences);
