import { formatDefaultDate } from '@dpdgroupuk/fmx-ui/utils/date';

import { changeDeliveryOption } from '../../../../store/orm/Parcel/actions';
import type { ChangeDeliveryOptionOptions } from '../../../../apis';

export const changeDeliveryPreferenceDay = (
  parcelCode: string,
  {
    day,
    contactName,
    contactEmail,
    contactPhone,
  }: {
    day: string,
    contactName: string,
    contactEmail: string,
    contactPhone: string,
  },
  options: ChangeDeliveryOptionOptions
) => (dispatch: Function) =>
  dispatch(
    changeDeliveryOption(
      parcelCode,
      {
        diaryDate: formatDefaultDate(day),
        contactName,
        contactEmail,
        contactPhone,
      },
      options
    )
  );
