import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import Mapbox from '../../../components/Mapbox';
import { API_HOST } from '../../../constants/app';
import type { AuthorizedParcel } from '../../../types';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  map: {
    [breakpoints.down('xs')]: {
      marginLeft: typography.pxToRem(5),
      marginRight: typography.pxToRem(5),
    },
  },
}));

type Props = {
  children: React.node,
  parcel: AuthorizedParcel,
  toggleMap: boolean,
  showMap?: boolean,
};

const MapContainer = ({
  children,
  parcel,
  toggleMap,
  showMap = false,
}: Props) => {
  const styles = useStyles();
  const mapSrc = `${API_HOST}/map/depot?depotCode=${parcel.deliveryDepot.depotCode}&size=1200*600`;
  return showMap ? (
    <Mapbox
      mapSrc={mapSrc}
      onClose={toggleMap}
      boxShadow={false}
      className={styles.map}
    />
  ) : (
    children
  );
};

export default MapContainer;
