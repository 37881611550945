import api from './api';

export const getParcelByParcelCode = (parcelCode: string) => {
  return api.getWithoutCache({
    path: `/parcels/${parcelCode}`,
  });
};

export const getVerifySession = (parcelCode: string) => {
  return api.getWithoutCache({
    path: `/parcels/${parcelCode}/verifySession`,
  });
};
