import api from './api';

export const getSearchParcelByReferenceNumber = (
  referenceNumber: string,
  postcode: string,
  origin: string
) => {
  return api.get({
    path: '/reference',
    query: {
      referenceNumber,
      postcode,
      origin: origin || 'PRTK',
    },
  });
};
