import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { compose } from 'recompose';
import { withTrack } from '@dpdgroupuk/react-event-tracker';

import {
  DUTY_NOTIFICATION_MESSAGE,
  THANKS_FOR_PAYING,
  THANKS_FOR_PAYING_CUSTOMS,
} from '../../../../../constants/message';
import {
  DTY_PAYMENT_COMPLETE,
  trackable,
} from '../../../../../constants/analytics';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  text: {
    fontSize: typography.pxToRem(20),
    maxWidth: '68%',
    marginBottom: typography.pxToRem(35),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
      marginBottom: typography.pxToRem(15),
    },
  },
}));

type Props = {
  isCustomsDeclarationTypeE: boolean,
};

const PaymentConfirmation = ({ isCustomsDeclarationTypeE }: Props) => {
  const styles = useStyles();

  return (
    <>
      {isCustomsDeclarationTypeE ? (
        <Typography className={styles.text}>
          {THANKS_FOR_PAYING_CUSTOMS}
        </Typography>
      ) : (
        <>
          <Typography className={styles.text}>{THANKS_FOR_PAYING}</Typography>
          <Typography className={styles.text}>
            {DUTY_NOTIFICATION_MESSAGE}
          </Typography>
        </>
      )}
    </>
  );
};

export default compose(withTrack(trackable(DTY_PAYMENT_COMPLETE)))(
  PaymentConfirmation
);
