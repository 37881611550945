import * as DriverActions from '../../store/orm/Driver/actions';
import { getDriverData } from '../../store/orm/Driver/selectors';
import { createBulkLoadAsyncActions } from '../../utils/actionCreator';
import { RouteActions } from '../../store/orm/Route';
import { getRouteCode } from '../../models/parcel';
import { getParcelData } from '../../store/orm/Parcel/selectors';
import { getRouteData } from '../../store/orm/Route/selectors';

export const fetchCacheableDriverRelatedData = createBulkLoadAsyncActions(
  [
    (state, { match }) => getDriverData(state, match.params.driverId),
    (state, { match }) => {
      const parcel = getParcelData(state, match.params.parcelId);

      return getRouteData(state, getRouteCode(parcel));
    },
    () => null,
  ],
  [
    ({ match }) => dispatch =>
      dispatch(DriverActions.fetchDriver(match.params.driverId)),
    ({ match }) => (dispatch, getState) => {
      const parcel = getParcelData(getState(), match.params.parcelId);
      const routeCode = getRouteCode(parcel);
      if (routeCode) {
        return dispatch(RouteActions.fetchRouteByCode(routeCode));
      }
      // return empty object as route is not important on driver profile page
      return {};
    },
    ({ match }) => dispatch =>
      dispatch(DriverActions.fetchDriverFeedback(match.params.driverId)),
  ]
);
