import * as React from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { Loadable } from '@dpdgroupuk/fmx-ui/components/Loader';

import ErrorScreen from '../pages/ErrorScreen';

type Fetch = (props: any) => Promise<any>;
type DataPropMapper = string | (any => { [key: string]: any });
type Options = {
  deliveryOptions?: boolean,
  dataPropMapper?: DataPropMapper,
  notFoundComponent?: any,
};

type InjectedProps = {
  [key: string]: any,
};

export default function withFetch<Config>(fetch: Fetch, options?: Options) {
  return (
    Component: React.AbstractComponent<{| ...Config, ...InjectedProps |}>
  ): React.AbstractComponent<Config> => {
    const dataPropMapper = get(options, 'dataPropMapper');
    let propsMapper = dataPropMapper;

    if (typeof dataPropMapper === 'string') {
      propsMapper = data => ({ [dataPropMapper]: data });
    }

    return function WrapperComponent(props: Config) {
      const dispatch = useDispatch();
      const [promiseFn] = React.useState(() => () => dispatch(fetch(props)));

      const asyncDataProps = React.useMemo(
        () => ({
          promiseFn,
          persistData: false,
          options,
        }),
        [promiseFn]
      );

      return (
        <Loadable asyncDataProps={asyncDataProps} errorScreen={ErrorScreen}>
          {data => {
            const newProps = propsMapper
              ? {
                  ...props,
                  ...propsMapper(data),
                }
              : { ...props };
            return <Component {...newProps} fetchedData={data} />;
          }}
        </Loadable>
      );
    };
  };
}
