import type { Address } from '../../../../components/SearchAddress/types';
import type { AuthorizedParcel } from '../../../../types';

export const filterNeighborsAddresses = (
  parcel: AuthorizedParcel,
  addresses: Array<Address>
) => {
  const udprn = parcel.deliveryDetails.address.udprn;

  return udprn
    ? addresses.filter((address: Address) => address.udprn !== udprn)
    : addresses;
};
