import * as React from 'react';
import Button from '@dpdgroupuk/fmx-ui/components/Button';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useTracker } from '@dpdgroupuk/react-event-tracker';
import { goToReferenceEntry } from '../../historyActions';
import { DELIVERY_OPTION_CONFIRMATION } from '../../../constants/analytics';

const useStyles = makeStyles(({ breakpoints, typography }) => ({
  button: {
    marginTop: typography.pxToRem(40),
    marginBottom: typography.pxToRem(20),
    paddingLeft: typography.pxToRem(10),
    paddingRight: typography.pxToRem(10),
    [breakpoints.down('xs')]: {
      marginTop: typography.pxToRem(40),
      marginBottom: typography.pxToRem(10),
    },
  },
  label: {
    fontSize: typography.pxToRem(13.5),
    letterSpacing: typography.pxToRem(0.9),
    lineHeight: 1.2,
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(11),
    },
  },
}));

type Props = {
  className?: string,
  style?: Object,
};

export default ({ className, style }: Props) => {
  const styles = useStyles();
  const history = useHistory();
  const tracker = useTracker();

  const onClick = React.useCallback(() => {
    tracker.logEvent(DELIVERY_OPTION_CONFIRMATION.ON_TRACK_ANOTHER_PARCEL);
    return goToReferenceEntry(history);
  }, [history, tracker]);

  return (
    <Button
      classes={{ root: clsx(styles.button, className), label: styles.label }}
      style={style}
      onClick={onClick}
    >
      Track another parcel
    </Button>
  );
};
