import { values, toLower } from 'lodash';

import * as ENUM from '@dpdgroupuk/redback-enums';
import { toUpperStartCase } from '@dpdgroupuk/fmx-ui/utils/helpers';
import { getAddressWithPostcode } from '@dpdgroupuk/fmx-ui/utils/address';
import { formatMessage } from '@dpdgroupuk/fmx-ui/utils/string';

import { NEXT_STEPS, ROAD_MAP_STEP_TYPE } from './constants';
import {
  DELIVER_TO_,
  WE_WILL,
  IF_NOT_WE_WILL,
  TRY_ABOVE,
  DELIVER_TO_$,
  DELIVER_TO_NEAREST_PICKUP,
  SAFE_PLACE,
  NEIGHBOUR,
  NO_DELIVERY_ATTEMPTS,
  NO_SAFE_PLACE,
  NO_NEIGHBOUR,
} from '../../constants/message';
import { getDeliveryAddress, getNextSteps } from '../../models/parcel';

const createRoadMapPreferenceItem = ({
  type,
  text,
  value = '',
  action,
  actionText,
  greenOption,
}) => ({
  type,
  text,
  value,
  action,
  actionText,
  greenOption,
});

export const getParcelRoadMapPreferences = (parcel, availableActions) => {
  const nextSteps = getNextSteps(parcel);

  if (
    !values(NEXT_STEPS).includes(nextSteps.code) ||
    nextSteps.code === NEXT_STEPS.NO_NEXT_STEP
  ) {
    return [
      createRoadMapPreferenceItem({
        type: ROAD_MAP_STEP_TYPE.ITEM,
        text: NO_DELIVERY_ATTEMPTS,
      }),
    ];
  }

  const address = getDeliveryAddress(parcel);

  const showDeliverToSafePlacePreference =
    nextSteps.safePlace ||
    availableActions.includes(ENUM.PARCEL_ACTION_CODE.SFP);

  const showDeliverToNeighbourPreference =
    nextSteps.neighbourInstruction ||
    availableActions.includes(ENUM.PARCEL_ACTION_CODE.DTN);

  const showSecondAttemptPreferences =
    showDeliverToSafePlacePreference || showDeliverToNeighbourPreference;

  const showPickupPreferences = [
    NEXT_STEPS.FIRST_SHOP,
    NEXT_STEPS.SECOND_SHOP,
    NEXT_STEPS.FIRST_SECOND_SHOP,
  ].includes(nextSteps.code);

  const firsAttemptPreferences = [
    createRoadMapPreferenceItem({
      type: ROAD_MAP_STEP_TYPE.TITLE,
      text: WE_WILL,
    }),
    createRoadMapPreferenceItem({
      type: ROAD_MAP_STEP_TYPE.ITEM,
      text: DELIVER_TO_,
      value: getAddressWithPostcode(address),
    }),
  ];

  const secondAttemptPreferences = showSecondAttemptPreferences
    ? [
        createRoadMapPreferenceItem({
          type: ROAD_MAP_STEP_TYPE.TITLE,
          text: IF_NOT_WE_WILL,
        }),
        showDeliverToSafePlacePreference &&
          createRoadMapPreferenceItem({
            type: ROAD_MAP_STEP_TYPE.ITEM,
            text: formatMessage(DELIVER_TO_$, SAFE_PLACE),
            value: nextSteps.safePlace || NO_SAFE_PLACE,
            action: ENUM.PARCEL_ACTION_CODE.SFP,
          }),
        showDeliverToNeighbourPreference &&
          createRoadMapPreferenceItem({
            type: ROAD_MAP_STEP_TYPE.ITEM,
            text: formatMessage(DELIVER_TO_$, NEIGHBOUR),
            value: nextSteps.neighbourInstruction
              ? toUpperStartCase(toLower(nextSteps.neighbourInstruction))
              : NO_NEIGHBOUR,
            action: ENUM.PARCEL_ACTION_CODE.DTN,
          }),
      ].filter(item => !!item)
    : [];

  const pickupPreferences = showPickupPreferences
    ? [
        createRoadMapPreferenceItem({
          type: ROAD_MAP_STEP_TYPE.TITLE,
          text: TRY_ABOVE,
        }),
        createRoadMapPreferenceItem({
          type: ROAD_MAP_STEP_TYPE.ITEM,
          value: DELIVER_TO_NEAREST_PICKUP,
          greenOption: true,
        }),
      ]
    : [];

  return [
    ...firsAttemptPreferences,
    ...secondAttemptPreferences,
    ...pickupPreferences,
  ];
};
