import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { withTrack, withTrackProps } from '@dpdgroupuk/react-event-tracker';

import RateInfoButton from './RateInfoButton';
import Button from '../../../components/Rate/Button';
import type { Driver } from '../../../types';
import * as MESSAGE from '../../../constants/message';
import { goToReferenceEntry } from '../../historyActions';
import { RATE_INFO_NAMES } from '../constants';

import {
  RATE_DRIVER_CONFIRMATION,
  trackable,
} from '../../../constants/analytics';
const useStyles = makeStyles(({ typography, breakpoints }) => {
  return {
    textBlock: {
      marginTop: typography.pxToRem(32),
    },
    text: {
      fontSize: typography.pxToRem(18),
      lineHeight: 1.39,
      letterSpacing: typography.pxToRem(0.64),
    },
    subText: {
      fontSize: typography.pxToRem(18),
      lineHeight: 1.39,
      letterSpacing: typography.pxToRem(0.64),
      fontWeight: 300,
      marginTop: typography.pxToRem(30),
    },
    homeButtonBlock: {
      marginTop: typography.pxToRem(110),
    },
    rateInfoIcon: {
      [breakpoints.down('xs')]: {
        marginTop: typography.pxToRem(22),
      },
    },
  };
});

type Props = {
  driver: Driver,
  data: any,
  onHomeClick: Function,
};

const RateDriverComplete = ({ driver, data, onHomeClick }: Props) => {
  const styles = useStyles();

  const positiveRating = get(data, 'driverRating') > 2;
  const driverComplimentIcon =
    get(data, 'driverComplimentIcon') ||
    (positiveRating ? RATE_INFO_NAMES.INFORMED : RATE_INFO_NAMES.NOT_GREAT);
  const driverName = get(driver, 'driverName');

  return (
    <Grid>
      {driverComplimentIcon && (
        <RateInfoButton
          icon={driverComplimentIcon}
          className={styles.rateInfoIcon}
        />
      )}
      <div className={styles.textBlock}>
        <Typography className={styles.text}>
          {positiveRating ? MESSAGE.THANKS_POSITIVE : MESSAGE.THANKS}
        </Typography>
        <Typography className={styles.subText}>
          {positiveRating
            ? MESSAGE.DRIVER_FEEDBACK_POSITIVE.replace(
                '{{driverName}}',
                driverName
              )
            : MESSAGE.DRIVER_FEEDBACK_NEGATIVE.replace(
                '{{driverName}}',
                driverName
              )}
        </Typography>
      </div>
      <div className={styles.homeButtonBlock}>
        <Button onClick={onHomeClick}>{MESSAGE.HOME}</Button>
      </div>
    </Grid>
  );
};

export default compose(
  withRouter,
  withTrack(trackable(RATE_DRIVER_CONFIRMATION)),
  withProps(({ history }) => ({
    onHomeClick: () => {
      goToReferenceEntry(history);
    },
  })),
  withTrackProps({
    onHomeClick: RATE_DRIVER_CONFIRMATION.ON_HOME,
  })
)(RateDriverComplete);
