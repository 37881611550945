import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Link from '@material-ui/core/Link';
import MobileTable from '@dpdgroupuk/fmx-ui/components/MobileTable';

const useStyles = makeStyles(({ palette }) => ({
  link: {
    fontWeight: 300,
    lineHeight: 1.07,
    letterSpacing: 0.44,
    color: palette.text.primary,
  },
}));

type Props = {
  parcels: Array<SearchParcel>,
  onSelectParcel: (parcelCode: string) => void,
};

const SearchResultsMobileTable = ({ parcels, onSelectParcel }: Props) => {
  const styles = useStyles();
  const parcelRows = parcels.map(el => {
    return [
      {
        title: 'Parcel No.',
        value: (
          <Link
            variant="button"
            component="button"
            className={styles.link}
            onClick={() => onSelectParcel(el.parcelCode)}
          >
            {el.parcelCode}
          </Link>
        ),
      },
      {
        title: 'Collection Date',
        value: el.collectionDate,
      },
      {
        value: el.parcelStatus,
      },
    ];
  });

  return <MobileTable rows={parcelRows} />;
};

export default SearchResultsMobileTable;
