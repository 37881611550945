import * as React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  value: {
    height: `calc(100% - ${typography.pxToRem(5)})`,
    backgroundImage: 'linear-gradient(to right, #dc0032, #9f041b)',
    borderRadius: typography.pxToRem(10),
    fontSize: typography.pxToRem(20.4),
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 1,
  },
  wrapper: {
    height: typography.pxToRem(42),
    width: typography.pxToRem(37),

    [breakpoints.down('xs')]: {
      height: typography.pxToRem(36),
      width: typography.pxToRem(31),
    },
  },
  arrow: {
    width: typography.pxToRem(10),
    height: typography.pxToRem(10),
    backgroundImage: 'linear-gradient(to right, #dc0032, #9f041b)',
    transform: 'rotate(45deg)',
    position: 'relative',
    top: typography.pxToRem(-5),
    left: `calc(50% - ${typography.pxToRem(5)})`,
    zIndex: 0,
  },
  blue: {
    backgroundImage: 'linear-gradient(to right, #009adf, #0060a2 99%)',
  },
}));

type Props = {
  className?: string,
  children: number,
  color?: string,
};

const ArrowBadge = ({ className, children, color }: Props) => {
  const styles = useStyles();

  return (
    <div className={clsx(styles.wrapper, className)}>
      <div className={clsx(styles.value, { [styles.blue]: color === 'blue' })}>
        {children}
      </div>
      <div
        className={clsx(styles.arrow, { [styles.blue]: color === 'blue' })}
      />
    </div>
  );
};

export default ArrowBadge;
