import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { get } from 'lodash';

import FluidContainer from '@dpdgroupuk/fmx-ui/components/FluidContainer';
import ParcelInfo from '@dpdgroupuk/fmx-ui/components/ParcelInfo';
import TitleWithNumber from '@dpdgroupuk/fmx-ui/components/TitleWithNumber';

import ParcelEvents from '../../ParcelEvents';

const useStyles = makeStyles(({ breakpoints, typography }) => ({
  parcelInfoContentWrapper: {
    display: 'block',
    paddingTop: typography.pxToRem(40),
    [breakpoints.down('xs')]: {
      paddingLeft: typography.pxToRem(25),
      paddingRight: typography.pxToRem(25),
    },
  },
  customPadding: {
    paddingLeft: ({ paddingLeft }) => typography.pxToRem(paddingLeft) || 'auto',
    paddingRight: ({ paddingRight }) =>
      typography.pxToRem(paddingRight) || 'auto',

    [breakpoints.down('xs')]: {
      paddingLeft: ({ xsPaddingLeft, paddingLeft }) =>
        typography.pxToRem(xsPaddingLeft) ||
        typography.pxToRem(paddingLeft) ||
        'auto',
      paddingRight: ({ xsPaddingRight, paddingRight }) =>
        typography.pxToRem(xsPaddingRight) ||
        typography.pxToRem(paddingRight) ||
        'auto',
    },

    [breakpoints.down(410)]: {
      paddingLeft: ({ paddingLeft410, xsPaddingLeft }) =>
        typography.pxToRem(paddingLeft410) ||
        typography.pxToRem(xsPaddingLeft) ||
        typography.pxToRem(25),
      paddingRight: ({ paddingRight410, xsPaddingRight }) =>
        typography.pxToRem(paddingRight410) ||
        typography.pxToRem(xsPaddingRight) ||
        typography.pxToRem(25),
    },
  },
}));

type Props = {
  paddingLeft410?: number,
  paddingRight410?: Number,
  paddingLeft?: number,
  paddingRight?: number,
  xsPaddingLeft?: number,
  xsPaddingRight?: number,
  withParcelEvents?: Boolean,
};

const withParcelInfo = (options: Props) => Component => ({
  parcel,
  ...props
}: Object) => {
  const paddingLeft410 = get(options, 'paddingLeft410');
  const paddingRight410 = get(options, 'paddingRight410');
  const paddingLeft = get(options, 'paddingLeft');
  const paddingRight = get(options, 'paddingRight');
  const xsPaddingLeft = get(options, 'xsPaddingLeft');
  const xsPaddingRight = get(options, 'xsPaddingRight');
  const withParcelEvents = get(options, 'withParcelEvents');
  const styles = useStyles({
    paddingLeft410,
    paddingRight410,
    paddingLeft,
    paddingRight,
    xsPaddingRight,
    xsPaddingLeft,
  });

  return (
    <FluidContainer>
      <ParcelInfo
        logo={parcel.customerImageLogo}
        contentWrapperClassName={clsx(styles.parcelInfoContentWrapper, {
          [styles.customPadding]:
            paddingLeft410 ||
            paddingRight410 ||
            paddingLeft ||
            paddingRight ||
            xsPaddingLeft ||
            xsPaddingRight,
        })}
      >
        <TitleWithNumber title={`Parcel No. ${parcel.parcelNumber}`} />
        <Component {...props} parcel={parcel} />
      </ParcelInfo>
      {withParcelEvents && <ParcelEvents guest />}
    </FluidContainer>
  );
};

export default withParcelInfo;
