import api from './api';

export const rateDelivery = (parcelCode: string, body: any): Promise<any> =>
  api.post({
    path: `/parcels/${parcelCode}/rate`,
    body,
  });

export const fetchCallMeSlots = (): Promise<any> =>
  api.get({
    path: `/callme/slots`,
  });

export const bookCallMeSlot = (body: any): Promise<any> =>
  api.post({
    path: `/callme/slots`,
    body,
  });
