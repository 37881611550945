import * as React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import { isMobile } from '@dpdgroupuk/fmx-ui/utils/helpers';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import DeliveryInfo from '@dpdgroupuk/fmx-ui/components/DeliveryInfo';
import { ParcelOptionIcon } from '@dpdgroupuk/fmx-ui/components/OptionIcon';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { formatOrdinalNumber } from '@dpdgroupuk/fmx-ui/utils/string';
import { fullDateValue, getTimeArray } from '@dpdgroupuk/fmx-ui/utils/date';

import TrackAnotherParcelButton from '../../components/TrackAnotherParcelButton';
import type { AuthorizedParcel } from '../../../../types/Parcel';
import StepLayout from '../../components/StepLayout';
import MapContainer from '../../components/MapContainer';
import {
  backToDpdSiteHeader,
  backToGreenSiteHeader,
  getDpdAppHeader,
  goToParcelInfoHeader,
  goToParcelInfoSpeedDeal,
  goBackToDeliveryDetailsSpeedDeal,
  trackAnotherParcelHeader,
  trackAnotherParcelSpeedDeal,
} from '../../../controlActions';
import {
  DELIVERY_OPTION_CONFIRMATION,
  trackable,
} from '../../../../constants/analytics';
import CompleteParcelNumber from '../../components/CompleteParcelNumber';

type Props = {
  actionCode: string,
  parcel: AuthorizedParcel,
  data: Object,
  depotAddress: string,
  showMap: boolean,
  toggleMap: Function,
};

const CompleteStepComponent = ({
  actionCode,
  toggleMap,
  parcel,
  depotAddress,
  data = { diaryTime: '', day: '' },
  showMap,
}: Props) => {
  const { day, diaryTime } = data;
  const timeArray = getTimeArray(diaryTime);
  const { year, month, dayOfWeek, dayNumber } = fullDateValue(day);
  const titleHtml = `<p>As requested, your parcel will now be ready for collection from the depot on ${dayOfWeek} the ${formatOrdinalNumber(
    dayNumber
  )} ${month} ${year} between <b>${timeArray[0]} and ${timeArray[1]}</b></p>`;

  return (
    <StepLayout title="Your Collection depot" copyText={depotAddress}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ marginBottom: isMobile ? 70 : 120 }}
      >
        <MapContainer showMap={showMap} parcel={parcel} toggleMap={toggleMap}>
          <DeliveryInfo
            actionCode={actionCode}
            titleHtml={titleHtml}
            getOptionIcon={() => ParcelOptionIcon}
          />
          <CompleteParcelNumber parcelNumber={parcel.parcelNumber} />
          <TrackAnotherParcelButton />
        </MapContainer>
      </Grid>
    </StepLayout>
  );
};

const createSpeedDealControlActions = props => [
  trackAnotherParcelSpeedDeal({ ...props, replace: true }),
  goToParcelInfoSpeedDeal({ ...props, replace: true }),
  goBackToDeliveryDetailsSpeedDeal(props),
];

const createHeaderControlActions = props => [
  trackAnotherParcelHeader({ ...props, replace: true }),
  goToParcelInfoHeader({ ...props, replace: true }),
  backToDpdSiteHeader(props),
  backToGreenSiteHeader(props),
  getDpdAppHeader(props),
];

const CompleteStep = compose(
  withRouter,
  withModal,
  withPageControl({
    trackable: trackable(DELIVERY_OPTION_CONFIRMATION),
    headerMenuActions: createHeaderControlActions,
    speedDealActions: createSpeedDealControlActions,
  })
)(CompleteStepComponent);

export default CompleteStep;
