import {
  backToDpdSiteHeader,
  backToDpdSiteSpeedDeal,
  getDpdAppHeader,
  goToParcelInfoHeader,
  goToParcelInfoSpeedDeal,
  trackAnotherParcelHeader,
  goBackToDeliveryDetailsSpeedDeal,
} from '../../../controlActions';

export const createHeaderControlActions = props => [
  goToParcelInfoHeader(props),
  trackAnotherParcelHeader(props),
  backToDpdSiteHeader(props),
  getDpdAppHeader(props),
];

export const createSpeedDealControlActions = props => [
  backToDpdSiteSpeedDeal(props),
  goBackToDeliveryDetailsSpeedDeal(props),
  goToParcelInfoSpeedDeal(props),
];
