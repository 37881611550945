import * as React from 'react';
import ReactDOM from 'react-dom';
import ChatBotApp from '@dpdgroupuk/cbot-fmp';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import clsx from 'clsx';
import { SpinnerBox } from '@dpdgroupuk/fmx-ui/components/Spinner';
import { isMobile } from '@dpdgroupuk/fmx-ui/utils/helpers';

const DARK_BACKGROUND = '#1a1d21';

const useStyles = makeStyles(({ zIndex, palette, typography }) => ({
  wrapper: {
    '& .final-thanks .thanks': {
      color: palette.text.primary,
    },
    '& #fmp-react-draggable': {
      right: '0!important',
      bottom: `${typography.pxToRem(105)}!important`,
    },
    '& #chat-container div#chat-view.rating-view .receiver-line': {
      display: 'none',
    },
    '& #chat-bar-button': {
      display: 'none',
    },
    '& #chat-container': {
      border: 'none',
      boxShadow: 'none',
    },
    '& .rating-view div': {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    '& #chat-container #chat-view': {
      height: `calc(100vh - ${typography.pxToRem(290)})`,
    },
    '& #chat-container #chat-view.ending-view, #chat-container #chat-view.rating-view, #chat-container #chat-view.final-view, #chat-container #queue-view': {
      height: `calc(100vh - ${typography.pxToRem(140)})`,
    },
    '& #chat-container #chat-view.ending-view, #chat-container #chat-view.final-view, #chat-container #queue-view': {
      justifyContent: 'center',
    },
    '& #chat-container #chat-view.final-view .enjoy-rest-of-day': {
      marginTop: typography.pxToRem(25),
    },
    '& div#minimize-button img:nth-of-type(1)': {
      display: 'none',
    },
    '& .chat-bubble img': {
      maxWidth: 230,
    },
    '& #skip-rating > button, #submit-rating > button, #leave-queue > button:first-child, .try-again-button, .end-chat': {
      border: 'unset!important',
    },
    '& #chat-view, #suggestion-container': {
      scrollbarWidth: 'none',
      '-ms-overflow-style': 'none',
    },
    '& ::-webkit-scrollbar': {
      display: 'none',
    },
    '& #queue-view #leave-queue': {
      marginTop: typography.pxToRem(10),
    },
  },
  darkTheme: () =>
    palette.type === 'dark' && {
      '& #chat-header, #chat-suggestions, #chat-bar-button': {
        background: 'linear-gradient(to bottom, #4c535d 1%,#1a1d21 100%);',
      },
      '& #chat-container': {
        background: `${DARK_BACKGROUND}!important`,
      },
      '& #chat-container #chat-view': {
        background: DARK_BACKGROUND,
      },
      '& #chat-container #chat-input': {
        background: DARK_BACKGROUND,
        borderTop: '1px solid #979797',
        '& button': {
          background: DARK_BACKGROUND,
          color: '#1d9ad0',
        },
        '& input, input::placeholder': {
          background: DARK_BACKGROUND,
          color: '#fff',
        },
        '& input[type="text"]:active, input[type="text"]:focus': {
          backgroundColor: `${DARK_BACKGROUND}!important`,
          color: '#fff',
        },
      },
      '& .chat-icon': {
        border: 'unset!important',
      },
      '& .flat-white': {
        color: '#414042!important',
        background: '#fff!important',
      },
      '& #chat-container #queue-view button.flat-white-reverse': {
        background: 'transparent',
        color: '#fff',
        borderColor: '#979797',
      },
      '& #chat-container #chat-view.rating-view .rating-header, #msg-wait-time, #chat-container #queue-view': {
        color: '#fff',
      },
      '& #chat-container #chat-view.rating-view #text-rating-block': {
        color: '#fff',
      },
    },
  backdrop: {
    zIndex: isMobile ? zIndex.speedDial + 1 : zIndex.speedDial - 1,
    color: '#fff',
  },
  hide: {
    display: 'none',
  },
}));

const ChatBotComponent = ({ show, chatAvailable }: boolean) => {
  const styles = useStyles();

  return (
    <Backdrop className={clsx(styles.backdrop, !show && styles.hide)} open>
      {chatAvailable ? null : <SpinnerBox />}
      <Grid className={clsx(styles.wrapper, styles.darkTheme)}>
        <ChatBotApp />
      </Grid>
    </Backdrop>
  );
};

const ChatBotWrapper = ({ show, loadChat, chatAvailable }) => {
  return ReactDOM.createPortal(
    loadChat ? (
      <ChatBotComponent show={show} chatAvailable={chatAvailable} />
    ) : null,
    document.getElementById('dpdgroup-chatbot-fmp')
  );
};

export default ChatBotWrapper;
