import * as React from 'react';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import queryString from 'query-string';
import type {
  Parcel,
  PickupLocation,
  PickupLocationParcel,
} from '@dpdgroupuk/fmx-ui/types';
import Grid from '@material-ui/core/Grid';
import { formatDayOfWeekDayMonthOrdinal } from '@dpdgroupuk/fmx-ui/utils/date';
import { useTracker } from '@dpdgroupuk/react-event-tracker';
import { Typography } from '@material-ui/core';
import { API_HOST } from '../../../../../constants/app';
import PickupPassQRCode from './PickupPassQRCode';
import MultiplePickupPassQRCodes from './MultiplePickupPassQRCodes';
import { getExpiryDateText } from '../../../../ChangeDeliveryOption/pages/CollectFromPickupShop/utils';
import * as MESSAGE from '../../../../../constants/message';
import PickupShopCardWithOpeningHours from '../../../../ChangeDeliveryOption/pages/CollectFromPickupShop/components/PickupShopCardWithOpeningHours';
import AddToGoogleButton from '../../../../../assets/images/AddToGoogleWalletButton.png';
import { COLLECT_FROM_PICKUP } from '../../../../../constants/analytics';

const useStyles = makeStyles(({ palette, breakpoints, typography }) => ({
  dialogContainer: {
    alignItems: 'center',
    maxWidth: typography.pxToRem(375),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: typography.pxToRem(22),
    position: 'relative',
    '& .alice-carousel__stage': {
      '@media print': {
        display: 'flex',
        flexWrap: 'wrap',
        transform: 'translate3d(0px, 0px, 0px)!important',
      },
    },
    '& .alice-carousel__stage-item': {
      '@media print': {
        display: 'block',
        marginBottom: '20px',
      },
    },
    '& .alice-carousel__stage-item.__cloned': {
      '@media print': {
        display: 'none',
      },
    },
    '@media print': {
      maxWidth: '100%',
      marginLeft: 0,
    },
  },
  imgContainer: {
    backgroundColor: '#fff',
    position: 'relative',
    minHeight: typography.pxToRem(100),
    maxHeight: typography.pxToRem(210),
    maxWidth: typography.pxToRem(210),
    marginTop: typography.pxToRem(25),
    marginBottom: typography.pxToRem(20),
    border: `${typography.pxToRem(1)} solid #808285`,
    borderRadius: typography.pxToRem(3),
    '@media print': {
      minWidth: '210px',
      minHeight: '210px',
    },
  },
  img: {
    margin: typography.pxToRem(23),
    maxHeight: typography.pxToRem(164),
    maxWidth: typography.pxToRem(164),
    '@media print': {
      maxHeight: '300px',
      maxWidth: '300px',
    },
  },
  parcelInfo: {
    position: 'relative',
    marginTop: typography.pxToRem(30),
    fontFamily: "'Roboto'",
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
      lineHeight: 1.72,
    },
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(20),
      lineHeight: 1.69,
    },
  },
  arrowFull: {
    width: typography.pxToRem(13),
    height: typography.pxToRem(13),
  },
  text: {
    lineHeight: 1.53,
    letterSpacing: 0.44,
    fontWeight: 400,
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(13),
    },
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(15),
    },
    '@media print': {
      fontSize: '15px',
      color: '#000',
    },
  },
  fontWeight500: {
    fontWeight: '500',
  },
  container: {
    height: typography.pxToRem(200),
    width: typography.pxToRem(200),
    backgroundColor: 'white',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: palette.border.iconBlock,
  },
  arrowButton: {
    position: 'absolute',
    right: typography.pxToRem(3),
    top: typography.pxToRem(3),
    '@media print': {
      display: 'none',
    },
  },
  textContainer: {
    justifyContent: 'center',
    '& p:first-child': {
      marginRight: typography.pxToRem(5),
    },
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
      lineHeight: 1.72,
    },
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(20),
      lineHeight: 1.69,
    },
  },
  pinContainer: {
    marginBottom: typography.pxToRem(20),
  },
  closeIcon: {
    fill: palette.icon.blockIcon,
    fontSize: typography.pxToRem(22),
    '@media print': {
      display: 'none',
    },
  },
  shopCardContainer: {
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    [breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& #logo': {
      marginRight: typography.pxToRem(24),
      [breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
    direction: 'row',
    justify: 'flex-start',
    alignItems: 'center',
    [breakpoints.up('sm')]: {
      maxWidth: typography.pxToRem(550),
    },
    '@media print': {
      display: 'none',
    },
  },
  expiryAlert: {
    marginTop: typography.pxToRem(5),
    fontStyle: 'normal',
    fontWeight: '450',
    fontSize: '16px',
    lineHeight: '18px',
    letterSpacing: '0.571429px',
    color: '#E5444B',
    '@media print': {
      display: 'none',
    },
  },
  yourDpdPickup: {
    fontFamily: "'Roboto'",
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '18px',
    marginBottom: '20px',
    '@media print': {
      display: 'none',
    },
  },
  addToGoogleButton: {
    display: 'block',
    marginBottom: '20px',
    '@media print': {
      display: 'none',
    },
  },
  addToGoogleImg: {
    width: '190px',
    height: '48px',
  },
  addToAppleWalletButton: {
    marginTop: '20px',
    display: 'block',
    '@media print': {
      display: 'none',
    },
  },
}));

type Props = {
  parcel: Parcel & PickupLocationParcel,
  pickupLocation: PickupLocation,
  relatedParcels: string[],
};

const PickupPass = ({ parcel, pickupLocation, relatedParcels }: Props) => {
  const styles = useStyles();
  const tracker = useTracker();

  const OS = React.useMemo(() => {
    const userAgent = navigator.userAgent;

    if (
      userAgent.indexOf('iPhone') !== -1 ||
      userAgent.indexOf('iPad') !== -1
    ) {
      return 'iOS';
    }

    if (userAgent.indexOf('Android') !== -1) {
      return 'Android';
    }
  }, []);

  const pickupPassSrc = (scale = 2) =>
    `${API_HOST}/pickupLocations/${parcel.pickupLocationCode}/parcels/${parcel.parcelCode}?scale=${scale}`;

  const contactName = get(
    parcel,
    'deliveryDetails.notificationDetails.contactName'
  );

  const expiryDateString = React.useMemo(
    () => formatDayOfWeekDayMonthOrdinal(parcel.pickupExpiryDate),
    [parcel.pickupExpiryDate]
  );

  const expiryDateText = React.useMemo(
    () => getExpiryDateText(expiryDateString),
    [expiryDateString]
  );

  // Filter to only show QR codes for parcels that were delivered
  relatedParcels = relatedParcels.filter(
    parcel =>
      !!parcel.collect_from_pickup_type &&
      parcel.collect_from_pickup_status === '2'
  );

  const getAppleWalletPassUrl = React.useCallback(
    (parcelCode, pickupCollectCode) =>
      OS === 'iOS'
        ? `${API_HOST}/pickupPasses/apple?${queryString.stringify({
            parcelCode,
            pickupCollectCode,
            web: true,
          })}`
        : null,
    [OS]
  );

  return (
    <div>
      <Typography className={styles.expiryAlert}>{expiryDateText}</Typography>
      <div className={clsx(styles.dialogContainer, 'print')}>
        {pickupLocation.kind === 'SHP' ? (
          <Grid>
            <Grid className={styles.parcelInfo}>
              {contactName && (
                <Grid
                  container
                  direction="row"
                  className={styles.textContainer}
                >
                  <Typography className={styles.text}>Name:</Typography>
                  <Typography
                    className={clsx(styles.text, styles.fontWeight500)}
                  >
                    {contactName}
                  </Typography>
                </Grid>
              )}
              <Grid container direction="row" className={styles.textContainer}>
                <Typography className={styles.text}>Reference:</Typography>
                <Typography className={clsx(styles.text, styles.fontWeight500)}>
                  {parcel.parcelNumber}
                </Typography>
              </Grid>
              <Grid container direction="row" className={styles.textContainer}>
                <Typography className={styles.text}>
                  Number of parcels to collect:
                </Typography>
                <Typography className={clsx(styles.text, styles.fontWeight500)}>
                  {get(parcel, 'deliveryDetails.numberOfParcels')}
                </Typography>
              </Grid>
            </Grid>
            <PickupPassQRCode
              styles={styles}
              src={pickupPassSrc(3)}
              parcelNumber={parcel.parcelNumber}
              parcelPickupCode={parcel.pickupCollectCode}
              isShop={pickupLocation.kind === 'SHP'}
              appleWalletPassUrl={getAppleWalletPassUrl(
                parcel.parcelCode,
                parcel.pickupCollectCode
              )}
            />
          </Grid>
        ) : parcel.deliveryDetails.numberOfParcels > 1 ? (
          <MultiplePickupPassQRCodes>
            <div>
              <PickupPassQRCode
                styles={styles}
                src={pickupPassSrc(3)}
                contactName={contactName}
                parcelNumber={parcel.parcelNumber}
                parcelPickupCode={parcel.pickupCollectCode}
                isShop={pickupLocation.kind === 'SHP'}
                appleWalletPassUrl={getAppleWalletPassUrl(
                  parcel.parcelCode,
                  parcel.pickupCollectCode
                )}
              />
            </div>
            {relatedParcels.map(parcelInfo => {
              return (
                <div key={parcelInfo}>
                  <PickupPassQRCode
                    styles={styles}
                    src={pickupPassSrc(3)}
                    contactName={contactName}
                    parcelNumber={parcelInfo.parcelNumber}
                    parcelPickupCode={parcelInfo.pickup_collect_code}
                    isShop={pickupLocation.kind === 'SHP'}
                    appleWalletPassUrl={getAppleWalletPassUrl(
                      parcel.parcelCode,
                      parcelInfo.pickupCollectCode
                    )}
                  />
                </div>
              );
            })}
          </MultiplePickupPassQRCodes>
        ) : (
          <PickupPassQRCode
            styles={styles}
            src={pickupPassSrc(3)}
            parcelNumber={parcel.parcelNumber}
            parcelPickupCode={parcel.pickupCollectCode}
            isShop={pickupLocation.kind === 'SHP'}
            appleWalletPassUrl={getAppleWalletPassUrl(
              parcel.parcelCode,
              parcel.pickupCollectCode
            )}
          />
        )}
        {OS === 'Android' && (
          <>
            <Typography className={styles.yourDpdPickup}>
              {MESSAGE.ADD_PASS_TO_YOUR_WALLET}
            </Typography>
            <a
              className={styles.addToGoogleButton}
              href={`${API_HOST}/pickupPasses/google?${queryString.stringify({
                parcelCode: parcel.parcelCode,
                web: true,
              })}`}
              onClick={() => {
                tracker.logEvent(COLLECT_FROM_PICKUP.ON_ADD_TO_GOOGLE_WALLET);
              }}
            >
              <img src={AddToGoogleButton} alt="Add to Google Wallet" />
            </a>
          </>
        )}
      </div>
      <Typography className={styles.yourDpdPickup}>
        {MESSAGE.YOUR_PICKUP_$.replace(
          '{{kind}}',
          pickupLocation.kind === 'LKR' ? 'LOCKER' : 'SHOP'
        )}
      </Typography>

      <Grid className={styles.shopCardContainer}>
        <PickupShopCardWithOpeningHours
          pickupLocation={pickupLocation}
          pointChosen
        />
      </Grid>
    </div>
  );
};

export default PickupPass;
