import * as React from 'react';
import { Grid, useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { THEMES } from '@dpdgroupuk/fmx-ui/themes';
import { useModal } from '@dpdgroupuk/fmx-ui/components/Dialog';

import FluidContainer from '@dpdgroupuk/fmx-ui/components/FluidContainer';
import MobileTable from '@dpdgroupuk/fmx-ui/components/MobileTable';
import { getAddressWithPostcode } from '@dpdgroupuk/fmx-ui/utils/address';
import type { Parcel, ParcelEvent } from '@dpdgroupuk/fmx-ui/types';
import {
  formatDayMonthYear,
  formatDayMonthYearTime,
} from '@dpdgroupuk/fmx-ui/utils/date';

import {
  WHERE_HAS_MY_PARCEL_BEEN,
  ADDITIONAL_INFO,
} from '../../../constants/message';
import { getSenderRefs } from '../../../models/parcel';

const useStyles = makeStyles(({ palette, typography }) => ({
  container: {
    paddingTop: typography.pxToRem(100),
    paddingLeft: typography.pxToRem(10),
    paddingRight: typography.pxToRem(10),
  },
  containerGuest: {
    paddingTop: typography.pxToRem(10),
    paddingLeft: typography.pxToRem(10),
    paddingRight: typography.pxToRem(10),
    boxShadow: `0 ${typography.pxToRem(1)} ${typography.pxToRem(
      6
    )} 0 rgba(0, 0, 0, 0.16)`,
    background: 'rgb(255,255,255)',
    marginTop: typography.pxToRem(15),
    paddingBottom: typography.pxToRem(15),
  },
  containerGuestDark: {
    paddingTop: typography.pxToRem(10),
    paddingLeft: typography.pxToRem(10),
    paddingRight: typography.pxToRem(10),
    boxShadow: `0 ${typography.pxToRem(1)} ${typography.pxToRem(
      6
    )} 0 rgba(0, 0, 0, 0.16)`,
    background: 'rgba(26, 29, 33, 0.7)',
    marginTop: typography.pxToRem(15),
    paddingBottom: typography.pxToRem(15),
  },
  title: {
    fontSize: typography.pxToRem(17),
    letterSpacing: 0.44,
    lineHeight: 0.86,
    color: palette.text.secondary,
    marginBottom: typography.pxToRem(27),
    marginTop: typography.pxToRem(50),
  },
  titleGuest: {
    fontSize: typography.pxToRem(17),
    letterSpacing: 0.44,
    lineHeight: 0.86,
    color: palette.text.secondary,
    marginBottom: typography.pxToRem(27),
    marginTop: typography.pxToRem(25),
  },
  cellTitle: {
    width: '45%',
    fontSize: typography.pxToRem(17),
    letterSpacing: 0.38,
    lineHeight: 1.01,
    color: palette.text.secondary,
  },
  cellSubtitle: {
    width: '55%',
    textAlign: 'left',
    fontSize: typography.pxToRem(15),
    letterSpacing: 0.44,
    lineHeight: 1.53,
  },
  cellContainer: {
    marginTop: typography.pxToRem(13),
  },
  cellContainerPartner: {
    marginTop: typography.pxToRem(30),
    paddingBottom: typography.pxToRem(10),
  },
  link: {
    color: '#1d9ad0',
    textDecoration: 'underline',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
  },
  icon: {
    color: palette.icon.red,
  },
  modal: {
    textAlign: 'center',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
  },
}));

type Props = {
  parcel: Parcel,
  events: Array<ParcelEvent>,
  guest: Boolean,
};

const ParcelEventsMobile = ({ parcel, events, guest }: Props) => {
  const styles = useStyles();
  const activeTheme = useTheme();
  const isDarkMode = [THEMES.RED_DARK, THEMES.BLUE_DARK].includes(
    activeTheme.name
  );
  const { showModal } = useModal();

  const getClassName = (guest, isDarkMode) => {
    if (!guest) {
      return styles.container;
    }

    if (guest && isDarkMode) {
      return styles.containerGuestDark;
    }

    return styles.containerGuest;
  };

  const parcelRows = events.map(el => {
    return [
      {
        title: 'Date & Time.',
        value: formatDayMonthYearTime(el.eventDate),
      },
      {
        title: 'Location.',
        value: el.eventLocation,
      },
      {
        value: el.eventText,
      },
    ];
  });
  const additionalRows = [
    [
      {
        title: 'Consignment Number.',
        value: parcel.consignmentNumber,
      },
      {
        title: 'Sender`s Reference.',
        value: getSenderRefs(parcel),
      },
      {
        title: 'Shipped Date.',
        value: formatDayMonthYear(parcel.shipmentDate),
      },
    ],
  ];

  const mobileParcelEventsJSX = () => {
    return (
      <>
        <Grid className={getClassName(guest, isDarkMode)}>
          {!guest && (
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              className={styles.cellContainer}
            >
              <Typography className={styles.cellTitle}>
                Delivery Address:
              </Typography>
              <Typography className={styles.cellSubtitle}>
                {getAddressWithPostcode(parcel.deliveryDetails.address)}
              </Typography>
            </Grid>
          )}
          {!guest && (
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              className={styles.cellContainer}
            >
              <Typography className={styles.cellTitle}>
                Parcel Number:
              </Typography>
              <Typography className={styles.cellSubtitle}>
                {parcel.parcelNumber}
              </Typography>
            </Grid>
          )}
          {!guest &&
            parcel?.partnerRef[0]?.carrierRef &&
            parcel?.partnerRef[0]?.carrierURL && (
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                className={styles.cellContainer}
              >
                <Typography className={styles.cellTitle}>
                  Delivery Partner:
                </Typography>
                <Typography className={styles.cellSubtitle}>
                  <button
                    className={styles.link}
                    onClick={() => {
                      showModal({
                        type: 'CONFIRMATION',
                        title: (
                          <div className={styles.modal}>
                            <ReportProblemOutlinedIcon
                              fontSize="large"
                              className={styles.icon}
                            />
                            <Typography variant="h6">
                              You're leaving our website
                            </Typography>
                            <Typography>
                              Please note we are not responsible for the content
                              of external websites.
                            </Typography>
                          </div>
                        ),
                        confirmLabel: 'Continue',
                        rejectLabel: 'Stay',
                        onConfirm: () => {
                          window.open(parcel.partnerRef[0].carrierURL);
                        },
                      });
                    }}
                  >
                    {parcel.partnerRef[0].carrierRef}
                  </button>
                </Typography>
              </Grid>
            )}

          {guest &&
            parcel?.partnerRef[0]?.carrierRef &&
            parcel?.partnerRef[0]?.carrierURL && (
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                className={styles.cellContainerPartner}
              >
                <Typography className={styles.cellTitle}>
                  Delivery Partner:
                </Typography>
                <Typography className={styles.cellSubtitle}>
                  <button
                    className={styles.link}
                    onClick={() => {
                      showModal({
                        type: 'CONFIRMATION',
                        title: (
                          <div className={styles.modal}>
                            <ReportProblemOutlinedIcon
                              fontSize="large"
                              className={styles.icon}
                            />
                            <Typography variant="h6">
                              You're leaving our website
                            </Typography>
                            <Typography>
                              Please note we are not responsible for the content
                              of external websites.
                            </Typography>
                          </div>
                        ),
                        confirmLabel: 'Continue',
                        rejectLabel: 'Stay',
                        onConfirm: () => {
                          window.open(parcel.partnerRef[0].carrierURL);
                        },
                      });
                    }}
                  >
                    {parcel.partnerRef[0].carrierRef}
                  </button>
                </Typography>
              </Grid>
            )}
          <Typography className={guest ? styles.titleGuest : styles.title}>
            {WHERE_HAS_MY_PARCEL_BEEN}
          </Typography>
          <MobileTable rows={parcelRows} />
          <Typography className={styles.title}>{ADDITIONAL_INFO}</Typography>
          <MobileTable rows={additionalRows} />
        </Grid>
      </>
    );
  };

  return (
    <>
      {guest ? (
        mobileParcelEventsJSX()
      ) : (
        <FluidContainer>{mobileParcelEventsJSX()}</FluidContainer>
      )}
    </>
  );
};

export default ParcelEventsMobile;
