import {
  getLocationTracking,
  getLocationTrackingMap,
  getLocationChangeDelivery,
  getLocationDeliveryOptions,
  getLocationReferenceEntry,
  getRateLocation,
} from './Parcel/actions/location';

export const goToTrackingMap = ({ history, parcel }) => {
  history.push(getLocationTrackingMap({ parcelId: parcel.parcelCode }));
};

export const goToTracking = ({ history, parcel }) => {
  history.push(getLocationTracking({ parcelId: parcel.parcelCode }));
};

export const goToTrackingReplace = ({ history, parcel }) => {
  history.replace(getLocationTracking({ parcelId: parcel.parcelCode }));
};

export const goToChangeDelivery = ({ history, parcel, actionCode }) => {
  history.push(
    getLocationChangeDelivery({ parcelId: parcel.parcelCode, actionCode })
  );
};

export const goToDeliveryOptions = ({ parcel, history }) => {
  history.push(
    getLocationDeliveryOptions({
      parcelId: parcel.parcelCode,
      actionCode: '',
    })
  );
};

export const goToReferenceEntry = history => {
  history.replace(getLocationReferenceEntry());
};

export const goToRating = ({ parcel, history, deliveryRating }) => {
  history.push({
    ...getRateLocation(
      {
        parcelId: parcel.parcelCode,
      },
      {
        type: deliveryRating,
      }
    ),
  });
};
