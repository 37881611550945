import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/string/repeat';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import 'unorm';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { getApiOrigin } from './utils/config';

const root = document.getElementById('root');
const {
  REACT_APP_CHATBOT_URL,
  REACT_APP_SALESFORCE,
  REACT_APP_DPD_CLIENT,
  REACT_APP_DEPLOYMENT_ID,
  REACT_APP_ORGANIZATION_ID,
  REACT_APP_BUTTON_ID,
  REACT_APP_CHATBOT_USER_AGENT,
  REACT_APP_STATIC_GOOGLE_MAP_API_KEY,
} = process.env;
// This is chatbot configuration
window.dpdGroupApp = {
  esg: `${getApiOrigin()}/chat`,
  chatbot: REACT_APP_CHATBOT_URL,
  salesforce: REACT_APP_SALESFORCE,
  dpdSession: 'dpdSession',
  client: REACT_APP_DPD_CLIENT,
  parcelCode: '',
  deploymentId: REACT_APP_DEPLOYMENT_ID,
  organizationId: REACT_APP_ORGANIZATION_ID,
  buttonId: REACT_APP_BUTTON_ID,
  businessUnit: 2,
  initialDelay: 10,
  userAgent: REACT_APP_CHATBOT_USER_AGENT,
  apikey: REACT_APP_STATIC_GOOGLE_MAP_API_KEY,
};

// import of app should always be AFTER window.dpdGroupApp set
const { default: App } = require('./App');
if (root) {
  // this need for fine recaptcha work in IE 11
  const bodyElement = document.querySelector('body');
  bodyElement.setActive && bodyElement.setActive();
  ReactDOM.render(<App />, root);
} else {
  throw new Error('Root element is missing');
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
