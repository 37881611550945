import React from 'react';
import clsx from 'clsx';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';

import { withTrack } from '@dpdgroupuk/react-event-tracker';
import Button from '@dpdgroupuk/fmx-ui/components/Button';

import {
  I_WANT_MY_PARCEL,
  RETURN_PARCEL_BUTTON,
  RETURN_PARCEL_CONFIRMATION,
} from '../../../../../constants/message';
import {
  DTY_CANCELLATION_MODAL,
  trackable,
} from '../../../../../constants/analytics';

const useStyles = makeStyles(({ palette, typography, breakpoints }) => ({
  dialog: {
    maxWidth: typography.pxToRem(433),
    backgroundColor: palette.background.modal,
  },
  dialogTitle: {
    padding: `${typography.pxToRem(54)} ${typography.pxToRem(
      60
    )} ${typography.pxToRem(42)}`,
    color: palette.text.primary,
    textAlign: 'center',
    [breakpoints.down('xs')]: {
      padding: `${typography.pxToRem(29)} ${typography.pxToRem(
        66
      )} ${typography.pxToRem(36)}`,
    },
    [breakpoints.down(440)]: {
      padding: `${typography.pxToRem(29)} ${typography.pxToRem(
        46
      )} ${typography.pxToRem(36)}`,
    },
  },
  dialogTitleText: {
    fontSize: typography.pxToRem(20.5),
    fontWeight: 300,
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(16),
    },
  },
  dialogActions: {
    padding: `0 ${typography.pxToRem(60)} ${typography.pxToRem(34)}`,
    flexDirection: 'column',
    [breakpoints.down('xs')]: {
      padding: `0 ${typography.pxToRem(66)} ${typography.pxToRem(30)}`,
    },
    [breakpoints.down(440)]: {
      padding: `0 ${typography.pxToRem(36)} ${typography.pxToRem(30)}`,
    },
  },
  dialogActionsSpacing: {
    '& > :not(:first-child)': {
      marginLeft: 0,
    },
  },
  closeButton: {
    position: 'absolute',
    top: typography.pxToRem(3),
    right: typography.pxToRem(3),
    color: '#808285',
  },
  button: {
    width: '100%',
    height: typography.pxToRem(48),
    paddingRight: 0,
    paddingLeft: 0,
    marginBottom: typography.pxToRem(24),
    background: palette.gradient.reject,
    [breakpoints.down('xs')]: {
      height: typography.pxToRem(37),
    },
  },
  rejectButton: {
    [breakpoints.down('xs')]: {
      paddingTop: typography.pxToRem(13),
    },
  },
  confirmButton: {
    background: palette.gradient.confirm,
  },
  rejectButtonLabel: {
    fontSize: typography.pxToRem(14),
    letterSpacing: typography.pxToRem(1),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(12),
    },
  },
  confirmButtonLabel: {
    fontSize: typography.pxToRem(14),
    letterSpacing: typography.pxToRem(1),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(12),
    },
  },
}));

type Props = {
  open: boolean,
  onClose: Function,
  onConfirm: Function,
};

const ConfirmationDialog = ({ open, onClose, onConfirm }: Props) => {
  const styles = useStyles();

  return (
    <Dialog
      open={open}
      classes={{ paperWidthSm: styles.dialog }}
      onClose={onClose}
    >
      <DialogTitle classes={{ root: styles.dialogTitle }}>
        <Typography className={styles.dialogTitleText}>
          {RETURN_PARCEL_CONFIRMATION}
        </Typography>
        <IconButton
          aria-label="close"
          className={styles.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogActions
        classes={{
          root: styles.dialogActions,
          spacing: styles.dialogActionsSpacing,
        }}
      >
        <Button
          classes={{
            root: clsx(styles.button, styles.confirmButton),
            label: styles.confirmButtonLabel,
          }}
          onClick={onConfirm}
        >
          {RETURN_PARCEL_BUTTON}
        </Button>
        <Button
          classes={{
            root: [styles.button, styles.rejectButton],
            label: styles.rejectButtonLabel,
          }}
          onClick={onClose}
        >
          {I_WANT_MY_PARCEL}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withTrack(trackable(DTY_CANCELLATION_MODAL))(ConfirmationDialog);
