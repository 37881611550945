import * as React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import withParcel from '../../HOCs/withParcel';
import { fetchDeliveryActions } from './actions';
import withFetch from '../../HOCs/withFetch';
import DeliveryOptionsRouter from './routes';

const DeliveryOptionsComponent = () => <DeliveryOptionsRouter />;

const DeliveryOptions = compose(
  withRouter,
  withParcel(),
  withFetch(fetchDeliveryActions)
)(DeliveryOptionsComponent);

export default DeliveryOptions;
