// @flow
import React from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PhoneOutlined } from '@material-ui/icons';
import { validateContactNumberWithMessage } from '@dpdgroupuk/fmx-ui/utils/validation';
import SubmitButton from '@dpdgroupuk/fmx-ui/components/SubmitButton';
import Input from '@dpdgroupuk/fmx-ui/components/Input';
import InputErrorMessage from '@dpdgroupuk/fmx-ui/components/Input/InputErrorMessage';

import * as MESSAGE from '../../constants/message';

const useStyles = makeStyles(({ breakpoints, palette, typography }) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [breakpoints.down('sm')]: {
      padding: `${typography.pxToRem(0)} ${typography.pxToRem(40)}`,
    },
  },
  contactNumberInput: {
    marginTop: typography.pxToRem(10),
    display: 'flex',
    direction: 'row',
    justify: 'space-between',
    width: '45%',
    [breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  icon: {
    fill: palette.primary.main,
    verticalAlign: 'middle',
    marginRight: typography.pxToRem(10),
    alignSelf: 'center',
  },
  submitButton: {
    marginTop: typography.pxToRem(30),
  },
  submitText: {
    fontSize: typography.pxToRem(13.5),
    lineHeight: 1.2,
    letterSpacing: typography.pxToRem(0.9),
  },
  bestNumberText: {
    fontSize: typography.pxToRem(16),
    letterSpacing: typography.pxToRem(0.7),
    lineHeight: 1.69,
    color: palette.text.primary,
    marginBottom: typography.pxToRem(20),
    maxWidth: '35%',
    [breakpoints.down('xs')]: {
      marginBottom: typography.pxToRem(19),
      maxWidth: '80%',
    },
  },
  field: {
    height: typography.pxToRem(29),
    '& .MuiInputBase-root': {
      borderRadius: typography.pxToRem(4.5),
      border: `solid ${typography.pxToRem(0.5)} #979797`,
      fontSize: typography.pxToRem(10),
      height: typography.pxToRem(29),
      boxSizing: 'border-box',
      padding: `${typography.pxToRem(8)} ${typography.pxToRem(6)}`,
      letterSpacing: 0.37,
    },
  },
  fieldError: {
    '& .MuiInputBase-root': {
      border: `solid ${typography.pxToRem(0.5)} ${palette.error.main}`,
    },
  },
}));

type Props = {
  originalContactNumber: string,
  onSubmit: Function,
};

const ContactNumberInputForm = ({ originalContactNumber, onSubmit }: Props) => {
  const styles = useStyles();
  const [contactNumber, setContactNumber] = React.useState(
    originalContactNumber
  );
  const [error, setError] = React.useState(null);

  const onChangeContactNumber = e => {
    const newNumber = e.target.value;
    setError(null);
    setContactNumber(newNumber);
    return setError(validateContactNumberWithMessage(newNumber));
  };

  const onSubmitNumber = () => onSubmit(contactNumber);

  return (
    <div className={styles.container}>
      <Typography className={styles.bestNumberText}>
        {MESSAGE.BEST_NUMBER_MESSAGE}
      </Typography>

      <div className={styles.contactNumberInput}>
        <PhoneOutlined className={styles.icon} />
        <Input
          placeholder={MESSAGE.CONTACT_NUMBER_PLACEHOLDER}
          value={contactNumber}
          onChange={onChangeContactNumber}
          withError={error}
          fullWidth
          className={clsx(styles.field, {
            [styles.fieldError]: !!error,
          })}
          inputProps={{ maxLength: 15 }}
        >
          {error && <InputErrorMessage message={error} />}
        </Input>
      </div>

      <SubmitButton
        onSubmit={onSubmitNumber}
        isSubmitHidden={!contactNumber || error}
        className={styles.submitButton}
      >
        <span className={styles.submitText}>Submit</span>
      </SubmitButton>
    </div>
  );
};

export default ContactNumberInputForm;
